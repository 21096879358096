import { Box, Modal } from "@mui/material";
import {
  CustomButtonDisabled,
  CustomButtonGreen,
  CustomButtonRed,
  CustomButtonYellow,
} from "../../../styles/button";
import { ReservationSearchData } from "../../../system/types/Reservation";
import { AccommodationApi } from "../../../system/api/AccommodationApi";
import { ErrorHandler } from "../../../system/ApiService";
import { ModalStyle } from "../../../styles/theme";
import { useState } from "react";
import CheckInModalReport from "../../MyPage/Modal/CheckInModalReport";
import CheckOutModalReport from "../../MyPage/Modal/CheckOutModalReport";
import AlertModal from "../AlertModal";
import { GREENCONTENTS, REDTITLE } from "../../../styles/color";
import Swal from "sweetalert2";
import { useSetRecoilState } from "recoil";
import { ReservationStatusAtom } from "../../../system/atoms";

interface AccommodationButtonProps {
  data: ReservationSearchData;
}

function AdminAccommodationButton({ data }: AccommodationButtonProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = (status: string) => {
    setModalStatus(status);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [modalStatus, setModalStatus] = useState("");
  const setReservationStatus = useSetRecoilState(ReservationStatusAtom);

  const cancelReservation = (id: number, status: string) => {
    if (status === "예약중") {
      Swal.fire({
        title: "정말 취소하시겠습니까?",
        text: "선택하신 휴양소 예약이 취소됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          AccommodationApi.deleteReservation(id)
            .then(() => {
              AlertModal("success", "예약이 취소되었습니다.");
              setReservationStatus(id);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    } else {
      Swal.fire({
        title: "정말 취소하시겠습니까?",
        text: "선택하신 휴양소 예약이 취소됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          AccommodationApi.deleteReservation(id)
            .then(() => {
              AlertModal("success", "예약이 취소되었습니다.");
              setReservationStatus(id);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  };

  function openMemo(message: string) {
    AlertModal("memo", "관리자 예약 메모", message);
  }

  return (
    <>
      {(data.status === "당첨" || data.status === "예약중") && (
        <CustomButtonRed
          onClick={() => cancelReservation(data.reservationId, data.status)}
        >
          예약 취소
        </CustomButtonRed>
      )}
      {data.status === "미당첨" && (
        <CustomButtonDisabled>추첨 완료</CustomButtonDisabled>
      )}
      {data.status === "이용중" && (
        <CustomButtonGreen onClick={() => handleOpen("입실열람")}>
          설문 열람
        </CustomButtonGreen>
      )}
      {data.status === "이용종료" && (
        <>
          {" "}
          <CustomButtonGreen onClick={() => handleOpen("입실열람")}>
            설문 열람
          </CustomButtonGreen>
          <CustomButtonDisabled>보고 제출 전</CustomButtonDisabled>
        </>
      )}
      {data.status === "보고완료" && (
        <>
          {" "}
          <CustomButtonGreen onClick={() => handleOpen("입실열람")}>
            설문 열람
          </CustomButtonGreen>
          <CustomButtonGreen onClick={() => handleOpen("보고열람")}>
            보고 열람
          </CustomButtonGreen>
        </>
      )}

      {data.adminReservation && data.memo && (
        <CustomButtonYellow onClick={() => openMemo(data.memo)}>
          관리자 메모
        </CustomButtonYellow>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <div>
            {modalStatus === "입실열람" && (
              <CheckInModalReport
                handleClose={handleClose}
                data={data.checkIn}
              />
            )}
            {modalStatus === "보고열람" && (
              <CheckOutModalReport
                handleClose={handleClose}
                data={data.checkOut}
              />
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AdminAccommodationButton;
