import { Grid } from "@mui/material";
import AdminTitle from "./AdminTitle";
import AccommodationInfo from "./Accommodation/AccommodationInfo";
import AccommodationReservationCheck from "./Accommodation/AccommodationReservationCheck";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { MaxWidth } from "../../styles/theme";

function AdminAccommodationTable() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MaxWidth>
          <AdminTitle titleName="휴양소" />
        </MaxWidth>
      </Grid>

      <Grid item xs={12}>
        <MaxWidth>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="예약 조회" value={0} />
            <Tab label="휴양소 정보" value={1} />
          </Tabs>
          {value === 0 && <AccommodationReservationCheck />}
          {value === 1 && <AccommodationInfo />}
        </MaxWidth>
      </Grid>
    </Grid>
  );
}

export default AdminAccommodationTable;
