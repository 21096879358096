import { Grid } from "@mui/material";
import { MainTitle, MaxWidth } from "../../styles/theme";

function QnaTable() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MaxWidth>
          <MainTitle>문의하기</MainTitle>
        </MaxWidth>
      </Grid>
    </Grid>
  );
}

export default QnaTable;
