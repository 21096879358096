import * as React from "react";
import { CrossEditorParams } from "./types";

declare const NamoSE: any;

export interface CrossEditorProps {
  name: string;
  baseUrl?: string;
  value?: string;
  params?: CrossEditorParams;
  onLoaded?: (e: Event, editor: any) => void;
}

export class CrossEditor extends React.Component<CrossEditorProps> {
  private _instance: any;

  get editorRef(): any {
    if (typeof this._instance === "undefined") {
      throw new Error("에디터 객체가 생성되지 않았습니다");
    }
    return this._instance;
  }

  get containerId(): string {
    return `${this.props.name}-container`;
  }

  componentDidMount(): void {
    this._startEditor();
  }

  render(): React.ReactElement {
    return <div id={this.containerId}></div>;
  }

  private _startEditor() {
    const { name, baseUrl, value, params = {}, onLoaded } = this.props;
    const $container = document.getElementById(this.containerId);

    if (!$container) {
      throw new Error("Load");
    }

    this._instance = new NamoSE(name);

    if (!params.event) {
      params.event = {};
    }
    params.NoSpellCheck = true;
    // params에 설정된 OnInitComplete를 백업
    const { OnInitCompleted } = params.event;

    params.event.OnInitCompleted = (e: Event): void => {
      this._instance.SetValue(value || "");

      if (OnInitCompleted) {
        OnInitCompleted(e);
      }

      if (onLoaded) {
        console.log("onload");
        onLoaded(e, this.editorRef);
      }
    };

    this._instance.params = params;
    // CrossEditor의 script를 불러올 때 사용하는 base 경로를 지정해야 한다.
    if (baseUrl) {
      this._instance.pBaseURL = baseUrl;
    }

    this._instance.params.ParentEditor = $container;

    this._instance.params.UploadFileExecutePath = `${process.env.REACT_APP_EDITOR_IMAGE_URL}`;
    this._instance.params.UploadEtcFileExecutePath = `${process.env.REACT_APP_EDITOR_IMAGE_URL}`;
    this._instance.params.PluginModeExecutePath = `${process.env.REACT_APP_EDITOR_IMAGE_URL}`;

    // Editor 시작
    this._instance.EditorStart();
  }
}

export default CrossEditor;
