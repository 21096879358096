import { useRecoilValue } from "recoil";
import { MyDataAtom } from "../../../system/atoms";
import Swal from "sweetalert2";
import {
  GRAYPALECONTENTS,
  GREENACCENT,
  GREENCONTENTS,
  ORANGECONTENTS,
  REDTITLE,
} from "../../../styles/color";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { ReminderApi } from "../../../system/api/ReminderApi";
import AlertModal from "../AlertModal";
import { ErrorHandler } from "../../../system/ApiService";
import {
  ReminderData,
  ReminderProblemList,
} from "../../../system/types/Reminder";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Fragment, useEffect, useState } from "react";
import { TooltipText } from "../../../styles/reminderStyle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Modal } from "@mui/material";
import { ModalStyle } from "../../../styles/theme";
import ReminderModal from "../../Reminder/ReminderModal";
interface ReminderButtonProps {
  data: ReminderData;
  refreshData?: () => void;
}

function ReminderButton({ data, refreshData }: ReminderButtonProps) {
  const myData = useRecoilValue(MyDataAtom);
  const Loading = useLoadingDispatch();
  const [problemData, setProblemData] = useState<ReminderProblemList[]>([]);

  useEffect(() => {
    setProblemData(
      data.problems.filter((dt: ReminderProblemList) => dt.isChecked === false)
    );
  }, []);

  const cancelReminder = (id: number | undefined) => {
    if (id !== undefined) {
      Swal.fire({
        title: "정말 취소하시겠습니까?",
        text: "선택하신 일정 알림이 취소됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          Loading({ type: "LOADING" });
          ReminderApi.deleteReminder(id)
            .then(() => {
              AlertModal("success", "해당 일정 알림이 취소되었습니다.");
              if (refreshData !== undefined) {
                refreshData();
              }
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              Loading({ type: "COMPLETE" });
            });
        }
      });
    }
  };

  const checkReminder = (id: number | undefined) => {
    if (id !== undefined) {
      Swal.fire({
        title: "일정을 확인하셨습니까?",
        text: "선택하신 일정이 확인 처리됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          Loading({ type: "LOADING" });
          ReminderApi.checkReminder(id)
            .then(() => {
              AlertModal("success", "해당 일정이 확인 처리되었습니다.");
              if (refreshData !== undefined) {
                refreshData();
              }
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              Loading({ type: "COMPLETE" });
            });
        }
      });
    }
  };
  // 모달
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const movetoModal = () => {
    handleOpen();
  };

  return (
    <>
      {/* 경고 버튼 */}

      {problemData.length > 0 && (
        <>
          {problemData[0].status === "WARNING" ? (
            <Tooltip
              title={
                <Fragment>
                  <TooltipText>{problemData[0].message}</TooltipText>
                </Fragment>
              }
            >
              <IconButton sx={{ cursor: "default !important" }}>
                <ReportProblemIcon
                  sx={{ color: ORANGECONTENTS, cursor: "default" }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <Fragment>
                  <TooltipText>{problemData[0].message}</TooltipText>
                </Fragment>
              }
            >
              <IconButton sx={{ cursor: "default !important" }}>
                <ReportProblemIcon
                  sx={{ color: REDTITLE, cursor: "default" }}
                />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      {/* 확인, 수정, 취소 버튼 */}
      {data.pic?.id === myData.id ||
      data.deputyPic?.id === myData.id ||
      data.manager?.id === myData.id ||
      data.deputyManager?.id === myData.id ? (
        <>
          {data.isChecked ? (
            <Tooltip
              title={
                <Fragment>
                  <TooltipText>
                    확인한 사람 : {data?.checkedEmployeeName}
                  </TooltipText>
                  <TooltipText>확인 날짜 : {data?.checkedDate}</TooltipText>
                </Fragment>
              }
            >
              <IconButton sx={{ cursor: "default !important" }}>
                <CheckCircleIcon
                  sx={{ color: GREENACCENT, cursor: "default" }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="미확인">
              <IconButton onClick={() => checkReminder(data.id)}>
                <CheckCircleIcon sx={{ color: REDTITLE }} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="수정">
            <IconButton onClick={() => movetoModal()}>
              <EditIcon sx={{ color: GRAYPALECONTENTS }} />
            </IconButton>
          </Tooltip>

          {data.status === "사용중" && (
            <>
              <Tooltip title="사용 안 함">
                <IconButton onClick={() => cancelReminder(data.id)}>
                  <DeleteIcon sx={{ color: GRAYPALECONTENTS }} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      ) : (
        <>
          {data.isChecked ? (
            <Tooltip
              title={
                <Fragment>
                  <TooltipText>
                    확인한 사람 : {data?.checkedEmployeeName}
                  </TooltipText>
                  <TooltipText>확인 날짜 : {data?.checkedDate}</TooltipText>
                </Fragment>
              }
            >
              <IconButton sx={{ cursor: "default !important" }}>
                <CheckCircleIcon
                  sx={{ color: GREENACCENT, cursor: "default" }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="미확인">
              <IconButton sx={{ cursor: "default !important" }}>
                <CheckCircleIcon sx={{ color: REDTITLE }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <ReminderModal
            handleClose={handleClose}
            refreshData={refreshData}
            detailedData={data}
          />
        </Box>
      </Modal>
    </>
  );
}
export default ReminderButton;
