import { Box, Grid, Modal } from "@mui/material";
import {
  VehicleGuideContentBox,
  VehicleGuideContentsAlign,
  VehicleGuideContentsBox,
  VehicleGuideManual,
  VehicleGuideTitle,
  VehicleRightBoxTitleAlign,
  VehicleRightBoxTitle,
} from "../../styles/vehicleStyle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { VehiclePc } from "../../pages/Layout/ResponsiveScreen";
import { useState } from "react";
import {
  AppliedItem,
  MainTitle,
  MidiumModalStyle,
  RaffleContent,
} from "../../styles/theme";
import { X } from "@phosphor-icons/react";
import DestinationBookmark from "./Bookmark/DestinationBookmark";
import VehicleBookmark from "./Bookmark/VehicleBookmark";

function VehicleGuideBox() {
  const [open, setOpen] = useState(false);
  const [modalState, setModalState] = useState("");
  const handleOpen = (state: string) => {
    setModalState(state);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <VehicleRightBoxTitleAlign
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <VehicleRightBoxTitle item>나의 정보</VehicleRightBoxTitle>
      </VehicleRightBoxTitleAlign>
      <VehicleRightBoxTitleAlign
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <VehicleGuideContentBox
          item
          onClick={() => {
            handleOpen("destination");
          }}
        >
          <VehicleGuideContentsBox>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid>
                <VehicleGuideTitle>자주 사용하는</VehicleGuideTitle>
                <VehicleGuideTitle>목적지</VehicleGuideTitle>
                <VehicleGuideContentsAlign>
                  바로가기 <ChevronRightIcon />
                </VehicleGuideContentsAlign>
              </Grid>
              <VehiclePc>
                <Grid>
                  <VehicleGuideManual src="/images/VEHICLE/maps.png"></VehicleGuideManual>
                </Grid>
              </VehiclePc>
            </Grid>
          </VehicleGuideContentsBox>
        </VehicleGuideContentBox>
        <VehicleGuideContentBox
          item
          onClick={() => {
            handleOpen("vehicle");
          }}
        >
          <VehicleGuideContentsBox>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid>
                <VehicleGuideTitle>자주 사용하는</VehicleGuideTitle>
                <VehicleGuideTitle>차량</VehicleGuideTitle>
                <VehicleGuideContentsAlign>
                  바로가기 <ChevronRightIcon />
                </VehicleGuideContentsAlign>
              </Grid>
              <VehiclePc>
                <Grid>
                  <VehicleGuideManual src="/images/VEHICLE/cars.png"></VehicleGuideManual>
                </Grid>
              </VehiclePc>
            </Grid>
          </VehicleGuideContentsBox>
        </VehicleGuideContentBox>
      </VehicleRightBoxTitleAlign>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MidiumModalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RaffleContent>
                <MainTitle>
                  {modalState === "destination"
                    ? "자주 사용하는 목적지"
                    : "자주 사용하는 차량"}
                </MainTitle>
                <AppliedItem onClick={handleClose}>
                  <X size={32} />
                </AppliedItem>
              </RaffleContent>
            </Grid>
            <Grid container item xs={12} mt="10px">
              {modalState === "destination" ? (
                <DestinationBookmark />
              ) : (
                <VehicleBookmark />
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default VehicleGuideBox;
