import { ReactElement, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Content from "../pages/Layout/Content";
import Footer from "../pages/Layout/Footer";
import Header from "../pages/Layout/Header";
import LandingMain from "../pages/Layout/LandingMain";
import { BodyWrap } from "../styles/headerStyle";
import {
  AdminRoutes,
  CommonRoutes,
  QnaRoutes,
  AccommodationRoutes,
  MypageRoutes,
  ReminderRoutes,
  VehicleRoutes,
} from "../system/types/type";
import AdminMain from "../pages/Admin/AdminMain";
import QnaMain from "../pages/Qna/QnaMain";

import MyPage from "../pages/MyPage/MyPage";
import {
  MainTitle,
  RaffleContent,
  SmallModalStyle,
  theme,
} from "../styles/theme";
import { ThemeProvider } from "@mui/material/styles";

import QnaBoard from "../pages/Qna/QnaBoard";
import AccommodationMain from "../pages/Accommodation/AccommodationMain";
import OpenHeader from "../pages/Layout/OpenHeader";
import { Pc } from "../pages/Layout/ResponsiveScreen";
import AdminEmployee from "../pages/Admin/AdminEmployee";
import AdminAccommodation from "../pages/Admin/AdminAccommodation";
import AdminQna from "../pages/Admin/AdminQna";
import AdminAddEmployee from "../pages/Admin/AdminAddEmployee";
import axios from "axios";
import { useRecoilState } from "recoil";
import {
  BranchEmployeeCheck,
  BranchEmployeeDataAtom,
  MyDataAtom,
  ReminderRoleAtom,
  RoleAtom,
  RoleCheckAtom,
  loadingModalAtom,
} from "../system/atoms";
import Loading from "../components/Common/Loading";
import ReminderMain from "../pages/Reminder/ReminderMain";
import ReminderModal from "../components/Reminder/ReminderModal";
import VehicleMain from "../pages/Vehicle/Vehicle";
import { Box, Grid, Modal } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { EmployeeApi } from "../system/api/EmployeeApi";
import { ErrorHandler } from "../system/ApiService";
import AlertModal from "../components/Common/AlertModal";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { CustomButtonGreen } from "../styles/button";
import AdminVehicle from "../components/Admin/AdminVehicle";
import AvailableVehicleCalendar from "../pages/Vehicle/AvailableVehicleCalendar";

function Routes(): ReactElement {
  const [isListHover, setIsListHover] = useState(false);
  const locationNow = useLocation();
  const [, setLoadingState] = useRecoilState(loadingModalAtom);
  const { accounts } = useMsal();
  const [RoleCheck, setRoleCheck] = useRecoilState(RoleCheckAtom);
  const [, setReminderRoleCheck] = useRecoilState(ReminderRoleAtom);
  const [, setAccountData] = useRecoilState(RoleAtom);
  const [myData, setMyData] = useRecoilState(MyDataAtom);

  useEffect(() => {
    setAccountData(accounts);
    //Role
    if (accounts[0]?.idTokenClaims?.roles !== undefined) {
      setRoleCheck(accounts[0]?.idTokenClaims?.roles.includes("Admin"));
      setReminderRoleCheck(
        accounts[0]?.idTokenClaims?.roles.includes("Admin" || "Reminder.Admin")
      );
    }
    //axios 호출시 인터셉트
    axios.interceptors.request.use(
      (request) => {
        setLoadingState({ isLoading: true, text: "로딩중" });
        return request;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
    //axios 호출 종료시 인터셉트
    axios.interceptors.response.use(
      (response) => {
        setLoadingState({ isLoading: false, text: "종료" });
        return response;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }, [
    accounts,
    setAccountData,
    setLoadingState,
    setReminderRoleCheck,
    setRoleCheck,
  ]);

  // 수정
  useEffect(() => {
    if (!myData.id) {
      EmployeeApi.checkOffice365().then((res) => {
        if (!res.data.branchEmployeeCheck) {
          EmployeeApi.getmyInfo()
            .then((res) => {
              setMyData(res.data);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  }, [setMyData, myData.id]);

  const test = {
    isLoading: true,
  };

  const [branchEmployee, setBranchEmployee] = useRecoilState(
    BranchEmployeeDataAtom
  );
  const [branchEmployeeCheck, setBranchEmployeeCheck] =
    useRecoilState(BranchEmployeeCheck);
  const [branchConfirmed, setBranchConfirmed] = useState(false);
  const [branchEmployeeData, setBranchEmployeeData] = useState<
    { [key: string]: Object }[]
  >([]);
  const [modalOpen, setModalOpen] = useState(false);
  function changeModalState() {
    setModalOpen(!modalOpen);
  }
  function branchAlert() {
    AlertModal("check", "계정을 설정해주세요.");
  }

  const fields: object = { text: "email", value: "id" };
  const onChange = (args: any) => {
    setBranchEmployee({
      id: args.value,
      email: args.target.itemData?.["email"],
    });
  };
  function itemTemplate(data: any): JSX.Element {
    return (
      <RaffleContent>
        <span> {data.name} </span>
        <span> {data.email} </span>
      </RaffleContent>
    );
  }

  function valueTemplate(data: any): JSX.Element {
    return (
      <RaffleContent>
        <span> {data.name} </span>
        <span> {data.email} </span>
      </RaffleContent>
    );
  }

  useEffect(() => {
    if (branchEmployee.id === 0) {
      EmployeeApi.checkOffice365().then((res) => {
        setBranchEmployeeCheck(res.data.branchEmployeeCheck);
        setBranchEmployeeData(res.data.branchEmployees);
        if (res.data.branchEmployeeCheck) {
          setModalOpen(true);
        }
        setBranchConfirmed(true);
      });
    }
  }, [branchEmployee.id, setBranchEmployeeCheck]);

  return (
    <>
      <BodyWrap>
        <ThemeProvider theme={theme}>
          {/* Header - Navigation Menu */}
          <Grid
            onMouseOver={() => setIsListHover(true)}
            onMouseLeave={() => setIsListHover(false)}
          >
            <Header />

            <Pc>
              {isListHover && locationNow.pathname !== "/" ? (
                <Grid>
                  <OpenHeader />
                </Grid>
              ) : null}
            </Pc>
          </Grid>
          {/* Body - Content */}
          <Grid>
            <Content>
              <Suspense fallback={<Loading loading={test} />}>
                {/* <Suspense fallback={<Loading loading={loadingState} />}> */}
                <Switch>
                  {/* 사이트 메인 페이지 */}
                  <Route
                    path={CommonRoutes.root}
                    component={LandingMain}
                    exact
                  ></Route>

                  {/* 휴양소 */}
                  <Route
                    path={AccommodationRoutes.root}
                    component={AccommodationMain}
                    exact
                  ></Route>

                  {/* Qna */}
                  <Route
                    path={QnaRoutes.root}
                    component={QnaMain}
                    exact
                  ></Route>
                  <Route
                    path={QnaRoutes.board}
                    component={QnaBoard}
                    exact
                  ></Route>
                  {/* 리마인더 */}
                  <Route
                    path={ReminderRoutes.root}
                    component={ReminderMain}
                    exact
                  ></Route>
                  <Route
                    path={ReminderRoutes.test}
                    component={ReminderModal}
                    exact
                  ></Route>

                  <Route
                    path={ReminderRoutes.test}
                    component={ReminderModal}
                    exact
                  ></Route>
                  {/* 나의예약 */}
                  <Route
                    path={MypageRoutes.root}
                    component={MyPage}
                    exact
                  ></Route>
                  <Route
                    path={VehicleRoutes.calendar}
                    component={AvailableVehicleCalendar}
                    exact
                  ></Route>
                  {/* 차량 */}
                  {branchConfirmed && branchEmployeeCheck ? (
                    <Redirect to={CommonRoutes.root} />
                  ) : (
                    <Route
                      path={VehicleRoutes.root}
                      component={VehicleMain}
                      exact
                    ></Route>
                  )}
                  {/* 설정 */}
                  {RoleCheck === true && [
                    <Route
                      key={AdminRoutes.root}
                      path={AdminRoutes.root}
                      component={AdminMain}
                      exact
                    />,
                    <Route
                      key={AdminRoutes.employee}
                      path={AdminRoutes.employee}
                      component={AdminEmployee}
                      exact
                    />,
                    <Route
                      key={AdminRoutes.addemployee}
                      path={AdminRoutes.addemployee}
                      component={AdminAddEmployee}
                      exact
                    />,
                    <Route
                      key={AdminRoutes.accommodation}
                      path={AdminRoutes.accommodation}
                      component={AdminAccommodation}
                      exact
                    />,
                    <Route
                      key={AdminRoutes.qna}
                      path={AdminRoutes.qna}
                      component={AdminQna}
                      exact
                    />,
                    <Route
                      key={AdminRoutes.vehicle}
                      path={AdminRoutes.vehicle}
                      component={AdminVehicle}
                      exact
                    />,
                  ]}
                </Switch>
              </Suspense>
              <Modal open={modalOpen} onClose={changeModalState}>
                <Box sx={SmallModalStyle}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <MainTitle>지사 직원 계정 설정</MainTitle>
                      <Grid item xs={12} margin="75px 0px">
                        <DropDownListComponent
                          dataSource={branchEmployeeData}
                          placeholder="본인의 계정을 선택해주세요."
                          fields={fields}
                          name="branchEmployee"
                          itemTemplate={itemTemplate}
                          valueTemplate={valueTemplate}
                          onChange={onChange}
                        />
                      </Grid>
                      <Grid container justifyContent="right">
                        <CustomButtonGreen
                          onClick={
                            branchEmployee.id ? changeModalState : branchAlert
                          }
                        >
                          선택
                        </CustomButtonGreen>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Content>
          </Grid>
        </ThemeProvider>
      </BodyWrap>
      {/* Footer */}
      <Footer></Footer>
    </>
  );
}

export default Routes;
