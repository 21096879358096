import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { MyReservation } from "../../../system/types/Reservation";
import {
  StyledBody,
  StyledEmptyBody,
  StyledHead1,
  StyledHead2,
} from "../../../styles/theme";

import MyAccommodationChip from "../../Common/Accommodation/MyAccommodationChip";

import AccommodationButton from "../../Common/Accommodation/AccommodationButton";

interface MyAccommodationTableProps {
  mydata: MyReservation[];
  refreshReservationData: () => void;
}
function MyAccommodationTable({
  mydata,
  refreshReservationData,
}: MyAccommodationTableProps) {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHead1 align="center">휴양소</StyledHead1>
              <StyledHead1 align="center">동/호</StyledHead1>
              <StyledHead2 align="center">기간</StyledHead2>
              <StyledHead1 align="center">상태</StyledHead1>
              <StyledHead2 align="center"></StyledHead2>
            </TableRow>
          </TableHead>
          <TableBody>
            {mydata.map((item, idx) => {
              return (
                <TableRow key={idx}>
                  <StyledBody align="center">
                    {item.accommodationName}
                  </StyledBody>
                  <StyledBody align="center">
                    {item.dong}동{item.ho ? " " + item.ho + "호" : null}
                  </StyledBody>
                  <StyledBody align="center">
                    {item.startDate} - {item.endDate}
                  </StyledBody>
                  <StyledBody align="center">
                    <MyAccommodationChip status={item.status} />
                  </StyledBody>

                  <StyledBody align="center">
                    <AccommodationButton
                      data={item}
                      refreshReservationData={refreshReservationData}
                    />
                  </StyledBody>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {mydata.length === 0 && (
        <Grid container>
          <StyledEmptyBody>조회된 데이터가 없습니다.</StyledEmptyBody>
        </Grid>
      )}
    </>
  );
}

export default MyAccommodationTable;
