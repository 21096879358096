import { Grid, styled } from "@mui/material";
import { MyReservationVehicleData } from "../../../system/types/Reservation";
import {
  CardDataBox,
  CardDataLayout,
  RaffleContent,
  StyledEmptyBodyNoBorder,
  ThickText,
} from "../../../styles/theme";
import MyAccommodationChip from "../../Common/Accommodation/MyAccommodationChip";
import MyCarButton from "./MyCarButton";
import { CustomButtonDisabled } from "../../../styles/button";

interface MyCarCardProps {
  myData: MyReservationVehicleData[];
  refreshReservationData: () => void;
}

function MyCarCard({ myData, refreshReservationData }: MyCarCardProps) {
  return (
    <Grid item xs={12}>
      <CardDataLayout>
        {myData.map((item: MyReservationVehicleData, idx) => {
          return (
            <CardDataBox key={idx}>
              <Grid item xs={12}>
                <CardDataContent>
                  <RaffleContent>
                    <ThickText>{item.startDate}</ThickText>
                    <ThickText>{item.time}</ThickText>
                  </RaffleContent>
                </CardDataContent>
                <CardDataContent>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MyAccommodationChip status={item.status} />
                    {item.vehicle.number}
                  </Grid>
                </CardDataContent>
                <CardDataContent>
                  <Grid container>
                    <ThickText style={{ marginRight: "5px" }}>출발</ThickText>
                    {item.realStartTime ? item.realStartTime : "정보 없음"}
                  </Grid>
                </CardDataContent>
                <CardDataContent>
                  <Grid container>
                    <ThickText style={{ marginRight: "5px" }}>도착</ThickText>
                    {item.realEndTime ? item.realEndTime : "정보 없음"}
                  </Grid>
                </CardDataContent>
                <CardDataContent>
                  <Grid container>
                    <ThickText style={{ marginRight: "5px" }}>목적지</ThickText>
                    {item.waypoints[0]?.placeName ? (
                      <>
                        {item.waypoints.length > 1
                          ? item.waypoints[0]?.placeName +
                            " 외 " +
                            item.waypoints.length +
                            "곳"
                          : item.waypoints[0]?.placeName}
                      </>
                    ) : (
                      <>
                        {item.waypoints.length > 1
                          ? item.waypoints[0]?.addressName +
                            " 외 " +
                            item.waypoints.length +
                            "곳"
                          : item.waypoints[0]?.addressName}
                      </>
                    )}
                  </Grid>
                </CardDataContent>
                <Grid container justifyContent="center">
                  <MyCarButton
                    data={item}
                    refreshReservationData={refreshReservationData}
                  />
                </Grid>
              </Grid>
            </CardDataBox>
          );
        })}
      </CardDataLayout>
      {myData.length === 0 && (
        <Grid container>
          <StyledEmptyBodyNoBorder>
            조회된 데이터가 없습니다.
          </StyledEmptyBodyNoBorder>
        </Grid>
      )}
    </Grid>
  );
}

export default MyCarCard;

const CardDataContent = styled("div")(() => ({
  marginBottom: "0.2rem",
}));
