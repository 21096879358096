import {
  VehicleReservationInfo,
  VehicleSearch,
} from "../../../system/types/Vehicle";
import {
  VehicleReserveTitleBox,
  VehicleReserveValidationBox,
} from "../../../styles/vehicleStyle";
import { Box, Grid, Modal, styled } from "@mui/material";
import {
  AppliedItem,
  MainTitle,
  MidiumModalStyle,
  RaffleContent,
  ValidationText,
} from "../../../styles/theme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { useState } from "react";
import {
  BLUESTROKE,
  GRAYPALEBACKGROUND1,
  GRAYPALEBORDER,
  GRAYPALECONTENTS,
  GRAYPALEDISABLED,
  REDTITLE,
} from "../../../styles/color";
import { CustomText } from "../../../styles/CustomText";
import { CustomButtonGray, CustomButtonGreen } from "../../../styles/button";
import uuid from "react-uuid";
import { InitVehicleSearch } from "../../../system/types/initObject";
import { X } from "@phosphor-icons/react";
import VRSDestinationModal from "./VRSDestinationModal";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

interface VRSDestinationProps {
  reservationData: VehicleReservationInfo;
  changeSelect: (type: string) => void;
  enterDestination: boolean;
  setReservationData: (dt: VehicleReservationInfo) => void;
}

function VRSDestination({
  reservationData,
  changeSelect,
  enterDestination,
  setReservationData,
}: VRSDestinationProps) {
  const [addressData, setAddressData] =
    useState<VehicleSearch>(InitVehicleSearch);

  const addNewDestination = () => {
    setReservationData({
      ...reservationData,
      waypoints: [...reservationData.waypoints, InitVehicleSearch],
    });
  };

  const refreshDestinationData = () => {
    setReservationData({
      ...reservationData,
      waypoints: [InitVehicleSearch],
    });
  };

  // 모달
  const [open, setOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState(0);
  const handleClose = () => setOpen(false);
  const handleOpen = (index: number) => {
    setSelectedNumber(index);
    setOpen(true);
    setAddressData(reservationData.waypoints[index]);
  };

  const deleteDestination = (idx: number) => {
    const newWaypoints = [
      ...reservationData.waypoints.slice(0, idx),
      ...reservationData.waypoints.slice(idx + 1),
    ];
    const newData = { ...reservationData, waypoints: newWaypoints };
    setReservationData(newData);
  };

  return (
    <>
      <VehicleReserveTitleBox
        item
        xs={12}
        onClick={() => {
          changeSelect("destination");
        }}
      >
        <Grid container item xs={23} direction="row" alignItems="center">
          <Grid container item xs={9}>
            <Grid container item xs={12} alignItems="center">
              <Grid item mr="10px">
                3. 목적지 입력
              </Grid>
              {reservationData.waypoints.filter((item) => item.kakaoLocId === 0)
                .length === 0 ? (
                <Grid item>
                  {reservationData.waypoints.length > 1
                    ? reservationData.waypoints[0]?.placeName +
                      " 외 " +
                      reservationData.waypoints.length +
                      "곳"
                    : reservationData.waypoints[0]?.placeName}
                </Grid>
              ) : (
                <Grid item>
                  <ValidationText>
                    <ErrorOutlineIcon fontSize="small" /> 목적지를 모두
                    입력해주세요.
                  </ValidationText>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={3} justifyContent="right">
            {enterDestination ? (
              <CaretUp size={30} weight="bold" />
            ) : (
              <CaretDown size={30} weight="bold" />
            )}
          </Grid>
        </Grid>
      </VehicleReserveTitleBox>
      {enterDestination && (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <DestinationBigBox container item xs={12} alignItems="center">
              {reservationData.waypoints.map((item, idx) => (
                <DestinationBox
                  container
                  item
                  xs={12}
                  alignItems="center"
                  pl="10px"
                  key={uuid()}
                  isLast={idx === reservationData.waypoints.length - 1}
                >
                  <Grid container item xs={12}>
                    <Grid
                      container
                      item
                      xs={11}
                      onClick={() => handleOpen(idx)}
                      style={{ cursor: "pointer" }}
                    >
                      {item.kakaoLocId === 0 ? (
                        <CustomText
                          type="subtitle"
                          bold400
                          color={GRAYPALECONTENTS}
                          cursorPointer
                        >
                          목적지 입력
                        </CustomText>
                      ) : (
                        <CustomText
                          type="subtitle"
                          bold400
                          color={GRAYPALECONTENTS}
                          cursorPointer
                        >
                          {item.placeName
                            ? item.placeName
                            : item.roadAddressName}
                        </CustomText>
                      )}
                    </Grid>

                    <Grid container item xs={1} justifyContent="right" pr="5px">
                      {idx !== 0 && (
                        <IconBox
                          onClick={() => {
                            deleteDestination(idx);
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconBox>
                      )}
                    </Grid>
                  </Grid>
                </DestinationBox>
              ))}
            </DestinationBigBox>

            <Grid container item xs={12}>
              <Grid item xs={6} ml="-5px">
                <CustomButtonGreen onClick={addNewDestination}>
                  목적지 추가
                </CustomButtonGreen>
              </Grid>
              <Grid item xs={6} container justifyContent="right" ml="5px">
                <CustomButtonGray onClick={refreshDestinationData}>
                  초기화
                </CustomButtonGray>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MidiumModalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RaffleContent>
                <MainTitle>목적지 선택</MainTitle>
                <AppliedItem onClick={handleClose}>
                  <X size={32} />
                </AppliedItem>
              </RaffleContent>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <VRSDestinationModal
              addressData={addressData}
              setAddressData={setAddressData}
              selectedNumber={selectedNumber}
              reservationData={reservationData}
              setReservationData={setReservationData}
              handleClose={handleClose}
            />
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default VRSDestination;

const DestinationBigBox = styled(Grid)({
  border: `1px solid ${GRAYPALECONTENTS}`,
  borderRadius: "8px",
  marginBottom: "10px",
});

interface DestinationBoxProps {
  isLast: boolean;
}

const DestinationBox = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isLast",
})`
  height: 40px;
  &:hover {
    border-radius: 8px;
    outline: none;
    box-shadow: 0 0 10px ${BLUESTROKE};
  }
  ${({ isLast }: DestinationBoxProps) =>
    !isLast &&
    `
      border-bottom: 1px solid ${GRAYPALEBORDER};
    `}
`;

const IconBox = styled(Grid)({
  color: GRAYPALEDISABLED,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: `${GRAYPALEBACKGROUND1}`,
    borderRadius: "8px",
    color: REDTITLE,
  },
});

// const IconBoxGrab = styled(Grid)({
//   color: GRAYPALEDISABLED,
//   cursor: "grab",
//   "&:hover": {
//     backgroundColor: `${GRAYPALEBACKGROUND1}`,
//     borderRadius: "8px",
//     color: BLUETITLE,
//     cursor: "grab",
//   },
// });
