import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../styles/color";

function AlertModal(type: string, message?: string, content?: string) {
  return (
    <>
      {type === "예약완료" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>예약이 완료됐습니다.</div>",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        })}
      {type === "success" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;'>" +
            message +
            "</div>",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        })}
      {type === "msg" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
            message +
            "</div>",
          icon: "error",
          confirmButtonText: "확인",
          confirmButtonColor: GREENCONTENTS,
        })}
      {type === "err" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
            message +
            "</div>",
          icon: "error",
          text: content,
          confirmButtonText: "확인",
          confirmButtonColor: GREENCONTENTS,
        })}
      {type === "check" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
            message +
            "</div>",
          icon: "warning",
          showConfirmButton: false,
          timer: 1500,
        })}
      {type === "question" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
            message +
            "</div>",
          icon: "question",
          confirmButtonText: "예",
          cancelButtonText: "아니오",
          showCancelButton: true,
          confirmButtonColor: GREENCONTENTS,
          cancelButtonColor: REDTITLE,
        })}
      {type === "memo" &&
        Swal.fire({
          title:
            "<div style='font-family:Noto sans CJK KR;font-size:20px;margin-bottom:10px'>" +
            message +
            "</div>",
          text: content,
          icon: "success",
          confirmButtonText: "확인",
          confirmButtonColor: GREENCONTENTS,
        })}
    </>
  );
}

export default AlertModal;
