import { Grid, styled } from "@mui/material";
import { VehicleLastVehicleInfo } from "../../../system/types/Vehicle";
import {
  CardDataCenterContent,
  CardDataLayout,
  RaffleContent,
  ThickText,
} from "../../../styles/theme";
import MyAccommodationChip from "../../Common/Accommodation/MyAccommodationChip";
import { CustomButtonYellow } from "../../../styles/button";
import AlertModal from "../../Common/AlertModal";
import MyCarButton from "../../MyPage/Car/MyCarButton";
import { GRAYPALETITLE } from "../../../styles/color";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import VehicleReservationDetailModal from "./VehicleReservationDetailModal";
interface DrivingHistoryCardProps {
  drivingData: VehicleLastVehicleInfo[];
  refreshData: () => void;
}

function DrivingHistoryCard({
  drivingData,
  refreshData,
}: DrivingHistoryCardProps) {
  function openMemo(message: string) {
    AlertModal("memo", "운행 중 남긴 메모", message);
  }
  return (
    <Grid item xs={12}>
      <CardDataLayout>
        {drivingData.map((item: VehicleLastVehicleInfo, idx) => {
          return (
            <CardDataBox key={idx}>
              <Grid item xs={12}>
                <CardDataContent>
                  <RaffleContent>
                    <ThickText>
                      {item.startDate + " " + item.time}{" "}
                      <VehicleReservationDetailModal data={item} />
                      {item.memo !== null && (
                        <StickyNote2Icon
                          onClick={() => openMemo(item.memo)}
                          style={{ cursor: "pointer", color: GRAYPALETITLE }}
                        />
                      )}
                    </ThickText>
                    <ThickText>
                      <MyAccommodationChip status={item.status} />
                    </ThickText>
                  </RaffleContent>
                </CardDataContent>
                <CardDataContent>
                  <Grid container>
                    <ThickText style={{ marginRight: "5px" }}>차량</ThickText>
                    {item.vehicle.shapeType + " • " + item.vehicle.number}
                  </Grid>
                </CardDataContent>

                <CardDataContent>
                  <Grid container>
                    <ThickText style={{ marginRight: "5px" }}>
                      출발/도착
                    </ThickText>
                    {item.startMileage !== null
                      ? item.startMileage.toLocaleString() +
                        " " +
                        item.vehicle.unitType
                      : "- " + item.vehicle.unitType}
                    {" / "}
                    {item.endMileage !== null
                      ? item.endMileage.toLocaleString() +
                        " " +
                        item.vehicle.unitType
                      : "- " + item.vehicle.unitType}
                  </Grid>
                </CardDataContent>
                <CardDataContent>
                  <Grid container>
                    <ThickText style={{ marginRight: "5px" }}>목적지</ThickText>
                    {item.waypoints[0]?.placeName ? (
                      <>
                        {item.waypoints.length > 1
                          ? item.waypoints[0]?.placeName +
                            " 외 " +
                            item.waypoints.length +
                            "곳"
                          : item.waypoints[0]?.placeName}
                      </>
                    ) : (
                      <>
                        {item.waypoints.length > 1
                          ? item.waypoints[0]?.addressName +
                            " 외 " +
                            item.waypoints.length +
                            "곳"
                          : item.waypoints[0]?.addressName}
                      </>
                    )}
                  </Grid>
                </CardDataContent>
                <CardDataContent>
                  <Grid container>
                    <ThickText style={{ marginRight: "5px" }}>운전자</ThickText>
                    {item.employee.department
                      ? item.employee.department
                      : item.employee.team}{" "}
                    {item.employee.name}
                  </Grid>
                </CardDataContent>
                <CardDataCenterContent>
                  {item?.id && (
                    <MyCarButton
                      data={{
                        id: item.id,
                        vehicle: item.vehicle,
                        employee: item.employee,
                        startDate: item.startDate,
                        startMileage: item.startMileage,
                        endMileage: item.endMileage,
                        status: item.status,
                        time: item.time,
                        realStartTime: item.realStartTime,
                        realEndTime: item.realEndTime,
                        waypoints: item.waypoints,
                        memo: item.memo,
                      }}
                      refreshReservationData={refreshData}
                    />
                  )}
                </CardDataCenterContent>
              </Grid>
            </CardDataBox>
          );
        })}
      </CardDataLayout>
    </Grid>
  );
}

export default DrivingHistoryCard;

const CardDataContent = styled("div")(() => ({
  marginBottom: "0.2rem",
}));

const CardDataBox = styled("div")(() => ({
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  margin: "1rem",
  padding: "1.3rem 2rem",
  maxWidth: "20rem",
  width: "100%",
  height: "245px",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));
