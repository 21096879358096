import { Box, Chip, Grid, Modal, styled } from "@mui/material";
import { useState } from "react";
import { X } from "@phosphor-icons/react";
import InfoIcon from "@mui/icons-material/Info";
import {
  AppliedItem,
  MainTitle,
  ModalStyle,
  RaffleContent,
} from "../../../styles/theme";
import { VehicleLastVehicleInfo } from "../../../system/types/Vehicle";
import { CustomText } from "../../../styles/CustomText";
import MyAccommodationChip from "../../Common/Accommodation/MyAccommodationChip";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
interface VehicleReservationDetailModalProps {
  data: VehicleLastVehicleInfo;
}

function VehicleReservationDetailModal({
  data,
}: VehicleReservationDetailModalProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <InfoIcon
        color="action"
        onClick={handleOpen}
        style={{ cursor: "pointer" }}
      />
      {open && (
        <Modal open={open} onClose={handleClose}>
          <Box sx={ModalStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RaffleContent>
                  <MainTitle>차량 운행 상세 내역</MainTitle>
                  <AppliedItem onClick={handleClose}>
                    <X size={32} />
                  </AppliedItem>
                </RaffleContent>
              </Grid>
              {data ? (
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Chip label="운행 정보" variant="outlined" />
                  </Grid>
                  <VehicleInfoBigBox container mt="10px" alignItems="center">
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">이용 날짜</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.startDate} {data.time}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">상태</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        <MyAccommodationChip status={data.status} />
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">
                          출발 누적{" "}
                          {data.vehicle.unitType === "km" ? "거리" : "시간"}
                        </CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.startMileage
                          ? data.startMileage.toLocaleString()
                          : "-"}
                        {" " + data.vehicle.unitType}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">
                          도착 누적{" "}
                          {data.vehicle.unitType === "km" ? "거리" : "시간"}
                        </CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.endMileage
                          ? data.endMileage.toLocaleString()
                          : "-"}
                        {" " + data.vehicle.unitType}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">출발 시간</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.realStartTime}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">도착 시간</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.realEndTime}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} alignItems="center">
                      <Grid container justifyContent="center" item xs={2}>
                        <CustomText type="subtitle">목적지</CustomText>
                      </Grid>
                      <Grid container item xs={10}>
                        {data.waypoints.map((item, idx) => {
                          return (
                            <Grid item key={idx}>
                              {idx !== 0 && <KeyboardArrowRightIcon />}
                              {item.placeName
                                ? item.placeName
                                : item.roadAddressName}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </VehicleInfoBox>
                  </VehicleInfoBigBox>

                  <Grid item xs={12} mt="20px">
                    <Chip label="차량 정보" variant="outlined" />
                  </Grid>
                  <VehicleInfoBigBox container mt="10px" alignItems="center">
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">이름</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.name}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">번호</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.number}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">종류</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.shapeType}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">크기</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.sizeType}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">탑승 인원</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.seater} 인승
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">연료</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.fuelType}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">용도</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.usageType ? data.vehicle.usageType : "-"}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">누적 주행 거리</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.totalMileage.toLocaleString()}{" "}
                        {data.vehicle.unitType}
                      </Grid>
                    </VehicleInfoBox>
                  </VehicleInfoBigBox>
                  <Grid item xs={12} mt="20px">
                    <Chip label="보험 정보" variant="outlined" />
                  </Grid>
                  <VehicleInfoBigBox container mt="10px" alignItems="center">
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">보험사명</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.insurance.company}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">보험 나이</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {"만 " + data.vehicle.insurance.applicableAge + " 세"}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">연락처</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.insurance.contact}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">기간</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.vehicle.insurance.coverageStartDate} {" ~ "}
                        {data.vehicle.insurance.coverageEndDate}
                      </Grid>
                    </VehicleInfoBox>
                  </VehicleInfoBigBox>
                  <Grid item xs={12} mt="20px">
                    <Chip label="담당자 정보" variant="outlined" />
                  </Grid>
                  <VehicleInfoBigBox container mt="10px" alignItems="center">
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">이름</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data?.vehicle.manager?.name}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox
                      container
                      item
                      xs={12}
                      sm={6}
                      alignItems="center"
                    >
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">연락처</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data?.vehicle.manager?.phone}
                      </Grid>
                    </VehicleInfoBox>
                  </VehicleInfoBigBox>
                </Grid>
              ) : (
                <Grid container justifyContent="center" mt="50px">
                  등록된 차량 정보가 없습니다.
                </Grid>
              )}
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default VehicleReservationDetailModal;

const VehicleInfoBigBox = styled(Grid)({
  borderTop: "1px solid #DCDFE3",
});

const VehicleInfoBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  padding: "0.7rem 0rem",
  fontSize: "1rem",
  minHeight: "55px",
});
