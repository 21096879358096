import { Box, Grid, Modal } from "@mui/material";
import { useState } from "react";
import { X } from "@phosphor-icons/react";
import InfoIcon from "@mui/icons-material/Info";
import {
  AppliedItem,
  MainTitle,
  RaffleContent,
  SmallModalStyle,
} from "../../styles/theme";
import { CustomText } from "../../styles/CustomText";

function VehicleNotAvailableModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <InfoIcon
        color="action"
        fontSize="small"
        onClick={handleOpen}
        style={{ marginTop: "-3px", cursor: "pointer" }}
      />
      {open && (
        <Modal open={open} onClose={handleClose}>
          <Box sx={SmallModalStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RaffleContent>
                  <MainTitle>이용 불가 시 해결 방안</MainTitle>
                  <AppliedItem onClick={handleClose}>
                    <X size={32} />
                  </AppliedItem>
                </RaffleContent>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} mb="15px">
                  1. 이전 사용자에게 연락하여 해당 차량 사용 완료 처리
                </Grid>
                <Grid item xs={12} mb="20px">
                  2. 이전 사용자와 연락이 안될 시 관리자에게 연락
                </Grid>
                <Grid item xs={12} mb="15px">
                  <CustomText type="subtitle">[관리자 연락처]</CustomText>
                </Grid>
                <Grid item xs={12} mb="15px">
                  총무팀 이준영 차장 : 010-9151-1179
                </Grid>
                <Grid item xs={12}>
                  총무팀 허준영 사원 : 010-4653-7491
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default VehicleNotAvailableModal;
