export const defaultValue =
  // eslint-disable-next-line no-multi-str
  '<table cellspacing="0" cellpadding="5" border="1" style="width: 100%; font-size:10pt; border-width: 0px;border-collapse:collapse; "> \
<tbody> \
<tr> \
<td > \
<span style="font-weight: bold;">예약 및 사전 준비 사항</span></td> \
</tr> \
<td > \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p></td> \
</tr> \
<tr> \
<td > \
<span style="font-weight: bold;">휴양소 / 캠핑장 구조 및 구비 품목</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; "> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; "> \
<span style="font-weight: bold;">주변 환경</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; "> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; "> \
<span style="font-weight: bold;">주의 사항</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px;  "> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; "> \
<span style="font-weight: bold;">입실 시 확인 사항</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px;"> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px;"> \
<span style="font-weight: bold;">퇴실 시 확인 사항</span></td> \
</tr> \
<tr> \
<td style="width: 100%; height: 30px; "> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p> \
<p><br /></p></td> \
</tr> \
</tbody> \
</table>';
