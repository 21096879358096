import client from "../ApiService";
import HttpClient from "../http-client";
import { ReminderData } from "../types/Reminder";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _ReminderApi extends _BasicApi {
  getReminder(
    type: string,
    page: number,
    size: number,
    sort: string,
    startDate?: string,
    endDate?: string,
    searchWord?: string
  ) {
    return this.client.get(`/${type}`, {
      params: {
        page: page,
        size: size,
        searchWord: searchWord,
        sort: sort,
        startDate: startDate,
        endDate: endDate,
      },
    });
  }
  createReminder(reminder: ReminderData) {
    return this.client.post(`/`, reminder);
  }
  updateReminder(reminder: ReminderData) {
    return this.client.put(`/`, reminder);
  }
  checkReminder(id: number) {
    return this.client.put(`/${id}`);
  }
  deleteReminder(id: number) {
    return this.client.delete(`/`, { params: { id: id } });
  }
  checkWarning(id: number) {
    return this.client.put(`/problem/${id}`);
  }
}

export const ReminderApi = new _ReminderApi(client);
