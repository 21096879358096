import {
  ScheduleComponent,
  Month,
  Inject,
  EventRenderedArgs,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import { Fragment, useEffect, useRef, useState } from "react";
import { ErrorHandler } from "../../system/ApiService";
import { useLoadingDispatch } from "../../system/context/LoadingContext";
import { Mobile, Pc } from "../../pages/Layout/ResponsiveScreen";
import {
  CenterLayout,
  LightTooltip,
  ModalStyle,
  PageHeaderInfoBox,
} from "../../styles/theme";
import {
  AccommodationRezData,
  Resource,
} from "../../system/types/Accommodation";

import {
  AccommodationCircleItem,
  AccommodationEmptyItem,
  MakeReservationButtonLayout,
  ScheduleItemBox,
  ScheduleItemPointerBox,
  ScheduleLegend,
  ScheduleLegendDisabledItem,
  ScheduleLegendItem,
  ScheduleLegendTitleBox,
  ScheduleLegendTitleLayout,
  ScheduleSmallItemBox,
  ScheduleSmallItemDefaultBox,
  ScheduleTitle,
  ScheduleTitleMobile,
  TooltipIcon,
} from "../../styles/accommodationStyle";
import { Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

// globalization
import { L10n } from "@syncfusion/ej2-base";

import { CustomButtonWhite } from "../../styles/button";
import { GRAYPALEDISABLED, TOTALACCOMMODATION } from "../../styles/color";

// 모달
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AccommodationRez from "./AccommodationRez";
import { InitAccommodationRez } from "../../system/types/initObject";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  DatepickerStartEndTimeAtom,
  HolidayDataAtom,
  accommodationState,
} from "../../system/atoms";
import { ResourceApi } from "../../system/api/Resource";
import AlertModal from "../Common/AlertModal";
import { addClass } from "@syncfusion/ej2-base";
import { HolidayApi } from "../../system/api/HolidayApi";
import { isoDateFormatter } from "../Common/IsoDateFormatter";
import { RaffleDate } from "./AccommodationDateFormat";
import { AccommodationApi } from "../../system/api/AccommodationApi";

interface HolidayProps {
  name: string;
  date: string;
}

function ReservationCalendar() {
  const [scheduleData, setScheduleData] = useState<any>(null);
  const [accommodationRegion, setAccommodationRegion] = useState("");
  const [resourceData, setResourceData] = useRecoilState(accommodationState);
  const [selectedAccommodationData, setSelectedAccommodationData] =
    useState<AccommodationRezData>(InitAccommodationRez);
  let scheduleObj = useRef<ScheduleComponent | null>(null);
  const setDateRange = useSetRecoilState(DatepickerStartEndTimeAtom);
  L10n.load({ ko: { schedule: { today: "오늘" } } });
  const [holidayList, setHolidayList] = useRecoilState(HolidayDataAtom);
  const Loading = useLoadingDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [, setNavigateData] = useState({});

  const handleDateNavigate = (args: any) => {
    if (args.requestType === "dateNavigate" || "toolBarItemRendered") {
      getReservationCalendar(scheduleObj.current?.selectedDate);
    }
    setNavigateData(args);
  };

  var todayYear = new Date().getFullYear();
  const [searchHolidayYear, setSearchHolidayYear] = useState(todayYear);
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(
    new Date(new Date().getTime() + 8 * 7 * 24 * 60 * 60 * 1000)
  );

  const [rafflePeriodList, setRafflePeriodList] = useState<
    (string | undefined)[]
  >([]);

  useEffect(() => {
    var todayDay = new Date().getDay();
    var todayHour = new Date().getHours();
    var todayMinute = new Date().getMinutes();
    // rafflePeriod list 담기
    const today = new Date();
    const daysUntilWednesday = (3 - todayDay + 7) % 7;
    // maxDate
    var firstMaxDate = new Date(
      today.getTime() + (5 * 7 + daysUntilWednesday - 1) * 24 * 60 * 60 * 1000
    );
    var secondMaxDate = new Date(
      today.getTime() + (4 * 7 + daysUntilWednesday - 1) * 24 * 60 * 60 * 1000
    );
    if (holidayList.length === 0) {
      HolidayApi.getHoliday(today.getFullYear()).then((res) => {
        setHolidayList(res.data);
      });
    }

    const preRafflePeriodList = [];
    if (
      (todayDay === 3 && todayHour === 10 && todayMinute > 4) ||
      (todayDay === 3 && todayHour > 10)
    ) {
      for (var i = 0; i < 7; i++) {
        preRafflePeriodList.push(
          isoDateFormatter(
            new Date(
              today.getTime() +
                (4 * 7 + daysUntilWednesday + i) * 24 * 60 * 60 * 1000
            )
          )
        );
      }
      setRafflePeriodList(preRafflePeriodList);
    } else {
      for (var j = 0; j < 7; j++) {
        preRafflePeriodList.push(
          isoDateFormatter(
            new Date(
              today.getTime() +
                (3 * 7 + daysUntilWednesday + j) * 24 * 60 * 60 * 1000
            )
          )
        );
      }
      setRafflePeriodList(preRafflePeriodList);
    }
    if (
      (todayDay === 3 && todayHour === 10 && todayMinute > 4) ||
      (todayDay === 3 && todayHour > 10)
    ) {
      setMaxDate(firstMaxDate);
    } else {
      setMaxDate(secondMaxDate);
    }

    Loading({ type: "LOADING" });
    ResourceApi.getResource("휴양소")
      .then((res) => {
        setResourceData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  }, [Loading, holidayList.length, setHolidayList, setResourceData]);

  const getReservationCalendar = (selectedDate: Date | undefined) => {
    if (!selectedDate) return;
    setSelectedCalendarDate(selectedDate);
    if (selectedDate.getFullYear() !== searchHolidayYear) {
      HolidayApi.getHoliday(selectedDate.getFullYear()).then((res) => {
        setHolidayList(res.data);
      });
      setSearchHolidayYear(selectedDate.getFullYear());
    }
    if (selectedDate.getMonth() !== selectedCalendarDate.getMonth()) {
      getReservationCalendarData(selectedDate, accommodationRegion);
    }
  };

  const getReservationCalendarData = (
    selectedDate?: Date | undefined,
    region?: string | ""
  ) => {
    Loading({ type: "LOADING" });
    AccommodationApi.getReservationCalendar(
      selectedDate
        ? selectedDate.getFullYear()
        : selectedCalendarDate.getFullYear(),
      selectedDate
        ? selectedDate.getMonth() + 1
        : selectedCalendarDate.getMonth() + 1,
      region
    )
      .then((res) => {
        setScheduleData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };

  function onEventRendered(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.CategoryColor as string;

    if (!args.element || !categoryColor) {
      return;
    } else {
      args.element.style.border = "0px";
      args.element.style.backgroundColor = "transparent";
      args.element.style.color = categoryColor;
      args.element.style.marginTop = `${args?.data?.Index * 1}px`;
      args.element.style.height = "25px";
      args.element.style.fontSize = "0.8rem";
    }
  }

  const onClickRez = (dataItem: any) => {
    handleOpen();
    setSelectedAccommodationData(dataItem);
    var EndDate = dataItem.EndTime;
    var selectedEndYear = new Date(EndDate).getFullYear();
    var selectedEndMonth = new Date(EndDate).getMonth();
    var selectedEndDate = new Date(EndDate).getDate();
    setDateRange([
      new Date(dataItem.StartTime),
      new Date(selectedEndYear, selectedEndMonth, selectedEndDate + 2),
    ]);
  };

  function onDataBinding(e: any) {
    let items = e.result;
    let ScheduleData = [];
    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        if (items[i].RafflePeriod) {
          ScheduleData.push({
            Id: items[i].Id,
            Subject: items[i].TotalCnt
              ? items[i].Subject + " " + items[i].TotalCnt
              : items[i].Subject,
            Location: items[i].Subject,
            StartTime: new Date(items[i].StartTime),
            EndTime: new Date(items[i].EndTime),
            IsAllDay: true,
            CategoryColor: items[i].CategoryColor,
            Data: items[i],
          });
        } else {
          var RoomItem = "";
          for (var j = 0; j < items[i].ConfirmedCnt; j++) {
            RoomItem = RoomItem + " ⬤";
          }
          for (var k = 0; k < items[i].RoomCnt - items[i].ConfirmedCnt; k++) {
            RoomItem = RoomItem + " ⛒";
          }
          if (accommodationRegion === "") {
            ScheduleData.push({
              Id: items[i].Id,
              Subject: items[i].Subject,
              StartTime: new Date(items[i].StartTime),
              EndTime: new Date(items[i].EndTime),
              IsAllDay: true,
              CategoryColor: items[i].CategoryColor,
              Data: { ...items[i] },
            });
          } else {
            ScheduleData.push({
              Id: items[i].Id,
              Subject: items[i].Subject,
              StartTime: new Date(items[i].StartTime),
              EndTime: new Date(items[i].EndTime),
              IsAllDay: true,
              CategoryColor: items[i].CategoryColor,
              Data: items[i],
            });
          }
        }
      }
    }

    e.result = ScheduleData;
  }
  const onPopupOpen = (args: any) => {
    args.cancel = true;
  };

  const getHoliday = (value: Date) => {
    var foundDate: HolidayProps[] = holidayList.filter(
      (e: HolidayProps) => e.date === isoDateFormatter(value)
    );
    if (foundDate.length > 0) {
      return `<div class="testClass">${foundDate[0].name}</div>`;
    } else {
      return "<div></div>";
    }
  };

  const onRenderCell = (args: any) => {
    if (rafflePeriodList.includes(isoDateFormatter(args.date))) {
      addClass([args.element], "raffleperiod");
    }
    if (args.elementType === "monthCells" && window.innerWidth >= 650) {
      let ele = document.createElement("div");
      if (getHoliday(args.date)) {
        ele.innerHTML = getHoliday(args.date);
      }

      args.element.appendChild(ele.firstChild);
    }
  };

  const [maxDate, setMaxDate] = useState(new Date());

  function legendCircle(region: string, color: string) {
    if (accommodationRegion === "") {
      if (region === "전체") {
        return (
          <ScheduleLegendItem
            color={color}
            container
            alignItems="center"
            justifyContent="center"
          >
            {region}
          </ScheduleLegendItem>
        );
      } else
        return (
          <ScheduleLegendItem
            color={color}
            container
            alignItems="center"
            justifyContent="center"
          >
            {region}
          </ScheduleLegendItem>
        );
    } else if (region === accommodationRegion) {
      return (
        <ScheduleLegendItem
          color={color}
          container
          alignItems="center"
          justifyContent="center"
        >
          {region}
        </ScheduleLegendItem>
      );
    } else {
      return (
        <ScheduleLegendDisabledItem
          color={color}
          container
          alignItems="center"
          justifyContent="center"
        >
          {region}
        </ScheduleLegendDisabledItem>
      );
    }
  }

  const template = (props: any) => {
    var CircleItem = [];
    var EmptyCircleItem = [];
    var CircleCnt = props.Data.ConfirmedCnt;
    var EmptyCircleCnt = props.Data.RoomCnt - props.Data.ConfirmedCnt;
    for (var i = 0; i < CircleCnt; i++) {
      CircleItem.push("⬤");
    }
    for (var j = 0; j < EmptyCircleCnt; j++) {
      EmptyCircleItem.push("◍");
    }
    if (accommodationRegion === "") {
      if (props.Data.RafflePeriod) {
        if (props.Data.ConfirmedCnt === props.Data.RoomCnt) {
          return (
            <ScheduleItemBox color={GRAYPALEDISABLED}>
              <ScheduleSmallItemBox color={GRAYPALEDISABLED}>
                {props.Subject}{" "}
              </ScheduleSmallItemBox>
            </ScheduleItemBox>
          );
        } else {
          return (
            <ScheduleItemPointerBox
              color={props.CategoryColor}
              onClick={() => onClickRez(props)}
            >
              <ScheduleSmallItemBox color={props.CategoryColor}>
                {props.Subject}
              </ScheduleSmallItemBox>
            </ScheduleItemPointerBox>
          );
        }
      } else {
        if (props.Data.ConfirmedCnt === props.Data.RoomCnt) {
          return (
            <ScheduleItemBox color={GRAYPALEDISABLED}>
              <ScheduleSmallItemBox color={GRAYPALEDISABLED}>
                {props.Subject}
              </ScheduleSmallItemBox>

              {EmptyCircleItem.map((item, index) => {
                return (
                  <AccommodationCircleItem
                    key={"circle" + index}
                  ></AccommodationCircleItem>
                );
              })}
              {CircleItem.map((item, index) => {
                return (
                  <AccommodationEmptyItem
                    key={"emptycircle" + index}
                  ></AccommodationEmptyItem>
                );
              })}
            </ScheduleItemBox>
          );
        } else {
          return (
            <ScheduleItemPointerBox
              color={props.CategoryColor}
              onClick={() => onClickRez(props)}
            >
              <ScheduleSmallItemBox color={props.CategoryColor}>
                {props.Subject}
              </ScheduleSmallItemBox>
              {props.Data.RafflePeriod ? (
                <>{props.Data.TotalCnt ? props.Data.TotalCnt : null}</>
              ) : (
                <>
                  {EmptyCircleItem.map((item, index) => {
                    return (
                      <AccommodationCircleItem
                        key={"circle" + index}
                      ></AccommodationCircleItem>
                    );
                  })}
                  {CircleItem.map((item, index) => {
                    return (
                      <AccommodationEmptyItem
                        key={"emptycircle" + index}
                      ></AccommodationEmptyItem>
                    );
                  })}
                </>
              )}
            </ScheduleItemPointerBox>
          );
        }
      }
    } else {
      if (props.Data.ConfirmedCnt > 0) {
        return (
          <ScheduleItemBox color={GRAYPALEDISABLED}>
            <ScheduleSmallItemDefaultBox color={GRAYPALEDISABLED}>
              {props.Subject}
            </ScheduleSmallItemDefaultBox>
          </ScheduleItemBox>
        );
      } else {
        return (
          <ScheduleItemPointerBox
            color={props.CategoryColor}
            onClick={() => onClickRez(props)}
          >
            <ScheduleSmallItemBox color={props.CategoryColor}>
              {props.Subject}
            </ScheduleSmallItemBox>
          </ScheduleItemPointerBox>
        );
      }
    }
  };
  const changeRegion = (region: string) => {
    if (region === "전체") {
      setAccommodationRegion("");
      getReservationCalendarData(undefined, "");
    } else {
      setAccommodationRegion(region);
      getReservationCalendarData(undefined, region);
    }
  };

  return (
    <>
      <Pc>
        <Grid container spacing={2}>
          {/* <AccommodationAlert item>
            <MessageComponent
              severity="Warning"
              variant="Outlined"
              content="11월 5일부터 3월 말까지 양평 캠핑장은 동절기 휴장으로 인해 예약이 불가합니다."
            />
          </AccommodationAlert> */}
          <Grid item xs={6}>
            <PageHeaderInfoBox>
              추첨 예약 기간 : {RaffleDate("기간")}
              <TooltipIcon>
                <LightTooltip
                  title={
                    <Fragment>
                      <div>
                        추첨 예약 기간은 오늘({RaffleDate("오늘")}) 기준 한달 뒤
                        수요일부터 그 다음주 화요일입니다.
                      </div>
                      <div>
                        예약 기간의 마지막 날인 화요일은 예약 종료일이 아닌 예약
                        시작일입니다.
                      </div>
                      <div>
                        마지막 날은 {RaffleDate("tooltip")} (1박2일) 휴양소
                        예약만 가능합니다.
                      </div>
                    </Fragment>
                  }
                >
                  <InfoIcon />
                </LightTooltip>
              </TooltipIcon>
            </PageHeaderInfoBox>
          </Grid>
          <Grid item xs={6}>
            <PageHeaderInfoBox>
              추첨 예약 발표일 : {RaffleDate("발표일")}
            </PageHeaderInfoBox>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>휴양소별 보기 :</Grid>

            <Grid onClick={() => changeRegion("전체")} item margin="5px">
              {legendCircle("전체", TOTALACCOMMODATION)}
            </Grid>

            {resourceData.map((region: Resource, idx: number) => (
              <Grid
                key={idx}
                onClick={() => changeRegion(region.name)}
                item
                margin="5px"
              >
                {legendCircle(region.name, region.color)}
              </Grid>
            ))}
          </Grid>

          <Grid container item xs={12} justifyContent="right">
            <CustomButtonWhite onClick={() => onClickRez(InitAccommodationRez)}>
              예약하기
            </CustomButtonWhite>
          </Grid>
        </Grid>
      </Pc>
      <Mobile>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeaderInfoBox>
              추첨 예약 기간 : {RaffleDate("기간")}
              <TooltipIcon>
                <LightTooltip
                  title={
                    <Fragment>
                      <div>
                        추첨 예약 기간은 오늘({RaffleDate("오늘")}) 기준 한달 뒤
                        수요일부터 그 다음주 화요일입니다.
                      </div>
                      <div>
                        예약 기간의 마지막 날인 화요일은 예약 종료일이 아닌 예약
                        시작일입니다.
                      </div>
                      <div>
                        마지막 날은 {RaffleDate("tooltip")} (1박2일) 휴양소
                        예약만 가능합니다.
                      </div>
                    </Fragment>
                  }
                >
                  <InfoIcon />
                </LightTooltip>
              </TooltipIcon>
            </PageHeaderInfoBox>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeaderInfoBox>
              추첨 예약 발표일 : {RaffleDate("발표일")}
            </PageHeaderInfoBox>
          </Grid>
        </Grid>
        <ScheduleTitle container spacing={2}>
          <Grid
            container
            item
            xs={12}
            justifyContent="cetner"
            alignItems="center"
          >
            <Grid container justifyContent="center">
              휴양소별 보기 :{" "}
            </Grid>
            <Grid container justifyContent="center">
              <Grid item onClick={() => changeRegion("전체")} margin="5px">
                {legendCircle("전체", TOTALACCOMMODATION)}
              </Grid>

              {resourceData.map((region: Resource, idx: number) => (
                <Grid
                  item
                  key={idx}
                  onClick={() => changeRegion(region.name)}
                  margin="5px"
                >
                  {legendCircle(region.name, region.color)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </ScheduleTitle>
        <ScheduleTitleMobile container spacing={2}>
          <Grid item xs={12} textAlign="right">
            <CustomButtonWhite onClick={() => onClickRez(InitAccommodationRez)}>
              예약하기
            </CustomButtonWhite>
          </Grid>
        </ScheduleTitleMobile>
      </Mobile>
      <ScheduleComponent
        currentView="Month"
        // eventSettings={{ dataSource: scheduleData }}
        actionComplete={handleDateNavigate}
        minDate={
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
        }
        maxDate={maxDate}
        eventSettings={{
          dataSource: scheduleData,
          template: template as any,
        }}
        ref={scheduleObj}
        eventRendered={onEventRendered}
        // actionBegin={onActionBegin}
        readonly={true}
        dataBinding={onDataBinding}
        locale="ko"
        rowAutoHeight={true}
        popupOpen={onPopupOpen}
        renderCell={onRenderCell}
      >
        <ViewsDirective>
          <ViewDirective option="Month" />
        </ViewsDirective>
        <Inject services={[Month]} />
      </ScheduleComponent>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <AccommodationRez
            selectedAccommodationData={selectedAccommodationData}
            handleClose={handleClose}
            setSelectedAccommodationData={setSelectedAccommodationData}
            calendarMaxDate={isoDateFormatter(new Date(maxDate))}
          />
        </Box>
      </Modal>
    </>
  );
}

export default ReservationCalendar;
