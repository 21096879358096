import { Box, Grid, Modal } from "@mui/material";
import {
  VehicleNoticeBoxContent,
  VehicleRightBoxTitleAlign2,
  VehicleRightBoxSideColor,
  VehicleRightBoxTitle,
  VehicleNoticeContentsBox,
  VehicleNoticeTitleBox,
  VehicleNoticeTitleLongBox,
  VehicleNoticeIcon,
} from "../../styles/vehicleStyle";
import { useCallback, useEffect, useState } from "react";
import {
  AppliedItem,
  MainTitle,
  MidiumModalStyle,
  ModalStyle,
  RaffleContent,
} from "../../styles/theme";
import VehicleNotice from "./VehicleNotice";
import { NoticeApi } from "../../system/api/NoticeApi";
import { Notice, NoticeData } from "../../system/types/Notice";
import { Pc, Mobile } from "../../pages/Layout/ResponsiveScreen";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import uuid from "react-uuid";
import { InitNoticeDetailData } from "../../system/types/initObject";
import { X } from "@phosphor-icons/react";
import HtmlContent from "../Common/HtmlContext";
import { CustomButtonGreen } from "../../styles/button";

function VehicleNoticeBox() {
  const [open, setOpen] = useState(false);
  const [noticeData, setNoticeData] = useState<NoticeData[]>([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNoticeState("table");
  };
  const todayDate = new Date();
  const openNoticeModal = () => {
    handleOpen();
  };

  // state 분류 - table, edit, detail
  const [noticeState, setNoticeState] = useState("table");
  const [selectedNotice, setSelectedNotice] =
    useState<NoticeData>(InitNoticeDetailData);

  // 메인공지 팝업
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupNoticeData, setPopupNoticeData] =
    useState<NoticeData>(InitNoticeDetailData);
  const handlePopupClose = () => setPopupOpen(false);
  const refreshData = useCallback(() => {
    const ComparedTodayDate = new Date();
    NoticeApi.getNoticeData("차량", 0, 5)
      .then((res) => {
        setNoticeData(res.data.notice);
        if (
          res.data.notice.length > 0 &&
          res.data.notice[0].mainStartDate &&
          new Date(res.data.notice[0].mainStartDate) < ComparedTodayDate &&
          (res.data.notice[0].mainEndDate === null ||
            new Date(res.data.notice[0].mainEndDate) > ComparedTodayDate)
        ) {
          setPopupNoticeData(res.data.notice[0]);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  useEffect(() => {
    if (popupNoticeData.id && open === false) {
      setPopupOpen(true);
    }
  }, [popupNoticeData.id]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const openDetailNotice = (item: NoticeData) => {
    handleOpen();
    setNoticeState("detail");
    setSelectedNotice(item);
  };

  return (
    <>
      <VehicleRightBoxTitleAlign2
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <VehicleRightBoxTitle item>공지사항</VehicleRightBoxTitle>
        <VehicleRightBoxSideColor item onClick={openNoticeModal}>
          전체보기
        </VehicleRightBoxSideColor>
      </VehicleRightBoxTitleAlign2>

      {noticeData.length > 0 ? (
        <VehicleNoticeContentsBox item>
          <Pc>
            {noticeData.map((item) => {
              return (
                <VehicleNoticeBoxContent
                  container
                  justifyContent="space-between"
                  key={item.id}
                  onClick={() => openDetailNotice(item)}
                >
                  <VehicleNoticeTitleBox item>
                    {item.mainStartDate &&
                    new Date(item.mainStartDate) < todayDate &&
                    (item.mainEndDate === null ||
                      new Date(item.mainEndDate) > todayDate) ? (
                      <VehicleNoticeIcon
                        src="/images/VEHICLE/mainSpeaker.png"
                        alt="주요 공지사항"
                      />
                    ) : (
                      <VehicleNoticeIcon
                        src="/images/VEHICLE/speaker.png"
                        alt="공지사항"
                      />
                    )}

                    {item.title}
                  </VehicleNoticeTitleBox>
                  <Grid item>{item.createdAt?.substring(0, 10)}</Grid>
                </VehicleNoticeBoxContent>
              );
            })}
          </Pc>
          <Mobile>
            {noticeData.map((item) => {
              return (
                <VehicleNoticeBoxContent
                  container
                  justifyContent="space-between"
                  key={uuid()}
                  onClick={() => openDetailNotice(item)}
                >
                  <VehicleNoticeTitleLongBox item>
                    {item.mainStartDate &&
                    new Date(item.mainStartDate) < todayDate &&
                    (item.mainEndDate === null ||
                      new Date(item.mainEndDate) > todayDate) ? (
                      <VehicleNoticeIcon
                        src="/images/VEHICLE/mainSpeaker.png"
                        alt="주요 공지사항"
                      />
                    ) : (
                      <VehicleNoticeIcon
                        src="/images/VEHICLE/speaker.png"
                        alt="공지사항"
                      />
                    )}
                    {item.title}
                  </VehicleNoticeTitleLongBox>
                </VehicleNoticeBoxContent>
              );
            })}
          </Mobile>
        </VehicleNoticeContentsBox>
      ) : (
        <VehicleNoticeContentsBox
          container
          justifyContent="center"
          alignItems="center"
        >
          현재 작성된 공지사항이 없습니다.
        </VehicleNoticeContentsBox>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <VehicleNotice
            handleClose={handleClose}
            noticeState={noticeState}
            setNoticeState={setNoticeState}
            selectedNotice={selectedNotice}
            setSelectedNotice={setSelectedNotice}
            refreshMainData={refreshData}
          />
        </Box>
      </Modal>
      <Modal
        open={popupOpen}
        onClose={handlePopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MidiumModalStyle}>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item xs={12}>
              <RaffleContent>
                <MainTitle>{popupNoticeData.title}</MainTitle>
                <AppliedItem onClick={handlePopupClose}>
                  <X size={32} />
                </AppliedItem>
              </RaffleContent>
            </Grid>
            <Grid
              container
              item
              xs={12}
              mt="10px"
              style={{ minHeight: "calc(100% - 100px)" }}
            >
              <HtmlContent
                data={
                  popupNoticeData.content
                    ? popupNoticeData.content
                    : "작성된 내용이 없습니다."
                }
              ></HtmlContent>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <CustomButtonGreen onClick={handlePopupClose}>
                확인
              </CustomButtonGreen>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default VehicleNoticeBox;
