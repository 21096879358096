import {
  ScheduleComponent,
  Month,
  Inject,
  EventRenderedArgs,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";

import { useEffect, useRef, useState } from "react";

import { ErrorHandler } from "../../../system/ApiService";
import { GRAYPALEDISABLED, TOTALACCOMMODATION } from "../../../styles/color";
import { AdminTabBox, ModalStyle } from "../../../styles/theme";

import {
  AccommodationCircleItem,
  AccommodationEmptyItem,
  ScheduleItemBox,
  ScheduleItemPointerBox,
  ScheduleLegend,
  ScheduleLegendDisabledItem,
  ScheduleLegendItem,
  ScheduleSmallItemBox,
  ScheduleSmallItemDefaultBox,
  ScheduleTitle,
} from "../../../styles/accommodationStyle";

// 모달
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";

import {
  AccommodationRezData,
  Resource,
} from "../../../system/types/Accommodation";
import { InitAccommodationRez } from "../../../system/types/initObject";
import {
  DatepickerStartEndTimeAtom,
  HolidayDataAtom,
  accommodationState,
} from "../../../system/atoms";
import { useRecoilState, useSetRecoilState } from "recoil";

import { ResourceApi } from "../../../system/api/Resource";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import AdminCalendarModal from "./AdminCalendarModal";
import AlertModal from "../../Common/AlertModal";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import { addClass } from "@syncfusion/ej2-base";
import { HolidayApi } from "../../../system/api/HolidayApi";
import { AccommodationApi } from "../../../system/api/AccommodationApi";
interface HolidayProps {
  name: string;
  date: string;
}

function AdminAccommodationCalendar() {
  const [scheduleData, setScheduleData] = useState<any>(null);
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(
    new Date(new Date().getTime() + 8 * 7 * 24 * 60 * 60 * 1000)
  );

  const [holidayList, setHolidayList] = useRecoilState(HolidayDataAtom);
  const scheduleObj = useRef<ScheduleComponent | null>(null);
  const [navigateData, setNavigateData] = useState({});
  const [accommodationRegion, setAccommodationRegion] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedAccommodationData, setSelectedAccommodationData] =
    useState<AccommodationRezData>(InitAccommodationRez);
  var todayYear = new Date().getFullYear();
  const [searchHolidayYear, setSearchHolidayYear] = useState(todayYear);
  const setDateRange = useSetRecoilState(DatepickerStartEndTimeAtom);

  const [resourceData, setResourceData] = useRecoilState(accommodationState);
  const Loading = useLoadingDispatch();
  const [maxDate, setMaxDate] = useState(new Date());

  useEffect(() => {
    handleDateNavigate(navigateData);
  }, [accommodationRegion]);

  const handleDateNavigate = (args: any) => {
    if (args.requestType === "dateNavigate" || "toolBarItemRendered") {
      getReservationCalendar(scheduleObj.current?.selectedDate);
    }
    setNavigateData(args);
  };

  const getReservationCalendar = (selectedDate: Date | undefined) => {
    if (!selectedDate) return;
    setSelectedCalendarDate(selectedDate);
    if (selectedDate.getFullYear() !== searchHolidayYear) {
      HolidayApi.getHoliday(selectedDate.getFullYear()).then((res) => {
        setHolidayList(res.data);
      });
      setSearchHolidayYear(selectedDate.getFullYear());
    }
    if (selectedDate.getMonth() !== selectedCalendarDate.getMonth()) {
      getReservationCalendarData(selectedDate, accommodationRegion);
    }
  };

  const getReservationCalendarData = (
    selectedDate?: Date | undefined,
    region?: string | ""
  ) => {
    Loading({ type: "LOADING" });
    AccommodationApi.getReservationCalendar(
      selectedDate
        ? selectedDate.getFullYear()
        : selectedCalendarDate.getFullYear(),
      selectedDate
        ? selectedDate.getMonth() + 1
        : selectedCalendarDate.getMonth() + 1,
      region
    )
      .then((res) => {
        setScheduleData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  };

  function onEventRendered(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.CategoryColor as string;

    if (!args.element || !categoryColor) {
      return;
    } else {
      args.element.style.border = "0px";
      args.element.style.backgroundColor = "transparent";
      args.element.style.color = categoryColor;
      args.element.style.marginTop = `${args?.data?.Index * 1}px`;
      args.element.style.height = "25px";
      args.element.style.fontSize = "0.8rem";
      if (args.data.Data.TotalCnt !== 0) {
        args.element.style.cursor = "pointer";
      }
    }
  }
  const onClickRez = (dataItem: any) => {
    setSelectedAccommodationData(dataItem);
    var EndDate = dataItem.EndTime;
    var selectedEndYear = new Date(EndDate).getFullYear();
    var selectedEndMonth = new Date(EndDate).getMonth();
    var selectedEndDate = new Date(EndDate).getDate();
    setDateRange([
      new Date(dataItem.StartTime),
      new Date(selectedEndYear, selectedEndMonth, selectedEndDate + 1),
    ]);
    handleOpen();
  };

  function onDataBinding(e: any) {
    let items = e.result;
    let ScheduleData = [];
    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        if (items[i].RafflePeriod === "true") {
          ScheduleData.push({
            Id: items[i].Id,
            Subject: items[i].Subject + items[i].TotalCnt,
            Location: items[i].Subject,
            StartTime: new Date(items[i].StartTime),
            EndTime: new Date(items[i].EndTime),
            IsAllDay: true,
            CategoryColor: items[i].CategoryColor,
            Data: items[i],
          });
        } else {
          var RoomItem = "";
          for (var j = 0; j < items[i].ConfirmedCnt; j++) {
            RoomItem = RoomItem + " ⬤";
          }
          for (var j = 0; j < items[i].RoomCnt - items[i].ConfirmedCnt; j++) {
            RoomItem = RoomItem + " ⛒";
          }

          ScheduleData.push({
            Id: items[i].Id,
            Subject: items[i].Subject,
            StartTime: new Date(items[i].StartTime),
            EndTime: new Date(items[i].EndTime),
            IsAllDay: true,
            CategoryColor: items[i].CategoryColor,
            Data: items[i],
          });
        }
      }
    }
    e.result = ScheduleData;
  }

  const template = (props: any) => {
    var CircleItem = [];
    var EmptyCircleItem = [];
    var CircleCnt = props.Data.ConfirmedCnt;
    var EmptyCircleCnt = props.Data.RoomCnt - props.Data.ConfirmedCnt;
    for (var i = 0; i < CircleCnt; i++) {
      CircleItem.push("⬤");
    }
    for (var i = 0; i < EmptyCircleCnt; i++) {
      EmptyCircleItem.push("◍");
    }

    if (accommodationRegion === "") {
      if (props.Data.RafflePeriod) {
        return (
          <>
            {props.Data.TotalCnt !== 0 ? (
              <ScheduleItemPointerBox
                color={props.CategoryColor}
                onClick={() => onClickRez(props)}
              >
                <ScheduleSmallItemBox color={props.CategoryColor}>
                  {props.Subject} {props.Data.TotalCnt}
                </ScheduleSmallItemBox>
              </ScheduleItemPointerBox>
            ) : (
              <ScheduleItemBox color={GRAYPALEDISABLED}>
                <ScheduleSmallItemDefaultBox color={GRAYPALEDISABLED}>
                  {props.Subject}
                </ScheduleSmallItemDefaultBox>
              </ScheduleItemBox>
            )}
          </>
        );
      } else {
        if (props.Data.TotalCnt !== 0 || props.Data.ConfirmedCnt !== 0) {
          return (
            <ScheduleItemPointerBox
              color={props.CategoryColor}
              onClick={() => onClickRez(props)}
            >
              <ScheduleSmallItemBox color={props.CategoryColor}>
                {props.Subject}
              </ScheduleSmallItemBox>
              {CircleItem.map((item, index) => {
                return (
                  <AccommodationCircleItem
                    key={"emptycircle" + index}
                  ></AccommodationCircleItem>
                );
              })}
              {EmptyCircleItem.map((item, index) => {
                return (
                  <AccommodationEmptyItem
                    key={"circle" + index}
                  ></AccommodationEmptyItem>
                );
              })}
            </ScheduleItemPointerBox>
          );
        } else {
          return (
            <ScheduleItemBox color={GRAYPALEDISABLED}>
              <ScheduleSmallItemDefaultBox color={GRAYPALEDISABLED}>
                {props.Subject}
              </ScheduleSmallItemDefaultBox>

              {EmptyCircleItem.map((item, index) => {
                return (
                  <AccommodationEmptyItem
                    key={"circle" + index}
                  ></AccommodationEmptyItem>
                );
              })}
              {CircleItem.map((item, index) => {
                return (
                  <AccommodationEmptyItem
                    key={"emptycircle" + index}
                  ></AccommodationEmptyItem>
                );
              })}
            </ScheduleItemBox>
          );
        }
      }
    } else {
      // 각 휴양소별
      if (props.Data.RafflePeriod) {
        return (
          <>
            {props.Data.TotalCnt !== 0 ? (
              <ScheduleItemPointerBox
                color={props.CategoryColor}
                onClick={() => onClickRez(props)}
              >
                <ScheduleSmallItemBox color={props.CategoryColor}>
                  {props.Subject} {props.Data.TotalCnt}
                </ScheduleSmallItemBox>
              </ScheduleItemPointerBox>
            ) : (
              <ScheduleItemBox color={GRAYPALEDISABLED}>
                <ScheduleSmallItemDefaultBox color={GRAYPALEDISABLED}>
                  {props.Subject}
                </ScheduleSmallItemDefaultBox>
              </ScheduleItemBox>
            )}
          </>
        );
      } else {
        if (props.Data.TotalCnt !== 0 || props.Data.ConfirmedCnt !== 0) {
          return (
            <ScheduleItemPointerBox
              color={props.CategoryColor}
              onClick={() => onClickRez(props)}
            >
              <ScheduleSmallItemBox color={props.CategoryColor}>
                {props.Subject} {props.Data.WinnerName}
              </ScheduleSmallItemBox>
            </ScheduleItemPointerBox>
          );
        } else {
          return (
            <ScheduleItemBox color={GRAYPALEDISABLED}>
              <ScheduleSmallItemDefaultBox color={GRAYPALEDISABLED}>
                {props.Subject}
              </ScheduleSmallItemDefaultBox>
            </ScheduleItemBox>
          );
        }
      }
    }
  };

  const getHoliday = (value: Date) => {
    var foundDate: HolidayProps[] = holidayList.filter(
      (e: HolidayProps) => e.date === isoDateFormatter(value)
    );

    if (foundDate.length > 0) {
      return `<div class="testClass">${foundDate[0].name}</div>`;
    } else {
      return "<div></div>";
    }
  };

  const onRenderCell = (args: any) => {
    if (rafflePeriodList.includes(isoDateFormatter(args.date))) {
      addClass([args.element], "raffleperiod");
    }
    if (args.elementType === "monthCells" && window.innerWidth >= 650) {
      let ele = document.createElement("div");
      if (getHoliday(args.date)) {
        ele.innerHTML = getHoliday(args.date);
      }

      args.element.appendChild(ele.firstChild);
    }
  };

  const changeRegion = (region: string) => {
    if (region === "전체") {
      setAccommodationRegion("");
      getReservationCalendarData(undefined, "");
    } else {
      setAccommodationRegion(region);
      getReservationCalendarData(undefined, region);
    }
  };

  const onPopupOpen = (args: any) => {
    args.cancel = true;
  };

  function legendCircle(region: string, color: string) {
    if (accommodationRegion === "") {
      if (region === "전체") {
        return (
          <ScheduleLegendItem
            color={color}
            container
            alignItems="center"
            justifyContent="center"
          >
            {region}
          </ScheduleLegendItem>
        );
      } else
        return (
          <ScheduleLegendItem
            color={color}
            container
            alignItems="center"
            justifyContent="center"
          >
            {region}
          </ScheduleLegendItem>
        );
    } else if (region === accommodationRegion) {
      return (
        <ScheduleLegendItem
          color={color}
          container
          alignItems="center"
          justifyContent="center"
        >
          {region}
        </ScheduleLegendItem>
      );
    } else {
      return (
        <ScheduleLegendDisabledItem
          color={color}
          container
          alignItems="center"
          justifyContent="center"
        >
          {region}
        </ScheduleLegendDisabledItem>
      );
    }
  }

  // Date
  var todayDay = new Date().getDay();
  var todayHour = new Date().getHours();
  var todayMinute = new Date().getMinutes();

  // rafflePeriod list 담기
  const today = new Date();
  const daysUntilWednesday = (3 - todayDay + 7) % 7;

  const [rafflePeriodList, setRafflePeriodList] = useState<
    (string | undefined)[]
  >([]);

  // maxDate
  var firstMaxDate = new Date(
    today.getTime() + (5 * 7 + daysUntilWednesday - 1) * 24 * 60 * 60 * 1000
  );
  var secondMaxDate = new Date(
    today.getTime() + (4 * 7 + daysUntilWednesday - 1) * 24 * 60 * 60 * 1000
  );

  useEffect(() => {
    if (holidayList.length === 0) {
      HolidayApi.getHoliday(today.getFullYear()).then((res) => {
        setHolidayList(res.data);
      });
    }

    const preRafflePeriodList = [];
    if (
      (todayDay === 3 && todayHour === 10 && todayMinute > 4) ||
      (todayDay === 3 && todayHour > 10)
    ) {
      for (var i = 0; i < 7; i++) {
        preRafflePeriodList.push(
          isoDateFormatter(
            new Date(
              today.getTime() +
                (4 * 7 + daysUntilWednesday + i) * 24 * 60 * 60 * 1000
            )
          )
        );
      }
      setRafflePeriodList(preRafflePeriodList);
    } else {
      for (var i = 0; i < 7; i++) {
        preRafflePeriodList.push(
          isoDateFormatter(
            new Date(
              today.getTime() +
                (3 * 7 + daysUntilWednesday + i) * 24 * 60 * 60 * 1000
            )
          )
        );
      }
      setRafflePeriodList(preRafflePeriodList);
    }
    if (
      (todayDay === 3 && todayHour === 10 && todayMinute > 4) ||
      (todayDay === 3 && todayHour > 10)
    ) {
      setMaxDate(firstMaxDate);
    } else {
      setMaxDate(secondMaxDate);
    }
    if (resourceData.length === 0) {
      Loading({ type: "LOADING" });
      ResourceApi.getResource("휴양소")
        .then((res) => {
          setResourceData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
  }, []);

  return (
    <AdminTabBox>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        mb="10px"
      >
        <Grid onClick={() => changeRegion("전체")} item margin="5px">
          {legendCircle("전체", TOTALACCOMMODATION)}
        </Grid>
        {resourceData.map((region: Resource, idx: number) => (
          <Grid
            key={idx}
            onClick={() => changeRegion(region.name)}
            item
            margin="5px"
          >
            {legendCircle(region.name, region.color)}
          </Grid>
        ))}
      </Grid>
      <ScheduleComponent
        currentView="Month"
        actionComplete={handleDateNavigate}
        eventSettings={{
          dataSource: scheduleData,
          template: template as any,
        }}
        ref={scheduleObj}
        eventRendered={onEventRendered}
        readonly={true}
        dataBinding={onDataBinding}
        locale="ko"
        rowAutoHeight={true}
        popupOpen={onPopupOpen}
        renderCell={onRenderCell}
        maxDate={maxDate}
      >
        <ViewsDirective>
          <ViewDirective option="Month" />
        </ViewsDirective>
        <Inject services={[Month]} />
      </ScheduleComponent>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <AdminCalendarModal
            selectedAccommodationData={selectedAccommodationData}
            handleClose={handleClose}
            accommodationRegion={accommodationRegion}
          />
        </Box>
      </Modal>
    </AdminTabBox>
  );
}

export default AdminAccommodationCalendar;
