import { useHistory, useLocation } from "react-router-dom";
import {
  HeaderMyMenu,
  MyOpenHeaderText,
  OpenHeaderBox,
  OpenHeaderText,
} from "../../styles/headerStyle";
import {
  RESERVATION_TYPE,
  RESERVATION_TYPE_BRANCH,
} from "../../system/Constants";
import { MypageRoutes } from "../../system/types/type";
import { useRecoilState, useRecoilValue } from "recoil";
import { BranchEmployeeCheck, MypageTabValue } from "../../system/atoms";
import { useMsal } from "@azure/msal-react";

function OpenHeader() {
  const history = useHistory();
  const locationNow = useLocation();
  const { instance } = useMsal();
  const branchEmployeeCheck = useRecoilValue(BranchEmployeeCheck);
  const reservationType = branchEmployeeCheck
    ? RESERVATION_TYPE_BRANCH
    : RESERVATION_TYPE;
  const onMoveSite = (route: string) => {
    if (
      locationNow.pathname.includes("/vehicle") ||
      locationNow.pathname.includes("/availableVehicle")
    ) {
      setTabValue(1);
    } else {
      setTabValue(0);
    }
    history.push({
      pathname: `${route}`,
    });
  };
  const [, setTabValue] = useRecoilState(MypageTabValue);

  function signOutClickHandler() {
    instance.logout();
    window.localStorage.clear();
  }

  function changeAccount() {
    window.localStorage.clear();
    window.location.reload();
  }

  return (
    <OpenHeaderBox>
      <div style={{ display: "flex" }}>
        {reservationType.map((item, index) => {
          return (
            <div key={index}>
              {item.child?.map((childItem, idx) => {
                return (
                  <OpenHeaderText
                    key={childItem.text}
                    onClick={() => onMoveSite(childItem.routes)}
                  >
                    {childItem.text}
                  </OpenHeaderText>
                );
              })}
            </div>
          );
        })}
      </div>

      <HeaderMyMenu>
        <MyOpenHeaderText onClick={() => onMoveSite(`${MypageRoutes.root}`)}>
          나의 예약
        </MyOpenHeaderText>
        <MyOpenHeaderText onClick={changeAccount}>계정 변경</MyOpenHeaderText>
        <MyOpenHeaderText onClick={signOutClickHandler}>
          로그아웃
        </MyOpenHeaderText>
      </HeaderMyMenu>
    </OpenHeaderBox>
  );
}

export default OpenHeader;
