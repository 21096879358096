import {
  Box,
  Grid,
  Modal,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  VehicleRightBoxTitleAlign,
  VehicleRightBoxSideColor,
  VehicleMyBoxMainBox,
  VehicleMyBoxYear,
  VehicleMyBoxMonth,
  VehicleMyBoxTitle,
  VehicleMyBoxContent,
  VehicleMyBoxContents,
  VehicleMyBoxContentsBox,
  VehicleRightBoxTitle,
  VehicleMyBoxAlign,
  VehicleMyBoxPointer,
} from "../../styles/vehicleStyle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useCallback, useEffect, useState } from "react";
import { useLoadingDispatch } from "../../system/context/LoadingContext";
import { ReservationApi } from "../../system/api/ReservationApi";
import { VehicleLastVehicleInfo } from "../../system/types/Vehicle";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import { useHistory } from "react-router-dom";
import { MypageRoutes } from "../../system/types/type";
import { useRecoilState, useRecoilValue } from "recoil";
import { BranchEmployeeDataAtom, MypageTabValue } from "../../system/atoms";
import { isoDateFormatter } from "../Common/IsoDateFormatter";
import { GRAYPALECONTENTS } from "../../styles/color";
import {
  AppliedItem,
  MainTitle,
  ModalStyle,
  RaffleContent,
  StyledBody,
  StyledHead1,
} from "../../styles/theme";
import { X } from "@phosphor-icons/react";
import { VehiclePc } from "../../pages/Layout/ResponsiveScreen";

interface VehicleMyBoxProps {
  myBoxState: string;
}

function VehicleMyBox({ myBoxState }: VehicleMyBoxProps) {
  const [reservationYear, setReservationYear] = useState(
    new Date().getFullYear()
  );
  const [reservationMonth, setReservationMonth] = useState(
    new Date().getMonth() + 1
  );
  const Loading = useLoadingDispatch();
  const history = useHistory();
  const [, setTabValue] = useRecoilState(MypageTabValue);
  const openMyReservationModal = () => {
    history.push({
      pathname: `${MypageRoutes.root}`,
    });
    setTabValue(1);
  };
  const [myVehicleData, setMyVehicleData] = useState<VehicleLastVehicleInfo[]>(
    []
  );
  const branchEmployee = useRecoilValue(BranchEmployeeDataAtom);

  const refreshReservationData = useCallback(() => {
    Loading({ type: "LOADING" });
    ReservationApi.getMyReservation(
      "차량",
      isoDateFormatter(new Date(reservationYear, reservationMonth - 1, 1)),
      isoDateFormatter(new Date(reservationYear, reservationMonth, 0)),
      branchEmployee.id
    )
      .then((res) => {
        setMyVehicleData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  }, [Loading, branchEmployee.id, reservationMonth, reservationYear]);

  useEffect(() => {
    refreshReservationData();
  }, [refreshReservationData]);

  const changeDate = (type: string) => {
    if (type === "up") {
      const newReservationMonth = reservationMonth + 1;
      if (newReservationMonth === 13) {
        setReservationYear(reservationYear + 1);
        setReservationMonth(1);
      } else {
        setReservationMonth(newReservationMonth);
      }
    } else {
      const newReservationMonth = reservationMonth - 1;
      if (newReservationMonth === 0) {
        setReservationMonth(12);
        setReservationYear(reservationYear - 1);
      } else {
        setReservationMonth(newReservationMonth);
      }
    }
  };
  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedData, setSelectedData] = useState<VehicleLastVehicleInfo[]>(
    []
  );
  const onChangeSelectedStatus = (
    status: string,
    dt: VehicleLastVehicleInfo[]
  ) => {
    setSelectedStatus(status);
    setSelectedData(dt);
    setOpen(true);
  };
  return (
    <>
      <VehicleRightBoxTitleAlign
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <VehicleRightBoxTitle item>나의 예약</VehicleRightBoxTitle>
        <VehicleRightBoxSideColor item onClick={openMyReservationModal}>
          전체보기
        </VehicleRightBoxSideColor>
      </VehicleRightBoxTitleAlign>

      <VehicleMyBoxMainBox container justifyContent="center">
        <VehicleMyBoxAlign
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <VehicleMyBoxContentsBox item xs={5}>
            <VehicleMyBoxYear>{reservationYear}</VehicleMyBoxYear>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <VehicleMyBoxPointer
                onClick={() => changeDate("down")}
                item
                pl="7px"
              >
                <ArrowBackIosIcon />
              </VehicleMyBoxPointer>
              <VehicleMyBoxMonth item>{reservationMonth}</VehicleMyBoxMonth>
              <VehicleMyBoxPointer
                onClick={() => changeDate("up")}
                item
                pl="5px"
              >
                <ArrowForwardIosIcon />
              </VehicleMyBoxPointer>
            </Grid>
          </VehicleMyBoxContentsBox>
          <VehicleMyBoxContentsBox item xs={6}>
            <Grid container justifyContent="center">
              <VehicleMyBoxContents container>
                <VehicleMyBoxTitle item xs={6}>
                  예약중
                </VehicleMyBoxTitle>
                <VehicleMyBoxContent item xs={6}>
                  {myVehicleData.filter((item) => item.status === "예약중")
                    .length > 0 ? (
                    <NumberOfMyReservationBox
                      onClick={() => {
                        onChangeSelectedStatus(
                          "예약중",
                          myVehicleData.filter(
                            (item) => item.status === "예약중"
                          )
                        );
                      }}
                    >
                      {myVehicleData.filter((item) => item.status === "예약중")
                        .length + " 건"}
                    </NumberOfMyReservationBox>
                  ) : (
                    <>0 건</>
                  )}
                </VehicleMyBoxContent>
              </VehicleMyBoxContents>
              <VehicleMyBoxContents container>
                <VehicleMyBoxTitle item xs={6}>
                  이용중
                </VehicleMyBoxTitle>
                <VehicleMyBoxContent item xs={6}>
                  {myVehicleData.filter((item) => item.status === "이용중")
                    .length > 0 ? (
                    <NumberOfMyReservationBox
                      onClick={() => {
                        onChangeSelectedStatus(
                          "이용중",
                          myVehicleData.filter(
                            (item) => item.status === "이용중"
                          )
                        );
                      }}
                    >
                      {myVehicleData.filter((item) => item.status === "이용중")
                        .length + " 건"}
                    </NumberOfMyReservationBox>
                  ) : (
                    <>0 건</>
                  )}
                </VehicleMyBoxContent>
              </VehicleMyBoxContents>
              <VehicleMyBoxContents container>
                <VehicleMyBoxTitle item xs={6}>
                  이용완료
                </VehicleMyBoxTitle>
                <VehicleMyBoxContent item xs={6}>
                  {myVehicleData.filter((item) => item.status === "이용완료")
                    .length > 0 ? (
                    <NumberOfMyReservationBox
                      onClick={() => {
                        onChangeSelectedStatus(
                          "이용완료",
                          myVehicleData.filter(
                            (item) => item.status === "이용완료"
                          )
                        );
                      }}
                    >
                      {myVehicleData.filter(
                        (item) => item.status === "이용완료"
                      ).length + " 건"}
                    </NumberOfMyReservationBox>
                  ) : (
                    <>0 건</>
                  )}
                </VehicleMyBoxContent>
              </VehicleMyBoxContents>
            </Grid>
          </VehicleMyBoxContentsBox>
        </VehicleMyBoxAlign>
      </VehicleMyBoxMainBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RaffleContent>
                <MainTitle>나의 예약 - {selectedStatus}</MainTitle>
                <AppliedItem onClick={handleClose}>
                  <X size={32} />
                </AppliedItem>
              </RaffleContent>
            </Grid>
            <Grid container mt="10px">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledHead1 align="center">예약일</StyledHead1>
                      <StyledHead1 align="center">시간대</StyledHead1>
                      <StyledHead1 align="center">목적지</StyledHead1>
                      <VehiclePc>
                        <StyledHead1 align="center">출발</StyledHead1>
                        <StyledHead1 align="center">도착</StyledHead1>
                      </VehiclePc>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedData.map((item: VehicleLastVehicleInfo, idx) => {
                      return (
                        <TableRow key={idx}>
                          <StyledBody align="center">
                            {item.startDate}
                          </StyledBody>
                          <StyledBody align="center">{item.time}</StyledBody>
                          <StyledBody align="center">
                            {item.waypoints[0]?.placeName ? (
                              <>
                                {item.waypoints.length > 1
                                  ? item.waypoints[0]?.placeName +
                                    " 외 " +
                                    item.waypoints.length +
                                    "곳"
                                  : item.waypoints[0]?.placeName}
                              </>
                            ) : (
                              <>
                                {item.waypoints.length > 1
                                  ? item.waypoints[0]?.addressName +
                                    " 외 " +
                                    item.waypoints.length +
                                    "곳"
                                  : item.waypoints[0]?.addressName}
                              </>
                            )}
                          </StyledBody>
                          <VehiclePc>
                            <StyledBody align="center">
                              <Grid container>
                                <Grid item xs={12}>
                                  {item.realStartTime
                                    ? item.realStartTime
                                    : "출발 정보 없음"}
                                </Grid>

                                {item.startMileage && (
                                  <Grid item xs={12}>
                                    {item.startMileage.toLocaleString() +
                                      " " +
                                      item.vehicle.unitType}
                                  </Grid>
                                )}
                              </Grid>
                            </StyledBody>
                            <StyledBody align="center">
                              <Grid container>
                                <Grid item xs={12}>
                                  {item.realEndTime
                                    ? item.realEndTime
                                    : "도착 정보 없음"}
                                </Grid>

                                {item.endMileage && (
                                  <Grid item xs={12}>
                                    {" "}
                                    {item.endMileage.toLocaleString() +
                                      " " +
                                      item.vehicle.unitType}
                                  </Grid>
                                )}
                              </Grid>
                            </StyledBody>
                          </VehiclePc>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default VehicleMyBox;

const NumberOfMyReservationBox = styled(Grid)(() => ({
  cursor: "pointer",
  textDecoration: `underline ${GRAYPALECONTENTS}`,
}));
