import { VehicleInfo } from "../../../system/types/Vehicle";
import { StyledBody, StyledHead1, StyledHead2 } from "../../../styles/theme";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MyAccommodationChip from "../../Common/Accommodation/MyAccommodationChip";
import { CustomButtonYellow } from "../../../styles/button";
import { useState } from "react";
import VehicleEdit from "./VehicleEdit";
import { InitVehicleInfo } from "../../../system/types/initObject";
interface VehicleManagementTableProps {
  vehicleData: VehicleInfo[];
  refreshData: () => void;
}

function VehicleManagementTable({
  vehicleData,
  refreshData,
}: VehicleManagementTableProps) {
  const [data, setData] = useState<VehicleInfo>(InitVehicleInfo);
  const [open, setOpen] = useState(false);
  const handleOpen = (item: VehicleInfo) => {
    setOpen(true);
    setData(item);
  };
  const handleClose = () => setOpen(false);
  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHead1 align="center"></StyledHead1>
              <StyledHead2 align="center">차량</StyledHead2>
              <StyledHead2 align="center">누적 주행 거리 (시간)</StyledHead2>
              <StyledHead1 align="center">연료</StyledHead1>
              <StyledHead1 align="center">인원수</StyledHead1>
              <StyledHead2 align="center">차량 보험</StyledHead2>
              <StyledHead1 align="center"></StyledHead1>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicleData.map((item, idx) => {
              return (
                <TableRow key={idx}>
                  <StyledBody align="center">
                    <MyAccommodationChip status={item.usageType} />
                  </StyledBody>
                  <StyledBody align="center">
                    <Grid container>
                      <Grid item xs={12}>
                        {item.name}
                      </Grid>
                      <Grid item xs={12}>
                        {item.number}
                      </Grid>
                    </Grid>
                  </StyledBody>
                  <StyledBody align="center">
                    {item.totalMileage.toLocaleString() + " " + item.unitType}
                  </StyledBody>
                  <StyledBody align="center">{item.fuelType}</StyledBody>
                  <StyledBody align="center">
                    {item.seater + " 인승"}
                  </StyledBody>
                  <StyledBody align="center">
                    <Grid container>
                      <Grid item xs={12}>
                        {item.insurance.company +
                          " (만 " +
                          item.insurance.applicableAge +
                          "세)"}
                      </Grid>
                      <Grid item xs={12}>
                        {item.insurance.contact}
                      </Grid>
                    </Grid>
                  </StyledBody>
                  <StyledBody align="center">
                    <CustomButtonYellow onClick={() => handleOpen(item)}>
                      수정
                    </CustomButtonYellow>
                  </StyledBody>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <VehicleEdit
          type="수정"
          open={open}
          handleClose={handleClose}
          data={data}
          setData={setData}
          refreshData={refreshData}
        />
      )}
    </Grid>
  );
}

export default VehicleManagementTable;
