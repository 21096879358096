import { Grid, IconButton, TextField, Tooltip } from "@mui/material";
import {
  AppliedItem,
  InputBox,
  MainTitle,
  MaxWidth,
  RaffleContent,
  ReservationTextarea,
  ValidationNoMarginText,
  ValidationText,
} from "../../styles/theme";
import { Autocomplete, Box } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import {
  RecurrenceData,
  ReminderData,
  ReminderProblemList,
} from "../../system/types/Reminder";
import {
  InitRecurrence,
  InitReminderData,
} from "../../system/types/initObject";

import {
  AutoCompleteNoOptionsText,
  AutocompleteBox,
  AutocompleteNameBox,
  RecurrenceDropdownMobileBox,
  RecurrenceDropdownMobileComponent,
  ReminderModalContentBox,
  ReminderModalContentNoMarginBox,
  ReminderModalMainBox,
  ReminderModalMainNoMarginBox,
  ReminderModalMainStartBox,
  ReminderModalPicBox,
  ReminderModalPicNoMarginBox,
  ReminderModalTitleBox,
  ReminderModalTitleNoMarginBox,
  ReminderSaveButtonBox,
  ReminderSelectedPicBox,
  RemindermodalContenstBox,
  RemindermodalContentMaxWidth,
  TooltipText,
} from "../../styles/reminderStyle";
import { MyInfo } from "../../system/types/Employee";
import { useLoadingDispatch } from "../../system/context/LoadingContext";
import { EmployeeApi } from "../../system/api/EmployeeApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import { CustomButtonGreen } from "../../styles/button";
import { MobileSmall, Tablet } from "../../pages/Layout/ResponsiveScreen";
import { X } from "@phosphor-icons/react";
import { ReminderApi } from "../../system/api/ReminderApi";
import ReminderRepeat from "./ReminderRepeat";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Checkbox from "@mui/material/Checkbox";
import {
  GREENCONTENTS,
  ORANGECONTENTS,
  PRIMARY,
  REDTITLE,
} from "../../styles/color";
import { useRecoilState } from "recoil";
import { PicListAtom } from "../../system/atoms";
import Swal from "sweetalert2";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
interface ReminderModalProps {
  handleClose: () => void;
  refreshData?: () => void;
  detailedData?: ReminderData;
}

function validation(data: ReminderData, repeatData: RecurrenceData) {
  if (data.title === "") {
    AlertModal("check", "리마인더 제목을 작성해주세요.");
    return false;
  } else if (data?.pic?.id === 0) {
    AlertModal("check", "담당자를 지정해주세요.");
    return false;
  } else if (data?.manager?.id === 0) {
    AlertModal("check", "관리자를 지정해주세요.");
    return false;
  } else if (data.startDate === "" || data.startDate === null) {
    AlertModal("check", "리마인더 구간 시작 날짜를 지정해주세요.");
    return false;
  } else if (data.content === "") {
    AlertModal("check", "리마인더에 대한 설명을 작성해주세요.");
    return false;
  } else if (data.pic?.id === data.manager?.id) {
    AlertModal("check", "담당자와 관리자를 다르게 설정해주세요. ");
    return false;
  } else if (
    repeatData.recurrenceEndDate &&
    new Date(data.startDate) > new Date(repeatData.recurrenceEndDate)
  ) {
    AlertModal(
      "check",
      "최종 종료 날짜를 리마인더 시작 날짜 이후로 변경해주세요. "
    );
    return false;
  }
  return true;
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function Reminder({
  handleClose,
  refreshData,
  detailedData,
}: ReminderModalProps) {
  const Loading = useLoadingDispatch();
  const [data, setData] = useState<ReminderData>(InitReminderData);
  const [repeatData, setRepeatData] = useState<RecurrenceData>(InitRecurrence);
  const [picListData, setPicListData] = useRecoilState(PicListAtom);

  var submitcheck = false;
  const onChange = (args: any, tgName?: string, tgContent?: any) => {
    if (
      tgName === "pic" ||
      tgName === "deputyPic" ||
      tgName === "manager" ||
      tgName === "deputyManager"
    ) {
      setData({
        ...data,
        [tgName]: tgContent,
      });
    } else {
      setData({
        ...data,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [(event.target as HTMLInputElement).name]: event.target.checked,
    });
  };
  useEffect(() => {
    if (picListData.length === 0) {
      Loading({ type: "LOADING" });

      EmployeeApi.getEmployee("본사", true)
        .then((res) => {
          const activeData = res.data.filter(
            (item: MyInfo) => item.active === true
          );
          setPicListData(activeData);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
    if (detailedData?.id) {
      setData(detailedData);
      setRepeatData(detailedData.recurrence);
    }
  }, []);

  const onSave = () => {
    if (submitcheck === false && validation(data, repeatData)) {
      submitcheck = true;
      Loading({ type: "LOADING" });
      if (detailedData?.id) {
        ReminderApi.updateReminder({ ...data, recurrence: repeatData })
          .then(() => {
            AlertModal("success", "리마인더가 수정되었습니다.");
            handleClose();
            if (refreshData) {
              refreshData();
            }
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
            submitcheck = false;
          });
      } else {
        submitcheck = true;
        ReminderApi.createReminder({ ...data, recurrence: repeatData })
          .then(() => {
            AlertModal("success", "리마인더가 등록되었습니다.");
            handleClose();
            if (refreshData) {
              refreshData();
            }
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
            submitcheck = false;
          });
      }
    }
  };

  // warning
  const [problemData, setProblemData] = useState<ReminderProblemList[]>([]);

  useEffect(() => {
    setProblemData(
      data.problems.filter((dt: ReminderProblemList) => dt.isChecked === false)
    );
  }, []);

  const checkWarning = (id: number, type: String, message: string) => {
    if (type === "WARNING") {
      Swal.fire({
        title: "경고창을 확인하셨습니까?",
        text: message + " 문제가 확인 처리됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          Loading({ type: "LOADING" });
          ReminderApi.checkWarning(id)
            .then(() => {
              AlertModal("success", "해당 문제가 확인 처리되었습니다.");
              if (refreshData !== undefined) {
                refreshData();
              }
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              Loading({ type: "COMPLETE" });
            });
        }
      });
    } else {
      Swal.fire({
        title: message + " 문제를 해결해주세요.",
        icon: "error",
        confirmButtonText: "확인",
        confirmButtonColor: GREENCONTENTS,
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaxWidth>
            <RaffleContent>
              {detailedData?.id ? (
                <MainTitle>리마인더 수정</MainTitle>
              ) : (
                <MainTitle>리마인더 신규 등록</MainTitle>
              )}

              <AppliedItem onClick={handleClose}>
                <X size={32} />
              </AppliedItem>
            </RaffleContent>
            <RemindermodalContenstBox>
              <RemindermodalContentMaxWidth>
                {/* 제목 추가 */}
                <ReminderModalMainBox>
                  <Tablet>
                    <ReminderModalTitleBox>제목</ReminderModalTitleBox>
                  </Tablet>
                  <ReminderModalContentBox>
                    <InputBox
                      type="text"
                      name="title"
                      value={data.title || ""}
                      onChange={onChange}
                      placeholder="제목 추가"
                    />
                  </ReminderModalContentBox>
                </ReminderModalMainBox>
                <ReminderModalMainNoMarginBox>
                  <Tablet>
                    <ReminderModalTitleBox></ReminderModalTitleBox>
                  </Tablet>
                  <ReminderModalContentNoMarginBox>
                    {!data.title ? (
                      <ValidationText>
                        <ErrorOutlineIcon fontSize="small" /> 제목을
                        작성해주세요.
                      </ValidationText>
                    ) : null}
                  </ReminderModalContentNoMarginBox>
                </ReminderModalMainNoMarginBox>

                <ReminderRepeat
                  data={data}
                  setData={setData}
                  repeatData={repeatData}
                  setRepeatData={setRepeatData}
                />
                {/* Azure 등록 여부 추가 */}
                <ReminderModalMainBox>
                  <Tablet>
                    <ReminderModalTitleBox></ReminderModalTitleBox>
                  </Tablet>
                  <Checkbox
                    {...label}
                    name="azureEventReg"
                    checked={data.azureEventReg}
                    onChange={handleChange}
                    sx={{
                      color: PRIMARY,
                      "&.Mui-checked": {
                        color: PRIMARY,
                      },
                      marginLeft: "-10px",
                    }}
                  />{" "}
                  담당자 및 관리자의 일정에 자동 등록
                </ReminderModalMainBox>
                {/* 담당자, 관리자 추가 */}
                <ReminderModalMainBox>
                  <Tablet>
                    <ReminderModalTitleBox>담당자</ReminderModalTitleBox>
                    <ReminderModalPicBox>
                      <ReminderSelectedPicBox>
                        <Autocomplete
                          options={picListData}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="담당자" />
                          )}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, option: MyInfo) => (
                            <Box component="li" {...props}>
                              <AutocompleteNameBox>
                                {option.name}
                              </AutocompleteNameBox>
                              <AutocompleteBox>{`\t ${option.team}, ${option.jobPosition}`}</AutocompleteBox>
                            </Box>
                          )}
                          onChange={(args, value: any) =>
                            onChange(args, "pic", value)
                          }
                          isOptionEqualToValue={(
                            option: MyInfo,
                            value: MyInfo
                          ) => option.id === value.id}
                          value={data.pic || null}
                          defaultChecked={true}
                          noOptionsText={
                            <AutoCompleteNoOptionsText>
                              해당자 없음
                            </AutoCompleteNoOptionsText>
                          }
                        />
                      </ReminderSelectedPicBox>
                    </ReminderModalPicBox>
                    <ReminderModalTitleBox>부담당자</ReminderModalTitleBox>
                    <ReminderModalPicBox>
                      <ReminderSelectedPicBox>
                        <Autocomplete
                          options={picListData}
                          getOptionLabel={(option) => option.name || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="부담당자 (선택사항)"
                            />
                          )}
                          renderOption={(props, option: MyInfo) => (
                            <Box component="li" {...props}>
                              <AutocompleteNameBox>
                                {option.name}
                              </AutocompleteNameBox>
                              <AutocompleteBox>{`\t ${option.team}, ${option.jobPosition}`}</AutocompleteBox>
                            </Box>
                          )}
                          onChange={(args, value: any) =>
                            onChange(args, "deputyPic", value)
                          }
                          value={data.deputyPic || null}
                          isOptionEqualToValue={(
                            option: MyInfo,
                            value: MyInfo
                          ) => option.id === value.id}
                          defaultChecked={true}
                          noOptionsText={
                            <AutoCompleteNoOptionsText>
                              해당자 없음
                            </AutoCompleteNoOptionsText>
                          }
                        />
                      </ReminderSelectedPicBox>
                    </ReminderModalPicBox>
                  </Tablet>

                  <MobileSmall>
                    <RecurrenceDropdownMobileBox>
                      <RecurrenceDropdownMobileComponent>
                        <Autocomplete
                          options={picListData}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="담당자" />
                          )}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, option: MyInfo) => (
                            <Box component="li" {...props}>
                              <AutocompleteNameBox>
                                {option.name}
                              </AutocompleteNameBox>
                              <AutocompleteBox>{`\t ${option.team}, ${option.jobPosition}`}</AutocompleteBox>
                            </Box>
                          )}
                          isOptionEqualToValue={(
                            option: MyInfo,
                            value: MyInfo
                          ) => option.id === value.id}
                          onChange={(args, value: any) =>
                            onChange(args, "pic", value)
                          }
                          value={data.pic || null}
                          defaultChecked={true}
                          noOptionsText={
                            <AutoCompleteNoOptionsText>
                              해당자 없음
                            </AutoCompleteNoOptionsText>
                          }
                        />
                      </RecurrenceDropdownMobileComponent>
                      {!data?.pic?.name ? (
                        <RecurrenceDropdownMobileComponent>
                          <ValidationText>
                            <ErrorOutlineIcon fontSize="small" /> 담당자를
                            지정해주세요.
                          </ValidationText>
                        </RecurrenceDropdownMobileComponent>
                      ) : null}

                      <RecurrenceDropdownMobileComponent>
                        <Autocomplete
                          options={picListData}
                          getOptionLabel={(option) => option.name || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="부담당자 (선택사항)"
                            />
                          )}
                          isOptionEqualToValue={(
                            option: MyInfo,
                            value: MyInfo
                          ) => option.id === value?.id}
                          renderOption={(props, option: MyInfo) => (
                            <Box component="li" {...props}>
                              <AutocompleteNameBox>
                                {option.name}
                              </AutocompleteNameBox>
                              <AutocompleteBox>{`\t ${option.team}, ${option.jobPosition}`}</AutocompleteBox>
                            </Box>
                          )}
                          onChange={(args, value: any) =>
                            onChange(args, "deputyPic", value)
                          }
                          value={data.deputyPic || null}
                          defaultChecked={true}
                          noOptionsText={
                            <AutoCompleteNoOptionsText>
                              해당자 없음
                            </AutoCompleteNoOptionsText>
                          }
                        />
                      </RecurrenceDropdownMobileComponent>
                      <RecurrenceDropdownMobileComponent>
                        <Autocomplete
                          options={picListData}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="관리자" />
                          )}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, option: MyInfo) => (
                            <Box component="li" {...props}>
                              <AutocompleteNameBox>
                                {option.name}
                              </AutocompleteNameBox>
                              <AutocompleteBox>{`\t ${option.team}, ${option.jobPosition}`}</AutocompleteBox>
                            </Box>
                          )}
                          onChange={(args, value: any) =>
                            onChange(args, "manager", value)
                          }
                          isOptionEqualToValue={(
                            option: MyInfo,
                            value: MyInfo
                          ) => option.id === value.id}
                          value={data.manager || null}
                          defaultChecked={true}
                          noOptionsText={
                            <AutoCompleteNoOptionsText>
                              해당자 없음
                            </AutoCompleteNoOptionsText>
                          }
                        />
                      </RecurrenceDropdownMobileComponent>
                      {!data.manager?.name ? (
                        <RecurrenceDropdownMobileComponent>
                          <ValidationText>
                            <ErrorOutlineIcon fontSize="small" /> 관리자를
                            지정해주세요.
                          </ValidationText>
                        </RecurrenceDropdownMobileComponent>
                      ) : null}

                      <RecurrenceDropdownMobileComponent>
                        <Autocomplete
                          options={picListData}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="부관리자 (선택사항)"
                            />
                          )}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, option: MyInfo) => (
                            <Box component="li" {...props}>
                              <AutocompleteNameBox>
                                {option.name}
                              </AutocompleteNameBox>
                              <AutocompleteBox>{`\t ${option.team}, ${option.jobPosition}`}</AutocompleteBox>
                            </Box>
                          )}
                          onChange={(args, value: any) =>
                            onChange(args, "deputyManager", value)
                          }
                          isOptionEqualToValue={(
                            option: MyInfo,
                            value: MyInfo
                          ) => option.id === value.id}
                          value={data.deputyManager || null}
                          defaultChecked={true}
                          noOptionsText={
                            <AutoCompleteNoOptionsText>
                              해당자 없음
                            </AutoCompleteNoOptionsText>
                          }
                        />
                      </RecurrenceDropdownMobileComponent>
                      {data.pic?.id === data.manager?.id ? (
                        <RecurrenceDropdownMobileComponent>
                          <ValidationText>
                            <ErrorOutlineIcon fontSize="small" /> 담당자와
                            관리자를 다르게 설정해주세요.
                          </ValidationText>
                        </RecurrenceDropdownMobileComponent>
                      ) : null}
                    </RecurrenceDropdownMobileBox>
                  </MobileSmall>
                </ReminderModalMainBox>
                <Tablet>
                  {!data.pic?.name ? (
                    <ReminderModalMainNoMarginBox>
                      <ReminderModalTitleBox></ReminderModalTitleBox>
                      <ReminderModalPicNoMarginBox>
                        <ReminderSelectedPicBox>
                          <ValidationText>
                            <ErrorOutlineIcon fontSize="small" /> 담당자를
                            지정해주세요.
                          </ValidationText>
                        </ReminderSelectedPicBox>
                      </ReminderModalPicNoMarginBox>
                    </ReminderModalMainNoMarginBox>
                  ) : null}

                  <ReminderModalMainBox>
                    <ReminderModalTitleBox>관리자</ReminderModalTitleBox>
                    <ReminderModalPicBox>
                      <ReminderSelectedPicBox>
                        <Autocomplete
                          options={picListData}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="관리자" />
                          )}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, option: MyInfo) => (
                            <Box component="li" {...props}>
                              <AutocompleteNameBox>
                                {option.name}
                              </AutocompleteNameBox>
                              <AutocompleteBox>{`\t ${option.team}, ${option.jobPosition}`}</AutocompleteBox>
                            </Box>
                          )}
                          onChange={(args, value: any) =>
                            onChange(args, "manager", value)
                          }
                          isOptionEqualToValue={(
                            option: MyInfo,
                            value: MyInfo
                          ) => option.id === value.id}
                          value={data.manager || null}
                          defaultChecked={true}
                          noOptionsText={
                            <AutoCompleteNoOptionsText>
                              해당자 없음
                            </AutoCompleteNoOptionsText>
                          }
                        />
                      </ReminderSelectedPicBox>
                    </ReminderModalPicBox>
                    <ReminderModalTitleBox>부관리자</ReminderModalTitleBox>
                    <ReminderModalPicBox>
                      <ReminderSelectedPicBox>
                        <Autocomplete
                          options={picListData}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="부관리자 (선택사항)"
                            />
                          )}
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(props, option: MyInfo) => (
                            <Box component="li" {...props}>
                              <AutocompleteNameBox>
                                {option.name}
                              </AutocompleteNameBox>
                              <AutocompleteBox>{`\t ${option.team}, ${option.jobPosition}`}</AutocompleteBox>
                            </Box>
                          )}
                          onChange={(args, value: any) =>
                            onChange(args, "deputyManager", value)
                          }
                          isOptionEqualToValue={(
                            option: MyInfo,
                            value: MyInfo
                          ) => option.id === value.id}
                          value={data.deputyManager || null}
                          defaultChecked={true}
                          noOptionsText={
                            <AutoCompleteNoOptionsText>
                              해당자 없음
                            </AutoCompleteNoOptionsText>
                          }
                        />
                      </ReminderSelectedPicBox>
                    </ReminderModalPicBox>
                  </ReminderModalMainBox>
                  {!data.manager?.name ? (
                    <ReminderModalMainNoMarginBox>
                      <ReminderModalTitleBox></ReminderModalTitleBox>
                      <ReminderModalPicNoMarginBox>
                        <ReminderSelectedPicBox>
                          <ValidationText>
                            <ErrorOutlineIcon fontSize="small" /> 관리자를
                            지정해주세요.
                          </ValidationText>
                        </ReminderSelectedPicBox>
                      </ReminderModalPicNoMarginBox>
                    </ReminderModalMainNoMarginBox>
                  ) : null}
                  {data.pic?.id && data.pic?.id === data.manager?.id ? (
                    <ReminderModalMainNoMarginBox>
                      <ReminderModalTitleBox></ReminderModalTitleBox>
                      <ReminderModalPicNoMarginBox>
                        <ReminderSelectedPicBox>
                          <ValidationText>
                            <ErrorOutlineIcon fontSize="small" /> 담당자와
                            관리자를 다르게 설정해주세요.
                          </ValidationText>
                        </ReminderSelectedPicBox>
                      </ReminderModalPicNoMarginBox>
                    </ReminderModalMainNoMarginBox>
                  ) : null}

                  {problemData.length > 0 && (
                    <>
                      {problemData.map((item) => {
                        return (
                          <ReminderModalMainNoMarginBox>
                            <ReminderModalTitleBox></ReminderModalTitleBox>
                            <ReminderModalPicNoMarginBox>
                              <ReminderSelectedPicBox>
                                {item.status === "WARNING" ? (
                                  <ValidationText>
                                    <ReportProblemIcon
                                      onClick={() =>
                                        checkWarning(
                                          item.id,
                                          "WARNING",
                                          item.message
                                        )
                                      }
                                      fontSize="small"
                                      sx={{
                                        color: ORANGECONTENTS,
                                        cursor: "pointer",
                                      }}
                                    />
                                    {item.message}
                                  </ValidationText>
                                ) : (
                                  <ValidationText>
                                    <ReportProblemIcon
                                      onClick={() =>
                                        checkWarning(
                                          item.id,
                                          "DANGER",
                                          item.message
                                        )
                                      }
                                      fontSize="small"
                                      sx={{
                                        color: REDTITLE,
                                        cursor: "pointer",
                                      }}
                                    />
                                    {item.message}
                                  </ValidationText>
                                )}
                              </ReminderSelectedPicBox>
                            </ReminderModalPicNoMarginBox>
                          </ReminderModalMainNoMarginBox>
                        );
                      })}
                    </>
                  )}
                </Tablet>
                {/* 메모 추가 */}
                <ReminderModalMainStartBox>
                  <Tablet>
                    <ReminderModalTitleBox>내용</ReminderModalTitleBox>
                  </Tablet>

                  <ReminderModalContentBox>
                    <ReservationTextarea
                      onChange={onChange}
                      name="content"
                      placeholder="해당 리마인더에 대한 설명을 적어주세요."
                      value={data.content || ""}
                    ></ReservationTextarea>
                  </ReminderModalContentBox>
                </ReminderModalMainStartBox>
                {!data.content ? (
                  <ReminderModalMainNoMarginBox>
                    <Tablet>
                      <ReminderModalTitleNoMarginBox></ReminderModalTitleNoMarginBox>
                    </Tablet>

                    <ReminderModalContentNoMarginBox>
                      <ValidationNoMarginText>
                        <ErrorOutlineIcon fontSize="small" /> 정기적으로
                        수행해야 할 일정에 대한 상세 내용을 적어주세요.
                      </ValidationNoMarginText>
                    </ReminderModalContentNoMarginBox>
                  </ReminderModalMainNoMarginBox>
                ) : null}

                <ReminderSaveButtonBox>
                  {" "}
                  <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
                </ReminderSaveButtonBox>
              </RemindermodalContentMaxWidth>
            </RemindermodalContenstBox>
          </MaxWidth>
        </Grid>
      </Grid>
    </>
  );
}

export default Reminder;
