import { useEffect, useState } from "react";
import { VehicleInsuranceInfo } from "../../../system/types/Vehicle";
import { InitVehicleInsuranceInfo } from "../../../system/types/initObject";
import { InsuranceApi } from "../../../system/api/InsuranceApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import {
  Box,
  Grid,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { CustomButtonGreen, CustomButtonYellow } from "../../../styles/button";
import { Mobile, Pc } from "../../../pages/Layout/ResponsiveScreen";
import {
  AppliedItem,
  CardDataBox,
  CardDataLayout,
  InputBox,
  MainTitle,
  MidiumModalStyle,
  RaffleContent,
  StyledBody,
  StyledHead1,
  ThickText,
} from "../../../styles/theme";
import { X } from "@phosphor-icons/react";
import { CustomText } from "../../../styles/CustomText";
import { REDTITLE } from "../../../styles/color";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";

function validation(data: VehicleInsuranceInfo) {
  if (data.company === "") {
    AlertModal("check", "보험 회사 이름을 작성해주세요.");
    return false;
  } else if (data.contact === "") {
    AlertModal("check", "보험 회사 연락처를 작성해주세요.");
    return false;
  } else if (data.coverageStartDate === "" || data.coverageEndDate === "") {
    AlertModal("check", "보험 기간을 선택해주세요.");
    return false;
  }
  return true;
}

function InsuranceManagement() {
  const [data, setData] = useState<VehicleInsuranceInfo[]>([]);
  const [insuranceData, setInsuranceData] = useState<VehicleInsuranceInfo>(
    InitVehicleInsuranceInfo
  );
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const handleOpen = (item: VehicleInsuranceInfo, type: string) => {
    setOpen(true);
    setInsuranceData(item);
    setModalTitle(type);
  };
  const handleClose = () => setOpen(false);
  const refreshData = () => {
    InsuranceApi.getInsurance(0, 12)
      .then((res) => {
        setData(res.data.insurances);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const onChangePaging = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value - 1);
  };

  const onChange = (args: any) => {
    setInsuranceData({
      ...insuranceData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  var submitcheck = false;
  const onSave = () => {
    if (submitcheck === false && validation(insuranceData)) {
      submitcheck = true;
      if (insuranceData.id) {
        InsuranceApi.updateInsurance(insuranceData)
          .then(() => {
            handleClose();
            AlertModal("success", "보험 정보가 수정되었습니다.");
            refreshData();
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      } else {
        InsuranceApi.createInsurance(insuranceData)
          .then(() => {
            handleClose();
            AlertModal("success", "보험이 생성되었습니다.");
            refreshData();
            submitcheck = false;
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    }
  };
  // 기간 추가
  const onChangeDate = (args: any) => {
    if (args.value && args.value[0] && args.value[1]) {
      setInsuranceData({
        ...insuranceData,
        coverageStartDate: isoDateFormatter(new Date(args.value[0])),
        coverageEndDate: isoDateFormatter(new Date(args.value[1])),
      });
    }
  };
  return (
    <Grid container>
      <Grid container item xs={12} justifyContent="right" mt="10px">
        <CustomButtonGreen
          onClick={() => handleOpen(InitVehicleInsuranceInfo, "등록")}
        >
          보험 등록
        </CustomButtonGreen>
      </Grid>
      <Grid container item xs={12}>
        <Pc>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledHead1 align="center">회사명</StyledHead1>
                    <StyledHead1 align="center">연락처</StyledHead1>
                    <StyledHead1 align="center">보장 나이</StyledHead1>
                    <StyledHead1 align="center">보험 기간</StyledHead1>
                    <StyledHead1 align="center"></StyledHead1>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item: VehicleInsuranceInfo, idx) => {
                    return (
                      <TableRow key={idx}>
                        <StyledBody align="center">{item.company}</StyledBody>
                        <StyledBody align="center">{item.contact}</StyledBody>
                        <StyledBody align="center">
                          {"만 " + item.applicableAge + " 세"}
                        </StyledBody>
                        <StyledBody align="center">
                          {item.coverageStartDate +
                            " ~ " +
                            item.coverageEndDate}
                        </StyledBody>
                        <StyledBody align="center">
                          <CustomButtonYellow
                            onClick={() => handleOpen(item, "수정")}
                          >
                            수정
                          </CustomButtonYellow>
                        </StyledBody>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Pc>
        <Mobile>
          <Grid item xs={12}>
            <CardDataLayout>
              {data.map((item: VehicleInsuranceInfo, idx) => {
                return (
                  <CardDataBox key={idx}>
                    <Grid item xs={12}>
                      <CardDataContent>
                        <ThickText>{item.company}</ThickText>
                      </CardDataContent>
                      <CardDataContent>{item.contact}</CardDataContent>
                      <CardDataContent>
                        {item.applicableAge + " 세"}
                      </CardDataContent>
                      <Grid container justifyContent="center">
                        <CustomButtonYellow
                          onClick={() => handleOpen(item, "수정")}
                        >
                          수정
                        </CustomButtonYellow>
                      </Grid>
                    </Grid>
                  </CardDataBox>
                );
              })}
            </CardDataLayout>
          </Grid>
        </Mobile>
      </Grid>
      {data.length === 0 ? (
        <EmptyBox container justifyContent="center">
          조회된 차량이 없습니다.
        </EmptyBox>
      ) : (
        <Grid container justifyContent="center" mt="10px">
          <Pagination
            count={totalPage}
            variant="outlined"
            page={pageNumber + 1}
            showFirstButton
            showLastButton
            onChange={onChangePaging}
            style={{ textAlign: "center" }}
          />
        </Grid>
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={MidiumModalStyle}>
            <ContentBox item xs={12}>
              <RaffleContent>
                <MainTitle>보험 {modalTitle}</MainTitle>
                <AppliedItem onClick={handleClose}>
                  <X size={32} />
                </AppliedItem>
              </RaffleContent>
            </ContentBox>
            <EditContentBox>
              <ContentGapBox container item xs={12}>
                회사명{" "}
                <CustomText fontSize="small" color={REDTITLE}>
                  *
                </CustomText>
              </ContentGapBox>
              <ContentBox item xs={12}>
                <InputBox
                  type="text"
                  onChange={onChange}
                  name="company"
                  value={insuranceData.company || ""}
                  placeholder="보험 회사명을 작성해주세요."
                />
              </ContentBox>

              <ContentGapBox container item xs={12}>
                연락처{" "}
                <CustomText fontSize="small" color={REDTITLE}>
                  *
                </CustomText>
              </ContentGapBox>
              <ContentBox item xs={12}>
                <InputBox
                  type="text"
                  onChange={onChange}
                  name="contact"
                  value={insuranceData.contact || ""}
                  placeholder="보험 회사 연락처를 작성해주세요."
                />
              </ContentBox>
              <ContentGapBox container item xs={12}>
                보장 나이 (만 나이)
                <CustomText fontSize="small" color={REDTITLE}>
                  *
                </CustomText>
              </ContentGapBox>
              <ContentBox item xs={12}>
                <InputBox
                  type="text"
                  onChange={onChange}
                  name="applicableAge"
                  value={insuranceData.applicableAge || ""}
                  placeholder="보험 보장 나이를 작성해주세요."
                />
              </ContentBox>
              <ContentGapBox container item xs={12}>
                보험 기간
                <CustomText fontSize="small" color={REDTITLE}>
                  *
                </CustomText>
              </ContentGapBox>
              <ContentBox item xs={12}>
                <DateRangePickerComponent
                  id="daterangepicker"
                  placeholder="보험 기간을 선택해주세요."
                  format="yyyy-MM-dd"
                  locale="ko"
                  delayUpdate={true}
                  value={
                    insuranceData.coverageStartDate &&
                    insuranceData.coverageEndDate
                      ? [
                          new Date(insuranceData.coverageStartDate),
                          new Date(insuranceData.coverageEndDate),
                        ]
                      : undefined
                  }
                  onChange={onChangeDate}
                  showClearButton={false}
                />
              </ContentBox>
            </EditContentBox>
            <Grid container justifyContent="right">
              <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
            </Grid>
          </Box>
        </Modal>
      )}
    </Grid>
  );
}

export default InsuranceManagement;

const EmptyBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  padding: "20% 0px",
});

const CardDataContent = styled("div")(() => ({
  marginBottom: "1.2rem",
}));

const ContentBox = styled(Grid)({
  marginBottom: "10px",
});

const ContentGapBox = styled(Grid)({
  marginBottom: "3px",
});

const EditContentBox = styled(Grid)({
  height: "calc(100% - 92px)",
  overflow: "scroll",
});
