import { defaultValue } from "../../components/Common/Editor/defaultValue";
import { isoDateFormatter } from "../../components/Common/IsoDateFormatter";
import { CheckInListItemStatus } from "../Constants";
import {
  Accommodation,
  AccommodationRezData,
  AccommodationRezDetailData,
  AccommodationType,
  DetailedAccommodation,
  DetailedAddresses,
  Resource,
} from "./Accommodation";
import { Amenity } from "./Amenity";
import { DestinationBookmarkInfo, ResourceBookmarkInfo } from "./Bookmark";
import { CheckOffice365Employee, Employee, MyInfo } from "./Employee";
import { Notice, NoticeData } from "./Notice";
import { ReminderData, RecurrenceData, ReminderProblemList } from "./Reminder";
import {
  AttachmentFilesConfirm,
  CreateReservation,
  HolidayData,
  MyReservation,
  ReservationSearchData,
} from "./Reservation";
import {
  VehicleDrivingInfo,
  VehicleEditInfo,
  VehicleInfo,
  VehicleInsuranceInfo,
  VehicleLastVehicleInfo,
  VehicleMemoInfo,
  VehicleOwnInfo,
  VehicleReservationInfo,
  VehicleReservationTime,
  VehicleSearch,
} from "./Vehicle";
import { AuthorityUser, MsUser } from "./interface";

export const InitUser: AuthorityUser = {
  department: "",
  departmentId: 0,
  email: "",
  employeeId: "",
  employeeNo: "",
  gwMail: "",
  gwName: "",
  jobPosition: "",
  jobTitle: "",
  name: "",
  positionCode: "",
  role: "",
  roles: [],
  team: "",
  teamId: 0,
  workplace: "",
};

export const InitMyINfo: MyInfo = {
  id: null,
  email: "",
  name: "",
  workplace: "",
  phone: "",
  department: "",
  departmentId: "",
  team: "",
  jobPosition: "",
  jobTitle: "",
  ticket: 0,
  type: "",
  active: true,
  isResignation: true,
};

export const InitDetailedAddresses: DetailedAddresses = {
  id: null,
  dong: 0,
  ho: 0,
  frontDoorPassword: "",
  roomPassword: "",
  availableCheck: false,
  message: null,
  forbidStartDate: null,
  forbidEndDate: null,
};

export const InitAccommodation: Accommodation = {
  id: null,
  icon: "",
  desc1: "",
  desc2: "",
  desc3: "",
  desc4: "",
  desc5: "",
  desc6: "",
  name: "",
  type: "",
  address: "",
  color: "",
  detailedAddresses: InitDetailedAddresses,
};

export const InitDetailedAccommodation: DetailedAccommodation = {
  id: null,
  ticketStatus: true,
  icon: "",
  desc1: defaultValue,
  desc2: "",
  desc3: "",
  desc4: "",
  desc5: "",
  desc6: "",
  name: "",
  type: "",
  address: "",
  color: "",
  latitude: "",
  longitude: "",
  detailedAddresses: [],
  amenities: [],
  setInfo: false,
  checkInGuideDocsUrl: "",
  checkOutGuideDocsUrl: "",
  available: true,
};

export const InitAmenity: Amenity = {
  id: null,
  name: "",
  icon: "",
  iconUrl: "",
};
export const InitMsUser: MsUser = {
  aud: "",
  iss: "",
  iat: 0,
  nbf: 0,
  exp: 0,
  aio: "",
  email: "",
  ipaddr: "",
  name: "",
  nonce: "",
  oid: "",
  preferred_username: "",
  rh: "",
  roles: [],
  sid: "",
  sub: "",
  tid: "",
  uti: "",
  ver: "",
};

export const InitResource: Resource = {
  id: null,
  name: "",
  icon: "",
  color: "",
};

export const InitEmployee: Employee = {
  id: null,
  email: "",
  name: "",
  workplace: "",
  phone: "010-",
  department: "",
  team: "",
  jobTitle: "",
  jobPosition: "",
};

export const InitCreateReservation: CreateReservation = {
  type: "",
  startDate: "",
  endDate: "",
  accommodation: InitAccommodation,
  branchEmployee: InitEmployee,
  check: false,
  memo: "",
};

export const InitMyReservation: MyReservation = {
  id: 0,
  type: "",
  accommodationId: 0,
  accommodationName: "",
  accommodationIcon: "",
  detailAddressId: 0,
  dong: 0,
  ho: 0,
  startDate: "",
  endDate: "",
  status: "",
  reReservation: false,
  checkIn: {
    id: 0,
    condition: "",
    opinions: "",
    attachmentFiles: [],
    reservationId: 0,
  },
  checkOut: { id: 0, attachmentFiles: [], reported: false, reservationId: 0 },
};

export const InitAttachmentFilesConfirm: AttachmentFilesConfirm = {
  id: 0,
  name: "",
  url: "",
};

export const InitCheckOffice365Employee: CheckOffice365Employee = {
  branchEmployeeCheck: false,
  branchEmployees: [],
};

export const InitAccommodationDetailRez: AccommodationRezDetailData = {
  CategoryColor: "",
  ConfirmedCnt: 0,
  EndTime: "",
  GroupId: 0,
  Id: 0,
  Location: "",
  RafflePeriod: false,
  RoomCnt: 0,
  RoomId: 0,
  StartTime: "",
  Subject: "",
  Guid: "",
  Index: 0,
  IsAllDay: false,
  data: {},
  WinnerName: "",
};

export const InitAccommodationRez: AccommodationRezData = {
  CategoryColor: "",
  EndTime: "",
  Id: 0,
  IsAllDay: true,
  StartTime: "",
  Subject: "",
  Guid: "",
  Data: InitAccommodationDetailRez,
  data: {},
  Index: 0,
};

export const InitReservationSearchData: ReservationSearchData = {
  startDate: "",
  endDate: "",
  reservationId: 0,
  employeeName: "",
  status: "",
  checkIn: {
    attachmentFiles: [],
    condition: "",
    id: 0,
    opinions: "",
    reservationId: 0,
  },
  checkOut: { attachmentFiles: [], id: 0, reported: false, reservationId: 0 },
  employeeId: 0,
  memo: "",
  accommodationName: "",
  adminReservation: false,
  detailedAddress: { id: 0, dong: 0, ho: 0 },
};

export const InitHolidayData: HolidayData = {
  name: "",
  date: "",
};

export const InitCheckInListItem: CheckInListItemStatus = {
  id: 0,
  checklistItem: "",
};

export const InitRecurrence: RecurrenceData = {
  daysOfWeek: [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ],
  interval: 1,
  recurrencePatternType: "DAILY",
  recurrenceRangeType: "NO_END",
  firstDayOfWeek: "SUNDAY",
  weekIndex: "UNEXPECTED_VALUE",
  recurrenceTimeZone: "Asia/Seoul",
};

const todayDate = new Date();
const tomorrowDate = todayDate.setDate(todayDate.getDate() + 1);

export const InitReminderData: ReminderData = {
  title: "",
  content: "",
  startDate: `${isoDateFormatter(new Date())} 00:00:00`,
  endDate: `${isoDateFormatter(new Date(tomorrowDate))} 00:00:00`,
  azureEventReg: true,
  recurrence: InitRecurrence,
  allDay: true,
  problems: [],
};

export const InitReminderProblem: ReminderProblemList = {
  id: 0,
  status: "",
  message: "",
  isChecked: false,
};

// 차량
export const InitVehicleSearch: VehicleSearch = {
  kakaoLocId: 0,
  addressName: "",
  roadAddressName: "",
  categoryName: "",
  placeName: "",
  longitude: 0,
  latitude: 0,
  placeUrl: "",
};

export const InitVehicleInsuranceInfo: VehicleInsuranceInfo = {
  id: null,
  company: "",
  contact: "",
  applicableAge: 0,
  coverageStartDate: "",
  coverageEndDate: "",
};

export const InitVehicleReservationInfo: VehicleReservationInfo = {
  vehicleId: 0,
  startDate: isoDateFormatter(new Date()),
  time: "종일",
  waypoints: [InitVehicleSearch],
};

export const InitVehicleDrivingInfo: VehicleDrivingInfo = {
  reservationId: 0,
  isStart: false,
  mileage: 0,
};

export const InitVehicleInfo: VehicleInfo = {
  id: 0,
  name: "",
  icon: "",
  usageType: "",
  number: "",
  available: true,
  seater: 0,
  fuelType: "",
  totalMileage: 0,
  unitType: "",
  sizeType: "",
  shapeType: "",
  insurance: InitVehicleInsuranceInfo,
  manager: null,
  designatedExecutives: null,
  isBookmarked: false,
  forbidStartDate: "",
  forbidEndDate: "",
};

export const InitVehicleLastVehicleInfo: VehicleLastVehicleInfo = {
  id: 0,
  vehicle: InitVehicleInfo,
  employee: InitEmployee,
  startDate: "",
  startMileage: 0,
  endMileage: 0,
  status: "",
  time: "",
  realStartTime: "",
  realEndTime: "",
  waypoints: [],
  memo: "",
};

export const InitVehicleMemoInfo: VehicleMemoInfo = {
  id: 0,
  memo: "주유 필요",
};

export const InitNoticeData: Notice = {
  notice: [],
  totalPages: 0,
  totalElements: 0,
};

export const InitNoticeDetailData: NoticeData = {
  id: null,
  title: "",
  content: "",
  type: "",
  mainStartDate: null,
  mainEndDate: null,
  setInfo: false,
};

export const InitDestinationBookmarkInfo: DestinationBookmarkInfo = {
  id: null,
  bookmarkType: "목적지",
  alias: "",
  address: InitVehicleSearch,
  isDefault: false,
};

export const InitAccommodationType: AccommodationType = {
  id: 0,
  name: "",
  icon: "",
};

export const InitResourceBookmarkInfo: ResourceBookmarkInfo = {
  id: null,
  bookmarkType: "",
  alias: "",
  resource: InitAccommodationType,
};

export const InitVehicleEditInfo: VehicleEditInfo = {
  startMileage: 0,
  endMileage: null,
  realStartTime: "",
  realEndTime: null,
};

export const InitVehicleOwnInfo: VehicleOwnInfo = {
  id: 0,
  name: "",
  number: 0,
};

export const InitVehicleReservationTime: VehicleReservationTime = {
  AmPm: "오전",
  hour: "01",
  minute: "00",
};
