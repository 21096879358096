import client from "../ApiService";
import HttpClient from "../http-client";

const baseUri: string = "/resource";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _ResourceApi extends _BasicApi {
  getResource(type: string) {
    return this.client.get(`${baseUri}/${type}`);
  }
}

export const ResourceApi = new _ResourceApi(client);
