import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  StyledBody,
  StyledEmptyBody,
  StyledHead,
  StyledHead1,
  StyledHead2,
} from "../../../styles/theme";
import { MyReservationVehicleData } from "../../../system/types/Reservation";
import MyAccommodationChip from "../../Common/Accommodation/MyAccommodationChip";
import MyCarButton from "./MyCarButton";
import { CustomButtonDisabled } from "../../../styles/button";

interface MyCarTableProps {
  myData: MyReservationVehicleData[];
  refreshReservationData: () => void;
}

function MyCarTable({ myData, refreshReservationData }: MyCarTableProps) {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHead align="center">상태</StyledHead>
              <StyledHead1 align="center">차량</StyledHead1>
              <StyledHead1 align="center">예약일</StyledHead1>
              <StyledHead align="center">시간대</StyledHead>
              <StyledHead1 align="center">출발</StyledHead1>
              <StyledHead1 align="center">도착</StyledHead1>
              <StyledHead2 align="center">관리</StyledHead2>
            </TableRow>
          </TableHead>
          <TableBody>
            {myData.map((item: MyReservationVehicleData, idx) => {
              return (
                <TableRow key={idx}>
                  <StyledBody align="center">
                    <MyAccommodationChip status={item.status} />
                  </StyledBody>
                  <StyledBody align="center">
                    <Grid container>
                      <Grid item xs={12}>
                        {item.vehicle.name}
                      </Grid>
                      <Grid item xs={12}>
                        {item.vehicle.number}
                      </Grid>
                    </Grid>
                  </StyledBody>
                  <StyledBody align="center">{item.startDate}</StyledBody>
                  <StyledBody align="center">{item.time}</StyledBody>

                  <StyledBody align="center">
                    <Grid container>
                      <Grid item xs={12}>
                        {item.realStartTime
                          ? item.realStartTime
                          : "출발 정보 없음"}
                      </Grid>

                      {item.startMileage !== null && item.startMileage >= 0 && (
                        <Grid item xs={12}>
                          {item.startMileage.toLocaleString() +
                            " " +
                            item.vehicle.unitType}
                        </Grid>
                      )}
                    </Grid>
                  </StyledBody>
                  <StyledBody align="center">
                    <Grid container>
                      <Grid item xs={12}>
                        {item.realEndTime ? item.realEndTime : "도착 정보 없음"}
                      </Grid>

                      {item.endMileage !== null && item.endMileage >= 0 && (
                        <Grid item xs={12}>
                          {" "}
                          {item.endMileage.toLocaleString() +
                            " " +
                            item.vehicle.unitType}
                        </Grid>
                      )}
                    </Grid>
                  </StyledBody>
                  <StyledBody align="center">
                    <MyCarButton
                      data={item}
                      refreshReservationData={refreshReservationData}
                    />
                  </StyledBody>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {myData.length === 0 && (
        <Grid container>
          <StyledEmptyBody>조회된 데이터가 없습니다.</StyledEmptyBody>
        </Grid>
      )}
    </>
  );
}

export default MyCarTable;
