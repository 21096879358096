import { useEffect, useState } from "react";
import { VehicleApi } from "../../../system/api/VehicleApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import { VehicleLastVehicleInfo } from "../../../system/types/Vehicle";
import { Grid, styled } from "@mui/material";
import { Mobile, Pc } from "../../../pages/Layout/ResponsiveScreen";
import DrivingHistoryTable from "./DrivingHistoryTable";
import DrivingHistoryCard from "./DrivingHistoryCard";
import { CustomButtonGreen } from "../../../styles/button";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import DownloadDrivingRecords from "./DownloadDrivingRecords";
import { VehicleData } from "../../../system/types/Reservation";
import {
  DropDownListComponent,
  FilteringEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { EditDropDownListComponentBox } from "../../../styles/theme";
import { Employee } from "../../../system/types/Employee";
import { EmployeeApi } from "../../../system/api/EmployeeApi";

function DrivingHistory() {
  const [selectedVehicleId, setSelectedVehicleId] = useState<number | null>(
    null
  );
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(
    null
  );
  var now = new Date();
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);
  const [drivingData, setDrivingData] = useState<VehicleLastVehicleInfo[]>([]);
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);
  const refreshData = () => {
    VehicleApi.getDrivingHistory(
      isoDateFormatter(dateRange[0]),
      isoDateFormatter(dateRange[1]),
      false,
      selectedVehicleId,
      selectedEmployeeId
    )
      .then((res) => {
        setDrivingData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    refreshData();
  }, [dateRange, selectedEmployeeId, selectedVehicleId]);

  useEffect(() => {
    VehicleApi.getVehicle(0, 9999999)
      .then((res) => {
        setVehicleAllData(res.data.vehicles);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
    EmployeeApi.getEmployee("본사", true)
      .then((res) => {
        setEmployeeData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onChangeDate = (args: any) => {
    if (args.value) {
      setDateRange(args.value);
    }
  };

  const [vehicleAllData, setVehicleAllData] = useState<VehicleData[]>([]);

  const onChange = (e: any, type: string) => {
    if (type === "employee") {
      setSelectedEmployeeId(e.target.value);
    } else {
      setSelectedVehicleId(e.target.value);
    }
  };

  const onFiltering = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("name", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  const onFilteringVehicle = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("number", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };
  const DataVehicleFields: Object = {
    dataSource: vehicleAllData,
    value: "id",
    text: "number",
    child: "children",
  };

  const DataFields: Object = {
    dataSource: vehicleAllData,
    value: "id",
    text: "name",
    child: "children",
  };
  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="baseline"
        mt="10px"
      >
        <Grid item xs={12} sm={6} md={3}>
          <DateRangePickerComponent
            id="daterangepicker"
            placeholder="원하는 기간을 선택해주세요."
            format="yyyy-MM-dd"
            locale="ko"
            delayUpdate={true}
            value={dateRange}
            onChange={onChangeDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} container justifyContent="right">
          <DownloadDrivingRecords
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Grid item xs={12} sm={6} md={3}>
          <EditDropDownListComponentBox
            locale="ko"
            showClearButton={true}
            dataSource={vehicleAllData}
            value={selectedVehicleId}
            onChange={(e: any) => onChange(e, "vehidle")}
            placeholder="차량을 선택해주세요."
            autoComplete="new-password"
            filtering={(args: any) => onFilteringVehicle(args, vehicleAllData)}
            allowFiltering={true}
            fields={DataVehicleFields}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <EditDropDownListComponentBox
            locale="ko"
            showClearButton={true}
            dataSource={employeeData}
            value={selectedEmployeeId}
            onChange={(e: any) => onChange(e, "employee")}
            placeholder="운전자를 선택해주세요."
            autoComplete="new-password"
            filtering={(args: any) => onFiltering(args, employeeData)}
            allowFiltering={true}
            fields={DataFields}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} mt="10px">
        <Pc>
          <DrivingHistoryTable
            drivingData={drivingData}
            refreshData={refreshData}
          />
        </Pc>
        <Mobile>
          <DrivingHistoryCard
            drivingData={drivingData}
            refreshData={refreshData}
          />
        </Mobile>
        {drivingData.length === 0 && (
          <EmptyBox container justifyContent="center">
            조회된 데이터가 없습니다.
          </EmptyBox>
        )}
      </Grid>
    </Grid>
  );
}

export default DrivingHistory;

const EmptyBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  padding: "20% 0px",
});
