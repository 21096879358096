import { MessageComponent } from "@syncfusion/ej2-react-notifications";
import { PRIMARY } from "./color";
import { styled } from "@mui/material/styles";
// FAQ
export const QuestionBox = styled("div")({
  display: "flex",
  margin: "1.5rem 0rem 0.5rem 0rem",
});
export const QuestionMark = styled("div")({
  color: PRIMARY,
  fontSize: "1.5rem",
  fontWeight: "700",
  margin: "-0.5rem",
  marginRight: "2rem",
  marginLeft: "0.2rem",
});

export const AnswerBox = styled("div")({
  margin: "0.5rem 0rem 1.5rem 0rem",
  display: "flex",
  alignItems: "flex-start",
});

export const MessageBox = styled(MessageComponent)({
  margin: "0.5rem 0rem",
});

export const EntranceImage = styled("img")(() => ({
  maxWidth: "80%",
  maxHeight: "30%",
  marginBottom: "5px",
}));

export const EntranceCheckImage = styled("img")(() => ({
  maxWidth: "40%",
  maxHeight: "30%",
  marginBottom: "5px",
}));
