const today = new Date();

const todayYear = today.getFullYear();
const todayMonth = today.getMonth() + 1;
const todayDate = today.getDate();
const todayDay = today.getDay();
const todayHour = today.getHours();
const todayMinute = today.getMinutes();

const daysUntilWednesday = (3 - todayDay + 7) % 7;

// 4주 뒤 수요일
const firstStartRafflePeriod = new Date(
  today.getTime() + (3 * 7 + daysUntilWednesday) * 24 * 60 * 60 * 1000
);

// 5주 뒤 화요일
const firstEndRafflePeriod = new Date(
  today.getTime() + (4 * 7 + daysUntilWednesday - 1) * 24 * 60 * 60 * 1000
);

// 5주 뒤 수요일 & 3주 뒤 수요일 (수욜 10:05 이후)
const firstFinalRafflePeriod = new Date(
  today.getTime() + (4 * 7 + daysUntilWednesday) * 24 * 60 * 60 * 1000
);

// 1주 뒤 수요일  (수욜 10:05 이후)
const firstReleaseDate = new Date(
  today.getTime() + daysUntilWednesday * 24 * 60 * 60 * 1000
);

// 4주 뒤 화요일 (수욜 10:05 이후)
const secondEndRafflePeriod = new Date(
  today.getTime() + (5 * 7 + daysUntilWednesday - 1) * 24 * 60 * 60 * 1000
);

// 5주 뒤 수요일
const secondFinalRafflePeriod = new Date(
  today.getTime() + (5 * 7 + daysUntilWednesday) * 24 * 60 * 60 * 1000
);

// 수욜 10:05 이후 다음주 수요일
const secondReleaseDate = new Date(
  today.getTime() + (7 + daysUntilWednesday) * 24 * 60 * 60 * 1000
);

export const RaffleDate = (item: string) => {
  if (item === "기간") {
    if (
      (todayDay === 3 && todayHour === 10 && todayMinute > 4) ||
      (todayDay === 3 && todayHour > 10)
    ) {
      return (
        firstFinalRafflePeriod.getFullYear() +
        "/" +
        String(firstFinalRafflePeriod.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(firstFinalRafflePeriod.getDate()).padStart(2, "0") +
        "(수) ~ " +
        String(secondEndRafflePeriod.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(secondEndRafflePeriod.getDate()).padStart(2, "0") +
        "(화)"
      );
    } else {
      return (
        firstStartRafflePeriod.getFullYear() +
        "/" +
        String(firstStartRafflePeriod.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(firstStartRafflePeriod.getDate()).padStart(2, "0") +
        "(수) ~ " +
        String(firstEndRafflePeriod.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(firstEndRafflePeriod.getDate()).padStart(2, "0") +
        "(화)"
      );
    }
  } else if (item === "발표일") {
    if (
      (todayDay === 3 && todayHour === 10 && todayMinute > 4) ||
      (todayDay === 3 && todayHour > 10)
    ) {
      return (
        secondReleaseDate.getFullYear() +
        "/" +
        String(secondReleaseDate.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(secondReleaseDate.getDate()).padStart(2, "0") +
        "(수) 오전 10시"
      );
    } else {
      return (
        firstReleaseDate.getFullYear() +
        "/" +
        String(firstReleaseDate.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(firstReleaseDate.getDate()).padStart(2, "0") +
        "(수) 오전 10시"
      );
    }
  } else if (item === "오늘") {
    return (
      today.getFullYear() +
      "/" +
      String(today.getMonth() + 1).padStart(2, "0") +
      "/" +
      String(today.getDate()).padStart(2, "0")
    );
  } else if (item === "tooltip") {
    if (
      (todayDay === 3 && todayHour === 10 && todayMinute > 4) ||
      (todayDay === 3 && todayHour > 10)
    ) {
      return (
        secondEndRafflePeriod.getFullYear() +
        "/" +
        String(secondEndRafflePeriod.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(secondEndRafflePeriod.getDate()).padStart(2, "0") +
        " ~ " +
        String(secondFinalRafflePeriod.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(secondFinalRafflePeriod.getDate()).padStart(2, "0")
      );
    } else {
      return (
        firstEndRafflePeriod.getFullYear() +
        "/" +
        String(firstEndRafflePeriod.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(firstEndRafflePeriod.getDate()).padStart(2, "0") +
        " ~ " +
        String(firstFinalRafflePeriod.getMonth() + 1).padStart(2, "0") +
        "/" +
        String(firstFinalRafflePeriod.getDate()).padStart(2, "0")
      );
    }
  }
};
