import { Grid } from "@mui/material";
import { useState } from "react";
import { MaxWidth } from "../../styles/theme";
import AdminTitle from "./AdminTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DrivingHistory from "./Vehicle/DrivingHistory";
import VehicleManagement from "./Vehicle/VehicleManagement";
import InsuranceManagement from "./Vehicle/InsuranceManagement";

function AdminVehicle() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MaxWidth>
          <AdminTitle titleName="차량" />
        </MaxWidth>
      </Grid>

      <Grid item xs={12}>
        <MaxWidth>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="운행 내역" value={0} />
            <Tab label="차량 관리" value={1} />
            <Tab label="보험 관리" value={2} />
          </Tabs>
          {value === 0 && <DrivingHistory />}
          {value === 1 && <VehicleManagement />}
          {value === 2 && <InsuranceManagement />}
        </MaxWidth>
      </Grid>
    </Grid>
  );
}

export default AdminVehicle;
