import { Grid } from "@mui/material";

import {
  CenterLayout,
  LeftMainItem,
  MainTopTitleBox,
  SubMainBox,
  TopTitleBox,
  TopTitleBoxMobile,
} from "../../styles/theme";

import { useHistory } from "react-router-dom";
import { Mobile, Pc } from "../../pages/Layout/ResponsiveScreen";
import {
  RESERVATION_TYPE,
  RESERVATION_TYPE_BRANCH,
} from "../../system/Constants";
import { CustomButtonCommon } from "../../styles/button";
import { AdminRoutes } from "../../system/types/type";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  BranchEmployeeCheck,
  HolidayDataAtom,
  RoleCheckAtom,
} from "../../system/atoms";
import { useEffect } from "react";
import { HolidayApi } from "../../system/api/HolidayApi";
import { CustomText } from "../../styles/CustomText";

function MainPage() {
  const history = useHistory();
  const branchEmployeeCheck = useRecoilValue(BranchEmployeeCheck);
  const reservationType = branchEmployeeCheck
    ? RESERVATION_TYPE_BRANCH
    : RESERVATION_TYPE;
  const [RoleCheck] = useRecoilState(RoleCheckAtom);
  const [holidayList, setHolidayList] = useRecoilState(HolidayDataAtom);
  const onMoveSite = (route: string) => {
    history.push({
      pathname: `${route}`,
    });
  };
  const today = new Date();
  useEffect(() => {
    if (holidayList.length === 0) {
      HolidayApi.getHoliday(today.getFullYear()).then((res) => {
        setHolidayList(res.data);
      });
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Mobile>
        <LeftMainItem item sm={12}>
          <TopTitleBoxMobile>인바디</TopTitleBoxMobile>
          <TopTitleBoxMobile>예약 서비스</TopTitleBoxMobile>
          <SubMainBox item xs={12}>
            <CustomText type="title" bold400>
              InServe에서
            </CustomText>
            <CustomText type="title" bold400>
              다양한 서비스를 이용해보세요.
            </CustomText>
          </SubMainBox>
          <CenterLayout>
            {reservationType.map((item, index) => {
              return (
                <span key={index}>
                  <CustomButtonCommon onClick={() => onMoveSite(item.routes)}>
                    {item.text}
                  </CustomButtonCommon>
                </span>
              );
            })}
            {RoleCheck && (
              <CustomButtonCommon
                onClick={() => onMoveSite(`${AdminRoutes.root}`)}
              >
                관리자 페이지
              </CustomButtonCommon>
            )}
          </CenterLayout>
        </LeftMainItem>
      </Mobile>
      <Pc>
        <Grid item sm={4}></Grid>
        <LeftMainItem item sm={4}>
          <MainTopTitleBox>인바디</MainTopTitleBox>
          <TopTitleBox>예약 서비스</TopTitleBox>
          <SubMainBox item xs={12}>
            <CustomText type="title" bold400>
              InServe에서
            </CustomText>
            <CustomText type="title" bold400>
              다양한 서비스를 이용해보세요.
            </CustomText>
          </SubMainBox>
          <CenterLayout>
            {reservationType.map((item, index) => {
              return (
                <span key={index}>
                  <CustomButtonCommon onClick={() => onMoveSite(item.routes)}>
                    {item.text}
                  </CustomButtonCommon>
                </span>
              );
            })}
            {RoleCheck && (
              <CustomButtonCommon
                onClick={() => onMoveSite(`${AdminRoutes.root}`)}
              >
                관리자 페이지
              </CustomButtonCommon>
            )}
          </CenterLayout>
        </LeftMainItem>
      </Pc>
    </Grid>
  );
}

export default MainPage;
