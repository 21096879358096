import styled from "@emotion/styled";
import { NoticeData } from "../../../system/types/Notice";
import { Grid } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import { CustomButtonGray, CustomButtonGreen } from "../../../styles/button";
import AlertModal from "../../Common/AlertModal";
import { NoticeApi } from "../../../system/api/NoticeApi";
import { ErrorHandler } from "../../../system/ApiService";
import { InputBox } from "../../../styles/theme";
import { createRef, useEffect, useState } from "react";
import CrossEditor from "../../Common/Editor/CrossEditor";
import {
  ChangeEventArgs,
  CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import * as gregorian from "../../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as NumberingSystems from "../../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import * as weekData from "../../../../node_modules/cldr-data/supplemental/weekData.json";
import { loadCldr } from "@syncfusion/ej2-base";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import { InitNoticeDetailData } from "../../../system/types/initObject";

loadCldr(NumberingSystems, gregorian, numbers, timeZoneNames, weekData);

interface VehicleNoticeEditProps {
  data: NoticeData;
  setNoticeState: (noticeState: string) => void;
  setData: (data: NoticeData) => void;
  refreshMainData: () => void;
}

function validation(data: NoticeData, mainCheck: boolean) {
  if (!data.title) {
    AlertModal("check", "제목을 작성해주세요.");
    return false;
  } else if (
    mainCheck &&
    (data.mainStartDate === null || data.mainEndDate === null)
  ) {
    AlertModal(
      "check",
      "주요 공지 설정 시, <br> 시작 기간과 종료 기간을 모두 입력해주세요."
    );
    return false;
  } else if (
    mainCheck &&
    data.mainStartDate &&
    data.mainEndDate &&
    new Date(data.mainStartDate) > new Date(data.mainEndDate)
  ) {
    AlertModal(
      "check",
      "주요 공지 설정 시,<br>시작 기간은 종료 기간보다 뒤로 설정할 수 없습니다.<br>올바른 기간을 선택해주세요."
    );
    return false;
  }
  return true;
}

function VehicleNoticeEdit({
  data,
  setNoticeState,
  refreshMainData,
}: VehicleNoticeEditProps) {
  const [editNoticeData, setNoticeData] =
    useState<NoticeData>(InitNoticeDetailData);
  const [infoUpdate, setInfoUpdate] = useState(false);
  const onChangeState = () => {
    setNoticeState("detail");
  };

  function onChange(args: any) {
    setNoticeData({
      ...editNoticeData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  }

  const [mainCheck, setMainCheck] = useState(false);
  const onChangeCheckBox = (args: ChangeEventArgs): void => {
    if (mainCheck) {
      setNoticeData({
        ...editNoticeData,
        mainStartDate: null,
        mainEndDate: null,
      });
    }
    setMainCheck(!mainCheck);
  };
  const params = {
    Width: "100%",
    Height: 400,
  };
  useEffect(() => {
    if (data.id) {
      setNoticeData(data);
      if (data.mainStartDate) {
        setMainCheck(true);
      }
    }
    setInfoUpdate(true);
  }, [data.id, setMainCheck, data]);

  const ref = createRef<CrossEditor>();
  const [started, setStarted] = useState<boolean>(false);

  const onLoaded = (_: any, editor: { GetBodyValue: () => any }) => {
    setStarted(true);
  };

  const onChangeDateTimePicker = (args: any) => {
    if (args.value) {
      setNoticeData({
        ...editNoticeData,
        mainStartDate: isoDateFormatter(new Date(args.value[0])) + " 00:00:00",
        mainEndDate: isoDateFormatter(new Date(args.value[1])) + " 23:59:59",
      });
    } else {
      setNoticeData({
        ...editNoticeData,
        mainStartDate: null,
        mainEndDate: null,
      });
    }
  };

  var submitcheck = false;
  function onSave() {
    if (!started) {
      window.alert(
        "에디터가 로드되지 않았습니다. 잠시 후 다시 시도해 주시기 바랍니다."
      );
      return;
    }

    if (
      submitcheck === false &&
      ref.current &&
      validation(editNoticeData, mainCheck)
    ) {
      const editor = ref.current.editorRef;
      submitcheck = true;
      if (editNoticeData.id) {
        NoticeApi.updateNotice({
          ...editNoticeData,
          type: "차량",
          content: editor.GetBodyValue() || "",
        })
          .then(() => {
            AlertModal("success", "공지사항이 수정되었습니다.");
            setNoticeState("table");
            refreshMainData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = false;
          });
      } else {
        NoticeApi.createNotice({
          ...editNoticeData,
          type: "차량",
          content: editor.GetBodyValue() || "",
        })
          .then(() => {
            AlertModal("success", "공지사항이 작성되었습니다.");
            setNoticeState("table");
            refreshMainData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = false;
          });
      }
    }
  }

  return (
    <StyledNoticeBox container mt="20px">
      <StyledNoticeTable container alignItems="center">
        <Grid item sx={{ width: "120px" }}>
          <CustomText type="subtitle">제목</CustomText>
        </Grid>
        <Grid item sx={{ width: "calc(100% - 120px)" }}>
          <InputBox
            type="text"
            name="title"
            onChange={onChange}
            value={editNoticeData.title || ""}
            placeholder="제목을 입력해주세요."
          />
        </Grid>
      </StyledNoticeTable>
      <StyledNoticeTable container alignItems="center">
        <Grid container item xs={12} sm={6}>
          <CustomText type="subtitle" width="120px">
            주요 공지
          </CustomText>
          <Grid>
            <CheckBoxComponent
              name="check"
              onChange={onChangeCheckBox}
              checked={mainCheck}
            />
          </Grid>
        </Grid>
        {mainCheck && (
          <Grid container item alignItems="center" xs={12} sm={6}>
            <CustomText type="subtitle" width="120px">
              주요 공지 기간
            </CustomText>
            <Grid
              container
              item
              alignItems="center"
              sx={{ width: "calc(100% - 120px)" }}
            >
              <DateRangePickerComponent
                placeholder="주요 공지 기간을 선택해주세요."
                locale="ko"
                format="yyyy-MM-dd"
                value={
                  editNoticeData.mainStartDate && editNoticeData.mainEndDate
                    ? [
                        new Date(editNoticeData.mainStartDate),
                        new Date(editNoticeData.mainEndDate),
                      ]
                    : undefined
                }
                showClearButton={false}
                delayUpdate={true}
                onChange={(args: any) => {
                  onChangeDateTimePicker(args);
                }}
              />
            </Grid>
          </Grid>
        )}
      </StyledNoticeTable>
      <StyledNoticeTable container>
        <CustomText type="subtitle" width="120px">
          내용
        </CustomText>
        <StyledTextBox item sx={{ width: "calc(100% - 120px)" }}>
          {infoUpdate && (
            <CrossEditor
              ref={ref}
              name="VehicleNotice"
              params={params}
              value={editNoticeData.content}
              onLoaded={onLoaded}
            />
          )}
        </StyledTextBox>
      </StyledNoticeTable>
      <Grid container justifyContent="center" mt="10px">
        <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
        <CustomButtonGray onClick={onChangeState}>목록으로</CustomButtonGray>
      </Grid>
    </StyledNoticeBox>
  );
}

export default VehicleNoticeEdit;

const StyledNoticeBox = styled(Grid)({
  borderTop: "1px solid #DCDFE3",
});

const StyledNoticeTable = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  padding: "10px 5px",
  minHeight: "60px",
});

const StyledTextBox = styled(Grid)({
  minHeight: "300px",
});
