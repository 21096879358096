import { Grid } from "@mui/material";
import {
  AppliedItem,
  InputBox,
  InputBoxLeft,
  InputBoxRight,
  InputLayout,
  MainTitle,
  RightLayout,
  RaffleContent,
} from "../../../styles/theme";

import { Employee } from "../../../system/types/Employee";
import { CustomButtonGreen } from "../../../styles/button";
import { EmployeeApi } from "../../../system/api/EmployeeApi";
import { ErrorHandler } from "../../../system/ApiService";
import { useHistory } from "react-router-dom";
import AlertModal from "../../Common/AlertModal";
import { InitEmployee } from "../../../system/types/initObject";
import { X } from "@phosphor-icons/react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { BranchEmployeeWorkplaces } from "../../../system/Constants";
import {
  AccommodationInfoTitle,
  EmployeeInfoBox,
} from "../../../styles/accommodationStyle";

function validation(data: Employee) {
  let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
  let regexphone = /\d{3}-\d{4}-\d{4}/;
  if (data.name === "") {
    AlertModal("check", "이름을 작성해주세요.");
    return false;
  } else if (data.email === "") {
    AlertModal("check", "이메일을 작성해주세요.");

    return false;
  } else if (regex.test(data.email) === false) {
    AlertModal("check", "이메일 형식을 확인해주세요.");
    return false;
  } else if (data.phone === "") {
    AlertModal("check", "연락처를 작성해주세요.");

    return false;
  } else if (regexphone.test(data.phone) === false) {
    AlertModal("check", "연락처 형식을 확인해주세요.");
    return false;
  } else if (data.workplace === "") {
    AlertModal("check", "소속(근무위치)을 선택해주세요.");
    return false;
  } else if (data.department === "") {
    AlertModal("check", "부서를 선택해주세요.");
    return false;
  } else if (data.team === "") {
    AlertModal("check", "팀을 선택해주세요.");

    return false;
  } else if (data.jobTitle === "") {
    AlertModal("check", "직책을 선택해주세요.");

    return false;
  } else if (data.jobPosition === "") {
    AlertModal("check", "직급을 선택해주세요.");
    return false;
  }

  return true;
}

interface AddEmployeeProps {
  employeeData: Employee;
  setEmployeeData: (status: Employee) => void;
  modalType: string;
  handleClose: () => void;
  refreshEmployeeData: () => void;
}

function AddEmployee({
  employeeData,
  setEmployeeData,
  modalType,
  handleClose,
  refreshEmployeeData,
}: AddEmployeeProps) {
  const history = useHistory();
  var submitcheck = false;
  const onChange = (args: any) => {
    setEmployeeData({
      ...employeeData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onSave = () => {
    if (submitcheck === false) {
      if (validation(employeeData)) {
        if (modalType === "추가") {
          submitcheck = true;
          EmployeeApi.createEmployee(employeeData)
            .then(() => {
              AlertModal("success", "작성하신 지사 직원이 추가되었습니다.");
              setEmployeeData(InitEmployee);
              handleClose();
              refreshEmployeeData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        } else {
          submitcheck = true;
          EmployeeApi.updateEmployeeInfo(employeeData)
            .then(() => {
              AlertModal("success", "해당 지사 직원 정보가 수정되었습니다.");
              setEmployeeData(InitEmployee);
              handleClose();
              refreshEmployeeData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RaffleContent>
            {modalType === "추가" ? (
              <MainTitle>지사 직원 추가</MainTitle>
            ) : (
              <MainTitle>지사 직원 정보 수정</MainTitle>
            )}

            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>
        </Grid>
        <Grid item xs={12}>
          <EmployeeInfoBox item xs={12}>
            <InputLayout item xs={12}>
              <Grid item xs={12}>
                <AccommodationInfoTitle>이름</AccommodationInfoTitle>
                <InputBox
                  type="text"
                  name="name"
                  onChange={onChange}
                  value={employeeData.name || ""}
                  placeholder="이름을 입력해주세요."
                />
              </Grid>
              <Grid item xs={12}>
                <AccommodationInfoTitle>이메일</AccommodationInfoTitle>
                <InputBox
                  type="text"
                  name="email"
                  value={employeeData.email || ""}
                  onChange={onChange}
                  placeholder="이메일을 입력해주세요. ex) 1234@inbody.com"
                />
              </Grid>
              <Grid item xs={12}>
                <AccommodationInfoTitle>연락처</AccommodationInfoTitle>
                <InputBox
                  type="text"
                  name="phone"
                  value={employeeData.phone || ""}
                  onChange={onChange}
                  placeholder="연락처를 입력해주세요. ex) 010-0000-0000"
                />
              </Grid>
            </InputLayout>
          </EmployeeInfoBox>
        </Grid>
        <Grid item xs={12}>
          <EmployeeInfoBox item xs={12}>
            <InputLayout item xs={12}>
              <Grid item xs={12}>
                <AccommodationInfoTitle>소속</AccommodationInfoTitle>
                <DropDownListComponent
                  dataSource={BranchEmployeeWorkplaces}
                  name="workplace"
                  value={employeeData.workplace || ""}
                  onChange={onChange}
                  placeholder="소속(근무 위치)을 입력해주세요."
                />
              </Grid>
              <InputBoxLeft item xs={6}>
                <AccommodationInfoTitle>부서</AccommodationInfoTitle>
                <InputBox
                  type="text"
                  onChange={onChange}
                  value={employeeData.department || ""}
                  name="department"
                  placeholder="부서를 입력해주세요."
                />
              </InputBoxLeft>

              <InputBoxRight item xs={6}>
                <AccommodationInfoTitle>팀</AccommodationInfoTitle>
                <InputBox
                  type="text"
                  onChange={onChange}
                  value={employeeData.team || ""}
                  name="team"
                  placeholder="팀을 입력해주세요."
                />
              </InputBoxRight>
              <InputBoxLeft item xs={6}>
                <AccommodationInfoTitle>직책</AccommodationInfoTitle>
                <InputBox
                  type="text"
                  onChange={onChange}
                  value={employeeData.jobTitle || ""}
                  name="jobTitle"
                  placeholder="직책을 입력해주세요."
                />
              </InputBoxLeft>

              <InputBoxRight item xs={6}>
                <AccommodationInfoTitle>직급</AccommodationInfoTitle>
                <InputBox
                  type="text"
                  onChange={onChange}
                  value={employeeData.jobPosition || ""}
                  name="jobPosition"
                  placeholder="직급을 입력해주세요."
                />
              </InputBoxRight>
            </InputLayout>
          </EmployeeInfoBox>
        </Grid>
        <Grid item xs={12}>
          <RightLayout>
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </RightLayout>
        </Grid>
      </Grid>
    </>
  );
}

export default AddEmployee;
