import { AccommodationDetailInfoBox } from "../../styles/theme";
import { DetailedAccommodation } from "../../system/types/Accommodation";
import HtmlContent from "../Common/HtmlContext";

interface AccommodationDataProps {
  data: DetailedAccommodation;
}

function AccommodationData({ data }: AccommodationDataProps) {
  return (
    <>
      <AccommodationDetailInfoBox>
        <HtmlContent
          data={data.desc1 ? data.desc1 : "작성된 내용이 없습니다."}
        ></HtmlContent>
      </AccommodationDetailInfoBox>
    </>
  );
}

export default AccommodationData;
