import {
  AppliedItem,
  MainTitle,
  RaffleContent,
  RaffleDay,
  ShowUploadFullImg,
  ShowUploadImg,
} from "../../../styles/theme";

import uuid from "react-uuid";
import { CheckInConfirm } from "../../../system/types/Reservation";
import { X } from "@phosphor-icons/react";
import {
  ReportAfterImgBox,
  ReportAfterImgItem,
  SelectAccommodationStatus,
  SelectAccommodationText,
  AccommodationStatusIcon,
  AdminAccommodationInfoBox,
  ReportAfterUseBox,
} from "../../../styles/accommodationStyle";
import { useState } from "react";

interface CheckInModalProps {
  handleClose: () => void;
  data: CheckInConfirm;
}

function CheckInModalReport({ handleClose, data }: CheckInModalProps) {
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const ImageViewer = (url: string) => {
    if (selectedImageUrl === url) {
      setSelectedImageUrl("");
    } else {
      setSelectedImageUrl(url);
    }
  };

  return (
    <>
      <RaffleContent>
        <MainTitle>입실 이전 휴양소 상태</MainTitle>
        <AppliedItem onClick={handleClose}>
          <X size={32} />
        </AppliedItem>
      </RaffleContent>
      {data && (
        <ReportAfterUseBox>
          <SelectAccommodationStatus>
            <RaffleDay>
              <AccommodationStatusIcon
                src={
                  data.condition === "GOOD"
                    ? "/images/SGOOD.png"
                    : "/images/GOOD.png"
                }
                alt="good"
              ></AccommodationStatusIcon>
              <SelectAccommodationText>최고예요</SelectAccommodationText>
            </RaffleDay>
            <RaffleDay>
              {" "}
              <AccommodationStatusIcon
                src={
                  data.condition === "SOSO"
                    ? "/images/SSOSO.png"
                    : "/images/SOSO.png"
                }
                alt="soso"
              ></AccommodationStatusIcon>
              <SelectAccommodationText>좋아요</SelectAccommodationText>
            </RaffleDay>
            <RaffleDay>
              <AccommodationStatusIcon
                src={
                  data.condition === "BAD"
                    ? "/images/SBAD.png"
                    : "/images/BAD.png"
                }
                alt="bad"
              ></AccommodationStatusIcon>
              <SelectAccommodationText>별로예요</SelectAccommodationText>
            </RaffleDay>
          </SelectAccommodationStatus>
          <SelectAccommodationText>
            기타의견
            <AdminAccommodationInfoBox>
              {data.opinions ? <pre>{data.opinions}</pre> : "-"}
            </AdminAccommodationInfoBox>
          </SelectAccommodationText>
          {data?.attachmentFiles.length > 0 && (
            <>
              <SelectAccommodationText>
                사진첨부
                <br />※ 사진 클릭 시, 해당 사진을 원본 크기로 확인하실 수
                있습니다.
              </SelectAccommodationText>
              <ReportAfterImgBox>
                {data.attachmentFiles.map((image, idx) => (
                  <ReportAfterImgItem key={uuid()}>
                    <ShowUploadImg
                      src={image.url}
                      alt="ReportImg"
                      onClick={() => {
                        ImageViewer(image.url);
                      }}
                    />
                  </ReportAfterImgItem>
                ))}
              </ReportAfterImgBox>
            </>
          )}
          {selectedImageUrl && (
            <>
              <SelectAccommodationText>
                [ 선택된 사진 확인 ]
              </SelectAccommodationText>
              <ShowUploadFullImg src={selectedImageUrl} alt="fullReportImg" />
            </>
          )}
        </ReportAfterUseBox>
      )}
    </>
  );
}

export default CheckInModalReport;
