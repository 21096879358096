import client from "../ApiService";
import HttpClient from "../http-client";
import {
  VehicleDrivingInfo,
  VehicleEditInfo,
  VehicleInfo,
  VehicleMemoInfo,
  VehicleReservationInfo,
} from "../types/Vehicle";

const baseUri: string = "/vehicle";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _VehicleApi extends _BasicApi {
  getVehicle(page: number, size: number, searchWord?: string, sort?: string) {
    return this.client.get(`${baseUri}`, {
      params: {
        page: page,
        size: size,
        searchWord: searchWord,
        sort: sort,
      },
    });
  }
  updateVehicle(vehicle: VehicleInfo) {
    return this.client.put(`${baseUri}`, vehicle);
  }
  createVehicle(vehicle: VehicleInfo) {
    return this.client.post(`${baseUri}`, vehicle);
  }
  getAvailableVehicle(
    startDate: string,
    time: string,
    sizeType?: string,
    shapeType?: string,
    search?: String
  ) {
    return this.client.get(`${baseUri}/available`, {
      params: {
        startDate: startDate,
        time: time,
        sizeType: sizeType,
        shapeType: shapeType,
        search: search,
      },
    });
  }
  updateDrivingVehicle(driving: VehicleDrivingInfo) {
    return this.client.post(`${baseUri}/driving`, driving);
  }
  getDrivingHistory(
    startDate: string | null | undefined,
    endDate: string | null | undefined,
    isFile: boolean,
    vehicleIds: number | null,
    employeeIds: number | null
  ) {
    const params = new URLSearchParams();

    if (startDate !== null && startDate !== undefined) {
      params.append("startDate", startDate);
    }

    if (endDate !== null && endDate !== undefined) {
      params.append("endDate", endDate);
    }

    params.append("isFile", String(isFile));

    if (vehicleIds !== null) {
      params.append("vehicleIds", String(vehicleIds));
    }

    if (employeeIds !== null) {
      params.append("employeeIds", String(employeeIds));
    }

    return this.client.get(
      `${baseUri}/driving/history?${params.toString()}`,
      isFile ? { responseType: "blob" } : {}
    );
  }
  getTodayReservationVehicle() {
    return this.client.get(`${baseUri}/reservation/today`);
  }
  updateVehicleMemo(memo: VehicleMemoInfo) {
    return this.client.post(`${baseUri}/reservation/memo`, memo);
  }
  updateVehicleReservation(reservationId: number, dt: VehicleEditInfo) {
    return this.client.put(`${baseUri}/reservation/${reservationId}`, dt);
  }
  createVehicleReservation(vehicle: VehicleReservationInfo) {
    return this.client.post(`${baseUri}/reservation`, vehicle);
  }
  deleteVehicleReservation(id: number) {
    return this.client.delete(`${baseUri}/reservation`, {
      params: { reservationId: id },
    });
  }
  getReservationCalendar(
    sizeType: string | undefined,
    year: number,
    month: number,
    isBookmarks: boolean,
    name?: string
  ) {
    return this.client.get(`${baseUri}/calendar`, {
      params: {
        sizeType: sizeType,
        year: year,
        month: month,
        isBookmarks: isBookmarks,
        name: name,
      },
    });
  }
  checkDesignatedExecutives() {
    return this.client.get(`${baseUri}/own`);
  }
}

export const VehicleApi = new _VehicleApi(client);
