import client from "../ApiService";
import HttpClient from "../http-client";
import { Employee } from "../types/Employee";

const baseUri: string = "/employee";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _EmployeeApi extends _BasicApi {
  getEmployee(employeeType: string, isActive: boolean) {
    return this.client.get(`${baseUri}`, {
      params: { employeeType: employeeType, isActive: isActive },
    });
  }
  createEmployee(employee: Employee) {
    return this.client.post(`${baseUri}/branch/create`, employee);
  }

  updateEmployee(employee: Employee) {
    return this.client.put(`${baseUri}/active`, employee);
  }

  updateEmployeeInfo(employee: Employee) {
    return this.client.put(`${baseUri}/branch/update`, employee);
  }

  checkOffice365() {
    return this.client.get(`${baseUri}/azure/account/check`);
  }

  getmyInfo() {
    return this.client.get(`${baseUri}/my`);
  }
}

export const EmployeeApi = new _EmployeeApi(client);
