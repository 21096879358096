import { Grid } from "@mui/material";
import {
  VehicleMainBox,
  VehicleLeftBox,
  VehicleRightBox,
  VehicleMainBoxPc,
} from "../../styles/vehicleStyle";
import { VehiclePc, VehicleMobile } from "../../pages/Layout/ResponsiveScreen";
import VehicleNoticeBox from "./VehicleNoticeBox";
import VehicleMyBox from "./VehicleMyBox";
import VehicleGuideBox from "./VehicleGuideBox";
import VehicleDrivingBox from "./VehicleDrivingBox";
import { useState } from "react";

function Vehicle() {
  const [myBoxState, setMyBoxState] = useState("");
  return (
    <>
      <VehicleMobile>
        <Grid container justifyContent="center" style={{ marginLeft: "0px" }}>
          <VehicleMainBox item xs={12}>
            <Grid container justifyContent="center">
              <VehicleLeftBox>
                <VehicleDrivingBox
                  setMyBoxState={setMyBoxState}
                  myBoxState={myBoxState}
                />
              </VehicleLeftBox>
            </Grid>
          </VehicleMainBox>
          <VehicleMainBox item xs={12}>
            <Grid container justifyContent="flex-start">
              <VehicleRightBox>
                <VehicleNoticeBox />
              </VehicleRightBox>
            </Grid>
            <Grid container justifyContent="flex-start">
              <VehicleRightBox>
                <VehicleMyBox myBoxState={myBoxState} />
              </VehicleRightBox>
            </Grid>
            <Grid container justifyContent="flex-start">
              <VehicleRightBox>
                <VehicleGuideBox />
              </VehicleRightBox>
            </Grid>
          </VehicleMainBox>
        </Grid>
      </VehicleMobile>
      <VehiclePc>
        <VehicleMainBoxPc container justifyContent="center">
          <VehicleMainBox item xs={5.9}>
            <Grid container justifyContent="flex-end">
              <VehicleLeftBox>
                <VehicleDrivingBox
                  setMyBoxState={setMyBoxState}
                  myBoxState={myBoxState}
                />
              </VehicleLeftBox>
            </Grid>
          </VehicleMainBox>
          <VehicleMainBox item xs={5.9}>
            <Grid container justifyContent="flex-start">
              <VehicleRightBox>
                <VehicleNoticeBox />
              </VehicleRightBox>
            </Grid>
            <Grid container justifyContent="flex-start">
              <VehicleRightBox>
                <VehicleMyBox myBoxState={myBoxState} />
              </VehicleRightBox>
            </Grid>
            <Grid container justifyContent="flex-start">
              <VehicleRightBox>
                <VehicleGuideBox />
              </VehicleRightBox>
            </Grid>
          </VehicleMainBox>
        </VehicleMainBoxPc>
      </VehiclePc>
    </>
  );
}
export default Vehicle;
