import client from "../ApiService";
import HttpClient from "../http-client";
import { InsuranceInfo } from "../types/Vehicle";

const baseUri: string = "/insurance";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _InsuranceApi extends _BasicApi {
  getInsurance(page: number, size: number) {
    return this.client.get(`${baseUri}`, {
      params: { page: page, size: size },
    });
  }
  createInsurance(insurance: InsuranceInfo) {
    return this.client.post(`${baseUri}`, insurance);
  }
  updateInsurance(insurance: InsuranceInfo) {
    return this.client.put(`${baseUri}`, insurance);
  }
}

export const InsuranceApi = new _InsuranceApi(client);
