import Vehicle from "../../components/Vehicle/Vehicle";

function VehicleMain() {
  return (
    <>
      <Vehicle />
    </>
  );
}

export default VehicleMain;
