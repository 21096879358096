import { AccommodationRezData } from "../../../../system/types/Accommodation";
import { ReservationSearchData } from "../../../../system/types/Reservation";
import {
  AdminCardDataBox,
  BlueText,
  CardDataCenterContent,
  CardDataContent,
  CardDataLayout,
  GreenContentsText,
  OrangeText,
  RedContentsText,
  ThickText,
  RaffleContent,
} from "../../../../styles/theme";
import MyAccommodationChip from "../../../Common/Accommodation/MyAccommodationChip";
import AdminAccommodationButton from "../../../Common/Accommodation/AdminAccommodationButton";

interface AccommodationRezProps {
  data: AccommodationRezData;
  adminData: ReservationSearchData[];
}

function AdminReservationCheckCard({ data, adminData }: AccommodationRezProps) {
  return (
    <CardDataLayout>
      {adminData.map((item, idx) => {
        return (
          <AdminCardDataBox key={idx}>
            <CardDataContent>
              <RaffleContent>
                <ThickText>
                  {item.accommodationName} {item.detailedAddress.dong}동{" "}
                  {item.detailedAddress.ho
                    ? item.detailedAddress.ho + "호"
                    : null}
                </ThickText>
                <ThickText>추첨 결과</ThickText>
              </RaffleContent>
            </CardDataContent>
            <CardDataContent>
              <RaffleContent>
                <MyAccommodationChip status={item.status} />

                {item.status ===
                  ("당첨" || "이용중" || "이용종료" || "보고완료") && (
                  <GreenContentsText>당첨</GreenContentsText>
                )}
                {item.status === "취소" && <OrangeText>취소</OrangeText>}
                {item.status === "예약중" && <BlueText>발표 전</BlueText>}
                {item.status === "미당첨" && (
                  <RedContentsText>미당첨</RedContentsText>
                )}
              </RaffleContent>
            </CardDataContent>
            <CardDataContent>{item.employeeName}</CardDataContent>
            <CardDataContent>
              {item.startDate} ~ {item.endDate}
            </CardDataContent>
            <CardDataCenterContent>
              <AdminAccommodationButton data={item} />
            </CardDataCenterContent>
          </AdminCardDataBox>
        );
      })}
    </CardDataLayout>
  );
}

export default AdminReservationCheckCard;
