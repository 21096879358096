import React, { ReactNode } from "react";
import { ContentShift } from "../../styles/theme";

interface ContentProps {
  children: ReactNode;
}

function Content({ children }: ContentProps) {
  return (
    <>
      <ContentShift>{children}</ContentShift>
    </>
  );
}

export default Content;
