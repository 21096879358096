import { Box, Modal } from "@mui/material";
import {
  CustomButtonBlue,
  CustomButtonDisabled,
  CustomButtonGreen,
  CustomButtonRed,
} from "../../../styles/button";
import { AccommodationApi } from "../../../system/api/AccommodationApi";
import { ErrorHandler } from "../../../system/ApiService";
import { ModalStyle } from "../../../styles/theme";
import { useState } from "react";
import CheckInModal from "../../MyPage/Modal/CheckInModal";
import CheckOutModal from "../../MyPage/Modal/CheckOutModal";
import CheckOutModalReport from "../../MyPage/Modal/CheckOutModalReport";
import AlertModal from "../AlertModal";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../styles/color";
import CheckInModalReport from "../../MyPage/Modal/CheckInModalReport";

interface AccommodationButtonProps {
  data: any;
  setReservationId?: (status: number) => void;
  refreshReservationData: () => void;
}

function AccommodationButton({
  data,
  setReservationId,
  refreshReservationData,
}: AccommodationButtonProps) {
  // Flow 모달
  const [open, setOpen] = useState(false);
  const handleOpen = (status: string) => {
    setModalStatus(status);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [modalStatus, setModalStatus] = useState("");

  const cancelReservation = (id: number, status: string) => {
    if (status === "예약중") {
      Swal.fire({
        title: "정말 취소하시겠습니까?",
        text: "선택하신 휴양소 예약이 취소됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          AccommodationApi.deleteReservation(id)
            .then(() => {
              AlertModal("success", "예약이 취소되었습니다.");
              refreshReservationData();
              if (setReservationId) {
                setReservationId(data.id);
              }
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    } else {
      Swal.fire({
        title: "정말 취소하시겠습니까?",
        text: "선택하신 휴양소 예약이 취소됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          AccommodationApi.deleteReservation(id)
            .then(() => {
              AlertModal("success", "예약이 취소되었습니다.");
              refreshReservationData();
              if (setReservationId) {
                setReservationId(data.id);
              }
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  };

  const rereservation = (id: number) => {
    AccommodationApi.reReservation(id)
      .then(() => {
        AlertModal("success", "재예약이 완료되었습니다.");
        if (setReservationId) {
          setReservationId(data.id);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };
  return (
    <>
      {/* 휴양소 예약 */}
      {data.status === "예약중" && (
        <CustomButtonRed
          onClick={() => cancelReservation(data.id, data.status)}
        >
          예약 취소
        </CustomButtonRed>
      )}
      {data.status === "당첨" && (
        <>
          <CustomButtonBlue onClick={() => handleOpen("입실")}>
            입실
          </CustomButtonBlue>

          <CustomButtonRed
            onClick={() => cancelReservation(data.id, data.status)}
          >
            예약 취소
          </CustomButtonRed>
        </>
      )}
      {data.status === "미당첨" && (
        <>
          {data.reReservation === false ? (
            <CustomButtonDisabled>재예약</CustomButtonDisabled>
          ) : (
            <CustomButtonGreen onClick={() => rereservation(data.id)}>
              재예약
            </CustomButtonGreen>
          )}
        </>
      )}
      {data.status === "이용중" && (
        <>
          <CustomButtonGreen onClick={() => handleOpen("입실열람")}>
            설문 열람
          </CustomButtonGreen>
          <CustomButtonRed onClick={() => handleOpen("퇴실")}>
            퇴실
          </CustomButtonRed>
        </>
      )}
      {data.status === "이용종료" && (
        <>
          <CustomButtonGreen onClick={() => handleOpen("입실열람")}>
            설문 열람
          </CustomButtonGreen>
          <CustomButtonGreen onClick={() => handleOpen("퇴실")}>
            사용 후 보고
          </CustomButtonGreen>
        </>
      )}
      {data.status === "보고완료" && (
        <>
          <CustomButtonGreen onClick={() => handleOpen("입실열람")}>
            설문 열람
          </CustomButtonGreen>
          <CustomButtonGreen onClick={() => handleOpen("보고열람")}>
            보고 열람
          </CustomButtonGreen>
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          {modalStatus === "입실" && (
            <CheckInModal
              handleClose={handleClose}
              data={data}
              refreshReservationData={refreshReservationData}
            />
          )}
          {modalStatus === "퇴실" && (
            <CheckOutModal
              handleClose={handleClose}
              data={data}
              refreshReservationData={refreshReservationData}
            />
          )}
          {modalStatus === "입실열람" && (
            <CheckInModalReport handleClose={handleClose} data={data.checkIn} />
          )}
          {modalStatus === "보고열람" && (
            <CheckOutModalReport
              handleClose={handleClose}
              data={data.checkOut}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default AccommodationButton;
