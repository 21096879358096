import client from "../ApiService";
import HttpClient from "../http-client";
import { Accommodation } from "../types/Accommodation";
import {
  CheckInData,
  CheckOutData,
  CreateReservation,
} from "../types/Reservation";

const baseUri: string = "/accommodation";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _AccommodationApi extends _BasicApi {
  getAccommodationDetail(id: number) {
    return this.client.get(`${baseUri}/detail`, { params: { id: id } });
  }
  createAccommodation(accommodation: Accommodation) {
    return this.client.post(`${baseUri}`, accommodation);
  }
  updateAccommodation(accommodation: Accommodation) {
    return this.client.put(`${baseUri}`, accommodation);
  }
  deleteAccommodation(id: number) {
    return this.client.delete(`${baseUri}`, { params: { id: id } });
  }
  deleteReservation(id: number) {
    return this.client.delete(`${baseUri}/reservation`, { params: { id: id } });
  }
  createReservation(reservation: CreateReservation) {
    return this.client.post(`${baseUri}/reservation`, reservation);
  }
  reReservation(id: number) {
    return this.client.put(`${baseUri}/re-reservation`, null, {
      params: { id: id },
    });
  }
  getReservationSearch(
    startDate: string | undefined,
    endDate: string | undefined,
    accommodationId?: number | null,
    detailAddressId?: number | null
  ) {
    return this.client.get(`${baseUri}/reservation`, {
      params: {
        accommodationId: accommodationId,
        detailAddressId: detailAddressId,
        startDate: startDate,
        endDate: endDate,
      },
    });
  }
  createCheckIn(checkIn: CheckInData) {
    return this.client.post(`${baseUri}/reservation/check-in`, checkIn);
  }

  createCheckOut(checkOut: CheckOutData) {
    return this.client.post(`${baseUri}/reservation/check-out`, checkOut);
  }
  getDetailAddress(
    startDate: string | undefined,
    endDate: string | undefined,
    accommodationId: number
  ) {
    return this.client.get(`${baseUri}/reservation/detailAddress`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        accommodationId: accommodationId,
      },
    });
  }
  getReservationCalendar(year: number, month: number, name?: string) {
    return this.client.get(`${baseUri}/calendar`, {
      params: { year: year, month: month, name: name },
    });
  }
}

export const AccommodationApi = new _AccommodationApi(client);
