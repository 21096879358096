import { useState } from "react";

import AccommodationReservationCheckOld from "./AccommodationReservationCheckOld";
import {
  AppliedItem,
  BoardDropdownRight,
  DisabledItem,
} from "../../../styles/theme";
import { CalendarBlank, MagnifyingGlass } from "@phosphor-icons/react";
import AdminAccommodationCalendar from "./AdminAccommodationCalendar";

function AccommodationReservationCheck() {
  const [newType, setNewType] = useState(true);

  const onChangeNewType = () => {
    setNewType(!newType);
  };
  return (
    <>
      {newType ? (
        <>
          <BoardDropdownRight>
            {" "}
            <AppliedItem onClick={onChangeNewType}>
              <CalendarBlank size={32} />{" "}
            </AppliedItem>
            <DisabledItem onClick={onChangeNewType}>
              {" "}
              <MagnifyingGlass size={32} />
            </DisabledItem>
          </BoardDropdownRight>
          <AdminAccommodationCalendar />
        </>
      ) : (
        <>
          <BoardDropdownRight>
            <DisabledItem onClick={onChangeNewType}>
              <CalendarBlank size={32} />{" "}
            </DisabledItem>
            <AppliedItem onClick={onChangeNewType}>
              {" "}
              <MagnifyingGlass size={32} />
            </AppliedItem>
          </BoardDropdownRight>

          <AccommodationReservationCheckOld />
        </>
      )}
    </>
  );
}

export default AccommodationReservationCheck;
