import { Grid, IconButton, Tooltip } from "@mui/material";
import { ReminderData, ReminderProblemList } from "../../system/types/Reminder";
import {
  AppliedItem,
  MainTitle,
  MaxWidth,
  RaffleContent,
} from "../../styles/theme";
import { X } from "@phosphor-icons/react";
import {
  ReminderDetailWarning,
  ReminderStyledBody,
  ReminderStyledBody2,
  RemindermodalContenstBox2,
  RemindermodalContentMaxWidth,
  TooltipText,
} from "../../styles/reminderStyle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import RecurrenceText from "./RecurrenceText";
import { isoDateFormatter } from "../Common/IsoDateFormatter";
import { Fragment, useEffect, useState } from "react";
import { ORANGECONTENTS, REDTITLE } from "../../styles/color";

interface ReminderDetailProps {
  data: ReminderData;
  handleClose: () => void;
}

function ReminderDetail({ data, handleClose }: ReminderDetailProps) {
  const [problemData, setProblemData] = useState<ReminderProblemList[]>([]);

  useEffect(() => {
    setProblemData(
      data.problems.filter(
        (dt: ReminderProblemList) =>
          dt.isChecked === false && dt.status === "WARNING"
      )
    );
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaxWidth>
            <RaffleContent>
              <MainTitle>리마인더 상세 페이지</MainTitle>
              <AppliedItem onClick={handleClose}>
                <X size={32} />
              </AppliedItem>
            </RaffleContent>
            <RemindermodalContenstBox2>
              <RemindermodalContentMaxWidth>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <ReminderStyledBody2>제목</ReminderStyledBody2>
                        <ReminderStyledBody>{data.title}</ReminderStyledBody>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          반복 주기
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <RecurrenceText
                            data={data}
                            repeatData={data.recurrence}
                            type="part"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          알림 시작 날짜
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {isoDateFormatter(new Date(data.startDate))}
                        </TableCell>
                      </TableRow>

                      {data?.recurrence?.recurrenceEndDate ? (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            알림 만료일
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {isoDateFormatter(new Date(data.startDate))}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            최종 종료 날짜
                          </TableCell>
                          <TableCell component="th" scope="row">
                            없음
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          담당자
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {data.pic?.department}{" "}
                          {data.pic?.department !== data.pic?.team
                            ? data.pic?.team
                            : null}{" "}
                          {data.pic?.name}{" "}
                          {data.pic?.isResignation && (
                            <Tooltip
                              title={
                                <Fragment>
                                  <TooltipText>
                                    퇴사자로 인해 담당자 변경이 필요합니다.
                                  </TooltipText>
                                </Fragment>
                              }
                            >
                              <IconButton sx={{ cursor: "default !important" }}>
                                <ReportProblemIcon
                                  sx={{ color: REDTITLE, cursor: "default" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                      {data?.deputyPic?.name && (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              부담당자
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {data.deputyPic.department}{" "}
                              {data.deputyPic.department !== data.deputyPic.team
                                ? data.deputyPic.team
                                : null}{" "}
                              {data.deputyPic.name}{" "}
                              {data.deputyPic.isResignation && (
                                <Tooltip
                                  title={
                                    <Fragment>
                                      <TooltipText>
                                        퇴사자로 인해 부담당자 변경이
                                        필요합니다.
                                      </TooltipText>
                                    </Fragment>
                                  }
                                >
                                  <IconButton
                                    sx={{ cursor: "default !important" }}
                                  >
                                    <ReportProblemIcon
                                      sx={{
                                        color: REDTITLE,
                                        cursor: "default",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          관리자
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {data.manager?.department}{" "}
                          {data.manager?.department !== data.manager?.team
                            ? data.manager?.team
                            : null}{" "}
                          {data.manager?.name}
                          {data.manager?.isResignation && (
                            <Tooltip
                              title={
                                <Fragment>
                                  <TooltipText>
                                    퇴사자로 인해 관리자 변경이 필요합니다.
                                  </TooltipText>
                                </Fragment>
                              }
                            >
                              <IconButton sx={{ cursor: "default !important" }}>
                                <ReportProblemIcon
                                  sx={{
                                    color: REDTITLE,
                                    cursor: "default",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                      {data?.deputyManager?.name && (
                        <>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              부관리자
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {data.deputyManager.department}{" "}
                              {data.deputyManager.department !==
                              data.deputyManager.team
                                ? data.deputyManager.team
                                : null}{" "}
                              {data.deputyManager.name}
                              {data.deputyManager.isResignation && (
                                <Tooltip
                                  title={
                                    <Fragment>
                                      <TooltipText>
                                        퇴사자로 인해 부관리자 변경이
                                        필요합니다.
                                      </TooltipText>
                                    </Fragment>
                                  }
                                >
                                  <IconButton
                                    sx={{ cursor: "default !important" }}
                                  >
                                    <ReportProblemIcon
                                      sx={{
                                        color: REDTITLE,
                                        cursor: "default",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          설명
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {data.content}
                        </TableCell>
                      </TableRow>
                      {problemData.length > 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            확인사항
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {problemData.map((item) => {
                              return (
                                <ReminderDetailWarning>
                                  <ReportProblemIcon
                                    sx={{
                                      color: ORANGECONTENTS,
                                      cursor: "default",
                                      marginRight: "10px",
                                    }}
                                  />

                                  {item.message}
                                </ReminderDetailWarning>
                              );
                            })}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </RemindermodalContentMaxWidth>
            </RemindermodalContenstBox2>
          </MaxWidth>
        </Grid>
      </Grid>
    </>
  );
}

export default ReminderDetail;
