import PacmanLoader from "react-spinners/PacmanLoader";
import { AxiosLoading, AxiosLoadingIndicator } from "../../styles/theme";
import { Box, CircularProgress } from "@mui/material";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "rgba(255, 255, 255, 0.7)",
  borderWidth: "10px",
};

const Loading = (props: any) => {
  const check = props.isLoading == null ? false : props.isLoading;

  return (
    check && (
      <AxiosLoading
        style={{ display: props.loading === true ? "block" : "none" }}
      >
        <AxiosLoadingIndicator>
          <>
            {" "}
            <Box sx={{ display: "flex", color: "black", fontSize: "100px" }}>
              <CircularProgress />
            </Box>
            <PacmanLoader
              color={"green"}
              loading={props.loading}
              cssOverride={override}
              size={40}
            />
          </>
        </AxiosLoadingIndicator>
      </AxiosLoading>
    )
  );
};

export default Loading;
