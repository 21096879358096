import { Grid, Tab, Tabs, styled } from "@mui/material";
import { useState } from "react";
import MyAccommodation from "./MyAccommodation";
import MyCarRent from "./MyCarRent";
import { MainTitle, MaxWidth } from "../../styles/theme";
import { useRecoilState, useRecoilValue } from "recoil";
import { BranchEmployeeCheck, MypageTabValue } from "../../system/atoms";
import { VehicleMyBoxPointer } from "../../styles/vehicleStyle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GRAYPALETITLE } from "../../styles/color";

function MyPageMain() {
  const [value, setValue] = useRecoilState(MypageTabValue);
  const branchEmployeeCheck = useRecoilValue(BranchEmployeeCheck);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [reservationYear, setReservationYear] = useState(
    new Date().getFullYear()
  );
  const [reservationMonth, setReservationMonth] = useState(
    new Date().getMonth() + 1
  );
  const changeDate = (type: string) => {
    if (type === "up") {
      const newReservationMonth = reservationMonth + 1;
      if (newReservationMonth === 13) {
        setReservationYear(reservationYear + 1);
        setReservationMonth(1);
      } else {
        setReservationMonth(newReservationMonth);
      }
    } else {
      const newReservationMonth = reservationMonth - 1;
      if (newReservationMonth === 0) {
        setReservationMonth(12);
        setReservationYear(reservationYear - 1);
      } else {
        setReservationMonth(newReservationMonth);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MaxWidth>
          <MainTitle>나의 예약</MainTitle>
        </MaxWidth>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center">
          <VehicleMyBoxPointer onClick={() => changeDate("down")} item pl="7px">
            <ArrowBackIosIcon />
          </VehicleMyBoxPointer>
          <YearMonthBox item padding="0px 20px">
            {reservationYear}년 {reservationMonth}월
          </YearMonthBox>
          <VehicleMyBoxPointer onClick={() => changeDate("up")} item pl="5px">
            <ArrowForwardIosIcon />
          </VehicleMyBoxPointer>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaxWidth>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="휴양소" value={0} />
            {!branchEmployeeCheck && <Tab label="차량" value={1} />}
            {/* <Tab label="회의실" value={2} disabled />
            <Tab label="도서" value={2} disabled /> */}
          </Tabs>
          {value === 0 ? (
            <MyAccommodation
              reservationMonth={reservationMonth}
              reservationYear={reservationYear}
            />
          ) : null}
          {value === 1 && (
            <MyCarRent
              reservationMonth={reservationMonth}
              reservationYear={reservationYear}
            />
          )}
          {/* {value === 2 && <MyMeetingRoom />}
          {value === 3 && <MyCheckOut />} */}
        </MaxWidth>
      </Grid>
    </Grid>
  );
}

export default MyPageMain;

const YearMonthBox = styled(Grid)(() => ({
  color: GRAYPALETITLE,
  fontSize: "20px",
  fontWeight: 550,
}));
