import styled from "@emotion/styled";
import { NoticeData } from "../../../system/types/Notice";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  BLUEBACKGROUND,
  GRAYPALEBACKGROUND1,
  GRAYPALETITLE,
} from "../../../styles/color";
import { VehicleNoticeIcon } from "../../../styles/vehicleStyle";
import { StyledEmptyBody } from "../../../styles/theme";
import { Fragment } from "react";
import uuid from "react-uuid";
import { VehiclePc } from "../../../pages/Layout/ResponsiveScreen";

interface VehicleNoticePcTableProps {
  noticeData: NoticeData[];
  setSelectedNotice: (selectedData: NoticeData) => void;
  setNoticeState: (noticeState: string) => void;
}

function VehicleNoticePcTable({
  noticeData,
  setSelectedNotice,
  setNoticeState,
}: VehicleNoticePcTableProps) {
  const todayDate = new Date();
  const onChangeSelectedData = (dt: NoticeData) => {
    setSelectedNotice(dt);
    setNoticeState("detail");
  };
  return (
    <NoticeContentBox item xs={12}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHead2 align="center"></StyledHead2>
              <StyledHead3 align="center">제목</StyledHead3>
              <VehiclePc>
                <StyledHead1 align="center">작성자</StyledHead1>
                <StyledHead1 align="center">작성일</StyledHead1>
              </VehiclePc>
            </TableRow>
          </TableHead>
          <TableBody>
            {noticeData.map((item: NoticeData, idx) => {
              return (
                <Fragment key={uuid()}>
                  {item.mainStartDate &&
                  new Date(item.mainStartDate) < todayDate &&
                  (item.mainEndDate === null ||
                    new Date(item.mainEndDate) > todayDate) ? (
                    <MainStyledTableRow
                      key={idx}
                      onClick={() => onChangeSelectedData(item)}
                    >
                      <StyledBody align="center">
                        <VehicleNoticeIcon
                          src="/images/VEHICLE/mainSpeaker.png"
                          alt="주요 공지사항"
                        />
                      </StyledBody>
                      <StyledBody>{item.title}</StyledBody>
                      <VehiclePc>
                        <StyledBody align="center">{item.createBy}</StyledBody>
                        <StyledBody align="center">
                          {item.createdAt && item.createdAt?.substring(0, 10)}
                        </StyledBody>
                      </VehiclePc>
                    </MainStyledTableRow>
                  ) : (
                    <StyledTableRow
                      key={idx}
                      onClick={() => onChangeSelectedData(item)}
                    >
                      <StyledBody align="center"></StyledBody>
                      <StyledBody>{item.title}</StyledBody>
                      <VehiclePc>
                        <StyledBody align="center">{item.createBy}</StyledBody>
                        <StyledBody align="center">
                          {item.createdAt && item.createdAt?.substring(0, 10)}
                        </StyledBody>
                      </VehiclePc>
                    </StyledTableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {noticeData.length === 0 && (
        <Grid container>
          <StyledEmptyBody>조회된 데이터가 없습니다.</StyledEmptyBody>
        </Grid>
      )}
    </NoticeContentBox>
  );
}

export default VehicleNoticePcTable;

const NoticeContentBox = styled(Grid)({
  height: "530px",
});

const StyledHead1 = styled(TableCell)({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#F0F2F5",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  width: "150px",
  padding: "0.6rem",
  whiteSpace: "nowrap" as const,
  fontSize: "1rem",
  color: GRAYPALETITLE,
  fontWeight: "bold",
  height: "2rem",
});

const StyledHead2 = styled(TableCell)({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#F0F2F5",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  width: "50px",
  padding: "0.6rem",
  whiteSpace: "nowrap" as const,
  fontSize: "1rem",
  color: GRAYPALETITLE,
  fontWeight: "bold",
  height: "2rem",
});

const StyledHead3 = styled(TableCell)({
  fontFamily: "Noto sans CJK KR",
  backgroundColor: "#F0F2F5",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  width: "550px",
  padding: "0.6rem",
  whiteSpace: "nowrap" as const,
  fontSize: "1rem",
  color: GRAYPALETITLE,
  fontWeight: "bold",
  height: "2rem",
});

const StyledBody = styled(TableCell)({
  fontFamily: "Noto sans CJK KR",
  borderTop: "1px solid #DCDFE3",
  borderBottom: "1px solid #DCDFE3",
  padding: "0.7rem",
  fontSize: "1rem",
  height: "2rem",
});

const StyledTableRow = styled(TableRow)({
  cursor: "pointer",
  "&:hover": { backgroundColor: BLUEBACKGROUND },
});

const MainStyledTableRow = styled(TableRow)({
  cursor: "pointer",
  backgroundColor: GRAYPALEBACKGROUND1,
  "&:hover": { backgroundColor: BLUEBACKGROUND },
});
