import {
  Bridge,
  Buildings,
  Garage,
  House,
  HouseLine,
  Lighthouse,
  Mountains,
  Park,
  SwimmingPool,
  Tent,
  TextAUnderline,
  Tipi,
  Warehouse,
} from "@phosphor-icons/react";

interface AccommodationIconProps {
  name: string;
}

function AccommodationIconList({ name }: AccommodationIconProps) {
  return (
    <>
      {name === "전체" ? <TextAUnderline size={40} weight="light" /> : null}
      {name === "building" ? <Buildings size={40} weight="light" /> : null}
      {name === "tent" ? <Tent size={40} weight="light" /> : null}
      {name === "bridge" ? <Bridge size={40} weight="light" /> : null}
      {name === "warehouse" ? <Warehouse size={40} weight="light" /> : null}
      {name === "house" ? <House size={40} weight="light" /> : null}
      {name === "houseline" ? <HouseLine size={40} weight="light" /> : null}
      {name === "garage" ? <Garage size={40} weight="light" /> : null}
      {name === "lighthouse" ? <Lighthouse size={40} weight="light" /> : null}
      {name === "park" ? <Park size={40} weight="light" /> : null}
      {name === "mountains" ? <Mountains size={40} weight="light" /> : null}
      {name === "swimmingpool" ? (
        <SwimmingPool size={40} weight="light" />
      ) : null}
      {name === "tipi" ? <Tipi size={40} weight="light" /> : null}
    </>
  );
}

export default AccommodationIconList;
