import { Grid } from "@mui/material";
import AdminTitle from "./AdminTitle";

function AdminQnaTable() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AdminTitle titleName="문의 관리" />
      </Grid>
    </Grid>
  );
}

export default AdminQnaTable;
