import { useEffect, useState } from "react";
import { MapLayout } from "../../styles/theme";

declare global {
  interface Window {
    kakao: any;
  }
}

interface MapContainerProps {
  latitude: string;
  longitude: string;
}

const MapContainer = ({ latitude, longitude }: MapContainerProps) => {
  const [error, setError] = useState<string | null>(null);
  const [map, setMap] = useState<any>(null);

  useEffect(() => {
    if (window.kakao && window.kakao.maps) {
      let container = document.getElementById("map");
      let options = {
        center: new window.kakao.maps.LatLng(
          parseFloat(latitude),
          parseFloat(longitude)
        ),
        level: 3,
      };

      try {
        setError("");
        if (map) {
          // 기존 지도가 있다면 중심 좌표를 변경
          map.setCenter(options.center);
        } else {
          // 기존 지도가 없으면 새로 생성
          const newMap = new window.kakao.maps.Map(container, options);
          setMap(newMap);
        }
      } catch (error) {
        setError("error creating the map");
      }
    } else {
      setError("kakao maps library not loaded.");
    }
  }, [latitude, longitude, map]);

  return <> {error ? <></> : <MapLayout id="map" />}</>;
};

export default MapContainer;
