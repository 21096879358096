import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./azure/authConfig";
import { registerLicense } from "@syncfusion/ej2-base";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
registerLicense(
  "ORg4AjUWIQA/Gnt2VFhiQllPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtQd0ViXX5ddXBRRGc="
);

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById("root");

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
  container
);

<script src="https://unpkg.com/react-router-dom/umd/react-router-dom.min.js"></script>;
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
