import { styled } from "@mui/material/styles";
import { AppBar, MenuItem, Menu, Grid } from "@mui/material";
import { GRAYPALEBACKGROUND1 } from "./color";

// header
export const HeaderStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));

export const ImgAlign = styled("img")(({ theme }) => ({
  marginRight: "3.5rem",
}));

export const Title = styled("div")(() => ({
  margin: "0rem 0.5rem",
  color: "#4B4F5A",
  width: "9rem",
  textAlign: "center",
  fontSize: "20px",
}));

export const DisabledTitle = styled("div")(() => ({
  margin: "0rem 0.5rem",
  color: "#B2B4B8",
  width: "9rem",
  textAlign: "center",
  fontSize: "20px",
}));

export const NavProfile = styled("div")(() => ({
  marginLeft: "auto",
}));

export const HeaderBarStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
}));

export const FooterStyle = styled("div")(({ theme }) => ({
  height: "3rem",
  width: "100%",

  position: "absolute",
  marginBottom: theme.spacing(1),
  textAlign: "center",
  zIndex: -1,
}));

export const BodyWrap = styled("div")(({ theme }) => ({
  minHeight: "100%",
  position: "relative",
  marginTop: "5rem",
}));

export const HeaderMenubox = styled(Menu)(({ theme }) => ({
  boxShadow: "0",
}));

export const DisabledMenuItem = styled(MenuItem)(({ theme }) => ({
  cursor: "default",
}));

export const HeaderMyMenu = styled("div")(({ theme }) => ({
  marginRight: "0.8rem",
  cursor: "pointer",
}));

// OPEN HEADER
export const OpenHeaderBox = styled("div")(({ theme }) => ({
  width: "100%",
  position: "fixed",
  backgroundColor: "white",
  marginTop: "-1rem",
  paddingLeft: "11.2rem",
  paddingBottom: "1rem",
  display: "flex",
  borderBottom: "1px solid #DCDFE3",
  zIndex: 20,
  justifyContent: "space-between",
}));

export const OpenHeaderText = styled("div")(() => ({
  color: "#4B4F5A",
  width: "9rem",
  margin: "0.5rem",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": { backgroundColor: GRAYPALEBACKGROUND1 },
}));

export const MyOpenHeaderText = styled("div")(() => ({
  color: "#4B4F5A",
  width: "9rem",
  margin: "0.5rem",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": { backgroundColor: GRAYPALEBACKGROUND1 },
}));

export const OpenDisabledHeaderText = styled("div")(() => ({
  color: "#B2B4B8",
  width: "9rem",
  margin: "0.5rem",
  textAlign: "center",
}));

// drawer
export const DrawerTitle = styled(Grid)(({ theme }) => ({
  color: "#4B4F5A",
  fontWeight: "bold",
  padding: "1rem",
}));

export const DisabledDrawerTitle = styled(Grid)(({ theme }) => ({
  color: "#B2B4B8",
  fontWeight: "bold",
  padding: "1rem",
}));

export const DrawerContent = styled(Grid)(({ theme }) => ({
  color: "#81848B",
  padding: "0.5rem 2rem",
  cursor: "pointer",
}));
