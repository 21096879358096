import VehicleCalendar from "../../components/Vehicle/VehicleCalendar";

function AvailableVehicleCalendar() {
  return (
    <>
      <VehicleCalendar />
    </>
  );
}

export default AvailableVehicleCalendar;
