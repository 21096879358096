import client from "../ApiService";
import HttpClient from "../http-client";
import { Amenity } from "../types/Amenity";

const baseUri: string = "/amenity";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _AmenityApi extends _BasicApi {
  getAmenityAll() {
    return this.client.get(`${baseUri}/all`);
  }
  createAmenity(amenity: Amenity) {
    return this.client.post(`${baseUri}`, amenity);
  }

  deleteAmenity(id: number) {
    return this.client.delete(`${baseUri}`, { params: { id: id } });
  }
}

export const AmenityApi = new _AmenityApi(client);
