import { Box, Modal } from "@mui/material";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  GrayPaleContentsText,
  ModalStyle,
  StyledBody,
  StyledBodyNoPadding,
  StyledHead1,
  StyledHead3,
  StyledHead4,
  StyledPointerBody,
} from "../../styles/theme";
import { ReminderData } from "../../system/types/Reminder";
import MyAccommodationChip from "../Common/Accommodation/MyAccommodationChip";
import ReminderButton from "../Common/Reminder/ReminderButton";
import { useState } from "react";

import { InitReminderData } from "../../system/types/initObject";
import ReminderDetail from "./ReminderDetail";
interface ReminderTableProps {
  data: ReminderData[];
  refreshData?: () => void;
}
function ReminderTable({ data, refreshData }: ReminderTableProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [detailedData, setDetailedData] =
    useState<ReminderData>(InitReminderData);
  const movetoModal = (detailedData: ReminderData, type: string) => {
    handleOpen();

    setDetailedData(detailedData);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHead1 align="center">정기업무 착수일</StyledHead1>
            <StyledHead3 align="center">업무명</StyledHead3>
            <StyledHead1 align="center">관련 부서</StyledHead1>
            <StyledHead1 align="center">담당자 / 관리자</StyledHead1>
            <StyledHead1 align="center">알림기능 상태</StyledHead1>
            <StyledHead4 align="center"></StyledHead4>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, idx) => {
            return (
              <TableRow key={idx}>
                <StyledBodyNoPadding align="center">
                  <div>
                    {item.status === "사용중" ? (
                      <>
                        {" "}
                        {item.dday === 0 ? <>D - DAY</> : <> D - {item.dday}</>}
                      </>
                    ) : (
                      "-"
                    )}{" "}
                  </div>
                  <GrayPaleContentsText>
                    {item.nextEventDate}
                  </GrayPaleContentsText>
                </StyledBodyNoPadding>

                <StyledPointerBody
                  align="center"
                  onClick={() => movetoModal(item, "detail")}
                >
                  {item.title}
                </StyledPointerBody>
                <StyledBody align="center">{item?.pic?.department}</StyledBody>
                <StyledBody align="center">
                  {item?.pic?.name} / {item?.manager?.name}
                </StyledBody>
                <StyledBody align="center">
                  {item.status !== undefined ? (
                    <MyAccommodationChip status={item.status} />
                  ) : null}
                </StyledBody>

                <StyledBody align="right">
                  <ReminderButton data={item} refreshData={refreshData} />
                </StyledBody>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <ReminderDetail data={detailedData} handleClose={handleClose} />
        </Box>
      </Modal>
    </TableContainer>
  );
}

export default ReminderTable;
