// header

import {
  QnaRoutes,
  AccommodationRoutes,
  AdminRoutes,
  VehicleRoutes,
} from "./types/type";

export const RESERVATION_TYPE_BRANCH = [
  {
    text: "휴양소",
    routes: `${AccommodationRoutes.root}`,
    child: [
      { text: "예약 가이드", routes: `${QnaRoutes.root}` },
      { text: "휴양소 예약", routes: `${AccommodationRoutes.root}` },
    ],
  },
];

export const RESERVATION_TYPE = [
  {
    text: "휴양소",
    routes: `${AccommodationRoutes.root}`,
    child: [
      { text: "예약 가이드", routes: `${QnaRoutes.root}` },
      { text: "휴양소 예약", routes: `${AccommodationRoutes.root}` },
    ],
  },
  {
    text: "차량",
    routes: `${VehicleRoutes.root}`,
    child: [
      { text: "차량 예약", routes: `${VehicleRoutes.root}` },
      { text: "이용 가능한 차량", routes: `${VehicleRoutes.calendar}` },
    ],
  },
  // { text: "회의실", status: false, routes: "", child: [] },
  // { text: "도서", status: false, routes: "", child: [] },
  // {
  //   text: "리마인더",
  //   status: false,
  //   routes: `${ReminderRoutes.root}`,
  //   child: [{ text: "생성 및 조회", routes: `${ReminderRoutes.root}` }],
  // },
  // {
  //   text: "문의",
  //   status: false,
  //   routes: `${QnaRoutes.root}`,
  //   child: [
  //     { text: "자주 묻는 질문", routes: `${QnaRoutes.root}` },
  //     { text: "문의하기", routes: `${QnaRoutes.board}` },
  //   ],
  // },
];

export const ADMIN_TYPE = [
  {
    text: "지사 직원 관리",
    routes: `${AdminRoutes.employee}`,
  },
  { text: "휴양소 관리", routes: `${AdminRoutes.accommodation}` },
  { text: "차량 관리", routes: `${AdminRoutes.vehicle}` },
  // { text: "문의 관리", routes: `${AdminRoutes.qna}` },
];

export const DATERANGETYPE = ["직접 설정", "분기별", "반기별", "12개월"];

export const QUARTERLYTYPE = [1, 2, 3, 4];

export const SEMI_ANNUALLYTYPE = ["상반기", "하반기"];

export const ColorPalette = [
  { id: 1, color: "#f44336" },
  { id: 2, color: "#e91e63" },
  { id: 3, color: "#9c27b0" },
  { id: 4, color: "#673ab7" },
  { id: 5, color: "#3f51b5" },
  { id: 6, color: "#2196f3" },
  { id: 7, color: "#03a9f4" },
  { id: 8, color: "#00bcd4" },
  { id: 9, color: "#009688" },
  { id: 10, color: "#4caf50" },
  { id: 11, color: "#8bc34a" },
  { id: 12, color: "#cddc39" },
  { id: 13, color: "#ffc107" },
  { id: 14, color: "#ff9800" },
  { id: 15, color: "#ff5722" },
  { id: 16, color: "#795548" },
  { id: 17, color: "#9e9e9e" },
  { id: 18, color: "#607d8b" },
];

export interface AccommoStatus {
  id: number;
  title: string;
  contents: string[];
  name: string;
}

export const InitAccommodationStatus: AccommoStatus = {
  id: 0,
  title: "",
  contents: [],
  name: "",
};

export interface CheckInListItemStatus {
  id: number;
  checklistItem: string;
}

export const CHECKINLIST = [
  {
    id: 1,
    location: "고성",
    checklist: [
      {
        id: 1,
        checklistItem:
          "콘도 식으로 현관문 옆 카드 꽂는 곳에 스위치 카드키가 꽂혀있습니다. 확인하셨나요?",
      },
      {
        id: 2,
        checklistItem:
          "입실 시 보일러가 외출모드로 되어있었는지 확인 후 원하는 설정으로 변경해서 사용하셔야 합니다. 확인하셨나요? (외출모드로 되어있지 않은 경우 다음 페이지에 나오는 휴양소 상태 설문에 작성부탁드립니다.)",
      },
      {
        id: 3,
        checklistItem:
          "입실하셨다면 창문을 열어 환기 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 4,
        checklistItem:
          "습도가 높다면 난방을 틀어 습기를 제거해주세요. 확인하셨나요?",
      },
      {
        id: 5,
        checklistItem: "사용할 이불은 건조부탁드립니다. 확인하셨나요?",
      },
      {
        id: 6,
        checklistItem:
          "고성 휴양소 입실 가이드에 적힌 주의사항은 필수로 확인 부탁드립니다. 확인하셨나요?",
      },
    ],
  },
  {
    id: 2,
    location: "양평",
    checklist: [
      {
        id: 1,
        checklistItem:
          "대문 비밀번호 및 화장실 비밀번호는 전송 받은 문자를 확인하시면 됩니다. 확인하셨나요? (예약 시작일 하루 전 발송)",
      },
      {
        id: 2,
        checklistItem:
          "지하수가 식수로는 부적합하므로 식수는 별도 준비하셔야 합니다. 확인하셨나요?",
      },
      {
        id: 3,
        checklistItem:
          "사용 후 나오는 쓰레기를 담아 갈 일반 쓰레기 봉투 및 관련 쓰레기 봉투를 준비하셔야 합니다. 확인하셨나요?",
      },
      {
        id: 4,
        checklistItem:
          "담장 및 울타리는 없으니 차량 주차를 활용하여 예약한 캠핑 구역을 확보해야 합니다. 확인하셨나요?",
      },
      {
        id: 5,
        checklistItem:
          "전기, 수도 시설을 사용해주시면 되며 문제 시 양평 캠핑장 입실 가이드에 적힌 시설 조치사항을 참조해주세요. 확인하셨나요?",
      },
      {
        id: 6,
        checklistItem: "주변 정리 및 화장실 청소 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 7,
        checklistItem:
          "사용하면서 발생된 쓰레기는 모두 가지고 가셔야합니다. 확인하셨나요?",
      },
      {
        id: 8,
        checklistItem:
          "분전함의 외등, 화장실 내 외부 전등을 포함한 모든 시설은 OFF 후 퇴장하셔야 합니다. 확인하셨나요?",
      },
      {
        id: 9,
        checklistItem:
          "사이트 내부에 사용자가 없을 경우 출입구는 잠가주세요. 확인하셨나요?",
      },
    ],
  },
  {
    id: 3,
    location: "여수",
    checklist: [
      {
        id: 1,
        checklistItem:
          "입실하셨다면 창문을 열어 환기 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 2,
        checklistItem:
          "습도가 높다면 난방을 틀어 습기를 제거해주세요. 확인하셨나요?",
      },
      {
        id: 3,
        checklistItem: "사용할 이불은 건조부탁드립니다. 확인하셨나요?",
      },
    ],
  },
  {
    id: 4,
    location: "태안",
    checklist: [
      {
        id: 1,
        checklistItem:
          "입실하셨다면 창문을 열어 환기 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 2,
        checklistItem:
          "습도가 높다면 난방을 틀어 습기를 제거해주세요. 확인하셨나요?",
      },
      {
        id: 3,
        checklistItem: "사용할 이불은 건조부탁드립니다. 확인하셨나요?",
      },
      {
        id: 4,
        checklistItem:
          "에어컨 가동 시, 실외기실 루버 갤러리창을 반드시 개방해야 합니다. 개방하지 않을 경우 화재위험이나 고장의 원인이 될 수 있습니다. 확인하셨나요?",
      },
    ],
  },
  {
    id: 5,
    location: "부산",
    checklist: [
      {
        id: 1,
        checklistItem:
          "입실하셨다면 창문을 열어 환기 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 2,
        checklistItem: "사용할 이불은 건조부탁드립니다. 확인하셨나요?",
      },
      {
        id: 3,
        checklistItem:
          "부산 휴양소 입실 가이드에 적힌 주의사항은 필수로 확인 부탁드립니다. 확인하셨나요?",
      },
    ],
  },
];

export const CHECKOUTLIST = [
  {
    id: 1,
    location: "고성",
    checklist: [
      {
        id: 1,
        checklistItem: "사용한 침구류는 세탁, 건조 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 2,
        checklistItem:
          "모든 실내등, 에어컨, 냉장고, 세탁기, 밥솥 등은 꺼주세요. 확인하셨나요?",
      },
      {
        id: 3,
        checklistItem: '보일러는 "외출" 설정 부탁드립니다. 확인하셨나요?',
      },
      {
        id: 4,
        checklistItem:
          "종량제 봉투에 담긴 일반 쓰레기와 재활용 쓰레기는 아파트 쓰레기장에 폐기 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 5,
        checklistItem: "중문은 잠금 금지입니다. 확인하셨나요?",
      },
      {
        id: 6,
        checklistItem:
          "스위치 카드키는 꽂혀있는 그대로 퇴실하시면 됩니다. 확인하셨나요?",
      },

      {
        id: 7,
        checklistItem:
          "6월 ~ 8월을 제외한 나머지 모든 기간에는 보일러실 에어컨 실외기 옆 스트리폼 설치를 확인하셔야 합니다. 확인하셨나요?",
      },
    ],
  },
  {
    id: 2,
    location: "양평",
    checklist: [
      {
        id: 1,
        checklistItem: "분전함의 외등을 끄셔야 합니다. 확인하셨나요?",
      },
      {
        id: 2,
        checklistItem: "화장실 내/외부 전등을 끄셔야 합니다. 확인하셨나요?",
      },
      {
        id: 3,
        checklistItem:
          "주변 정리 및 화장실 청소 부탁드립니다. 해당 부분은 사진 촬영하여 다음 화면에 나오는 사용 후 보고에 사진을 업로드해주세요. 확인하셨나요?",
      },
      {
        id: 4,
        checklistItem:
          "캠핑장 내부 사용자가 없다면 출입구를 잠가주세요. 확인하셨나요?",
      },
    ],
  },
  {
    id: 3,
    location: "여수",
    checklist: [
      {
        id: 1,
        checklistItem: "대걸레를 이용하여 반드시 물청소해주세요. 확인하셨나요?",
      },
      {
        id: 2,
        checklistItem: "사용한 침구류는 세탁, 건조 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 3,
        checklistItem: "베란다 창문은 반드시 닫아주세요. 확인하셨나요?",
      },
      {
        id: 4,
        checklistItem:
          "방, 화장실, 신발장, 부엌장 문은 환기를 위해 열어두세요. 확인하셨나요?",
      },
      {
        id: 5,
        checklistItem: "가스레인지와 벨브는 잠가주세요. 확인하셨나요?.",
      },
      {
        id: 6,
        checklistItem:
          "모든 실내등, 에어컨, 냉장고, 세탁기, 밥솥 등은 꺼주세요. 확인하셨나요?",
      },
      {
        id: 7,
        checklistItem:
          '보일러는 거실, 안방, 작은방 모두의 컨트롤러에서 "외출" 설정하셔야합니다. 확인하셨나요?',
      },
      {
        id: 8,
        checklistItem: "냉장고문, 세탁기문은 열어두세요. 확인하셨나요?",
      },
      {
        id: 9,
        checklistItem:
          "종량제 봉투에 담긴 일반 쓰레기와 재활용 쓰레기는 아파트 쓰레기장에 폐기해주세요. 확인하셨나요?",
      },
      {
        id: 10,
        checklistItem:
          "총무팀 요청이 있을 때 보일러실 가스 사용량을 체크하여 담당자(이준영 차장)에게 메시지를 전달하셔야 합니다. 계량기 지침은 붉은 부분을 제외하고 앞 4자리 입니다. 확인하셨나요?",
      },
    ],
  },
  {
    id: 4,
    location: "태안",
    checklist: [
      {
        id: 1,
        checklistItem:
          "대걸레를 이용하여 반드시 물걸레 청소를 해주셔야합니다. 확인하셨나요?",
      },
      {
        id: 2,
        checklistItem: "사용한 침구류는 세탁, 건조 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 3,
        checklistItem: "베란다 창문은 반드시 닫아주세요. 확인하셨나요?",
      },
      {
        id: 4,
        checklistItem:
          "방, 화장실, 신발장, 부엌장 문은 환기를 위해 열어두세요. 확인하셨나요?",
      },
      {
        id: 5,
        checklistItem: "가스레인지와 벨브는 잠가주세요. 확인하셨나요?",
      },
      {
        id: 6,
        checklistItem:
          "모든 실내등, 에어컨, 냉장고, 세탁기, 밥솥 등은 꺼주세요. 확인하셨나요?",
      },
      {
        id: 7,
        checklistItem:
          '보일러는 거실, 안방, 작은 방 모두의 컨트롤러에서 "외출" 설정하셔야합니다. 확인하셨나요?',
      },
      {
        id: 8,
        checklistItem: "냉장고문, 세탁기문은 열어두세요. 확인하셨나요?",
      },
      {
        id: 9,
        checklistItem:
          "종량제 봉투에 담긴 일반 쓰레기와 재활용 쓰레기는 아파트 쓰레기장에 폐기해주세요. 확인하셨나요?",
      },
      {
        id: 10,
        checklistItem:
          "총무팀 요청이 있을 때 보일러실 가스 사용량을 체크하여 담당자(이준영 차장)에게 메시지를 전달하셔야 합니다. 계량기 지침은 붉은 부분을 제외하고 앞 4자리 입니다. 확인하셨나요?",
      },
    ],
  },
  {
    id: 5,
    location: "부산",
    checklist: [
      {
        id: 1,
        checklistItem: "사용한 침구류는 세탁, 건조 부탁드립니다. 확인하셨나요?",
      },
      {
        id: 2,
        checklistItem:
          "모든 실내등, 에어컨, 세탁기, 밥솥 등은 꺼주세요. 확인하셨나요?",
      },
      {
        id: 3,
        checklistItem: `보일러는 "외출" 설정 부탁드립니다. 확인하셨나요?`,
      },
      {
        id: 4,
        checklistItem:
          "종량제 봉투에 담긴 일반 쓰레기와 재활용 쓰레기는 아파트 쓰레기장에 폐기 부탁드립니다. 확인하셨나요?",
      },
    ],
  },
];

export const BranchEmployeeWorkplaces = [
  "강남",
  "강원",
  "광주",
  "남부",
  "대구",
  "대전",
  "부경",
  "서부",
  "중부",
  "코르트",
  "인바디핏",
];

export const ReminderClassification = [
  "전사(회사 전체) 일정",
  "소속 파트 일정",
  "소속 팀 일정",
  "내 담당/관리 일정",
];

export const ReminderClassification2 = [
  "소속 파트 일정",
  "소속 팀 일정",
  "내 담당/관리 일정",
];

export const GOSEONGSTATUS = [
  {
    id: 1,
    title: "거실 욕실 - 전체, 변기, 세면대, 욕조, 배수구 [총 5장]",
  },
  {
    id: 2,
    title: "안방 욕실 - 전체, 변기, 세면대,  배수구 [총 4장]",
  },
  {
    id: 3,
    title: "주방 - 전체, 싱크대, 싱크 배수구, 가스레인지 [총 4장]",
  },
  {
    id: 4,
    title: "청소기 - 세척 상태 [총 1장]",
  },
  {
    id: 5,
    title: "세탁 건조기 - 먼지 거름망 비움 [총 1장]",
  },
  {
    id: 6,
    title: "이불장 - 정리 및 건조 상태 [총 1장]",
  },
  {
    id: 7,
    title:
      "보일러실 - 실외기, 응축수 양동이 비움 사진(204호만 해당) [총 1장 이상]",
  },
  {
    id: 8,
    title: "보일러 - 외출 설정 [총 1장]",
  },
  {
    id: 9,
    title: "현관 - 입구 [총 1장]",
  },
  {
    id: 10,
    title: "기타 - 전체 사진 및 참조 사진 [있을 경우 첨부]",
  },
];

export const TAEANSTATUS = [
  {
    id: 1,
    title: "거실 욕실 - 전체, 변기, 세면대, 욕조, 배수구 [총 5장]",
  },
  {
    id: 2,
    title: "안방 욕실 - 전체, 변기, 세면대, 배수구 [총 4장]",
  },
  {
    id: 3,
    title:
      "주방 - 전체, 싱크대, 싱크 배수구, 가스레인지 주변, 밸브 잠금, 냉장고 비움 [총 6장]",
  },
  {
    id: 4,
    title: "청소기 - 세척 상태 [총 1장]",
  },
  {
    id: 5,
    title: "세탁 건조기 - 먼지 거름망 비움 [총 1장]",
  },
  {
    id: 6,
    title: "이불장 - 정리 및 건조 상태 [총 1장]",
  },
  {
    id: 7,
    title:
      "각방 보일러 - 컨트롤러에서 외출 설정(거실, 안방, 작은방 전체의 보일러 외출 설정) [총 1장]",
  },
  {
    id: 8,
    title: "현관 - 입구 [총 1장]",
  },
  {
    id: 9,
    title: "기타 - 전체 사진 및 참조 사진 [있을 경우 첨부]",
  },
];

export const YANGPYEONGSTATUS = [
  {
    id: 1,
    title: "화장실 - 바닥청소 상태, 배수구, 세면대, 변기(뚜껑 열고) [총 4장]",
  },
  {
    id: 2,
    title: "수돗가 - 정리 상태 [총 1장]",
  },
  {
    id: 3,
    title: "사이트 - 주변 정리 상태 [총 1장]",
  },
  {
    id: 4,
    title: "분전함 - 외등 스위치 [총 1장]",
  },
  {
    id: 5,
    title: "기타 - 쓰레기 수거 사진, 참고 사진 [있을 경우 첨부]",
  },
];

export const YEOSUSTATUS = [
  {
    id: 1,
    title: "거실 욕실 - 전체, 변기, 세면대, 욕조, 배수구 [총 5장]",
  },
  {
    id: 2,
    title: "안방 욕실 - 전체, 변기, 세면대, 배수구 [총 4장]",
  },
  {
    id: 3,
    title:
      "주방 - 전체, 싱크대, 싱크대 배수구, 가스레인지 주변 및 밸브 잠금 상태, 냉장고 [총 6장]",
  },
  {
    id: 4,
    title: "청소기 - 세척 상태 [총 1장]",
  },
  {
    id: 5,
    title: "세탁 건조기 - 먼지 거름망 [총 1장]",
  },
  {
    id: 6,
    title: "이불장 - 정리 및 건조 상태 [총 1장]",
  },
  {
    id: 7,
    title: "각방 보일러 컨트롤러 - 외출 설정 [총 1장]",
  },
  {
    id: 8,
    title: "현관 - 입구 [총 1장]",
  },
  {
    id: 9,
    title: "기타 - 전체 사진 및 참조 사진 [있을 경우 첨부]",
  },
];

export const BUSANSTATUS = [
  {
    id: 1,
    title: "거실 욕실 - 전체, 배수구 [총 2장]",
  },
  {
    id: 2,
    title: "안방 욕실 - 전체, 배수구 [총 2장]",
  },
  {
    id: 3,
    title:
      "주방 - 전체, 싱크대 배수구, 가스레인지 주변 및 밸브 잠금 상태, 냉장고 [총 5장]",
  },
  {
    id: 4,
    title: "보일러 스위치 - 외출 설정 [총 1장]",
  },
  {
    id: 8,
    title: "각방 - 큰방, 작은방1, 작은방2 [총 3장]",
  },
  {
    id: 9,
    title: "거실 - 전체 사진 [총 1장 이상]",
  },
];

export const VEHICLEUSAGETYPE = ["임원용", "업무용"];

export const VEHICLEFUELTYPE = [
  "휘발유",
  "경유",
  "전기",
  "하이브리드",
  "액화석유가스",
  "천연가스",
  "수소",
  "바이오",
];

export const VEHICLEUNITTYPE = ["km", "시간"];

export const VEHICLESIZETYPE = ["경차", "소형차", "중형차", "대형차", "기타"];

export const VEHICLESHAPETYPE = [
  "경차",
  "세단",
  "SUV",
  "해치백",
  "벤",
  "화물차",
  "굴식기",
];
