import { useEffect, useState } from "react";
import { BookmarkApi } from "../../../system/api/BookmarkApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import { Grid, styled } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { CustomButtonGray, CustomButtonGreen } from "../../../styles/button";
import { InitResourceBookmarkInfo } from "../../../system/types/initObject";
import { CustomText } from "../../../styles/CustomText";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../styles/color";
import { ResourceBookmarkInfo } from "../../../system/types/Bookmark";
import { VehicleData } from "../../../system/types/Reservation";
import { VehicleApi } from "../../../system/api/VehicleApi";
import uuid from "react-uuid";

function validation(data: ResourceBookmarkInfo) {
  if (data.alias === "") {
    AlertModal("check", "차량명을 입력해 주세요.");
    return false;
  } else if (data.resource.id === 0) {
    AlertModal("check", "차량을 선택해주세요.");
    return false;
  }
  return true;
}

function VehicleBookmark() {
  const [data, setData] = useState<ResourceBookmarkInfo[]>([]);
  const [bookmarkState, setBookmarkState] = useState("table");
  const [bookmarkData, setBookmarkData] = useState<ResourceBookmarkInfo>(
    InitResourceBookmarkInfo
  );
  const [vehicleAllData, setVehicleAllData] = useState<VehicleData[]>([]);
  const getBookmarkData = () => {
    BookmarkApi.getBookmark("차량")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    if (bookmarkState === "table") {
      getBookmarkData();
    }
    if (bookmarkState === "edit") {
      VehicleApi.getVehicle(0, 9999999)
        .then((res) => {
          setVehicleAllData(res.data.vehicles);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [bookmarkState]);

  const onChangeState = (state: string) => {
    setBookmarkState(state);
  };
  function onChange(args: any) {
    setBookmarkData({
      ...bookmarkData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  }

  const onDeleteBookmark = (id: number | null) => {
    Swal.fire({
      title: "정말 삭제하시겠습니까?",
      text: "선택하신 차량이 삭제됩니다.",
      icon: "question",
      confirmButtonText: "예",
      cancelButtonText: "아니오",
      showCancelButton: true,
      confirmButtonColor: GREENCONTENTS,
      cancelButtonColor: REDTITLE,
    }).then((result) => {
      if (result.isConfirmed && id) {
        BookmarkApi.deleteBookmark(id)
          .then(() => {
            AlertModal("success", "선택하신 차량이 삭제되었습니다.");
            getBookmarkData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    });
  };

  const onChangeEdit = (dt: ResourceBookmarkInfo) => {
    setBookmarkData(dt);
    setBookmarkState("edit");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookmarkData({
      ...bookmarkData,
      resource: {
        ...bookmarkData.resource,
        id: Number((event.target as HTMLInputElement).value),
      },
    });
  };
  var submitcheck = false;
  const onSave = () => {
    if (submitcheck === false && validation(bookmarkData)) {
      submitcheck = true;
      if (bookmarkData.id) {
        BookmarkApi.updateBookmark({ ...bookmarkData, bookmarkType: "차량" })
          .then(() => {
            AlertModal("success", "자주 사용하는 차량이 수정되었습니다.");
            setBookmarkState("table");
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = false;
          });
      } else {
        BookmarkApi.createBookmark({ ...bookmarkData, bookmarkType: "차량" })
          .then(() => {
            AlertModal("success", "자주 사용하는 차량이 추가되었습니다.");
            setBookmarkState("table");
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = false;
          });
      }
    }
  };

  return (
    <>
      {bookmarkState === "table" && (
        <Grid container item xs={12}>
          <Grid container justifyContent="right">
            <CustomButtonGreen
              onClick={() => {
                onChangeState("edit");
                setBookmarkData(InitResourceBookmarkInfo);
              }}
            >
              차량 추가
            </CustomButtonGreen>
          </Grid>
          <Grid container>
            {data.length === 0 && (
              <Grid container justifyContent="center" mt="50px">
                등록된 자주 사용하는 차량이 없습니다.
              </Grid>
            )}
            {data.map((item: ResourceBookmarkInfo, idx) => {
              return (
                <BookmarkBox
                  container
                  key={idx}
                  item
                  xs={12}
                  alignItems="center"
                >
                  <Grid container item xs={10}>
                    <Grid item xs={12}>
                      {item.alias}
                    </Grid>
                    <Grid item xs={12}>
                      {item.resource.name}
                    </Grid>
                  </Grid>
                  <Grid container item xs={2} justifyContent="right">
                    <Tooltip title="수정">
                      <IconButton
                        onClick={() => {
                          onChangeEdit(item);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="삭제">
                      <IconButton
                        onClick={() => {
                          onDeleteBookmark(item.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </BookmarkBox>
              );
            })}
          </Grid>
        </Grid>
      )}
      {bookmarkState === "edit" && (
        <Grid container>
          <Grid item xs={12}>
            <CustomText type="subtitle">차량명</CustomText>
          </Grid>
          <Grid item xs={12} mt="10px">
            <InputBox
              type="text"
              name="alias"
              onChange={onChange}
              value={bookmarkData.alias || ""}
              placeholder="차량명을 입력해주세요."
            />
          </Grid>
          <Grid item xs={12} mt="20px">
            <CustomText type="subtitle">차량 선택</CustomText>
          </Grid>
          <VehicleSelectBox mt="10px" item xs={12}>
            <FormControl>
              <RadioGroup
                value={bookmarkData.resource.id}
                onChange={handleChange}
              >
                {vehicleAllData
                  .filter((item) => item.isBookmarked === false)
                  .map((dt: VehicleData) => {
                    return (
                      <FormControlLabel
                        key={uuid()}
                        value={dt.id}
                        control={<Radio />}
                        label={
                          <Grid container margin="5px 0px">
                            <Grid item xs={12}>
                              <CustomText type="subtitle">{dt.name}</CustomText>
                            </Grid>
                            <Grid container>
                              <Grid mr="20px">{dt.number}</Grid>
                              <Grid mr="20px"> {dt.shapeType}</Grid>
                              <Grid mr="20px"> {dt.sizeType}</Grid>
                              <Grid mr="20px"> {dt.seater + " 인승"}</Grid>
                              <Grid> {dt.fuelType}</Grid>
                            </Grid>
                          </Grid>
                        }
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
          </VehicleSelectBox>

          <Grid container justifyContent="center" mt="30px">
            <CustomButtonGray
              onClick={() => {
                onChangeState("table");
              }}
            >
              목록으로
            </CustomButtonGray>
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default VehicleBookmark;

const InputBox = styled("input")({
  width: "100%",
  height: "56px",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  paddingLeft: "10px",
  "&::placeholder": { color: "#4B4F5A", fontWeight: "300" },
  "&:hover": { border: "1px solid #DCDFE3" },
  "&:focus": { border: "1px solid #DCDFE3", outline: "none" },
});

const BookmarkBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  padding: "10px 0px",
});

const VehicleSelectBox = styled(Grid)({
  height: "210px",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  padding: "10px",
  overflow: "scroll",
});
