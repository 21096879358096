import { Chip } from "@mui/material";

interface MyAccommodationChipProps {
  status: string | null;
}

function MyAccommodationChip({ status }: MyAccommodationChipProps) {
  return (
    <>
      {/* default */}
      {status === "예약중" ? (
        <Chip label={status} color="default" variant="outlined" />
      ) : null}
      {/* 초록 */}
      {status === "이용중" || status === "사용중" || status === "임원용" ? (
        <Chip label={status} color="success" variant="outlined" />
      ) : null}
      {/* 파랑 */}
      {status === "당첨" || status === "승인대기" || status === "업무용" ? (
        <Chip label={status} color="info" variant="outlined" />
      ) : null}
      {/* 빨강 */}
      {status === "미당첨" || status === "기간만료" ? (
        <Chip label={status} color="error" variant="outlined" />
      ) : null}

      {status === "이용종료" || status === "이용완료" ? (
        <Chip label={status} />
      ) : null}
      {status === "보고완료" || status === "비활성화" ? (
        <Chip label={status} />
      ) : null}
      {/* 노랑 */}
      {status === "취소" ? (
        <Chip label={status} color="warning" variant="outlined" />
      ) : null}
      {status === null && (
        <Chip label={"기타"} color="default" variant="outlined" />
      )}
    </>
  );
}

export default MyAccommodationChip;
