import { useEffect, useMemo, useState } from "react";
import { DestinationBookmarkInfo } from "../../../system/types/Bookmark";
import { BookmarkApi } from "../../../system/api/BookmarkApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import { Autocomplete, Grid, TextField, debounce, styled } from "@mui/material";
import { CustomButtonGray, CustomButtonGreen } from "../../../styles/button";
import { InitDestinationBookmarkInfo } from "../../../system/types/initObject";

import { VehicleReserveDestinationAlign } from "../../../styles/vehicleStyle";
import { VehicleSearch } from "../../../system/types/Vehicle";
import { AutoCompleteNoOptionsText } from "../../../styles/reminderStyle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import { CustomText } from "../../../styles/CustomText";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../styles/color";
function validation(data: DestinationBookmarkInfo) {
  if (data.alias === "") {
    AlertModal("check", "목적지명을 입력해 주세요.");
    return false;
  } else if (
    data.address?.kakaoLocId === 0 &&
    data.address.roadAddressName === "" &&
    data.address.addressName === ""
  ) {
    AlertModal("check", "목적지 주소를 입력해 주세요.");
    return false;
  }
  return true;
}

function DestinationBookmark() {
  const [data, setData] = useState<DestinationBookmarkInfo[]>([]);
  const [bookmarkState, setBookmarkState] = useState("table");
  const [bookmarkData, setBookmarkData] = useState<DestinationBookmarkInfo>(
    InitDestinationBookmarkInfo
  );

  const getBookmarkData = () => {
    BookmarkApi.getBookmarkDestination()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  useEffect(() => {
    if (bookmarkState === "table") {
      getBookmarkData();
    }
  }, [bookmarkState]);

  const onChangeState = (state: string) => {
    setBookmarkState(state);
  };
  function onChange(args: any) {
    setBookmarkData({
      ...bookmarkData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  }

  const onDeleteBookmark = (id: number | null) => {
    Swal.fire({
      title: "정말 삭제하시겠습니까?",
      text: "선택하신 목적지가 삭제됩니다.",
      icon: "question",
      confirmButtonText: "예",
      cancelButtonText: "아니오",
      showCancelButton: true,
      confirmButtonColor: GREENCONTENTS,
      cancelButtonColor: REDTITLE,
    }).then((result) => {
      if (result.isConfirmed && id) {
        BookmarkApi.deleteBookmarkDestination(id)
          .then(() => {
            AlertModal("success", "선택하신 목적지가 삭제되었습니다.");
            getBookmarkData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    });
  };

  const onChangeEdit = (dt: DestinationBookmarkInfo) => {
    setBookmarkData(dt);
    setBookmarkState("edit");
  };

  // 주소
  const [options, setOptions] = useState<VehicleSearch[]>([]);
  const [value, setValue]: any = useState(null);
  const [inputValue, setInputValue] = useState("");

  const kakaoKey = "b4cfe488cd8ef4b5f7bc9fbcd0f4b055";
  const debounceTic = 100;
  const fetchKakaoLocations = useMemo(
    () =>
      debounce(async (value, active) => {
        if (!active || value === null || value === "") {
          setOptions([]);
          return undefined;
        }
        try {
          const response = await axios.get(
            `https://dapi.kakao.com/v2/local/search/keyword.json?query=${value}`,
            {
              headers: {
                Authorization: `KakaoAK ${kakaoKey}`, // YOUR_KAKAO_API_KEY를 발급받은 키로 대체해주세요.
              },
            }
          );

          const locations = response.data.documents.map((location: any) => ({
            roadAddressName: location.road_address_name,
            addressName: location.address_name,
            kakaoLocId: location.id,
            categoryName: location.category_name,
            placeName: location.place_name,
            longitude: location.x,
            latitude: location.y,
            placeUrl: location.place_url,
          }));

          setOptions([]);
          setOptions(locations);
        } catch (error) {
          console.error("Error fetching Kakao location data:", error);
        }
      }, debounceTic),
    []
  );
  useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetchKakaoLocations(inputValue, active);
    return () => {
      active = false;
    };
  }, [value, inputValue, fetchKakaoLocations]);

  var submitcheck = false;
  const onSave = () => {
    if (submitcheck === false && validation(bookmarkData)) {
      submitcheck = true;
      if (bookmarkData.id) {
        BookmarkApi.updateBookmarkDestination(bookmarkData)
          .then(() => {
            AlertModal("success", "자주 사용하는 목적지가 수정되었습니다.");
            setBookmarkState("table");
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = false;
          });
      } else {
        BookmarkApi.createBookmarkDestination(bookmarkData)
          .then(() => {
            AlertModal("success", "자주 사용하는 목적지가 추가되었습니다.");
            setBookmarkState("table");
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = false;
          });
      }
    }
  };

  return (
    <>
      {bookmarkState === "table" && (
        <Grid container item xs={12}>
          <Grid container justifyContent="right">
            <CustomButtonGreen
              onClick={() => {
                onChangeState("edit");
                setBookmarkData(InitDestinationBookmarkInfo);
              }}
            >
              목적지 추가
            </CustomButtonGreen>
          </Grid>
          <Grid container>
            {data.length === 0 && (
              <Grid container justifyContent="center" mt="50px">
                등록된 자주 사용하는 목적지가 없습니다.
              </Grid>
            )}
            {data.map((item: DestinationBookmarkInfo, idx) => {
              return (
                <BookmarkBox
                  container
                  key={idx}
                  item
                  xs={12}
                  alignItems="center"
                >
                  <Grid container item xs={10}>
                    <Grid item xs={12}>
                      {item.alias}
                    </Grid>
                    <Grid item xs={12}>
                      {item.address?.roadAddressName}
                    </Grid>
                    <Grid item xs={12}>
                      {item.address?.addressName}
                    </Grid>
                  </Grid>

                  <Grid container item xs={2} justifyContent="right">
                    {item.isDefault !== true && (
                      <>
                        <Tooltip title="수정">
                          <IconButton
                            onClick={() => {
                              onChangeEdit(item);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="삭제">
                          <IconButton
                            onClick={() => {
                              onDeleteBookmark(item.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Grid>
                </BookmarkBox>
              );
            })}
          </Grid>
        </Grid>
      )}
      {bookmarkState === "edit" && (
        <Grid container>
          <Grid item xs={12}>
            <CustomText type="subtitle">목적지명</CustomText>
          </Grid>
          <Grid item xs={12} mt="10px">
            <InputBox
              type="text"
              name="alias"
              onChange={onChange}
              value={bookmarkData.alias || ""}
              placeholder="목적지명을 입력해주세요."
            />
          </Grid>
          <Grid item xs={12} mt="20px">
            <CustomText type="subtitle">주소</CustomText>
          </Grid>
          <Grid item xs={12}>
            <VehicleReserveContentBox viewheight={100}>
              <VehicleReserveDestinationAlign container justifyContent="center">
                <Autocomplete
                  sx={{ width: 600 }}
                  getOptionLabel={(option: VehicleSearch) =>
                    typeof option === "string" ? option : option.placeName
                  }
                  filterOptions={(x) => x}
                  options={options}
                  autoComplete
                  //   includeInputInList
                  autoSelect
                  filterSelectedOptions
                  value={value}
                  isOptionEqualToValue={(
                    option: VehicleSearch,
                    value: VehicleSearch
                  ) => option.kakaoLocId === value.kakaoLocId}
                  onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                    setBookmarkData({
                      ...bookmarkData,
                      address: newValue,
                    });
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.kakaoLocId}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ display: "flex", width: 44 }}>
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                          </Grid>
                          <Grid
                            item
                            sx={{
                              width: "calc(100% - 44px)",
                              wordWrap: "break-word",
                            }}
                          >
                            {option.placeName}
                            <Grid>{option.addressName}</Grid>
                            <Grid>{option.roadAddressName}</Grid>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="두 글자 이상 키워드를 입력하세요"
                    />
                  )}
                  noOptionsText={
                    <AutoCompleteNoOptionsText>
                      검색 결과 없음
                    </AutoCompleteNoOptionsText>
                  }
                />
              </VehicleReserveDestinationAlign>
            </VehicleReserveContentBox>
          </Grid>
          {bookmarkData.address?.kakaoLocId !== 0 && (
            <>
              <Grid item xs={12}>
                <CustomText type="subtitle">선택한 주소 상세 확인</CustomText>
              </Grid>
              {bookmarkData.address?.placeName && (
                <Grid item xs={12} mt="10px">
                  {bookmarkData.address.placeName}
                </Grid>
              )}
              {bookmarkData.address?.roadAddressName && (
                <Grid item xs={12}>
                  {bookmarkData.address?.roadAddressName}
                </Grid>
              )}
              {bookmarkData.address?.addressName && (
                <Grid item xs={12}>
                  {bookmarkData.address.addressName}
                </Grid>
              )}
            </>
          )}
          <Grid container justifyContent="center" mt="30px">
            <CustomButtonGray
              onClick={() => {
                onChangeState("table");
              }}
            >
              목록으로
            </CustomButtonGray>
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default DestinationBookmark;

const InputBox = styled("input")({
  width: "100%",
  height: "56px",
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  paddingLeft: "10px",
  "&::placeholder": { color: "#4B4F5A", fontWeight: "300" },
  "&:hover": { border: "1px solid #DCDFE3" },
  "&:focus": { border: "1px solid #DCDFE3", outline: "none" },
});
interface vehicleReserveProps {
  viewheight: number;
}
const VehicleReserveContentBox = styled(Grid)(
  ({ viewheight }: vehicleReserveProps) => ({
    maxHeight: `${viewheight}px`,
    minHeight: "100px",
    overflow: "scroll",
  })
);

const BookmarkBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  padding: "10px 0px",
});
