import { Grid } from "@mui/material";

import {
  CardDataLayout,
  CardDataBox,
  CardDataContent,
  CardDataCenterContent,
} from "../../../../styles/theme";
import { ReservationSearchData } from "../../../../system/types/Reservation";

import MyAccommodationChip from "../../../Common/Accommodation/MyAccommodationChip";

import AdminAccommodationButton from "../../../Common/Accommodation/AdminAccommodationButton";

interface ReservationCheckTableProps {
  adminData: ReservationSearchData[];
}

function ReservationCheckCard({ adminData }: ReservationCheckTableProps) {
  return (
    <Grid item xs={12}>
      <CardDataLayout>
        {adminData.map((item, idx) => {
          return (
            <CardDataBox key={idx}>
              <Grid item xs={12}>
                <CardDataContent>{item.employeeName}</CardDataContent>
                <CardDataContent>
                  <MyAccommodationChip status={item.status} />
                </CardDataContent>
                <CardDataContent>
                  {item.startDate} - {item.endDate}
                </CardDataContent>
                <CardDataCenterContent>
                  <AdminAccommodationButton data={item} />
                </CardDataCenterContent>
              </Grid>
            </CardDataBox>
          );
        })}
      </CardDataLayout>
    </Grid>
  );
}
export default ReservationCheckCard;
