import { useState } from "react";
import { CustomButtonGreen } from "../../../styles/button";
import { Box, Grid, Modal, styled } from "@mui/material";
import {
  AppliedItem,
  MainTitle,
  RaffleContent,
  SmallModalStyle,
} from "../../../styles/theme";
import { X } from "@phosphor-icons/react";
import { CustomText } from "../../../styles/CustomText";
import { BLUETITLE, GRAYPALEBACKGROUND2 } from "../../../styles/color";
import CheckIcon from "@mui/icons-material/Check";
import {
  DATERANGETYPE,
  QUARTERLYTYPE,
  SEMI_ANNUALLYTYPE,
} from "../../../system/Constants";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { VehicleApi } from "../../../system/api/VehicleApi";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import AlertModal from "../../Common/AlertModal";
import { ErrorHandler } from "../../../system/ApiService";
import { AxiosResponse } from "axios";

interface DownloadDrivingRecordsProps {
  dateRange: Date[];
  setDateRange: (dateRange: Date[]) => void;
}

function DownloadDrivingRecords({
  dateRange,
  setDateRange,
}: DownloadDrivingRecordsProps) {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  var now = new Date();

  const [selectedDaterangeType, setSelectedDaterangeType] = useState<
    string | number
  >("직접 설정");
  const [selectedQuarterlyType, setSelectedQuarterlyType] = useState<
    string | number
  >(1);
  const [selectedSemiAnnuallyType, setSelectedSemiAnnuallyType] = useState<
    string | number
  >("상반기");
  const handleOpen = () => {
    setOpen(true);
    setSelectedDaterangeType("직접 설정");
  };
  function onChange(selectedValue: string | number, type: string) {
    if (type === "maintype" && selectedDaterangeType !== selectedValue) {
      setSelectedDaterangeType(selectedValue);
      if (selectedValue === "분기별") {
        setSelectedQuarterlyType(1);
        setDateRange([
          new Date(now.getFullYear(), 0, 1),
          new Date(now.getFullYear(), 3, 0),
        ]);
      } else if (selectedValue === "반기별") {
        setSelectedSemiAnnuallyType("상반기");
        setDateRange([
          new Date(now.getFullYear(), 0, 1),
          new Date(now.getFullYear(), 6, 0),
        ]);
      } else if (selectedValue === "12개월") {
        setDateRange([
          new Date(now.getFullYear(), 0, 1),
          new Date(now.getFullYear(), 12, 0),
        ]);
      }
    } else if (type === "subtypequarter") {
      setSelectedQuarterlyType(selectedValue);
      setDateRange([
        new Date(now.getFullYear(), (Number(selectedValue) - 1) * 3, 1),
        new Date(now.getFullYear(), Number(selectedValue) * 3, 0),
      ]);
    } else if (type === "subtypesemiannually") {
      setSelectedSemiAnnuallyType(selectedValue);
      if (selectedValue === "상반기") {
        setDateRange([
          new Date(now.getFullYear(), 0, 1),
          new Date(now.getFullYear(), 6, 0),
        ]);
      } else {
        setDateRange([
          new Date(now.getFullYear(), 5, 1),
          new Date(now.getFullYear(), 12, 0),
        ]);
      }
    }
  }

  const onChangeDate = (args: any) => {
    if (args.value) {
      setDateRange(args.value);
    }
  };

  const onSave = () => {
    VehicleApi.getDrivingHistory(
      isoDateFormatter(dateRange[0]),
      isoDateFormatter(dateRange[1]),
      true,
      null,
      null
    )
      .then((res) => {
        const blob = new Blob([res.data]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        link.style.display = "none";
        const injectFilename = (res: AxiosResponse<any, any>) => {
          var filename = "";
          var disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return decodeURI(filename);
        };
        link.download = injectFilename(res);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        AlertModal("success", "차량운행일지 다운로드가 완료되었습니다.");
        handleClose();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  return (
    <>
      <CustomButtonGreen onClick={handleOpen}>운행일지 출력</CustomButtonGreen>
      {open}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SmallModalStyle}>
          <Grid item xs={12}>
            <RaffleContent>
              <MainTitle>차랑운행일지 기간 설정</MainTitle>
              <AppliedItem onClick={handleClose}>
                <X size={32} />
              </AppliedItem>
            </RaffleContent>
            <Grid container mt="20px">
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                alignItems="center"
                mt="10px"
              >
                {DATERANGETYPE.map((item: string, idx) => {
                  return (
                    <BoxBorder
                      container
                      item
                      justifyContent="center"
                      alignItems="center"
                      xs={3}
                      key={idx}
                      onClick={() => onChange(item, "maintype")}
                      Idxnum={idx}
                    >
                      {selectedDaterangeType === item ? (
                        <SelectedBox>
                          {item} <CheckIcon />
                        </SelectedBox>
                      ) : (
                        item
                      )}
                    </BoxBorder>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container mt="5px" style={{ height: "31px" }}>
              {selectedDaterangeType === "분기별" && (
                <>
                  {QUARTERLYTYPE.map((item: number, idx) => {
                    return (
                      <BoxBorder
                        container
                        item
                        justifyContent="center"
                        alignItems="center"
                        xs={3}
                        key={idx}
                        onClick={() => onChange(item, "subtypequarter")}
                        Idxnum={idx}
                      >
                        {selectedQuarterlyType === item ? (
                          <SelectedBox>
                            {item + "분기"} <CheckIcon />
                          </SelectedBox>
                        ) : (
                          <> {item + "분기"}</>
                        )}
                      </BoxBorder>
                    );
                  })}
                </>
              )}
              {selectedDaterangeType === "반기별" && (
                <>
                  {SEMI_ANNUALLYTYPE.map((item: string, idx) => {
                    return (
                      <BoxBorder
                        container
                        item
                        justifyContent="center"
                        alignItems="center"
                        xs={6}
                        key={idx}
                        onClick={() => onChange(item, "subtypesemiannually")}
                        Idxnum={idx}
                      >
                        {selectedSemiAnnuallyType === item ? (
                          <SelectedBox>
                            {item} <CheckIcon />
                          </SelectedBox>
                        ) : (
                          <> {item}</>
                        )}
                      </BoxBorder>
                    );
                  })}
                </>
              )}
            </Grid>
            <Grid item xs={12} mt="10px">
              <DateRangePickerComponent
                id="daterangepicker2"
                placeholder="날짜를 선택해주세요."
                format="yyyy-MM-dd"
                locale="ko"
                delayUpdate={true}
                onChange={onChangeDate}
                value={dateRange}
              />
            </Grid>
            <Grid container justifyContent="right" mt="30px">
              <CustomButtonGreen onClick={onSave}>다운로드</CustomButtonGreen>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default DownloadDrivingRecords;

const SelectedBox = styled(Grid)({
  color: BLUETITLE,
  fontWeight: 500,
});

interface DropDownListComponentProps {
  Idxnum?: number;
}

export const BoxBorder = styled(Grid)(
  ({ Idxnum }: DropDownListComponentProps) => ({
    borderLeft: Idxnum === 0 ? "0px" : "1px solid #DCDFE3",
    padding: "2px 0px",
    cursor: "pointer",
    "&:hover": { backgroundColor: GRAYPALEBACKGROUND2 },
  })
);
