import { Chip } from "@mui/material";

interface MyAccommodationChipProps {
  status: string;
}

function MyAccommodationChipSmall({ status }: MyAccommodationChipProps) {
  return (
    <>
      {/* 초록 */}
      {status === "예약중" || status === "이용중" || status === "사용중" ? (
        <Chip label={status} color="success" variant="outlined" size="small" />
      ) : null}
      {/* 파랑 */}
      {status === "당첨" || status === "승인대기" ? (
        <Chip label={status} color="info" variant="outlined" size="small" />
      ) : null}
      {/* 빨강 */}
      {status === "미당첨" || status === "기간만료" ? (
        <Chip label={status} color="error" variant="outlined" size="small" />
      ) : null}

      {status === "이용종료" ? <Chip label={status} /> : null}
      {status === "보고완료" ? <Chip label={status} /> : null}
      {/* 노랑 */}
      {status === "취소" ? (
        <Chip label={status} color="warning" variant="outlined" size="small" />
      ) : null}
    </>
  );
}

export default MyAccommodationChipSmall;
