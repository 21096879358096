import {
  VehicleAvailableInfo,
  VehicleReservationInfo,
} from "../../../system/types/Vehicle";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import {
  VehicleReserveButton,
  VehicleReserveCarSearch,
  VehicleReserveCarSearchBox,
  VehicleReserveCarSearchBoxContent,
  VehicleReserveCarSelectDropdownBox,
  VehicleReserveContentBox,
  VehicleReserveTitleBox,
  VehicleReserveTitleBoxDisabled,
  VehicleReserveUnvailableBox,
  VehicleReserveValidationBox,
} from "../../../styles/vehicleStyle";
import { InputBoxNoBorder, ValidationText } from "../../../styles/theme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  VEHICLE_SIZE_TYPE,
  VEHICLE_TYPE,
} from "../../../system/constants/VehicleConstants";
import { Fragment } from "react";
import { TooltipText } from "../../../styles/reminderStyle";
import uuid from "react-uuid";
import {
  VehicleMobile,
  VehiclePc,
} from "../../../pages/Layout/ResponsiveScreen";
import SearchIcon from "@mui/icons-material/Search";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import styled from "@emotion/styled";
import { FormControl as MuiFormControl } from "@mui/material";
interface VRSVehicleProps {
  reservationData: VehicleReservationInfo;
  changeSelect: (type: string) => void;
  availableVehicleData: VehicleAvailableInfo[];

  carSelect: boolean;
  onChangeSearch: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => void;
  onClickSearch: () => void;
  onChangeVehicle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  vehicleId?: number;
}

const FormControl = styled(MuiFormControl)`
  &.MuiFormControl-root {
    min-width: 100% !important;
  }
`;

function VRSVehicle({
  reservationData,
  changeSelect,
  availableVehicleData,
  carSelect,
  onChangeSearch,
  onChangeVehicle,
  onClickSearch,
  vehicleId,
}: VRSVehicleProps) {
  const todayDate = new Date();
  const handleOnKeyPress = (e: any) => {
    if (e.key === "Enter") {
      onClickSearch();
    }
  };
  return (
    <>
      {vehicleId ? (
        <VehicleReserveTitleBoxDisabled item xs={12}>
          <Grid container direction="row" alignItems="center">
            <Grid container item xs={12} sm={9} mt="3px">
              <Grid item> 2. 차량 선택</Grid>

              <VehicleReserveValidationBox item>
                {availableVehicleData.filter(
                  (dt: VehicleAvailableInfo) =>
                    dt.vehicle.id === reservationData.vehicleId
                ).length > 0 && (
                  <>
                    {
                      availableVehicleData.filter(
                        (dt: VehicleAvailableInfo) =>
                          dt.vehicle.id === reservationData.vehicleId
                      )[0].vehicle.number
                    }
                  </>
                )}
              </VehicleReserveValidationBox>
            </Grid>
          </Grid>
        </VehicleReserveTitleBoxDisabled>
      ) : (
        <VehicleReserveTitleBox
          item
          xs={12}
          onClick={() => {
            changeSelect("car");
          }}
        >
          <Grid container item xs={12} direction="row" alignItems="center">
            <Grid container item xs={9} alignItems="center">
              <Grid item mr="10px">
                2. 차량 선택
              </Grid>
              {reservationData.vehicleId ? (
                <Grid item>
                  {availableVehicleData.filter(
                    (dt: VehicleAvailableInfo) =>
                      dt.vehicle.id === reservationData.vehicleId
                  ).length > 0 && (
                    <>
                      {
                        availableVehicleData.filter(
                          (dt: VehicleAvailableInfo) =>
                            dt.vehicle.id === reservationData.vehicleId
                        )[0].vehicle.number
                      }
                    </>
                  )}
                </Grid>
              ) : (
                <ValidationText>
                  <ErrorOutlineIcon fontSize="small" /> 차량을 선택해주세요.
                </ValidationText>
              )}
            </Grid>
            {!vehicleId && (
              <VehicleReserveButton
                container
                item
                xs={3}
                justifyContent="right"
              >
                {carSelect ? (
                  <CaretUp size={30} weight="bold" />
                ) : (
                  <CaretDown size={30} weight="bold" />
                )}
              </VehicleReserveButton>
            )}
          </Grid>
        </VehicleReserveTitleBox>
      )}

      {carSelect && (
        <Grid item xs={12}>
          <Grid container item xs={12} justifyContent="right">
            <VehicleReserveCarSelectDropdownBox item xs={3} sm={1.5}>
              <DropDownListComponent
                dataSource={VEHICLE_TYPE}
                placeholder="종류"
                onChange={(e: any) => {
                  onChangeSearch(e, "shapeType");
                }}
              />
            </VehicleReserveCarSelectDropdownBox>
            <VehicleReserveCarSelectDropdownBox item xs={3} sm={1.5}>
              <DropDownListComponent
                dataSource={VEHICLE_SIZE_TYPE}
                placeholder="크기"
                onChange={(e: any) => {
                  onChangeSearch(e, "sizeType");
                }}
              />
            </VehicleReserveCarSelectDropdownBox>

            <Grid container item xs={6} sm={3} mt="5px">
              <VehicleReserveCarSearch item>
                <InputBoxNoBorder
                  placeholder="차량 검색"
                  onChange={(e) => {
                    onChangeSearch(e, "search");
                  }}
                  onKeyDown={handleOnKeyPress}
                />
              </VehicleReserveCarSearch>

              <Grid item>
                <Tooltip
                  title={
                    <Fragment>
                      <TooltipText>검색</TooltipText>
                    </Fragment>
                  }
                >
                  <IconButton onClick={onClickSearch}>
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <VehicleReserveContentBox item xs={12} viewheight={260}>
            <FormControl
              sx={{
                "& .MuiFormControlLabel-root": {
                  width: "100% !important",
                },
                "& .MuiFormControlLabel-label": {
                  width: "100% !important",
                },
              }}
            >
              {reservationData.startDate && reservationData.time && (
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="vehicleId"
                  value={reservationData.vehicleId}
                  onChange={onChangeVehicle}
                >
                  <VehiclePc>
                    {availableVehicleData.map((item) => {
                      return (
                        <Fragment key={uuid()}>
                          {item.reserveAvailable === false ||
                          (item.vehicle.forbidStartDate &&
                            item.vehicle.forbidEndDate &&
                            new Date(item.vehicle.forbidStartDate) <
                              todayDate &&
                            todayDate <
                              new Date(item.vehicle.forbidEndDate)) ? (
                            <VehicleReserveCarSearchBox container key={uuid()}>
                              <FormControlLabel
                                disabled
                                control={<Radio />}
                                label={
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={1}
                                      justifyContent="center"
                                    >
                                      {item.vehicle.isBookmarked && (
                                        <BookmarkIcon />
                                      )}
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={2.5}
                                    >
                                      {item.vehicle.name}
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={2.5}
                                    >
                                      <Grid item> {item.vehicle.number}</Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={1.5}
                                    >
                                      <Grid item>{item.vehicle.shapeType}</Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={1.5}
                                    >
                                      <Grid item> {item.vehicle.sizeType}</Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={3}
                                    >
                                      <Grid item>
                                        {"만 " +
                                          item.vehicle.insurance.applicableAge}
                                        {" 세 이상"}
                                      </Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                  </Grid>
                                }
                              />
                            </VehicleReserveCarSearchBox>
                          ) : (
                            <VehicleReserveCarSearchBox container key={uuid()}>
                              <FormControlLabel
                                value={item.vehicle.id}
                                control={<Radio />}
                                label={
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={1}
                                      justifyContent="center"
                                    >
                                      {item.vehicle.isBookmarked && (
                                        <BookmarkIcon />
                                      )}
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={2.5}
                                    >
                                      {item.vehicle.name}
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={2.5}
                                    >
                                      <Grid item> {item.vehicle.number}</Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={1.5}
                                    >
                                      <Grid item>{item.vehicle.shapeType}</Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={1.5}
                                    >
                                      <Grid item> {item.vehicle.sizeType}</Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={3}
                                    >
                                      <Grid item>
                                        {"만 " +
                                          item.vehicle.insurance.applicableAge +
                                          " 세 이상"}
                                      </Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                  </Grid>
                                }
                              />
                            </VehicleReserveCarSearchBox>
                          )}
                        </Fragment>
                      );
                    })}
                  </VehiclePc>
                  <VehicleMobile>
                    {availableVehicleData.map((item) => {
                      return (
                        <Fragment key={uuid()}>
                          {item.reserveAvailable === false ||
                          (item.vehicle.forbidStartDate &&
                            item.vehicle.forbidEndDate &&
                            new Date(item.vehicle.forbidStartDate) <
                              todayDate &&
                            todayDate <
                              new Date(item.vehicle.forbidEndDate)) ? (
                            <VehicleReserveCarSearchBox container key={uuid()}>
                              <FormControlLabel
                                disabled
                                control={<Radio />}
                                label={
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={1}
                                      justifyContent="center"
                                      overflow="hidden"
                                    >
                                      {item.vehicle.isBookmarked && (
                                        <BookmarkIcon />
                                      )}
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={5}
                                    >
                                      <Grid item>{item.vehicle.name}</Grid>
                                      <Grid item> {item.vehicle.number}</Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={5}
                                    >
                                      <Grid item>
                                        {"만 " +
                                          item.vehicle.insurance.applicableAge +
                                          " 세 이상"}
                                      </Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                  </Grid>
                                }
                              />
                            </VehicleReserveCarSearchBox>
                          ) : (
                            <VehicleReserveCarSearchBox container key={uuid()}>
                              <FormControlLabel
                                value={item.vehicle.id}
                                control={<Radio />}
                                label={
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={2}
                                      justifyContent="center"
                                    >
                                      {item.vehicle.isBookmarked && (
                                        <BookmarkIcon />
                                      )}
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={5}
                                    >
                                      <Grid item>{item.vehicle.name}</Grid>
                                      <Grid item> {item.vehicle.number}</Grid>
                                    </VehicleReserveCarSearchBoxContent>
                                    <VehicleReserveCarSearchBoxContent
                                      item
                                      xs={5}
                                    >
                                      {"만 " +
                                        item.vehicle.insurance.applicableAge +
                                        " 세 이상"}
                                    </VehicleReserveCarSearchBoxContent>
                                  </Grid>
                                }
                              />
                            </VehicleReserveCarSearchBox>
                          )}
                        </Fragment>
                      );
                    })}
                  </VehicleMobile>
                </RadioGroup>
              )}
            </FormControl>
            {availableVehicleData.length === 0 && (
              <VehicleReserveUnvailableBox>
                <Grid>죄송합니다, 현재 이용 가능한 차량이 없습니다.</Grid>
                <Grid>
                  다른 시간대를 검토하거나 다른 차량 옵션을 살펴보세요.
                </Grid>
              </VehicleReserveUnvailableBox>
            )}
          </VehicleReserveContentBox>
        </Grid>
      )}
    </>
  );
}

export default VRSVehicle;
