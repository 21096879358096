import { Grid } from "@mui/material";
import {
  AdminTabBox,
  BoardDropdownRight,
  DisabledItem,
  AppliedItem,
  TypeGap,
} from "../../styles/theme";
import { useEffect, useState } from "react";
import { ListBullets, SquaresFour } from "@phosphor-icons/react";
import { Mobile, Pc } from "../../pages/Layout/ResponsiveScreen";
import MyAccommodationTable from "./Accommodation/MyAccommodationTable";
import MyAccommodationCard from "./Accommodation/MyAccommodationCard";
import { MyReservation } from "../../system/types/Reservation";
import { ReservationApi } from "../../system/api/ReservationApi";
import { ErrorHandler } from "../../system/ApiService";

import { useLoadingDispatch } from "../../system/context/LoadingContext";
import AlertModal from "../Common/AlertModal";
import { useRecoilValue } from "recoil";
import { BranchEmployeeDataAtom } from "../../system/atoms";
import { isoDateFormatter } from "../Common/IsoDateFormatter";

interface MyAccommodationProps {
  reservationMonth: number;
  reservationYear: number;
}

function MyAccommodation({
  reservationMonth,
  reservationYear,
}: MyAccommodationProps) {
  const [cardType, setCardType] = useState(false);
  const [mydata, setMyData] = useState<MyReservation[]>([]);
  const onChangeCardType = () => {
    setCardType(!cardType);
  };
  const branchEmployee = useRecoilValue(BranchEmployeeDataAtom);

  const Loading = useLoadingDispatch();

  useEffect(() => {
    refreshReservationData();
  }, [branchEmployee.id, reservationMonth, reservationYear]);

  function refreshReservationData() {
    Loading({ type: "LOADING" });
    ReservationApi.getMyReservation(
      "휴양소",
      isoDateFormatter(new Date(reservationYear, reservationMonth - 1, 1)),
      isoDateFormatter(new Date(reservationYear, reservationMonth, 0)),
      branchEmployee.id
    )
      .then((res) => {
        setMyData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  }

  return (
    <AdminTabBox>
      <Grid item xs={12}>
        {/* <BoardTopLayout item xs={12}>
          {" "}
          <BoardDropdownLeft item xs={6}>
            <Grid item xs={3}>
              {" "}
              <DatePickerComponent id="datepicker" />
            </Grid>
          </BoardDropdownLeft>
          <BoardDropdownRight item xs={6}>
            <Grid item xs={3} p={1}>
              {" "}
              <DropDownListComponent id="ddlelement" />
            </Grid>
            <Grid item xs={3} p={1}>
              {" "}
              <DropDownListComponent id="ddlelement" />
            </Grid>
          </BoardDropdownRight>
        </BoardTopLayout> */}
        <Pc>
          <TypeGap>
            {cardType ? (
              <BoardDropdownRight>
                <DisabledItem onClick={onChangeCardType}>
                  <ListBullets size={32} />{" "}
                </DisabledItem>
                <AppliedItem onClick={onChangeCardType}>
                  <SquaresFour size={32} />
                </AppliedItem>
              </BoardDropdownRight>
            ) : (
              <BoardDropdownRight>
                <AppliedItem onClick={onChangeCardType}>
                  <ListBullets size={32} />{" "}
                </AppliedItem>
                <DisabledItem onClick={onChangeCardType}>
                  <SquaresFour size={32} />
                </DisabledItem>
              </BoardDropdownRight>
            )}
          </TypeGap>

          {cardType ? (
            <MyAccommodationCard
              mydata={mydata}
              refreshReservationData={refreshReservationData}
            />
          ) : (
            <MyAccommodationTable
              mydata={mydata}
              refreshReservationData={refreshReservationData}
            />
          )}
        </Pc>
        <Mobile>
          <MyAccommodationCard
            mydata={mydata}
            refreshReservationData={refreshReservationData}
          />
        </Mobile>
      </Grid>
    </AdminTabBox>
  );
}

export default MyAccommodation;
