import { useEffect, useState } from "react";
import { MyReservationVehicleData } from "../../system/types/Reservation";
import { useRecoilValue } from "recoil";
import { BranchEmployeeDataAtom } from "../../system/atoms";
import { useLoadingDispatch } from "../../system/context/LoadingContext";
import { ReservationApi } from "../../system/api/ReservationApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import {
  AdminTabBox,
  AppliedItem,
  BoardDropdownRight,
  DisabledItem,
  TypeGap,
} from "../../styles/theme";
import { Grid } from "@mui/material";
import { Mobile, Pc } from "../../pages/Layout/ResponsiveScreen";
import { ListBullets, SquaresFour } from "@phosphor-icons/react";
import MyCarCard from "./Car/MyCarCard";
import MyCarTable from "./Car/MyCarTable";
import { isoDateFormatter } from "../Common/IsoDateFormatter";
import { AccommodationAlert } from "../../styles/accommodationStyle";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";
interface MyCarRentProps {
  reservationMonth: number;
  reservationYear: number;
}

function MyCarRent({ reservationMonth, reservationYear }: MyCarRentProps) {
  const [cardType, setCardType] = useState(false);
  const [mydata, setMyData] = useState<MyReservationVehicleData[]>([]);
  const onChangeCardType = () => {
    setCardType(!cardType);
  };
  const branchEmployee = useRecoilValue(BranchEmployeeDataAtom);

  const Loading = useLoadingDispatch();

  useEffect(() => {
    refreshReservationData();
  }, [branchEmployee.id, reservationMonth, reservationYear]);

  function refreshReservationData() {
    Loading({ type: "LOADING" });
    ReservationApi.getMyReservation(
      "차량",
      isoDateFormatter(new Date(reservationYear, reservationMonth - 1, 1)),
      isoDateFormatter(new Date(reservationYear, reservationMonth, 0)),
      branchEmployee.id
    )
      .then((res) => {
        setMyData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  }

  return (
    <AdminTabBox>
      <Grid item xs={12}>
        <Pc>
          <TypeGap>
            {cardType ? (
              <BoardDropdownRight>
                <DisabledItem onClick={onChangeCardType}>
                  <ListBullets size={32} />{" "}
                </DisabledItem>
                <AppliedItem onClick={onChangeCardType}>
                  <SquaresFour size={32} />
                </AppliedItem>
              </BoardDropdownRight>
            ) : (
              <BoardDropdownRight>
                <AppliedItem onClick={onChangeCardType}>
                  <ListBullets size={32} />{" "}
                </AppliedItem>
                <DisabledItem onClick={onChangeCardType}>
                  <SquaresFour size={32} />
                </DisabledItem>
              </BoardDropdownRight>
            )}
          </TypeGap>
          {mydata.length > 0 && (
            <AccommodationAlert>
              <MessageComponent
                severity="Warning"
                variant="Outlined"
                content="차량 내역 수정은 예약일로부터 7일 이내에만 가능합니다."
              />
            </AccommodationAlert>
          )}
          {cardType ? (
            <MyCarCard
              myData={mydata}
              refreshReservationData={refreshReservationData}
            />
          ) : (
            <MyCarTable
              myData={mydata}
              refreshReservationData={refreshReservationData}
            />
          )}
        </Pc>
        <Mobile>
          {mydata.length > 0 && (
            <AccommodationAlert>
              <MessageComponent
                severity="Warning"
                variant="Outlined"
                content="차량 내역 수정은 예약일로부터 7일 이내에만 가능합니다."
              />
            </AccommodationAlert>
          )}
          <MyCarCard
            myData={mydata}
            refreshReservationData={refreshReservationData}
          />
        </Mobile>
      </Grid>
    </AdminTabBox>
  );
}

export default MyCarRent;
