import { useEffect, useState } from "react";
import {
  AppliedItem,
  CardDataCenterContent,
  DisabledImgBox,
  ImgLabelBox,
  MainTitle,
  ReservationTextarea,
  RightLayout,
  UploadImg,
  RaffleContent,
} from "../../../styles/theme";

import { CustomButtonGray, CustomButtonGreen } from "../../../styles/button";
import CloseIcon from "@mui/icons-material/Close";

import { AccommodationApi } from "../../../system/api/AccommodationApi";
import { ErrorHandler } from "../../../system/ApiService";
import { MyReservation } from "../../../system/types/Reservation";
import uuid from "react-uuid";
import AddIcon from "@mui/icons-material/Add";
import { AzureApi } from "../../../system/api/AzureApi";
import { BlobServiceClient } from "@azure/storage-blob";
import { X } from "@phosphor-icons/react";
import AlertModal from "../../Common/AlertModal";
import { CHECKINLIST, CheckInListItemStatus } from "../../../system/Constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CheckListBox,
  CheckListBoxItem,
  DeleteIconButton,
  ReportAfterImgBox,
  ReportAfterImgItem,
  SelectedCheckList,
  SelectAccommodationItem,
  SelectAccommodationStatus,
  SelectAccommodationText,
  AccommodationStatusIcon,
  ModalTextScrollBox,
} from "../../../styles/accommodationStyle";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { MobileSmall, Tablet } from "../../../pages/Layout/ResponsiveScreen";
import axios, { AxiosError } from "axios";

interface CheckInModalProps {
  handleClose: () => void;
  data: MyReservation;
  refreshReservationData: () => void;
}

function validation(status: string) {
  if (status === "") {
    AlertModal("check", "입실 이전 휴양소 상태를 선택해주세요.");

    return false;
  }
  return true;
}

function CheckInModal({
  handleClose,
  data,
  refreshReservationData,
}: CheckInModalProps) {
  const Loading = useLoadingDispatch();
  const [selectStatus, setSelectStatus] = useState("");
  const [opinions, setOpinions] = useState("");
  const OnchangeSelectStatus = (status: string) => {
    setSelectStatus(status);
  };
  const [checklist, setCheckList] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const onChange = (args: any) => {
    setOpinions(args.target.value);
  };
  const ImageKeyId = uuid();
  const [showImages, setShowImages] = useState<string[]>([]);

  const [sasToken, setSasToken] = useState("");
  const AccommodationCheckInList = CHECKINLIST;
  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      if (event.target.files !== null) {
        let preFileList: File[] = [];
        for (var i = 0; i < event.target.files.length; i++) {
          const oldFile = event.target.files[i];
          const fileExtension = event.target.files[i].name.split(".")[1]; // 업로드 파일 확장자
          let newFile = null;
          if (fileExtension) {
            newFile = new File(
              [oldFile],
              `${ImageKeyId + "_입실이전휴양소상태_" + i}.${fileExtension}`,
              {
                type: oldFile.type,
              }
            );
          }
          if (newFile !== null) {
            preFileList = preFileList.concat([newFile]);
          }
        }
        const completedFileList = fileList.concat(preFileList);
        setFileList(completedFileList);

        // 미리보기
        let imageUrlLists = [];
        for (var j = 0; j < completedFileList.length; j++) {
          const currentImageUrl = URL.createObjectURL(completedFileList[j]);
          imageUrlLists.push(currentImageUrl);
        }
        setShowImages(imageUrlLists);
      }
    }
  };
  const deleteFile = (id: number) => {
    setShowImages(showImages.filter((_, idx) => idx !== id));
    setFileList(fileList.filter((_, idx) => idx !== id));
  };

  useEffect(() => {
    AzureApi.getSasToken()
      .then((res) => {
        setSasToken(res.data.sasUrl);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const uploadFileToBlob = async (file: File[]) => {
    // upload file
    const blobService = new BlobServiceClient(sasToken);
    const containerClient = blobService.getContainerClient("");

    const uploadPromises = file.map((document) => {
      if (document.name === null) {
        return Promise.resolve();
      }

      const blockBlobClient = containerClient.getBlockBlobClient(
        "inserve/" + process.env.REACT_APP_ACTIVE_MODE + "/" + document.name
      );

      return blockBlobClient.uploadData(document!);
    });
    return Promise.all(uploadPromises);
  };

  const onSave = async () => {
    if (validation(selectStatus)) {
      Loading({ type: "LOADING_MESSAGE", message: "파일 업로드 중입니다." });
      try {
        await uploadFileToBlob(fileList);
        const uploadFileList = fileList.map((file) => ({
          name: file.name,
          url: `https://inbodyinfra.blob.core.windows.net/images/inserve/${process.env.REACT_APP_ACTIVE_MODE}/${file.name}`,
        }));

        await AccommodationApi.createCheckIn({
          condition: selectStatus,
          opinions: opinions,
          reservationId: data.id,
          attachmentFiles: uploadFileList,
        });

        AlertModal("success", "입실 이전 휴양소 상태 설문이 제출되었습니다.");
        handleClose();
        refreshReservationData();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const msg = ErrorHandler(err as AxiosError);
          AlertModal("msg", msg);
        } else {
          console.error(err);
          AlertModal("msg", "예기치 않은 오류가 발생했습니다.");
        }
      } finally {
        Loading({ type: "COMPLETE" });
      }
    }
  };

  const onChangeNextPage = () => {
    setCheckList(true);
  };

  return (
    <>
      {checklist === false && (
        <>
          <RaffleContent>
            <MainTitle>
              {" "}
              {data.accommodationName} {data.type} 입실 시 확인 사항
            </MainTitle>
            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>

          <Tablet>
            <ModalTextScrollBox>
              <SelectAccommodationText>
                아래 내용을 모두 확인하신 후 다음으로 버튼을 눌러주세요.
              </SelectAccommodationText>
              <CheckListBox>
                {AccommodationCheckInList.filter(
                  (itm) => itm.location === data.accommodationName
                )[0]?.checklist.map(
                  (item: CheckInListItemStatus, idx: number) => (
                    <CheckListBoxItem key={"checkinlist" + idx}>
                      <SelectedCheckList>
                        <CheckCircleIcon />
                      </SelectedCheckList>

                      {item.checklistItem}
                    </CheckListBoxItem>
                  )
                )}
              </CheckListBox>
            </ModalTextScrollBox>
          </Tablet>
          <MobileSmall>
            <SelectAccommodationText>
              아래 내용을 모두 확인하신 후 다음으로 버튼을 눌러주세요.
            </SelectAccommodationText>

            {AccommodationCheckInList.filter(
              (itm) => itm.location === data.accommodationName
            )[0]?.checklist.map((item: CheckInListItemStatus, idx: number) => (
              <CheckListBoxItem key={"checkinlist" + idx}>
                <SelectedCheckList>
                  <CheckCircleIcon />
                </SelectedCheckList>

                {item.checklistItem}
              </CheckListBoxItem>
            ))}
          </MobileSmall>
          <RightLayout>
            <CustomButtonGreen onClick={onChangeNextPage}>
              다음으로
            </CustomButtonGreen>
          </RightLayout>
        </>
      )}
      {checklist && (
        <>
          <RaffleContent>
            <MainTitle>입실 이전 휴양소 상태</MainTitle>
            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>

          <SelectAccommodationStatus>
            <SelectAccommodationItem
              onClick={() => OnchangeSelectStatus("GOOD")}
            >
              <AccommodationStatusIcon
                src={
                  selectStatus === "GOOD"
                    ? "/images/SGOOD.png"
                    : "/images/GOOD.png"
                }
                alt="good"
              ></AccommodationStatusIcon>
              <SelectAccommodationText>최고예요</SelectAccommodationText>
            </SelectAccommodationItem>
            <SelectAccommodationItem
              onClick={() => OnchangeSelectStatus("SOSO")}
            >
              {" "}
              <AccommodationStatusIcon
                src={
                  selectStatus === "SOSO"
                    ? "/images/SSOSO.png"
                    : "/images/SOSO.png"
                }
                alt="soso"
              ></AccommodationStatusIcon>
              <SelectAccommodationText>좋아요</SelectAccommodationText>
            </SelectAccommodationItem>
            <SelectAccommodationItem
              onClick={() => OnchangeSelectStatus("BAD")}
            >
              <AccommodationStatusIcon
                src={
                  selectStatus === "BAD"
                    ? "/images/SBAD.png"
                    : "/images/BAD.png"
                }
                alt="bad"
              ></AccommodationStatusIcon>
              <SelectAccommodationText>별로예요</SelectAccommodationText>
            </SelectAccommodationItem>
          </SelectAccommodationStatus>
          <SelectAccommodationText>기타의견 (선택)</SelectAccommodationText>
          <ReservationTextarea onChange={onChange} name="opinions" />
          <SelectAccommodationText>사진첨부 (선택)</SelectAccommodationText>

          <ReportAfterImgBox>
            <ImgLabelBox htmlFor={"file"}>
              {" "}
              <AddIcon />
            </ImgLabelBox>
            <DisabledImgBox
              id={"file"}
              type="file"
              onChange={(e) => onSaveFile(e)}
              multiple
            ></DisabledImgBox>
            <br />

            {showImages.map((image, idx) => (
              <ReportAfterImgItem key={uuid()}>
                <UploadImg src={image} alt="test" />

                <DeleteIconButton
                  sx={{ fontSize: 3 }}
                  onClick={() => deleteFile(idx)}
                >
                  <CloseIcon />
                </DeleteIconButton>
              </ReportAfterImgItem>
            ))}
          </ReportAfterImgBox>

          <CardDataCenterContent>
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>{" "}
            <CustomButtonGray onClick={handleClose}>취소</CustomButtonGray>
          </CardDataCenterContent>
        </>
      )}
    </>
  );
}

export default CheckInModal;
