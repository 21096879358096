export const isoDateFormatter = (date: Date) => {
  if (!date) return undefined;
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month >= 10 ? month : "0" + month}-${
    day >= 10 ? day : "0" + day
  }`;
};

export const isoHourFormatter = (date: Date) => {
  if (!date) return undefined;
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${hour >= 10 ? hour : "0" + hour}:${
    minute >= 10 ? minute : "0" + minute
  }:00`;
};

export const getDayOfTheWeekFormatter = (date: string) => {
  const preDate = new Date(date);
  const dayOfTheWeek = preDate.getDay();
  switch (dayOfTheWeek) {
    case 0:
      return "일요일";
    case 1:
      return "월요일";
    case 2:
      return "화요일";
    case 3:
      return "수요일";
    case 4:
      return "목요일";
    case 5:
      return "금요일";
    case 6:
      return "토요일";
  }
};
