import {
  VehicleReservationInfo,
  VehicleSearch,
} from "../../../system/types/Vehicle";
import axios from "axios";
import { debounce } from "@mui/material/utils";
import { Fragment, useEffect, useMemo, useState } from "react";
import { DestinationBookmarkInfo } from "../../../system/types/Bookmark";
import { BookmarkApi } from "../../../system/api/BookmarkApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import {
  VehicleReserveContentBox,
  VehicleReserveDestinationAlign,
} from "../../../styles/vehicleStyle";
import { Autocomplete, Chip, Grid, TextField } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { AutoCompleteNoOptionsText } from "../../../styles/reminderStyle";
import { CustomText } from "../../../styles/CustomText";
import { CustomButtonGreen } from "../../../styles/button";
import { REDTITLE } from "../../../styles/color";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { InitVehicleSearch } from "../../../system/types/initObject";
import uuid from "react-uuid";
interface VRSDestinationModalProps {
  addressData: VehicleSearch;
  setAddressData: (dt: VehicleSearch) => void;
  selectedNumber: number;
  setReservationData: (dt: VehicleReservationInfo) => void;
  reservationData: VehicleReservationInfo;
  handleClose: () => void;
}

function VRSDestinationModal({
  addressData,
  setAddressData,
  selectedNumber,
  reservationData,
  setReservationData,
  handleClose,
}: VRSDestinationModalProps) {
  // 목적지
  const [options, setOptions] = useState<VehicleSearch[]>([]);
  const [value, setValue]: any = useState(null);
  const [inputValue, setInputValue] = useState("");
  const debounceTic = 100;

  const onSave = () => {
    if (
      addressData?.kakaoLocId &&
      reservationData.waypoints.some(
        (item) => item.kakaoLocId === addressData.kakaoLocId
      ) === false
    ) {
      const newList = reservationData.waypoints.map((item, index) => {
        if (index === selectedNumber) {
          return addressData;
        }
        return item;
      });

      const newData = { ...reservationData, waypoints: newList };
      setReservationData(newData);
      handleClose();
      setAddressData(InitVehicleSearch);
    } else {
      AlertModal("check", "목적지를 다시 입력해주세요.");
    }
  };

  const kakaoKey = "b4cfe488cd8ef4b5f7bc9fbcd0f4b055";

  const fetchKakaoLocations = useMemo(
    () =>
      debounce(async (value, active) => {
        if (!active || value === null || value === "") {
          setOptions([]);
          return undefined;
        }
        try {
          const response = await axios.get(
            `https://dapi.kakao.com/v2/local/search/keyword.json?query=${value}`,
            {
              headers: {
                Authorization: `KakaoAK ${kakaoKey}`, // YOUR_KAKAO_API_KEY를 발급받은 키로 대체해주세요.
              },
            }
          );

          const locations = response.data.documents.map((location: any) => ({
            roadAddressName: location.road_address_name,
            addressName: location.address_name,
            kakaoLocId: location.id,
            categoryName: location.category_name,
            placeName: location.place_name,
            longitude: location.x,
            latitude: location.y,
            placeUrl: location.place_url,
          }));

          setOptions([]);
          setOptions(locations);
        } catch (error) {
          console.error("Error fetching Kakao location data:", error);
        }
      }, debounceTic),
    []
  );
  useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchKakaoLocations(inputValue, active);

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchKakaoLocations]);

  // bookmark
  const [bookmarkData, setBookmarkData] = useState<DestinationBookmarkInfo[]>(
    []
  );
  useEffect(() => {
    BookmarkApi.getBookmarkDestination()
      .then((res) => {
        setBookmarkData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const onChange = (dt: DestinationBookmarkInfo) => {
    if (dt.address !== null) {
      const newList = reservationData.waypoints.map((item, index) => {
        if (index === selectedNumber) {
          return dt.address ? dt.address : InitVehicleSearch;
        }
        return item;
      });

      const newData = { ...reservationData, waypoints: newList };
      setReservationData(newData);
      setAddressData(InitVehicleSearch);
      handleClose();
    }
  };

  return (
    <>
      <Grid container item xs={12} mt="10px">
        <CustomText type="subtitle">자주 사용하는 목적지</CustomText>
        <Grid container item xs={12} mt="5px">
          {bookmarkData.length > 0 ? (
            <>
              {bookmarkData.map((item: DestinationBookmarkInfo, idx) => {
                return (
                  <Fragment key={uuid()}>
                    {reservationData.waypoints.some(
                      (dt) => dt.kakaoLocId === item?.address?.kakaoLocId
                    ) === false && (
                      <Chip
                        key={idx}
                        label={item.alias}
                        variant="outlined"
                        style={{ margin: "5px" }}
                        onClick={() => onChange(item)}
                      />
                    )}
                  </Fragment>
                );
              })}
            </>
          ) : (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              mt="20px"
              mb="10px"
            >
              등록된 자주 사용하는 목적지가 없습니다.
            </Grid>
          )}
        </Grid>
      </Grid>
      <VehicleReserveContentBox container viewheight={200} mt="10px">
        <Grid container item xs={12}>
          <CustomText type="subtitle">목적지 검색</CustomText>
        </Grid>
        <VehicleReserveDestinationAlign container justifyContent="center">
          <Autocomplete
            sx={{ width: 600 }}
            getOptionLabel={(option: VehicleSearch) =>
              typeof option === "string" ? option : option.placeName
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            //   includeInputInList
            autoSelect
            filterSelectedOptions
            value={value}
            isOptionEqualToValue={(
              option: VehicleSearch,
              value: VehicleSearch
            ) => option.kakaoLocId === value.kakaoLocId}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);

              if (newValue !== null) {
                setValue(newValue);
                setAddressData(newValue);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.kakaoLocId}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: "flex", width: 44 }}>
                      <LocationOnIcon sx={{ color: "text.secondary" }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 44px)",
                        wordWrap: "break-word",
                      }}
                    >
                      {option.placeName}
                      <Grid>{option.addressName}</Grid>
                      <Grid>{option.roadAddressName}</Grid>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="두 글자 이상 키워드를 입력하세요"
              />
            )}
            noOptionsText={
              <AutoCompleteNoOptionsText>
                검색 결과 없음
              </AutoCompleteNoOptionsText>
            }
          />
        </VehicleReserveDestinationAlign>
      </VehicleReserveContentBox>
      <Grid container mt="10px">
        <Grid container item xs={12}>
          {addressData.kakaoLocId !== 0 &&
            reservationData.waypoints.some(
              (dt) => dt.kakaoLocId === Number(addressData.kakaoLocId)
            ) && (
              <CustomText type="subtitle" color={REDTITLE} marginLeft="10px">
                <ErrorOutlineIcon /> 이미 선택된 목적지입니다.
              </CustomText>
            )}
        </Grid>
        {addressData.kakaoLocId !== 0 && (
          <Grid container item xs={12} margin="10px 0px">
            <Grid item xs={12}>
              {addressData.placeName}
            </Grid>
            <Grid item xs={12}>
              {addressData.roadAddressName}
            </Grid>
            <Grid item xs={12}>
              {addressData.addressName}
            </Grid>
          </Grid>
        )}
        {addressData && (
          <Grid container justifyContent="right" item xs={12}>
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default VRSDestinationModal;
