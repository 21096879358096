import { Grid } from "@mui/material";
import { MaxWidth } from "../../styles/theme";
import ReservationCalendar from "./ReservationCalendar";

function AccommodationView() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaxWidth>
            <ReservationCalendar />
          </MaxWidth>
        </Grid>
      </Grid>
    </>
  );
}

export default AccommodationView;
