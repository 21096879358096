import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  BLUEBACKGROUND,
  BLUECONTENTS,
  GRAYPALEBACKGROUND2,
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  GREENCONTENTS,
  PRIMARY,
  REDBACKGROUND,
  REDCONTENTS,
} from "./color";
export const VehicleMainBox = styled(Grid)(() => ({
  margin: "5px",
}));

export const VehicleMainBoxPc = styled(Grid)(() => ({
  marginTop: "30px",
  marginLeft: "0px",
}));

export const VehicleMainAlign = styled("div")(() => ({
  margin: "30px 0px",
}));

export const VehicleLeftBox = styled("div")(() => ({
  borderRadius: "8px",
  border: "1px solid #EDEDED",
  minHeight: "735px",
  width: "100%",
  maxWidth: "600px",
  margin: "5px 0px",
  padding: "22px 7%",
}));

export const VehicleRightBox = styled("div")(() => ({
  borderRadius: "8px",
  border: "1px solid #EDEDED",
  height: "238px",
  width: "100%",
  maxWidth: "600px",
  margin: "5px 0px",
  padding: "17px 7%",
}));

// VehicleDrivingBox
export const VehicleCarInfoTitleBox = styled("div")(() => ({
  borderRadius: "8px",
  backgroundColor: "#E9ECF0",
  textAlign: "center",
  width: "150px",
  padding: "3px",
  position: "relative",
  zIndex: "1",
  color: GRAYPALETITLE,
}));

export const VehicleCarInfoContentBox = styled(Grid)(() => ({
  borderRadius: "8px",
  backgroundColor: "#F7F8FA",
  padding: "15px 10px 10px 10px",
  marginTop: "-15px",
  position: "relative",
  zIndex: "0",
  minHeight: "146px",
}));

export const VehicleCarInfoBox = styled("div")(() => ({
  margin: "15px 0px",
}));

export const VehicleCarInfoBoxLimit = styled(Grid)(() => ({
  margin: "15px 0px",
  height: "32px",
}));

export const VehicleReservationButton = styled("div")(() => ({
  width: "200px",
  height: "200px",
  backgroundColor: BLUECONTENTS,
  borderRadius: "50%",
  color: "#ffffff",
  margin: "auto",
  cursor: "pointer",
}));

export const VehicleDrivingButton = styled("div")(() => ({
  width: "200px",
  height: "200px",
  backgroundColor: GREENCONTENTS,
  borderRadius: "50%",
  color: "#ffffff",
  margin: "auto",
  cursor: "pointer",
}));

export const VehicleDrivingStopButton = styled("div")(() => ({
  width: "200px",
  height: "200px",
  backgroundColor: REDCONTENTS,
  borderRadius: "50%",
  color: "#ffffff",
  margin: "auto",
  cursor: "pointer",
}));

export const VehicleDrivingButtonfont = styled("div")(() => ({
  fontWeight: "700",
  fontSize: "50px",
  marginTop: "-10px",
}));
export const VehicleDrivingButtonfont2 = styled("div")(() => ({
  fontSize: "25px",
  paddingTop: "45px",
}));

export const VehicleCarMemoBox = styled("div")(() => ({
  borderRadius: "8px",
  backgroundColor: "#F7F8FA",
  padding: "10px 15px",
  margin: "20px 0px",
  height: "130px",
  overflowX: "auto",
  overflowWrap: "anywhere",
  overflowY: "scroll",
}));

export const VehicleCarReservationBox = styled("div")(() => ({
  borderRadius: "8px",
  backgroundColor: BLUEBACKGROUND,
  fontWeight: 700,
  textAlign: "center",
  width: "150px",
  padding: "5px",
  color: BLUECONTENTS,
  cursor: "pointer",
}));

export const VehicleSaveButton = styled("div")(() => ({
  borderRadius: "8px",
  backgroundColor: "#3D4861",
  color: "#ffffff",
  width: "70px",
  textAlign: "center",
  cursor: "pointer",
}));

export const VehicleDrivingBoxModalAlign = styled(Grid)(() => ({
  margin: "50px auto",
  fontSize: "25px",
}));

export const VehicleDrivingBoxModalInputBox = styled(Grid)(() => ({
  width: "50%",
}));

export const VehicleDrivingInfoBox = styled(Grid)(() => ({
  margin: "5px 0px",
}));

export const VehicleDrivingInfoTitle = styled(Grid)(() => ({
  color: GRAYPALETITLE,
  fontWeight: 600,
  width: "80px",
  textAlign: "center",
}));

export const VehicleDrivingInfoContents = styled(Grid)(() => ({
  color: GRAYPALETITLE,
  marginLeft: "10px",
}));

export const VehicleDrivingInfoContentsBox = styled(Grid)(() => ({
  minWidth: "48%",
}));

export const VehicleMemoTextarea = styled("textarea")({
  width: "100%",
  height: "70px",
  resize: "none",
  borderRadius: "8px",
  marginTop: "5px",
  marginBottom: "20px",
  "&::placeholder": { color: "#B2B4B8", fontWeight: "300" },
});

// VehicleRightBox
export const VehicleRightBoxTitleAlign = styled(Grid)(() => ({
  marginBottom: "15px",
}));

export const VehicleRightBoxTitleAlign2 = styled(Grid)(() => ({
  marginBottom: "10px",
}));

export const VehicleRightBoxTitle = styled(Grid)(() => ({
  color: GRAYPALETITLE,
  fontWeight: 500,
}));

export const VehicleRightBoxSideColor = styled(Grid)(() => ({
  color: GRAYPALECONTENTS,
  cursor: "pointer",
}));

// VehicleNoticeBox
export const VehicleNoticeBoxContent = styled(Grid)(() => ({
  margin: "8px 0px",
  cursor: "pointer",
}));

export const VehicleNoticeContentsBox = styled(Grid)(() => ({
  height: "160px",
  overflow: "hidden",
}));

export const VehicleNoticeTitleBox = styled(Grid)(() => ({
  width: "80%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const VehicleNoticeTitleLongBox = styled(Grid)(() => ({
  width: "100%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const VehicleNoticeIcon = styled("img")(() => ({
  width: "20px",
  marginTop: "-3px",
  marginRight: "5px",
}));

// VehicleMyBox
export const VehicleMyBoxMainBox = styled(Grid)(() => ({
  borderRadius: "8px",
  backgroundColor: "#F7F8FA",
  padding: "0px 10px",
  height: "150px",
}));
export const VehicleMyBoxContentsBox = styled(Grid)(() => ({
  width: "46%",
  margin: "2%",
}));

export const VehicleMyBoxYear = styled("div")(() => ({
  color: GRAYPALECONTENTS,
  textAlign: "center",
}));
export const VehicleMyBoxMonth = styled(Grid)(() => ({
  color: GRAYPALETITLE,
  textAlign: "center",
  fontSize: "40px",
}));

export const VehicleMyBoxContents = styled(Grid)(() => ({
  margin: "10px 0px",
}));

export const VehicleMyBoxTitle = styled(Grid)(() => ({
  color: GRAYPALETITLE,
  textAlign: "center",
}));

export const VehicleMyBoxContent = styled(Grid)(() => ({
  color: GRAYPALECONTENTS,
  textAlign: "right",
  paddingRight: "10px",
}));

export const VehicleMyBoxAlign = styled(Grid)(() => ({
  height: "100%",
}));

export const VehicleMyBoxPointer = styled(Grid)(() => ({
  cursor: "pointer",
  width: "30px",
  height: "30px",
  borderRadius: "30px",
  "&:hover": { color: PRIMARY, backgroundColor: REDBACKGROUND },
}));

// VehicleGuideBox
export const VehicleGuideContentsBox = styled("div")(() => ({
  borderRadius: "8px",
  border: "1px solid #EDEDED",
  width: "100%",
  height: "140px",
  padding: "25px 10px 15px 10px",
  cursor: "pointer",
}));

export const VehicleGuideContentBox = styled(Grid)(() => ({
  width: "49%",
}));

export const VehicleGuideManual = styled("img")(() => ({
  height: "70px",
}));

export const VehicleGuideContentsAlign = styled("div")(() => ({
  marginTop: "10px",
}));

export const VehicleGuideTitle = styled("div")(() => ({
  color: PRIMARY,
  fontWeight: 700,
  width: "100%",
  overflow: "hidden",
  textOverflow: "elllipsis",
  whiteSpace: "nowrap",
}));

// VehicleReservation
interface vehicleReserveProps {
  viewheight: number;
}

export const VehicleReserveTitleBoxDisabled = styled(Grid)(() => ({
  borderBottom: "1px solid #EDEDED",
  borderTop: "1px solid #EDEDED",
  backgroundColor: GRAYPALEBACKGROUND2,
  marginBottom: "10px",
  color: GRAYPALETITLE,
  minHeight: "60px",
  padding: "13px 20px",
  fontWeight: 700,
}));

export const VehicleReserveTitleBox = styled(Grid)(() => ({
  borderBottom: "1px solid #EDEDED",
  borderTop: "1px solid #EDEDED",
  backgroundColor: GRAYPALEBACKGROUND2,
  marginBottom: "10px",
  color: GRAYPALETITLE,
  minHeight: "60px",
  padding: "13px 20px",
  fontWeight: 700,
  cursor: "pointer",
}));

export const VehicleReserveButton = styled(Grid)(() => ({
  cursor: "pointer",
}));

export const VehicleReserveContentBox = styled(Grid)(
  ({ viewheight }: vehicleReserveProps) => ({
    maxHeight: `${viewheight}px`,
    minHeight: "70px",
    overflow: "scroll",
  })
);

export const VehicleReserveDestinationAlign = styled(Grid)(() => ({
  marginTop: "20px",
}));

export const VehicleReserveBigBox = styled(Grid)(
  ({ viewheight }: vehicleReserveProps) => ({
    height: `${viewheight * 0.7 - 80}px`,
    overflow: "scroll",
  })
);

export const VehicleReserveMobileBigBox = styled(Grid)(
  ({ viewheight }: vehicleReserveProps) => ({
    height: `${viewheight - 100}px`,
    overflow: "scroll",
  })
);

export const VehicleReserveCarSearch = styled(Grid)(() => ({
  width: "calc(100% - 50px)",
}));

export const VehicleReserveCarSearchBox = styled(Grid)(() => ({
  borderTop: "1px solid #EDEDED",
  padding: "10px 0px",
}));

export const VehicleReserveCarSearchBoxContent = styled(Grid)(() => ({
  padding: "10px 5px",
  textAlign: "center",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const VehicleReserveCarSelectDropdownBox = styled(Grid)(() => ({
  marginTop: "7px",
  paddingRight: "5px",
}));

export const VehicleReserveUnvailableBox = styled(Grid)(() => ({
  margin: "20px 0px",
  textAlign: "center",
}));

export const VehicleReserveValidationBox = styled(Grid)(() => ({
  marginLeft: "20px",
}));

export const VehicleReserveDrivingAlign = styled(Grid)(() => ({
  marginTop: "10px",
}));
