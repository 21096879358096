//React
import { NavLink, useLocation } from "react-router-dom";

//MUI
import { Toolbar, Drawer, Divider, Grid } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

//user component

import { CursorPointer, IconColor } from "../../styles/theme";
import {
  HeaderBarStyle,
  HeaderStyle,
  ImgAlign,
  NavProfile,
  Title,
  DrawerContent,
  DrawerTitle,
} from "../../styles/headerStyle";
import { useHistory } from "react-router-dom";

// phosper-icons
import { CaretLeft, CaretRight, List, UserCircle } from "@phosphor-icons/react";
import { MypageRoutes } from "../../system/types/type";
import { useState } from "react";
import {
  RESERVATION_TYPE,
  RESERVATION_TYPE_BRANCH,
} from "../../system/Constants";
import { Mobile, Pc } from "./ResponsiveScreen";
import { CustomText } from "../../styles/CustomText";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  BranchEmployeeCheck,
  BranchEmployeeDataAtom,
  MyDataAtom,
  MypageTabValue,
} from "../../system/atoms";
import { useMsal } from "@azure/msal-react";

const drawerWidth = "100%";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: "none",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

function Header() {
  const locationNow = useLocation();
  const history = useHistory();
  const [, setIsListHover] = useState(false);
  const myData = useRecoilValue(MyDataAtom);
  const [, setTabValue] = useRecoilState(MypageTabValue);
  // drawer
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // 휴양소 버튼

  const onMoveSite = (route: string) => {
    if (
      locationNow.pathname.includes("/vehicle") ||
      locationNow.pathname.includes("/availableVehicle")
    ) {
      setTabValue(1);
    } else {
      setTabValue(0);
    }
    history.push({
      pathname: `${route}`,
    });
    setOpen(false);
  };

  const [photoData, setPhotoData]: any = useState("");
  const { instance } = useMsal();
  function signOutClickHandler() {
    instance.logout();
    window.localStorage.clear();
  }

  function changeAccount() {
    window.localStorage.clear();
    window.location.reload();
  }

  // useEffect(() => {
  //   let token = sessionStorage.getItem("token") || "";
  //   axios
  //     .get<Blob>("https://graph.microsoft.com/v1.0/me/photo/$value", {
  //       headers: { Authorization: token },
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       const myFile = new File([res.data], "imageName");
  //       const reader = new FileReader();
  //       reader.onload = (ev) => {
  //         const previewImage = String(ev.target?.result);
  //         setPhotoData(previewImage);
  //       };
  //       reader.readAsDataURL(myFile);
  //     });
  // }, []);

  const branchEmployee = useRecoilValue(BranchEmployeeDataAtom);
  const branchEmployeeCheck = useRecoilValue(BranchEmployeeCheck);
  const reservationType = branchEmployeeCheck
    ? RESERVATION_TYPE_BRANCH
    : RESERVATION_TYPE;

  return (
    <HeaderStyle
      onMouseOver={() => setIsListHover(true)}
      onMouseOut={() => setIsListHover(false)}
    >
      <Pc>
        <HeaderBarStyle position="fixed">
          <Toolbar>
            <NavLink to="/">
              <ImgAlign
                src="/images/inbody_logo.png"
                width="100px"
                alt="title"
              ></ImgAlign>
            </NavLink>

            {locationNow.pathname === "/" ? null : (
              <>
                {reservationType.map((item, index) => {
                  return (
                    <div key={index}>
                      <Title onClick={() => {}}>
                        <Grid>{item.text}</Grid>
                      </Title>
                      {item.child?.map((childItem, idx) => {
                        return <div key={childItem.text}></div>;
                      })}
                    </div>
                  );
                })}

                <NavProfile id="profile">
                  <Grid container alignItems="center">
                    <CustomText
                      type="title"
                      marginRight="5px"
                      bold400
                      color="#4B4F5A"
                    >
                      {branchEmployee.email
                        ? branchEmployee.email
                        : myData.name}{" "}
                      님
                    </CustomText>
                    {photoData ? (
                      <div>
                        <img
                          src={photoData}
                          alt="프로필 사진"
                          className="profilephoto"
                        />
                      </div>
                    ) : (
                      <IconColor>
                        <UserCircle size={40} />
                      </IconColor>
                    )}
                  </Grid>
                </NavProfile>
              </>
            )}
          </Toolbar>
        </HeaderBarStyle>
      </Pc>
      <Mobile>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <NavLink to="/">
              <img
                src="/images/inbody_logo.png"
                width="100px"
                alt="title"
              ></img>
            </NavLink>
            <NavProfile>
              <IconColor>
                <List size={40} onClick={handleDrawerOpen}></List>
              </IconColor>
            </NavProfile>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <CursorPointer onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <IconColor>
                  <CaretLeft size={40} />
                </IconColor>
              ) : (
                <IconColor>
                  <CaretRight size={40} />
                </IconColor>
              )}
            </CursorPointer>
            <CustomText type="subtitle">
              {" "}
              {branchEmployee.email ? branchEmployee.email : myData.name} 님
            </CustomText>
          </DrawerHeader>
          {reservationType.map((item, index) => {
            return (
              <div key={index}>
                <Divider />
                <DrawerTitle>{item.text}</DrawerTitle>
                {item.child?.map((childItem, idx) => {
                  return (
                    <DrawerContent
                      key={childItem.text}
                      onClick={() => onMoveSite(childItem.routes)}
                    >
                      {childItem.text}
                    </DrawerContent>
                  );
                })}
              </div>
            );
          })}
          <Divider />
          <DrawerTitle>내 정보</DrawerTitle>
          <DrawerContent onClick={() => onMoveSite(`${MypageRoutes.root}`)}>
            나의 예약
          </DrawerContent>
          <DrawerContent onClick={changeAccount}>계정 변경</DrawerContent>
          <DrawerContent onClick={signOutClickHandler}>로그아웃</DrawerContent>
        </Drawer>
      </Mobile>
      <div></div>
    </HeaderStyle>
  );
}

export default Header;
