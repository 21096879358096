import { Box, Grid, IconButton, Input, Modal, Tooltip } from "@mui/material";
import {
  AdminTabBox,
  AppliedItem,
  BoardDropdownRight,
  DisabledItem,
  MainTitle,
  MaxWidth,
  ModalStyle,
  TypeGap,
} from "../../styles/theme";
import ReminderHeader from "./ReminderHeader";
import { Fragment, useEffect, useState } from "react";
import {
  Mobile,
  MobileSmall,
  Pc,
  Tablet,
} from "../../pages/Layout/ResponsiveScreen";
import { ListBullets, SquaresFour } from "@phosphor-icons/react";
import {
  MobileMainTitle,
  MobileSmallContentBox,
  RecentReminderSearchContent,
  ReminderHeaderInfoBox2,
  ReminderInfoContents,
  ReminderInfoMobileContents,
  ReminderPagination,
  ReminderSubHeaderAlign,
  TooltipText,
} from "../../styles/reminderStyle";
import { CustomButtonGreen } from "../../styles/button";
import { ReminderData } from "../../system/types/Reminder";
import { ReminderApi } from "../../system/api/ReminderApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import ReminderTable from "./ReminderTable";
import ReminderCard from "./ReminderCard";
import ReminderModal from "./ReminderModal";
import { EmployeeApi } from "../../system/api/EmployeeApi";
import { useLoadingDispatch } from "../../system/context/LoadingContext";
import { MyDataAtom, ReminderRoleAtom } from "../../system/atoms";
import { useRecoilState } from "recoil";
import Pagination from "@mui/material/Pagination";
import { InitReminderData } from "../../system/types/initObject";
import SearchIcon from "@mui/icons-material/Search";

function Reminder() {
  const [cardType, setCardType] = useState(false);
  const [searchType, setSearchType] = useState("my");
  const onChangeCardType = () => {
    setCardType(!cardType);
  };
  const Loading = useLoadingDispatch();
  const [data, setData] = useState<ReminderData[]>([]);
  const [headerData, setHeaderData] = useState<ReminderData>(InitReminderData);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [myData, setMyData] = useRecoilState(MyDataAtom);
  const onOpenModal = () => {
    handleOpen();
  };
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [, setReminderRoleCheck] = useRecoilState(ReminderRoleAtom);

  function refreshData() {
    Loading({ type: "LOADING" });
    ReminderApi.getReminder(
      searchType,
      page,
      6,
      "dday,desc",
      startDate,
      endDate,
      searchWord
    )
      .then((res) => {
        setData(res.data.reminds);
        setTotalPage(res.data.totalPages);
        if (
          res.data.reminds.length > 0 &&
          headerData.title === "" &&
          searchType === "my"
        ) {
          setHeaderData(res.data.reminds[0]);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  }

  useEffect(() => {
    if (myData.id === 0) {
      EmployeeApi.getmyInfo()
        .then((res) => {
          setMyData(res.data);
          if (
            res.data.jobTitle === "파트장" ||
            res.data.jobPosition === "회장" ||
            res.data.jobPosition === "사장" ||
            res.data.jobPosition === "부사장" ||
            res.data.jobPosition === "상무이사"
          ) {
            setReminderRoleCheck(true);
          }
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, []);

  useEffect(() => {
    refreshData();
  }, [page, searchWord, startDate, endDate, searchType]);

  const onChangePaging = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  // searchword
  const [inputWord, setInputWord] = useState("");

  const onChangeSearchWord = (args: any) => {
    setInputWord(args.target.value);
  };

  const onClickSearch = () => {
    setSearchWord(inputWord);
  };

  const handleOnKeyPress = (e: any) => {
    if (e.key === "Enter") {
      onClickSearch();
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MaxWidth>
            <Tablet>
              <MainTitle>
                리마인더
                <ReminderInfoContents>
                  향후 정기적으로 수행해야 할 일정 등록 및 알림 서비스
                </ReminderInfoContents>
              </MainTitle>
            </Tablet>
            <MobileSmall>
              <MobileMainTitle>리마인더</MobileMainTitle>
              <ReminderInfoMobileContents>
                향후 정기적으로 수행해야 할 일정 등록 및 알림 서비스
              </ReminderInfoMobileContents>
            </MobileSmall>
            <AdminTabBox></AdminTabBox>
            <ReminderHeader
              searchType={searchType}
              setSearchType={setSearchType}
              setSearchWord={setSearchWord}
              headerData={headerData}
              setPage={setPage}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            ></ReminderHeader>

            <Pc>
              <TypeGap>
                <ReminderSubHeaderAlign>
                  {cardType ? (
                    <>
                      <DisabledItem onClick={onChangeCardType}>
                        <ListBullets size={32} />{" "}
                      </DisabledItem>
                      <AppliedItem onClick={onChangeCardType}>
                        {" "}
                        <SquaresFour size={32} />
                      </AppliedItem>
                    </>
                  ) : (
                    <>
                      {" "}
                      <AppliedItem onClick={onChangeCardType}>
                        <ListBullets size={32} />{" "}
                      </AppliedItem>
                      <DisabledItem onClick={onChangeCardType}>
                        {" "}
                        <SquaresFour size={32} />
                      </DisabledItem>
                    </>
                  )}
                </ReminderSubHeaderAlign>
                <div style={{ display: "flex" }}>
                  <ReminderHeaderInfoBox2>
                    <RecentReminderSearchContent>
                      <Input
                        placeholder="업무명, (부)담당자, (부)관리자"
                        sx={{
                          width: "220px",
                          borderBottom: "1px solid #DCDFE3",
                        }}
                        onChange={onChangeSearchWord}
                        onKeyDown={handleOnKeyPress}
                      />

                      <div>
                        <Tooltip
                          title={
                            <Fragment>
                              <TooltipText>검색</TooltipText>
                            </Fragment>
                          }
                        >
                          <IconButton onClick={onClickSearch}>
                            <SearchIcon style={{ marginTop: "5px" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </RecentReminderSearchContent>
                  </ReminderHeaderInfoBox2>
                  <CustomButtonGreen onClick={onOpenModal}>
                    신규 등록
                  </CustomButtonGreen>
                </div>
              </TypeGap>
              {cardType ? (
                <ReminderCard data={data} refreshData={refreshData} />
              ) : (
                <ReminderTable data={data} refreshData={refreshData} />
              )}
            </Pc>
            <Mobile>
              <MobileSmallContentBox>
                <BoardDropdownRight>
                  <CustomButtonGreen onClick={onOpenModal}>
                    신규 등록
                  </CustomButtonGreen>
                </BoardDropdownRight>
              </MobileSmallContentBox>
              <ReminderCard data={data} refreshData={refreshData} />
            </Mobile>

            <ReminderPagination>
              <Pagination
                count={totalPage}
                variant="outlined"
                page={page + 1}
                showFirstButton
                showLastButton
                onChange={onChangePaging}
                style={{ textAlign: "center" }}
              />
            </ReminderPagination>
          </MaxWidth>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <ReminderModal
            handleClose={handleClose}
            refreshData={refreshData}
            detailedData={InitReminderData}
          />
        </Box>
      </Modal>
    </>
  );
}

export default Reminder;
