import { Divider, Grid } from "@mui/material";
import {
  AdminTabBox2,
  MainTitle,
  MaxWidth,
  ThickTextSpan,
} from "../../styles/theme";
import {
  AnswerBox,
  EntranceCheckImage,
  EntranceImage,
  QuestionBox,
  QuestionMark,
} from "../../styles/faqStyle";

function Faq() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MaxWidth>
          <MainTitle>휴양소 예약 가이드</MainTitle>
          <AdminTabBox2></AdminTabBox2>
          <Grid item xs={12}>
            <QuestionBox>
              <QuestionMark>Q.</QuestionMark>
              <MainTitle>휴양소 예약은 몇일 단위로 신청 가능한가요?</MainTitle>
            </QuestionBox>
            <AnswerBox>
              <QuestionMark>A.</QuestionMark>
              휴양소 예약 신청은 1박 2일 또는 2박 3일을 기본 신청 단위로 합니다.
              <br />그 이상의 기간은 예약이 불가합니다.
            </AnswerBox>
            <Divider />
            <QuestionBox>
              <QuestionMark>Q.</QuestionMark>
              <MainTitle>휴양소에 대한 정보는 어떻게 얻을 수 있나요?</MainTitle>
            </QuestionBox>
            <AnswerBox>
              <QuestionMark>A.</QuestionMark>
              <div>
                예약 전 필요한 휴양소 정보(주소, 사진, 구조, 구비품목, 주의사항
                등)는 휴양소 예약 페이지에서 보실 수 있습니다.
                <br />
                휴양소 <ThickTextSpan>입실에 필요한 정보</ThickTextSpan>는
                휴양소{" "}
                <ThickTextSpan>입실 시작일 하루 전 오전 09시</ThickTextSpan>에{" "}
                <ThickTextSpan>문자</ThickTextSpan>로 발송됩니다.
                <br /> 휴양소 <ThickTextSpan>퇴실에 필요한 정보</ThickTextSpan>
                는 휴양소 <ThickTextSpan> 퇴실 당일 오전 07시</ThickTextSpan>에{" "}
                <ThickTextSpan>문자</ThickTextSpan>로 발송됩니다.
              </div>
            </AnswerBox>
            <Divider />
            <QuestionBox>
              <QuestionMark>Q.</QuestionMark>
              <MainTitle>휴양소 입실, 퇴실 시간은 몇 시 인가요?</MainTitle>
            </QuestionBox>
            <AnswerBox>
              <QuestionMark>A.</QuestionMark>
              <div>
                입실은 신청한 시작일 기준 14시, 퇴실은 신청한 종료일 기준 12시
                입니다.
                <br />
                <br />
                [예시]
                <br />
                <ThickTextSpan>예약 기간</ThickTextSpan> - 2023-07-13 ~
                2023-07-15
                <br />
                <ThickTextSpan>입실 시간</ThickTextSpan> - 7월 13일{" "}
                <ThickTextSpan>14시 이후</ThickTextSpan>
                <br />
                <ThickTextSpan>퇴실 시간</ThickTextSpan> - 7월 15일{" "}
                <ThickTextSpan>12시 이전</ThickTextSpan>
              </div>
            </AnswerBox>
            <Divider />
            <QuestionBox>
              <QuestionMark>Q.</QuestionMark>
              <MainTitle> 휴양소 예약 신청은 언제까지 가능한가요?</MainTitle>
            </QuestionBox>
            <AnswerBox>
              <QuestionMark>A.</QuestionMark>
              <div>
                {" "}
                예약은 2가지로 나뉩니다.
                <br />
                1. 선착순 예약
                <br />
                - 오늘 이후이며 추첨 예약 기간이 아닌 기간을 말합니다.
                <br />
                2. 추첨 예약
                <br />
                - 추첨 켈린더에 예약 가능한 마지막 수요일부터 차주 화요일까지를
                의미합니다.
                <br />
                <br />
                [예시]
                <br />
                <ThickTextSpan>2023-07-12 수요일 10시 이전</ThickTextSpan>
                <br />
                1. 선착순 예약 기간 : 2023-07-13 ~ 2023-08-01
                <br />
                2. 추첨 예약 기간 : 2023-08-02 ~ 2023-08-08
                <br /> <br />
                <ThickTextSpan>2023-07-12 수요일 10시 이후</ThickTextSpan>
                <br />
                1.선착순 예약 기간 : 2023-07-13 ~ 2023-08-08 <br />
                2. 추첨 예약 기간 : 2023-08-09 ~ 2023-08-15
                <br /> <br />
                <ThickTextSpan>2023-07-13 목요일 기준</ThickTextSpan>
                <br />
                1. 선착순 예약 기간 : 2023-07-14 ~ 2023-08-08
                <br />
                2. 추첨 예약 기간 : 2023-08-09 ~ 2023-08-15{" "}
              </div>
            </AnswerBox>
            <Divider />
            <QuestionBox>
              <QuestionMark>Q.</QuestionMark>
              <MainTitle>휴양소 예약은 언제 확정이 되나요?</MainTitle>
            </QuestionBox>
            <AnswerBox>
              <QuestionMark>A.</QuestionMark>
              <div>
                {" "}
                1. 선착순 예약의 경우 : 예약 즉시 확정
                <br />
                2. 추첨 예약의 경우 : 사이트 예약 켈린더 페이지 우측 상단에
                표시된
                <ThickTextSpan> 추첨 예약 발표일 이후</ThickTextSpan>{" "}
              </div>
            </AnswerBox>
            <Divider />

            <QuestionBox>
              <QuestionMark>Q.</QuestionMark>
              <MainTitle>
                추첨 이후 예약이 없는 휴양소에 대한 예약은 어떻게 신청하나요?
              </MainTitle>
            </QuestionBox>
            <AnswerBox>
              <QuestionMark>A.</QuestionMark>
              <div>
                {" "}
                추첨에 예약자가 없는 휴양소는 10시 5분 이후부터 선착순으로 신청
                방식이 전환됩니다. (매주 수요일 10시 ~ 10시 4분 59초까지 신청
                불가){" "}
              </div>
            </AnswerBox>
            <Divider />
            <QuestionBox>
              <QuestionMark>Q.</QuestionMark>
              <MainTitle>추첨은 어떤 방식으로 진행되나요?</MainTitle>
            </QuestionBox>
            <AnswerBox>
              <QuestionMark>A.</QuestionMark>
              <div>
                {" "}
                휴양소는 여러명이 동일 장소로 동일 일자에 신청한 경우 추첨제로
                진행합니다.
                <br />
                추첨제를 적용한 이유는 최대한 많은 임직원들이 휴양소를 이용할 수
                있도록 하기 위한 장치입니다.{" "}
              </div>
            </AnswerBox>
            <Divider />
            <QuestionBox>
              <QuestionMark>Q.</QuestionMark>
              <MainTitle> 입실, 퇴실 절차는 어떻게 진행되나요?</MainTitle>
            </QuestionBox>
            <AnswerBox>
              <QuestionMark>A.</QuestionMark>
              <div>
                [입실 절차]
                <br />
                1. 예약 확정 이후{" "}
                <ThickTextSpan>나의 예약 페이지</ThickTextSpan>
                에서 활성화된
                <ThickTextSpan> 입실 버튼</ThickTextSpan>을 클릭해주세요.
                <br />
                <EntranceImage src="/images/FAQ/입실버튼.png" />
                <br />
                2. 입실 버튼을 클릭하시면
                <ThickTextSpan> 휴양소 입실 시 확인 사항</ThickTextSpan> 목록을
                보실 수 있습니다. 아래 목록을 모두 확인하신 후{" "}
                <ThickTextSpan>다음으로</ThickTextSpan> 버튼을 눌러주세요.
                <br />{" "}
                <EntranceCheckImage src="/images/FAQ/휴양소_입실_시_확인_사항_활성화.png" />
                <br />
                3. <ThickTextSpan>다음으로</ThickTextSpan> 버튼을 클릭 하시면{" "}
                <ThickTextSpan>입실 이전 휴양소 상태 보고서</ThickTextSpan>
                를 작성하실 수 있고 저장 버튼을 눌러 제출하시면 입실 완료
                처리됩니다.
                <br />
                ※ 입실 시작일 전에 보고서 제출은 막혀있으니 반드시 입실 후 작성
                부탁드립니다. [아래 오른쪽 그림 참고]
                <br />{" "}
                <EntranceCheckImage src="/images/FAQ/입실_이전_휴양소_상태.png" />
                <EntranceCheckImage src="/images/FAQ/입실_경고문.png" />
                <br />
                <br />
                [퇴실 절차]
                <br />
                1. 입실 이후 <ThickTextSpan>나의 예약 페이지</ThickTextSpan>
                에서 활성화된 <ThickTextSpan>퇴실 버튼</ThickTextSpan>을
                클릭해주세요.
                <br />
                <EntranceImage src="/images/FAQ/퇴실.png" />
                <br />
                2. 퇴실 버튼을 클릭하시면{" "}
                <ThickTextSpan>휴양소 퇴실 시 확인 사항</ThickTextSpan> 목록을
                보실 수 있습니다. 아래 목록을 모두 확인하신 후{" "}
                <ThickTextSpan>다음으로</ThickTextSpan> 버튼을 눌러주세요.
                <br />{" "}
                <EntranceCheckImage src="/images/FAQ/퇴실_시_확인_사항_활성화.png" />
                <br />
                3. <ThickTextSpan>다음으로</ThickTextSpan> 버튼을 클릭하시면{" "}
                <ThickTextSpan>사용 후 보고</ThickTextSpan>를 작성하실 수 있고
                저장 버튼을 눌러 제출하시면 퇴실 완료 처리됩니다.
                <br />
                ※ 사용 후 보고는 반드시 휴양소 사용 후 청소 상태가 담긴 사진을
                1장 이상 등록해주셔야 합니다. [아래 오른쪽 그림 참고]
                <br />
                <EntranceCheckImage src="/images/FAQ/사용후보고_사진.png" />
                <EntranceCheckImage src="/images/FAQ/사용후보고_경고창.png" />
              </div>
            </AnswerBox>
            <Divider />
            <QuestionBox>
              <QuestionMark>Q.</QuestionMark>
              <MainTitle>
                {" "}
                인바디 예약 서비스 사이트에 관한 기술적인 문의는 어디로 하면
                되나요?
              </MainTitle>
            </QuestionBox>
            <AnswerBox>
              <QuestionMark>A.</QuestionMark>
              <div>
                인바디 예약 서비스를 이용하며 기술적인 문의가 생기셨다면
                인프라개발파트 윤기현, 하지애 사원에게 문의해주시면 됩니다.
              </div>
            </AnswerBox>

            <Divider />
          </Grid>
        </MaxWidth>
      </Grid>
    </Grid>
  );
}

export default Faq;
