import { Grid } from "@mui/material";
import { NoticeData } from "../../../system/types/Notice";
import { CustomText } from "../../../styles/CustomText";
import styled from "@emotion/styled";
import { CustomButtonGray, CustomButtonYellow } from "../../../styles/button";
import { VehicleNoticeIcon } from "../../../styles/vehicleStyle";
import HtmlContent from "../../Common/HtmlContext";
import { useRecoilValue } from "recoil";
import { RoleCheckAtom } from "../../../system/atoms";

interface VehicleNoticeDetailProps {
  data: NoticeData;
  setNoticeState: (noticeState: string) => void;
}

function VehicleNoticeDetail({
  data,
  setNoticeState,
}: VehicleNoticeDetailProps) {
  const todayDate = new Date();
  const onChangeState = (state: string) => {
    setNoticeState(state);
  };
  const rolecheck = useRecoilValue(RoleCheckAtom);

  return (
    <StyledNoticeBox container mt="20px">
      <StyledNoticeTable container alignItems="center">
        <CustomText type="subtitle" width="120px">
          제목
        </CustomText>
        {data.mainStartDate &&
          new Date(data.mainStartDate) < todayDate &&
          (data.mainEndDate === null ||
            new Date(data.mainEndDate) > todayDate) && (
            <VehicleNoticeIcon
              src="/images/VEHICLE/mainSpeaker.png"
              alt="주요 공지사항"
              style={{ marginBottom: "-5px" }}
            />
          )}

        <Grid> {data.title}</Grid>
      </StyledNoticeTable>
      <StyledNoticeTable container justifyContent="space-between">
        <Grid container item xs={12} sm={6}>
          <CustomText type="subtitle" width="120px">
            작성자
          </CustomText>
          {data.createBy}
        </Grid>
        <Grid container item xs={12} sm={6}>
          <CustomText type="subtitle" width="120px">
            작성일
          </CustomText>
          {data.createdAt}
        </Grid>
      </StyledNoticeTable>
      {rolecheck && (
        <StyledNoticeTable container justifyContent="space-between">
          <Grid container item xs={12} sm={6}>
            <CustomText type="subtitle" width="120px">
              주요 공지 시작
            </CustomText>
            {data.mainStartDate ? data.mainStartDate : "해당 사항 없음"}
          </Grid>
          <Grid container item xs={12} sm={6}>
            <CustomText type="subtitle" width="120px">
              주요 공지 종료
            </CustomText>
            {data.mainEndDate ? data.mainEndDate : "해당 사항 없음"}
          </Grid>
        </StyledNoticeTable>
      )}
      <StyledNoticeTable container>
        <CustomText type="subtitle" width="120px">
          내용
        </CustomText>
        <StyledTextBox>
          <HtmlContent
            data={data.content ? data.content : "작성된 내용이 없습니다."}
          ></HtmlContent>
        </StyledTextBox>
      </StyledNoticeTable>
      <Grid container justifyContent="center" mt="10px">
        {rolecheck && (
          <CustomButtonYellow
            onClick={() => {
              onChangeState("edit");
            }}
          >
            수정
          </CustomButtonYellow>
        )}
        <CustomButtonGray
          onClick={() => {
            onChangeState("table");
          }}
        >
          목록으로
        </CustomButtonGray>
      </Grid>
    </StyledNoticeBox>
  );
}

export default VehicleNoticeDetail;

const StyledNoticeBox = styled(Grid)({
  borderTop: "1px solid #DCDFE3",
});

const StyledNoticeTable = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  padding: "10px 5px",
});

const StyledTextBox = styled(Grid)({
  minHeight: "300px",
});
