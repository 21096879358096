import { useState } from "react";
import {
  CustomButtonDisabled,
  CustomButtonGreen,
  CustomButtonRed,
  CustomButtonYellow,
} from "../../../styles/button";
import { MyReservationVehicleData } from "../../../system/types/Reservation";
import {
  VehicleDrivingInfo,
  VehicleEditInfo,
  VehicleReservationTime,
} from "../../../system/types/Vehicle";
import {
  InitVehicleDrivingInfo,
  InitVehicleEditInfo,
  InitVehicleReservationTime,
} from "../../../system/types/initObject";
import {
  Box,
  Grid,
  IconButton,
  Input,
  Modal,
  Tooltip,
  styled,
} from "@mui/material";
import {
  AppliedItem,
  MainTitle,
  RaffleContent,
  SmallModalStyle,
} from "../../../styles/theme";
import { X } from "@phosphor-icons/react";
import { VehicleDrivingBoxModalAlign } from "../../../styles/vehicleStyle";
import { CustomText } from "../../../styles/CustomText";
import {
  GRAYPALECONTENTS,
  GREENCONTENTS,
  PRIMARY,
  REDTITLE,
} from "../../../styles/color";
import { VehicleApi } from "../../../system/api/VehicleApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import {
  isoDateFormatter,
  isoHourFormatter,
} from "../../Common/IsoDateFormatter";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import Swal from "sweetalert2";
interface MyCarButtonProps {
  data: MyReservationVehicleData;
  refreshReservationData: () => void;
}

function validation(
  data: VehicleEditInfo,
  departmentTime: string,
  arrivalTime: string
) {
  if (!data.realStartTime) {
    AlertModal("check", "출발 시 시간을 작성해주세요.");
    return false;
  } else if (
    new Date(departmentTime) &&
    new Date(arrivalTime) &&
    new Date(departmentTime) >= new Date(arrivalTime)
  ) {
    AlertModal("check", "출발 시간이 도착 시간보다 늦을 수는 없습니다.");
    return false;
  } else if (
    data.startMileage >= 0 &&
    data.endMileage &&
    data.startMileage > data.endMileage
  ) {
    AlertModal(
      "check",
      "출발 시 누적 주행 거리(시간)가 <br> 도착 시 누적 주행 거리(시간)보다 클 수는 없습니다."
    );
    return false;
  }
  return true;
}

function MyCarButton({ data, refreshReservationData }: MyCarButtonProps) {
  const [drivingModalOpen, setDrivingModalOpen] = useState(false);
  const handleDrivingModalClose = () => setDrivingModalOpen(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setEditData(InitVehicleEditInfo);
  };
  const [vehicleUnitType, setVehicleUnitType] = useState("");
  const [drivingData, setDrivingData] = useState<VehicleDrivingInfo>(
    InitVehicleDrivingInfo
  );
  const [editData, setEditData] =
    useState<VehicleEditInfo>(InitVehicleEditInfo);
  const now = new Date();
  const openDrivingModal = (
    status: string,
    id: number,
    mileage: number,
    unitType: string
  ) => {
    setDrivingModalOpen(true);
    setDrivingData({
      ...drivingData,
      reservationId: id,
      isStart: status === "예약중" ? true : false,
      mileage: mileage,
    });
    setVehicleUnitType(unitType);
  };

  const openEditModal = () => {
    setEditModalOpen(true);
    setEditData({
      startMileage: data.startMileage,
      endMileage: data.endMileage ? data.endMileage : null,
      realStartTime:
        isoDateFormatter(new Date(data.startDate)) +
        " " +
        isoHourFormatter(new Date(data.realStartTime)),
      realEndTime: data.realEndTime
        ? isoDateFormatter(new Date(data.startDate)) +
          " " +
          isoHourFormatter(new Date(data.realEndTime))
        : null,
    });
    setDepartmentTime(convertToAmPm(new Date(data.realStartTime)));
    setArrivalTime(
      data.realEndTime
        ? convertToAmPm(new Date(data.realEndTime))
        : InitVehicleReservationTime
    );
  };

  function onSave() {
    VehicleApi.updateDrivingVehicle(drivingData)
      .then(() => {
        refreshReservationData();
        handleDrivingModalClose();
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }
  const removeCommas = (str: string) => {
    return str.replace(/,/g, "");
  };

  const formatNumber = (num: number) => {
    return num ? num.toLocaleString() : null;
  };

  const onChangeDistance = (args: any) => {
    const newValue = parseFloat(removeCommas(args.target.value));
    setDrivingData({
      ...drivingData,
      mileage: newValue,
    });
  };

  const onChangeMileage = (args: any) => {
    const numericValue = parseFloat(removeCommas(args.target.value));
    setEditData({
      ...editData,
      [(args.target as HTMLInputElement).name]: numericValue,
    });
  };

  var submitcheck = false;
  function onSaveEdit() {
    if (
      submitcheck === false &&
      validation(
        editData,
        convertToTime(departmentTime),
        convertToTime(arrivalTime)
      )
    ) {
      submitcheck = true;
      VehicleApi.updateVehicleReservation(data.id, {
        ...editData,
        realStartTime: convertToTime(departmentTime),
        realEndTime: data.realEndTime ? convertToTime(arrivalTime) : null,
      })
        .then(() => {
          AlertModal("success", "차량 내역 수정이 완료되었습니다.");
          handleEditModalClose();
          refreshReservationData();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          submitcheck = false;
        });
    }
  }
  // TIME
  const [departmentTime, setDepartmentTime] = useState<VehicleReservationTime>(
    InitVehicleReservationTime
  );
  const [arrivalTime, setArrivalTime] = useState<VehicleReservationTime>(
    InitVehicleReservationTime
  );
  const AMPMTYPE = ["오전", "오후"];
  const HOURTYPE = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );
  const MINUTETYPE = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const onChangeDepartmentTime = (args: any) => {
    setDepartmentTime({
      ...departmentTime,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeArrivalTime = (args: any) => {
    setArrivalTime({
      ...arrivalTime,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const convertToTime = (dt: VehicleReservationTime) => {
    // 시간과 분을 숫자로 변환
    let hours = parseInt(dt.hour, 10);
    const minutes = parseInt(dt.minute, 10);

    // '오전'/'오후'에 따라 24시간 형식으로 변환
    if (dt.AmPm === "오전" && hours === 12) {
      hours = 0; // 오전 12시는 0시
    } else if (dt.AmPm === "오후" && hours < 12) {
      hours += 12; // 오후는 12를 더해줌
    }

    // 새로운 Date 객체 생성
    const date = new Date(
      new Date(data.startDate).getFullYear(),
      new Date(data.startDate).getMonth(),
      new Date(data.startDate).getDate(),
      hours,
      minutes
    );
    return isoDateFormatter(date) + " " + isoHourFormatter(date);
  };
  const convertToAmPm = (date: Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const AmPm = hours >= 12 ? "오후" : "오전";

    // 24시간 형식을 12시간 형식으로 변환
    hours = hours % 12 || 12; // 0은 12로, 13~23은 1~11로 변환
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return {
      AmPm,
      hour: formattedHours,
      minute: formattedMinutes,
    };
  };

  const cancelReservation = (id: number) => {
    Swal.fire({
      title: "정말 취소하시겠습니까?",
      text: "선택하신 차량 예약이 취소됩니다.",
      icon: "question",
      confirmButtonText: "예",
      cancelButtonText: "아니오",
      showCancelButton: true,
      confirmButtonColor: GREENCONTENTS,
      cancelButtonColor: REDTITLE,
    }).then((result) => {
      if (result.isConfirmed) {
        VehicleApi.deleteVehicleReservation(id)
          .then(() => {
            AlertModal("success", "예약이 취소되었습니다.");
            refreshReservationData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    });
  };

  return (
    <>
      {data.status === "예약중" && (
        <>
          {now >= new Date(data.startDate) && (
            <CustomButtonGreen
              onClick={() =>
                openDrivingModal(
                  data.status,
                  data.id,
                  data.vehicle.totalMileage,
                  data.vehicle.unitType
                )
              }
            >
              이용 시작
            </CustomButtonGreen>
          )}
          <CustomButtonRed onClick={() => cancelReservation(data.id)}>
            예약 취소
          </CustomButtonRed>
        </>
      )}
      {data.status === "이용중" && (
        <CustomButtonRed
          onClick={() =>
            openDrivingModal(
              data.status,
              data.id,
              data.startMileage,
              data.vehicle.unitType
            )
          }
        >
          이용 완료
        </CustomButtonRed>
      )}
      {(data.status === "이용중" || data.status === "이용완료") && (
        <>
          {now >= new Date(data.startDate) &&
          now <=
            new Date(
              new Date(data.startDate).setDate(
                new Date(data.startDate).getDate() + 7
              )
            ) ? (
            <CustomButtonYellow onClick={openEditModal}>
              수정
            </CustomButtonYellow>
          ) : (
            <CustomButtonDisabled>수정 기간 만료</CustomButtonDisabled>
          )}
        </>
      )}

      <Modal
        open={drivingModalOpen}
        onClose={handleDrivingModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={SmallModalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RaffleContent>
                <MainTitle>
                  {drivingData.isStart ? "운행 시작" : "운행 종료"}
                </MainTitle>
                <AppliedItem onClick={handleDrivingModalClose}>
                  <X size={32} />
                </AppliedItem>
              </RaffleContent>
              <CustomText type="subtitle" bold400 color={GRAYPALECONTENTS}>
                {drivingData.isStart
                  ? "출발 시 차량 누적 주행 정보가 맞게 입력됐는지 확인해주세요."
                  : "현재 누적 주행 거리(시간)를 입력해주세요."}
              </CustomText>
              <VehicleDrivingBoxModalAlign container justifyContent="center">
                <Grid item>
                  <Input
                    value={formatNumber(drivingData.mileage)}
                    placeholder="현재 누적 주행 거리(시간)"
                    sx={{
                      width: "220px",
                      borderBottom: "1px solid #DCDFE3",
                      fontSize: "25px",
                    }}
                    onChange={onChangeDistance}
                  />
                </Grid>
                <Grid item ml="10px">
                  {vehicleUnitType}
                </Grid>
              </VehicleDrivingBoxModalAlign>

              <Grid container justifyContent="right">
                <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={editModalOpen}
        onClose={handleEditModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MidiumModalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RaffleContent>
                <MainTitle>차량 예약 수정</MainTitle>
                <AppliedItem onClick={handleEditModalClose}>
                  <X size={32} />
                </AppliedItem>
              </RaffleContent>
              <ContentBox
                container
                item
                xs={12}
                margin="20px 0px"
                alignItems="center"
              >
                <Grid container item xs={6}>
                  <CustomText type="subtitle">예약 날짜</CustomText>
                </Grid>
                <Grid container item xs={6}>
                  {data.startDate}
                </Grid>
              </ContentBox>
              <ContentBox
                container
                item
                xs={12}
                margin="20px 0px"
                alignItems="center"
              >
                <Grid container item xs={12} sm={6}>
                  <CustomText type="subtitle">출발 시간</CustomText>
                </Grid>
                <Grid container item xs={12} sm={6}>
                  {data.realStartTime ? (
                    <>
                      {" "}
                      <Grid item xs={4} pr="5px">
                        <DropDownListComponent
                          dataSource={AMPMTYPE}
                          name="AmPm"
                          placeholder="오전/오후"
                          value={departmentTime.AmPm}
                          onChange={onChangeDepartmentTime}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <DropDownListComponent
                          dataSource={HOURTYPE}
                          name="hour"
                          placeholder="시간"
                          value={departmentTime.hour}
                          onChange={onChangeDepartmentTime}
                        />
                      </Grid>
                      <Grid item xs={4} pl="5px">
                        <DropDownListComponent
                          dataSource={MINUTETYPE}
                          name="minute"
                          placeholder="분"
                          value={departmentTime.minute}
                          onChange={onChangeDepartmentTime}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid container item xs={12} justifyContent="center">
                      해당 정보 없음
                    </Grid>
                  )}
                </Grid>
              </ContentBox>
              <ContentBox
                container
                item
                xs={12}
                margin="20px 0px"
                alignItems="center"
              >
                <Grid container item xs={12} sm={6}>
                  <CustomText type="subtitle">
                    출발 시 누적 주행 거리(시간)
                  </CustomText>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {data.startMileage ? (
                    <>
                      <Input
                        name="startMileage"
                        value={formatNumber(editData.startMileage)}
                        sx={{
                          borderBottom: "1px solid #DCDFE3",
                        }}
                        onChange={(e) => {
                          onChangeMileage(e);
                        }}
                      />
                      km (시간)
                    </>
                  ) : (
                    <Grid container item xs={12} justifyContent="center">
                      해당 정보 없음
                    </Grid>
                  )}
                </Grid>
              </ContentBox>

              <ContentBox
                container
                item
                xs={12}
                margin="20px 0px"
                alignItems="center"
              >
                <Grid container item xs={12} sm={6} alignItems="center">
                  <CustomText type="subtitle">도착 시간</CustomText>{" "}
                  {editData.realEndTime &&
                    new Date(editData.realStartTime) >
                      new Date(editData.realEndTime) && (
                      <Tooltip title="출발 시간이 도착 시간보다 늦을 수는 없습니다.">
                        <IconButton>
                          <ErrorOutlineIcon sx={{ color: REDTITLE }} />
                        </IconButton>
                      </Tooltip>
                    )}
                </Grid>
                <Grid container item xs={12} sm={6} justifyContent="center">
                  {data.status === "이용완료" && data.realEndTime
                    ? editData.realEndTime && (
                        <Grid container item xs={12}>
                          <Grid item xs={4} pr="5px">
                            <DropDownListComponent
                              dataSource={AMPMTYPE}
                              name="AmPm"
                              placeholder="오전/오후"
                              value={arrivalTime.AmPm}
                              onChange={onChangeArrivalTime}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <DropDownListComponent
                              dataSource={HOURTYPE}
                              name="hour"
                              placeholder="시간"
                              value={arrivalTime.hour}
                              onChange={onChangeArrivalTime}
                            />
                          </Grid>
                          <Grid item xs={4} pl="5px">
                            <DropDownListComponent
                              dataSource={MINUTETYPE}
                              name="minute"
                              placeholder="분"
                              value={arrivalTime.minute}
                              onChange={onChangeArrivalTime}
                            />
                          </Grid>
                        </Grid>
                      )
                    : "해당 정보 없음"}
                </Grid>
              </ContentBox>
              <ContentBox
                container
                item
                xs={12}
                margin="15px 0px"
                alignItems="center"
              >
                <Grid container item xs={12} sm={6} alignItems="center">
                  <CustomText type="subtitle">
                    도착 시 누적 주행 거리(시간)
                  </CustomText>
                  {editData.endMileage &&
                    editData.startMileage > editData.endMileage && (
                      <Tooltip title="출발 시 누적 주행 거리(시간)가 도착 시 누적 주행 거리(시간)보다 클 수는 없습니다.">
                        <IconButton>
                          <ErrorOutlineIcon sx={{ color: REDTITLE }} />
                        </IconButton>
                      </Tooltip>
                    )}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {data.status === "이용완료" && editData.endMileage ? (
                    <>
                      <Input
                        name="endMileage"
                        value={formatNumber(editData.endMileage)}
                        sx={{
                          borderBottom: "1px solid #DCDFE3",
                        }}
                        onChange={(e) => {
                          onChangeMileage(e);
                        }}
                      />
                      km (시간)
                    </>
                  ) : (
                    <Grid container item xs={12} justifyContent="center">
                      해당 정보 없음
                    </Grid>
                  )}
                </Grid>
              </ContentBox>

              <Grid container justifyContent="right" mt="10px">
                <CustomButtonGreen onClick={onSaveEdit}>저장</CustomButtonGreen>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default MyCarButton;

const MidiumModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "80%",
  maxHeight: "480px",
  maxWidth: "600px",
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  borderRadius: "5px",
};

const ContentBox = styled(Grid)(() => ({
  minHeight: "32px",
}));
