import { atom } from "recoil";
import { InitMyINfo } from "./types/initObject";
import { AccountInfo } from "@azure/msal-browser";

export const accommodationState = atom({
  key: "accommodationState",
  default: [],
});

interface LoadingModalInterface {
  isLoading: boolean;
  text: string;
}

export const LoadingModalInit: LoadingModalInterface = {
  isLoading: false,
  text: "",
};

/**
 *  로딩 Modal
 */
export const loadingModalAtom = atom<LoadingModalInterface>({
  key: "loadingModalAtom",
  default: LoadingModalInit,
});

export const RoleAtom = atom<AccountInfo[]>({
  key: "RoleAtom",
  default: [],
});

export const RoleCheckAtom = atom({
  key: "RoleCheckAtom",
  default: false,
});

export const DatepickerStartEndTimeAtom = atom<Date[]>({
  key: "DatepickerStartEndTimeAtom",
  default: [],
});

export const ReservationStatusAtom = atom({
  key: "ReservationStatusAtom",
  default: 0,
});

export const BranchEmployeeDataAtom = atom({
  key: "BranchEmployeeDataAtom",
  default: { id: 0, email: "" },
});

export const HolidayDataAtom = atom({
  key: "HolidayDataAtom",
  default: [],
});

export const MyDataAtom = atom({
  key: "MyDataAtom",
  default: InitMyINfo,
});

export const PicListAtom = atom({
  key: "PicListAtom",
  default: [],
});

export const ReminderRoleAtom = atom({
  key: "ReminderRoleAtom",
  default: false,
});

export const MypageTabValue = atom({
  key: "MypageTabValue",
  default: 0,
});

export const BranchEmployeeCheck = atom({
  key: "BranchEmployeeCheck",
  default: true,
});
