import client from "../ApiService";
import HttpClient from "../http-client";

const baseUri: string = "/holiday";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _HolidayApi extends _BasicApi {
  getHoliday(year: number) {
    return this.client.get(`${baseUri}`, {
      params: { year: year },
    });
  }
}

export const HolidayApi = new _HolidayApi(client);
