import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  useMediaQuery,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const PWAInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showBanner, setShowBanner] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  useEffect(() => {
    if (isIOS() && isMobile) {
      setShowBanner(true);
    }
  }, [isMobile]);

  const handleBeforeInstallPrompt = (event: Event) => {
    event.preventDefault();
    setDeferredPrompt(event);
    setShowBanner(true);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
        setShowBanner(false);
      });
    }
  };

  const handleCancelClick = () => {
    setDeferredPrompt(null);
    setShowBanner(false);
  };

  const isIOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  //모바일일 경우만 앱 설치 배너 open
  return isMobile ? (
    <Slide direction="up" in={showBanner} mountOnEnter unmountOnExit>
      <AppBar
        position="fixed"
        color="default"
        style={{ top: "auto", bottom: 0 }}
      >
        <Toolbar
          style={{
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Typography
            variant="body1"
            align="center"
            style={{ marginBottom: isMobile ? "10px" : "0" }}
          >
            인바디 예약앱을 설치하시겠습니까?
          </Typography>
          {isIOS() && (
            <Typography
              variant="body2"
              align="center"
              style={{ marginBottom: isMobile ? "10px" : "0" }}
            >
              iOS에서 설치하려면 Safari 브라우저에서 공유 버튼을 누르고 "홈
              화면에 추가"를 선택하세요.
            </Typography>
          )}

          <Box>
            {deferredPrompt && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleInstallClick}
                style={{
                  width: isMobile ? "100%" : "auto",
                  marginBottom: isMobile ? "10px" : "0",
                }}
              >
                설치
              </Button>
            )}
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancelClick}
              style={{
                width: isMobile ? "100%" : "auto",
                marginLeft: isMobile ? "0" : "10px",
              }}
            >
              닫기
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Slide>
  ) : (
    <div></div>
  );
};

export default PWAInstallPrompt;
