import {
  AccommodationDisabledIcon,
  AccommodationIcon,
  SelectedIcon,
} from "../../styles/accommodationStyle";
import { CursorPointer, TitleZIndex } from "../../styles/theme";
import { AccommodationType } from "../../system/types/Accommodation";
import AccommodationIconList from "./AccommodationIconList";

interface AdminTitleProps {
  data: AccommodationType[];
  resourceId: number;
  selectAccomodation: (id: number) => void;
  selectedResourceId?: number;
}

function SelectAccommodationBox({
  data,
  resourceId,
  selectAccomodation,
  selectedResourceId,
}: AdminTitleProps) {
  return (
    <>
      {selectedResourceId ? (
        <>
          {data.map((item, idx) => {
            return (
              <TitleZIndex key={idx}>
                {resourceId === item.id && resourceId === selectedResourceId ? (
                  <SelectedIcon onClick={() => selectAccomodation(item.id)}>
                    <div>
                      <AccommodationIconList name={item.icon} />
                    </div>
                    <div>{item.name}</div>
                  </SelectedIcon>
                ) : (
                  <AccommodationDisabledIcon>
                    <div>
                      <AccommodationIconList name={item.icon} />
                    </div>
                    <div>{item.name}</div>
                  </AccommodationDisabledIcon>
                )}
              </TitleZIndex>
            );
          })}
        </>
      ) : (
        <>
          {data.map((item, idx) => {
            return (
              <CursorPointer
                key={idx}
                onClick={() => selectAccomodation(item.id)}
              >
                {resourceId === item.id ? (
                  <SelectedIcon>
                    <div>
                      <AccommodationIconList name={item.icon} />
                    </div>
                    <div>{item.name}</div>
                  </SelectedIcon>
                ) : (
                  <AccommodationIcon>
                    <div>
                      <AccommodationIconList name={item.icon} />
                    </div>
                    <div>{item.name}</div>
                  </AccommodationIcon>
                )}
              </CursorPointer>
            );
          })}
        </>
      )}
    </>
  );
}

export default SelectAccommodationBox;
