import { Autocomplete, Box, Chip } from "@mui/material";
import { useMemo, useState } from "react";
import { ErrorHandler } from "../../system/ApiService";
import { AmenitiesDetail } from "../../system/types/Accommodation";
import { AmenityApi } from "../../system/api/Amenity";
import { SECONDARY } from "../../styles/color";
import { CustomTextField } from "../../styles/theme";
import AlertModal from "./AlertModal";

interface SelectEquippedItemsProps {
  list?: AmenitiesDetail[];
  onChange: (data: AmenitiesDetail[]) => void;
}

function SelectEquippedItems({
  list,
  onChange: _onChange,
}: SelectEquippedItemsProps) {
  const [data, setData] = useState<AmenitiesDetail[]>([]);

  useMemo(() => {
    AmenityApi.getAmenityAll()
      .then((res) => {
        const SelectedItem = [];

        for (var i = 0; i < res.data.length; i++) {
          var itemData = res.data[i];
          delete itemData.icon;
          delete itemData.iconUrl;
          SelectedItem.push(itemData);
        }

        setData(SelectedItem);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const chip = { backgroundColor: SECONDARY, color: "white" };

  return (
    <>
      <Autocomplete
        multiple
        options={data}
        value={list}
        defaultValue={list}
        defaultChecked={true}
        disableCloseOnSelect
        onChange={(event: any, newValue: AmenitiesDetail[]) => {
          _onChange(newValue);
        }}
        isOptionEqualToValue={(
          option: AmenitiesDetail,
          value: AmenitiesDetail
        ) => option.id === value.id}
        getOptionLabel={(option: AmenitiesDetail) => option.name}
        renderOption={(props, option: AmenitiesDetail) => (
          <Box component="li" {...props}>
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <CustomTextField {...params} placeholder="검색 후 선택해 주세요." />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              style={chip}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        style={{ width: "100%" }}
      />
    </>
  );
}

export default SelectEquippedItems;
