import { RecurrenceData, ReminderData } from "../../system/types/Reminder";
import { isoDateFormatter } from "../Common/IsoDateFormatter";

interface RecurrenceTextProps {
  data: ReminderData;
  repeatData: RecurrenceData;
  type: string;
  submitDayOfWeek?: string[];
}

function RecurrenceText({
  data,
  repeatData,
  type,
  submitDayOfWeek,
}: RecurrenceTextProps) {
  const weeklyDay = (dt: string[]) => {
    var weeklyText = "";
    for (var i = 0; i < dt.length; i++) {
      var weeklyDayText = "";
      if (dt[i] === "MONDAY") {
        weeklyDayText = "월";
      } else if (dt[i] === "TUESDAY") {
        weeklyDayText = "화";
      } else if (dt[i] === "WEDNESDAY") {
        weeklyDayText = "수";
      } else if (dt[i] === "THURSDAY") {
        weeklyDayText = "목";
      } else if (dt[i] === "FRIDAY") {
        weeklyDayText = "금";
      } else if (dt[i] === "SATURDAY") {
        weeklyDayText = "토";
      } else if (dt[i] === "SUNDAY") {
        weeklyDayText = "일";
      }
      if (i > 0) {
        weeklyText += ", ";
      }
      weeklyText += weeklyDayText;
    }
    return weeklyText;
  };

  const typeOfWeek = (dt: string) => {
    if (dt === "FIRST") {
      return "첫 번째";
    } else if (dt === "SECOND") {
      return "두 번째";
    } else if (dt === "THIRD") {
      return "세 번째";
    } else if (dt === "FOURTH") {
      return "네 번째";
    } else if (dt === "LAST") {
      return "마지막 주";
    }
  };

  return (
    <>
      {type === "all" ? (
        <>
          {" "}
          {isoDateFormatter(new Date(data.startDate))}부터{" "}
          {repeatData.recurrenceEndDate && (
            <> {repeatData.recurrenceEndDate}까지 </>
          )}
        </>
      ) : (
        <></>
      )}
      {repeatData.recurrencePatternType === "DAILY" &&
        repeatData.interval === 1 && <> 매일 </>}
      {repeatData.recurrencePatternType === "DAILY" &&
        repeatData.interval !== 1 && <> {repeatData.interval}일 마다 </>}
      {repeatData.recurrencePatternType === "WEEKLY" && (
        <>
          {repeatData.interval}주 마다 {weeklyDay(repeatData.daysOfWeek)}요일에
        </>
      )}
      {repeatData.recurrencePatternType === "ABSOLUTE_MONTHLY" && (
        <>
          {repeatData.interval}개월 마다 {repeatData.dayOfMonth}일에
        </>
      )}
      {repeatData.recurrencePatternType === "RELATIVE_MONTHLY" && (
        <>
          {repeatData.interval}개월 마다{" "}
          {repeatData.weekIndex !== undefined &&
            typeOfWeek(repeatData.weekIndex)}{" "}
          {repeatData.daysOfWeek.length > 0 ? (
            weeklyDay(repeatData.daysOfWeek)
          ) : (
            <>{submitDayOfWeek && weeklyDay(submitDayOfWeek)}</>
          )}
          요일에
        </>
      )}
      {repeatData.recurrencePatternType === "ABSOLUTE_YEARLY" && (
        <>
          {repeatData.interval}년 마다 {repeatData.month}월{" "}
          {repeatData.dayOfMonth}일에
        </>
      )}
      {repeatData.recurrencePatternType === "RELATIVE_YEARLY" && (
        <>
          {repeatData.interval}년 마다 {repeatData.month}월{" "}
          {repeatData.weekIndex !== undefined &&
            typeOfWeek(repeatData.weekIndex)}{" "}
          {repeatData.daysOfWeek.length > 0 ? (
            weeklyDay(repeatData.daysOfWeek)
          ) : (
            <>{submitDayOfWeek && weeklyDay(submitDayOfWeek)}</>
          )}
          요일에
        </>
      )}{" "}
      반복합니다.
    </>
  );
}

export default RecurrenceText;
