import client from "../ApiService";
import HttpClient from "../http-client";

const baseUri: string = "/sas/token";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _AzureApi extends _BasicApi {
  getSasToken() {
    return this.client.get(`${baseUri}`);
  }
}

export const AzureApi = new _AzureApi(client);
