import { useEffect, useState } from "react";
import { CustomButtonGray, CustomButtonGreen } from "../../../styles/button";
import {
  AppliedItem,
  CardDataCenterContent,
  DisabledImgBox,
  ImgLabelBox,
  MainTitle,
  RightLayout,
  UploadImg,
  RaffleContent,
} from "../../../styles/theme";
import {
  BUSANSTATUS,
  CHECKOUTLIST,
  CheckInListItemStatus,
  GOSEONGSTATUS,
  TAEANSTATUS,
  YANGPYEONGSTATUS,
  YEOSUSTATUS,
} from "../../../system/Constants";
import { MyReservation } from "../../../system/types/Reservation";
import CloseIcon from "@mui/icons-material/Close";

import uuid from "react-uuid";
import AddIcon from "@mui/icons-material/Add";

import { AccommodationApi } from "../../../system/api/AccommodationApi";
import { ErrorHandler } from "../../../system/ApiService";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { AzureApi } from "../../../system/api/AzureApi";
import { BlobServiceClient } from "@azure/storage-blob";
import AlertModal from "../../Common/AlertModal";
import { X } from "@phosphor-icons/react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  CheckListBox,
  CheckListBoxItem,
  DeleteIconButton,
  ReportAfterImgBox,
  ReportAfterImgItem,
  SelectedCheckList,
  SelectAccommodationText,
  CheckoutImgUploadInfoBox,
  CheckoutImgUploadInfoTitle,
  CheckoutImgUploadInfoContent,
  ModalTextScrollBox,
} from "../../../styles/accommodationStyle";
import { Tablet, MobileSmall } from "../../../pages/Layout/ResponsiveScreen";
import axios, { AxiosError } from "axios";

interface CheckOutModalProps {
  handleClose: () => void;
  data: MyReservation;
  refreshReservationData: () => void;
}

function validation(fileLength: number) {
  if (fileLength === 0) {
    AlertModal("check", "휴양소 사용 후 사진을 1장 이상 등록해주세요.");

    return false;
  }
  return true;
}

function CheckOutModal({
  handleClose,
  data,
  refreshReservationData,
}: CheckOutModalProps) {
  const [fileList, setFileList] = useState<File[]>([]);
  const ImageKeyId = uuid();
  const [sasToken, setSasToken] = useState("");
  const [checklist, setCheckList] = useState(false);

  const AccommodationCheckInList = CHECKOUTLIST;

  const [showImages, setShowImages] = useState<string[]>([]);
  const Loading = useLoadingDispatch();

  const onSaveFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      if (event.target.files !== null) {
        let preFileList: File[] = [];
        for (var i = 0; i < event.target.files.length; i++) {
          const oldFile = event.target.files[i];
          const fileExtension = event.target.files[i].name.split(".")[1]; // 업로드 파일 확장자
          let newFile = null;
          if (fileExtension) {
            newFile = new File(
              [oldFile],
              `${ImageKeyId + "_사용 후 보고_" + i}.${fileExtension}`,
              {
                type: oldFile.type,
              }
            );
          }
          if (newFile !== null) {
            preFileList = preFileList.concat([newFile]);
          }
        }
        const completedFileList = fileList.concat(preFileList);
        setFileList(completedFileList);

        // 미리보기
        let imageUrlLists = [];
        for (var j = 0; j < completedFileList.length; j++) {
          const currentImageUrl = URL.createObjectURL(completedFileList[j]);
          imageUrlLists.push(currentImageUrl);
        }
        setShowImages(imageUrlLists);
      }
    }
  };
  const deleteFile = (id: number) => {
    setShowImages(showImages.filter((_, idx) => idx !== id));
    setFileList(fileList.filter((_, idx) => idx !== id));
  };
  useEffect(() => {
    AzureApi.getSasToken()
      .then((res) => {
        setSasToken(res.data.sasUrl);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  const UploadFileToBlob = async (file: File[]) => {
    // upload file
    const blobService = new BlobServiceClient(sasToken);
    const containerClient = blobService.getContainerClient("");

    const uploadPromises = file.map((document) => {
      if (document.name === null) {
        return Promise.resolve();
      }

      const blockBlobClient = containerClient.getBlockBlobClient(
        "inserve/" + process.env.REACT_APP_ACTIVE_MODE + "/" + document.name
      );

      return blockBlobClient.uploadData(document!);
    });
    return Promise.all(uploadPromises);
  };

  const onSave = async () => {
    if (validation(fileList.length)) {
      Loading({ type: "LOADING_MESSAGE", message: "파일 업로드 중입니다." });
      try {
        await UploadFileToBlob(fileList);
        const uploadFileList = fileList.map((file) => ({
          name: file.name,
          url: `https://inbodyinfra.blob.core.windows.net/images/inserve/${process.env.REACT_APP_ACTIVE_MODE}/${file.name}`,
        }));

        if (data.id) {
          await AccommodationApi.createCheckOut({
            reservationId: data.id,
            attachmentFiles: uploadFileList,
            reported: true,
          });
          AlertModal("success", "사용 후 보고가 제출되었습니다.");
          handleClose();
          refreshReservationData();
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const msg = ErrorHandler(err as AxiosError);
          AlertModal("msg", msg);
        } else {
          console.error(err);
          AlertModal("msg", "예기치 않은 오류가 발생했습니다.");
        }
      } finally {
        Loading({ type: "COMPLETE" });
      }
    }
  };

  const onChangeNextPage = () => {
    setCheckList(true);
  };

  return (
    <>
      {checklist ? (
        <>
          <RaffleContent>
            <MainTitle>사용 후 보고</MainTitle>
            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>
          <Tablet>
            <ModalTextScrollBox>
              <SelectAccommodationText>
                사용 후 청소 상태 및 필수 사진 업로드
              </SelectAccommodationText>

              <ReportAfterImgBox>
                <ImgLabelBox htmlFor={"file"}>
                  {" "}
                  <AddIcon />
                </ImgLabelBox>
                <DisabledImgBox
                  id={"file"}
                  type="file"
                  onChange={(e) => onSaveFile(e)}
                  multiple
                ></DisabledImgBox>
                <br />

                {showImages.map((image, idx) => (
                  <ReportAfterImgItem key={uuid()}>
                    <UploadImg src={image} alt="test" />

                    <DeleteIconButton
                      sx={{ fontSize: 3 }}
                      onClick={() => deleteFile(idx)}
                    >
                      <CloseIcon />
                    </DeleteIconButton>
                  </ReportAfterImgItem>
                ))}
              </ReportAfterImgBox>
              <CheckoutImgUploadInfoBox>
                <CheckoutImgUploadInfoTitle>
                  [필수 사진 업로드 목록]
                </CheckoutImgUploadInfoTitle>
                {data.accommodationName === "고성" && (
                  <>
                    {GOSEONGSTATUS.map((item, idx) => (
                      <CheckoutImgUploadInfoContent key={uuid()}>
                        {idx + 1}. {item.title}
                      </CheckoutImgUploadInfoContent>
                    ))}
                  </>
                )}
                {data.accommodationName === "태안" && (
                  <>
                    {TAEANSTATUS.map((item, idx) => (
                      <CheckoutImgUploadInfoContent key={uuid()}>
                        {" "}
                        {idx + 1}. {item.title}
                      </CheckoutImgUploadInfoContent>
                    ))}
                  </>
                )}
                {data.accommodationName === "양평" && (
                  <>
                    {YANGPYEONGSTATUS.map((item, idx) => (
                      <CheckoutImgUploadInfoContent key={uuid()}>
                        {" "}
                        {idx + 1}. {item.title}
                      </CheckoutImgUploadInfoContent>
                    ))}
                  </>
                )}
                {data.accommodationName === "여수" && (
                  <>
                    {YEOSUSTATUS.map((item, idx) => (
                      <CheckoutImgUploadInfoContent key={uuid()}>
                        {" "}
                        {idx + 1}. {item.title}
                      </CheckoutImgUploadInfoContent>
                    ))}
                  </>
                )}
                {data.accommodationName === "부산" && (
                  <>
                    {BUSANSTATUS.map((item, idx) => (
                      <CheckoutImgUploadInfoContent key={uuid()}>
                        {" "}
                        {idx + 1}. {item.title}
                      </CheckoutImgUploadInfoContent>
                    ))}
                  </>
                )}
              </CheckoutImgUploadInfoBox>
            </ModalTextScrollBox>
          </Tablet>
          <MobileSmall>
            <SelectAccommodationText>
              사용 후 청소 상태 및 필수 사진 업로드
            </SelectAccommodationText>

            <ReportAfterImgBox>
              <ImgLabelBox htmlFor={"file"}>
                {" "}
                <AddIcon />
              </ImgLabelBox>
              <DisabledImgBox
                id={"file"}
                type="file"
                onChange={(e) => onSaveFile(e)}
                multiple
              ></DisabledImgBox>
              <br />

              {showImages.map((image, idx) => (
                <ReportAfterImgItem key={uuid()}>
                  <UploadImg src={image} alt="test" />

                  <DeleteIconButton
                    sx={{ fontSize: 3 }}
                    onClick={() => deleteFile(idx)}
                  >
                    <CloseIcon />
                  </DeleteIconButton>
                </ReportAfterImgItem>
              ))}
            </ReportAfterImgBox>
            <CheckoutImgUploadInfoBox>
              <CheckoutImgUploadInfoTitle>
                [필수 사진 업로드 목록]
              </CheckoutImgUploadInfoTitle>
              {data.accommodationName === "고성" && (
                <>
                  {GOSEONGSTATUS.map((item, idx) => (
                    <CheckoutImgUploadInfoContent key={uuid()}>
                      {idx + 1}. {item.title}
                    </CheckoutImgUploadInfoContent>
                  ))}
                </>
              )}
              {data.accommodationName === "태안" && (
                <>
                  {TAEANSTATUS.map((item, idx) => (
                    <CheckoutImgUploadInfoContent key={uuid()}>
                      {" "}
                      {idx + 1}. {item.title}
                    </CheckoutImgUploadInfoContent>
                  ))}
                </>
              )}
              {data.accommodationName === "양평" && (
                <>
                  {YANGPYEONGSTATUS.map((item, idx) => (
                    <CheckoutImgUploadInfoContent key={uuid()}>
                      {" "}
                      {idx + 1}. {item.title}
                    </CheckoutImgUploadInfoContent>
                  ))}
                </>
              )}
              {data.accommodationName === "고성" && (
                <>
                  {YEOSUSTATUS.map((item, idx) => (
                    <CheckoutImgUploadInfoContent key={uuid()}>
                      {" "}
                      {idx + 1}. {item.title}
                    </CheckoutImgUploadInfoContent>
                  ))}
                </>
              )}
            </CheckoutImgUploadInfoBox>
          </MobileSmall>
          <CardDataCenterContent>
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>

            <CustomButtonGray onClick={handleClose}>취소</CustomButtonGray>
          </CardDataCenterContent>
        </>
      ) : (
        <>
          <RaffleContent>
            <MainTitle>
              {" "}
              {data.accommodationName} {data.type} 퇴실 시 확인 사항
            </MainTitle>
            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>

          <Tablet>
            <ModalTextScrollBox>
              <SelectAccommodationText>
                아래 내용을 모두 확인하신 후 다음으로 버튼을 눌러주세요.
              </SelectAccommodationText>
              <CheckListBox>
                {AccommodationCheckInList.filter(
                  (itm) => itm.location === data.accommodationName
                )[0]?.checklist.map(
                  (item: CheckInListItemStatus, idx: number) => (
                    <CheckListBoxItem key={"checkinlist" + idx}>
                      <SelectedCheckList>
                        <CheckCircleIcon />
                      </SelectedCheckList>

                      {item.checklistItem}
                    </CheckListBoxItem>
                  )
                )}
              </CheckListBox>
            </ModalTextScrollBox>
          </Tablet>
          <MobileSmall>
            <SelectAccommodationText>
              아래 내용을 모두 확인하신 후 다음으로 버튼을 눌러주세요.
            </SelectAccommodationText>
            <CheckListBox>
              {AccommodationCheckInList.filter(
                (itm) => itm.location === data.accommodationName
              )[0]?.checklist.map(
                (item: CheckInListItemStatus, idx: number) => (
                  <CheckListBoxItem key={"checkinlist" + idx}>
                    <SelectedCheckList>
                      <CheckCircleIcon />
                    </SelectedCheckList>

                    {item.checklistItem}
                  </CheckListBoxItem>
                )
              )}
            </CheckListBox>
          </MobileSmall>
          <RightLayout>
            <CustomButtonGreen onClick={onChangeNextPage}>
              다음으로
            </CustomButtonGreen>
          </RightLayout>
        </>
      )}
    </>
  );
}

export default CheckOutModal;
