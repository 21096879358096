import {
  StyledBody,
  StyledHead1,
  StyledHead2,
  StyledHead3,
} from "../../../styles/theme";
import { VehicleLastVehicleInfo } from "../../../system/types/Vehicle";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MyAccommodationChip from "../../Common/Accommodation/MyAccommodationChip";
import AlertModal from "../../Common/AlertModal";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import InfoIcon from "@mui/icons-material/Info";
import { GRAYPALETITLE } from "../../../styles/color";
import MyCarButton from "../../MyPage/Car/MyCarButton";
import VehicleReservationDetailModal from "./VehicleReservationDetailModal";
interface DrivingHistoryTableProps {
  drivingData: VehicleLastVehicleInfo[];
  refreshData: () => void;
}

function DrivingHistoryTable({
  drivingData,
  refreshData,
}: DrivingHistoryTableProps) {
  function openMemo(message: string) {
    AlertModal("memo", "운전자가 남긴 메모", message);
  }
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHead1 align="center">사용일자</StyledHead1>
            <StyledHead1 align="center">차량</StyledHead1>
            <StyledHead2 align="center">운전자</StyledHead2>
            <StyledHead2 align="center">출도착 정보</StyledHead2>
            <StyledHead1 align="center">상태</StyledHead1>
            <StyledHead1 align="center">상세내용</StyledHead1>
            <StyledHead3 align="center">관리</StyledHead3>
          </TableRow>
        </TableHead>
        <TableBody>
          {drivingData.map((item, idx) => {
            return (
              <TableRow key={idx}>
                <StyledBody align="center">
                  {item.startDate} {item.time}
                </StyledBody>
                <StyledBody align="center">{item.vehicle.number}</StyledBody>
                <StyledBody align="center">
                  {item.employee.department
                    ? item.employee.department
                    : item.employee.team}{" "}
                  {item.employee.name}
                </StyledBody>
                <StyledBody align="center">
                  {item.startMileage !== null
                    ? item.startMileage.toLocaleString() +
                      " " +
                      item.vehicle.unitType
                    : "- " + item.vehicle.unitType}
                  {" / "}
                  {item.endMileage !== null
                    ? item.endMileage.toLocaleString() +
                      " " +
                      item.vehicle.unitType
                    : "- " + item.vehicle.unitType}
                </StyledBody>
                <StyledBody align="center">
                  <MyAccommodationChip status={item.status} />
                </StyledBody>
                <StyledBody align="center">
                  <VehicleReservationDetailModal data={item} />
                  {item.memo !== null && (
                    <StickyNote2Icon
                      onClick={() => openMemo(item.memo)}
                      style={{ cursor: "pointer", color: GRAYPALETITLE }}
                    />
                  )}
                </StyledBody>

                <StyledBody align="center">
                  {item?.id && (
                    <MyCarButton
                      data={{
                        id: item.id,
                        vehicle: item.vehicle,
                        employee: item.employee,
                        startDate: item.startDate,
                        startMileage: item.startMileage,
                        endMileage: item.endMileage,
                        status: item.status,
                        time: item.time,
                        realStartTime: item.realStartTime,
                        realEndTime: item.realEndTime,
                        waypoints: item.waypoints,
                        memo: item.memo,
                      }}
                      refreshReservationData={refreshData}
                    />
                  )}
                </StyledBody>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DrivingHistoryTable;
