import { Divider, Grid } from "@mui/material";
import { AdminTabBox, CenterLayout, MaxWidth } from "../../../styles/theme";

import {
  AccommodationBox,
  AccommodationPointerIcon,
  AdminAccomodationBackgroundBox,
  AdminAccomodationEmptyBox,
  SelectedIcon,
} from "../../../styles/accommodationStyle";

import { useEffect, useState } from "react";
import SelectAccommodationBox from "../../Common/SelectAccommodationBox";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Mobile, Pc } from "../../../pages/Layout/ResponsiveScreen";
import ReservationCheckTable from "./ReservationCheck/ReservationCheckTable";
import ReservationCheckCard from "./ReservationCheck/ReservationCheckCard";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  ReservationStatusAtom,
  accommodationState,
} from "../../../system/atoms";
import {
  Accommodation,
  DetailedAddress,
} from "../../../system/types/Accommodation";
import { InitAccommodation } from "../../../system/types/initObject";
import { AccommodationApi } from "../../../system/api/AccommodationApi";
import { ErrorHandler } from "../../../system/ApiService";
import { CustomButtonPrimary } from "../../../styles/button";
import { ReservationSearchData } from "../../../system/types/Reservation";
import { ResourceApi } from "../../../system/api/Resource";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import { Globe } from "@phosphor-icons/react";
import AlertModal from "../../Common/AlertModal";

function OldAccommodationReservationCheck() {
  const [resourceData, setResourceData] = useRecoilState(accommodationState);
  const [data, setData] = useState<Accommodation>(InitAccommodation);
  const [resourceId, setResourceId] = useState(0);
  const [DonghoData, setDonghoData]: any = useState([]);
  const [ReservationData, setReservationData] = useState<
    ReservationSearchData[]
  >([]);
  const reservationStatus = useRecoilValue(ReservationStatusAtom);
  const Loading = useLoadingDispatch();
  const selectAccomodation = (id: number) => {
    setResourceId(id);
    setDetailedAddressesId(0);
    setReservationData([]);
  };

  useEffect(() => {
    Loading({ type: "LOADING" });
    ResourceApi.getResource("휴양소")
      .then((res) => {
        setResourceData(res.data);
        setResourceId(res.data.id);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      })
      .finally(() => {
        Loading({ type: "COMPLETE" });
      });
  }, []);

  useEffect(() => {
    if (resourceId) {
      Loading({ type: "LOADING" });
      AccommodationApi.getAccommodationDetail(resourceId)
        .then((res) => {
          setData(res.data);
          var newDonghoData = [];
          var newDonghoList = [];
          for (var i = 0; i < res.data.detailedAddresses.length; i++) {
            var newDongho: DetailedAddress = { id: 0, address: "" };
            newDongho.id = res.data.detailedAddresses[i].id;
            if (res.data.detailedAddresses[i].dong) {
              if (res.data.detailedAddresses[i].ho) {
                newDonghoData.push(
                  `${res.data.detailedAddresses[i].dong}동 ${res.data.detailedAddresses[i].ho}호`
                );
                newDongho.address = `${res.data.detailedAddresses[i].dong}동 ${res.data.detailedAddresses[i].ho}호`;
              } else {
                newDonghoData.push(`${res.data.detailedAddresses[i].dong}동`);
                newDongho.address = `${res.data.detailedAddresses[i].dong}동`;
              }
              newDonghoList.push(newDongho);
            }
          }
          setDonghoData(newDonghoData);
          setDonghoList(newDonghoList);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    } else {
      Loading({ type: "LOADING" });
      AccommodationApi.getReservationSearch(
        isoDateFormatter(dateRange[0]),
        isoDateFormatter(dateRange[1])
      )
        .then((res) => {
          setReservationData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
  }, [resourceId]);

  // 세부 휴양소
  const [DonghoList, setDonghoList]: any = useState([]);
  const [detailedAddressesId, setDetailedAddressesId] = useState(0);
  const onChangeAddress = (args: any) => {
    const donghovalue = DonghoList.filter(
      (dongho: DetailedAddress) => dongho.address === args.target.value
    );
    setDetailedAddressesId(donghovalue[0].id);
  };

  // 기간 선택
  const [dateGap, setDateGap] = useState(0);
  var now = new Date();
  const [dateRange, setDateRange] = useState<Date[]>([
    new Date(now.getFullYear(), now.getMonth(), 1),
    new Date(now.getFullYear(), now.getMonth() + 1, 0),
  ]);

  const onChangeDate = (args: any) => {
    if (args.value) {
      var dategap = args.value[1].getTime() - args.value[0].getTime();
      var gapday = dategap / (1000 * 60 * 60 * 24);
      setDateGap(gapday);
      setDateRange(args.value);
    }
  };

  function searchReservation() {
    if (resourceId) {
      if (detailedAddressesId) {
        Loading({ type: "LOADING" });
        AccommodationApi.getReservationSearch(
          isoDateFormatter(dateRange[0]),
          isoDateFormatter(dateRange[1]),
          data.id,
          detailedAddressesId
        )
          .then((res) => {
            setReservationData(res.data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          });
      } else {
        Loading({ type: "LOADING" });
        AccommodationApi.getReservationSearch(
          isoDateFormatter(dateRange[0]),
          isoDateFormatter(dateRange[1]),
          data.id
        )
          .then((res) => {
            setReservationData(res.data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          });
      }
    } else {
      Loading({ type: "LOADING" });
      AccommodationApi.getReservationSearch(
        isoDateFormatter(dateRange[0]),
        isoDateFormatter(dateRange[1])
      )
        .then((res) => {
          setReservationData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          Loading({ type: "COMPLETE" });
        });
    }
  }

  useEffect(() => {
    searchReservation();
  }, [reservationStatus]);

  function onChangeAllData() {
    setResourceId(0);
  }

  return (
    <AdminTabBox>
      <Grid item xs={12}>
        <AccommodationBox>
          {resourceId ? (
            <AccommodationPointerIcon onClick={onChangeAllData}>
              <div>
                <Globe size={40} weight="light" />
              </div>
              <div>전체</div>
            </AccommodationPointerIcon>
          ) : (
            <SelectedIcon>
              <div>
                <Globe size={40} weight="light" />
              </div>
              <div>전체</div>
            </SelectedIcon>
          )}
          <SelectAccommodationBox
            data={resourceData}
            resourceId={resourceId}
            selectAccomodation={selectAccomodation}
          />
        </AccommodationBox>
        <AccommodationBox>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <DropDownListComponent
              dataSource={DonghoData}
              id="ddlelement"
              placeholder="조회할 휴양소를 선택해주세요."
              onChange={onChangeAddress}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </AccommodationBox>
        <AccommodationBox>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <DateRangePickerComponent
              id="daterangepicker2"
              placeholder="날짜를 선택해주세요."
              format="yyyy-MM-dd"
              locale="ko"
              delayUpdate={true}
              onChange={onChangeDate}
              value={dateRange}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Divider orientation="vertical" flexItem />
        </AccommodationBox>
        <CenterLayout>
          <CustomButtonPrimary onClick={searchReservation}>
            조회
          </CustomButtonPrimary>
        </CenterLayout>
      </Grid>
      <AdminAccomodationBackgroundBox />
      <Grid item xs={12}>
        <AdminAccomodationEmptyBox />
      </Grid>

      <Grid item xs={12}>
        <MaxWidth>
          {" "}
          <Pc>
            <ReservationCheckTable adminData={ReservationData} />
          </Pc>
          <Mobile>
            <ReservationCheckCard adminData={ReservationData} />
          </Mobile>
        </MaxWidth>
      </Grid>
    </AdminTabBox>
  );
}

export default OldAccommodationReservationCheck;
