import { Box, IconButton, Input, InputAdornment, Tooltip } from "@mui/material";
import { MobileSmall, Tablet } from "../../pages/Layout/ResponsiveScreen";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ReminderClassification,
  ReminderClassification2,
} from "../../system/Constants";
import {
  RecentReminderCardBox,
  RecentReminderCardTitle,
  RecentReminderContent,
  RecentReminderTitle,
  ReminderHeaderInfoBox,
  ReminderTitleGreen,
  ReminderTitleOrange,
  ReminderCardTitleOrange,
  ReminderCardTitleGreen,
  ReminderMobileSearchBox,
  RecentReminderSearchMobileContent,
  ReminderMobileSearchContent,
  RecentReminderSearchTitle,
  ReminderMobileSearchBox2,
  TooltipText,
} from "../../styles/reminderStyle";
import { CardDataContent, RaffleContent } from "../../styles/theme";
import { Fragment, useState } from "react";
import { ReminderData } from "../../system/types/Reminder";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { isoDateFormatter } from "../Common/IsoDateFormatter";
import { useRecoilValue } from "recoil";
import { ReminderRoleAtom } from "../../system/atoms";
import SearchIcon from "@mui/icons-material/Search";
interface ReminderHeaderProps {
  setSearchType: (status: string) => void;
  setSearchWord: (status: string) => void;
  setStartDate: (status: string | undefined) => void;
  setEndDate: (status: string | undefined) => void;
  searchType: string;
  headerData: ReminderData;
  setPage: (status: number) => void;
}

function ReminderHeader({
  setSearchType,
  setSearchWord,
  searchType,
  headerData,
  setPage,
  setStartDate,
  setEndDate,
}: ReminderHeaderProps) {
  const [inputWord, setInputWord] = useState("");
  const reminderRoleCheck = useRecoilValue(ReminderRoleAtom);
  const onChange = (args: any) => {
    setPage(0);
    if (args.target.value) {
      if (args.target.value === "내 담당/관리 일정") {
        setSearchType("my");
      } else if (args.target.value === "소속 파트 일정") {
        setSearchType("part");
      } else if (args.target.value === "소속 팀 일정") {
        setSearchType("team");
      } else {
        setSearchType("all");
      }
    }
  };

  const onChangeSearchWord = (args: any) => {
    setInputWord(args.target.value);
  };

  const onClickSearch = () => {
    setSearchWord(inputWord);
  };

  const onChangeDate = (args: any) => {
    if (args.target.name === "startDate") {
      setStartDate(isoDateFormatter(args.value));
    } else {
      setEndDate(isoDateFormatter(args.value));
    }
  };
  const handleOnKeyPress = (e: any) => {
    if (e.key === "Enter") {
      onClickSearch();
    }
  };
  return (
    <>
      <Tablet>
        {headerData.title && (
          <ReminderHeaderInfoBox>
            <RecentReminderTitle>다가오는 일정</RecentReminderTitle>
            <ReminderTitleGreen>
              {headerData.dday === 0 ? (
                <>D - DAY</>
              ) : (
                <>D - {headerData.dday}</>
              )}
            </ReminderTitleGreen>
            <RecentReminderContent>{headerData.title}</RecentReminderContent>
            <ReminderTitleOrange>
              담당자: {headerData.pic?.name},{"  "} 관리자:{"  "}
              {headerData.manager?.name}
            </ReminderTitleOrange>
          </ReminderHeaderInfoBox>
        )}

        <ReminderHeaderInfoBox>
          <RecentReminderTitle> 분류</RecentReminderTitle>
          <RecentReminderTitle>
            <DropDownListComponent
              dataSource={
                reminderRoleCheck
                  ? ReminderClassification
                  : ReminderClassification2
              }
              value={searchType}
              onChange={onChange}
              placeholder="내 담당/관리 일정"
            />
          </RecentReminderTitle>
          <RecentReminderTitle> 기간</RecentReminderTitle>
          <RecentReminderTitle>
            {" "}
            <DatePickerComponent
              name="startDate"
              placeholder="다음 착수일"
              format="yyyy-MM-dd"
              locale="ko"
              onChange={onChangeDate}
            />
          </RecentReminderTitle>
          <RecentReminderSearchTitle>~</RecentReminderSearchTitle>
          <RecentReminderTitle>
            {" "}
            <DatePickerComponent
              name="endDate"
              placeholder="반복 종료일"
              format="yyyy-MM-dd"
              locale="ko"
              onChange={onChangeDate}
            />
          </RecentReminderTitle>
        </ReminderHeaderInfoBox>
      </Tablet>
      <MobileSmall>
        {headerData.title && (
          <RecentReminderCardBox>
            <CardDataContent>
              <RaffleContent>
                <div>다가오는 일정</div>
                <ReminderCardTitleGreen>
                  D - {headerData.dday}
                </ReminderCardTitleGreen>
              </RaffleContent>
              <RecentReminderCardTitle>
                {headerData.title}
              </RecentReminderCardTitle>
              <ReminderCardTitleOrange>
                담당자 : {headerData.pic?.name} 관리자 :{"  "}
                {headerData.manager?.name}
              </ReminderCardTitleOrange>
            </CardDataContent>
          </RecentReminderCardBox>
        )}

        <ReminderMobileSearchBox>
          <RecentReminderTitle> 분류</RecentReminderTitle>
          <ReminderMobileSearchContent>
            {" "}
            <DropDownListComponent
              dataSource={ReminderClassification}
              value={searchType}
              onChange={onChange}
              placeholder="내 담당/관리 일정"
            />
          </ReminderMobileSearchContent>
        </ReminderMobileSearchBox>

        <ReminderMobileSearchBox>
          <RecentReminderTitle> 기간</RecentReminderTitle>
          <ReminderTitleOrange>
            {" "}
            <DatePickerComponent
              name="startDate"
              placeholder="다음 착수일"
              format="yyyy-MM-dd"
              locale="ko"
              onChange={onChangeDate}
            />
          </ReminderTitleOrange>
          <RecentReminderSearchTitle>~</RecentReminderSearchTitle>
          <ReminderTitleOrange>
            {" "}
            <DatePickerComponent
              name="endDate"
              placeholder="반복 종료일"
              format="yyyy-MM-dd"
              locale="ko"
              onChange={onChangeDate}
            />
          </ReminderTitleOrange>
        </ReminderMobileSearchBox>

        <ReminderMobileSearchBox2>
          <RecentReminderSearchMobileContent>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1 },
                width: "100%",
              }}
              noValidate
              autoComplete="off"
            >
              <Input
                placeholder="업무명, (부)담당자, (부)관리자"
                sx={{ width: "100%", borderBottom: "1px solid #DCDFE3" }}
                onChange={onChangeSearchWord}
                className="centered-placeholder"
                onKeyDown={handleOnKeyPress}
              />
            </Box>
            <Tooltip
              title={
                <Fragment>
                  <TooltipText>검색</TooltipText>
                </Fragment>
              }
            >
              <IconButton onClick={onClickSearch}>
                <SearchIcon style={{ marginTop: "5px" }} />
              </IconButton>
            </Tooltip>
          </RecentReminderSearchMobileContent>
        </ReminderMobileSearchBox2>
      </MobileSmall>
    </>
  );
}

export default ReminderHeader;
