import { useEffect, useState } from "react";
import { VehicleInfo } from "../../../system/types/Vehicle";
import { VehicleApi } from "../../../system/api/VehicleApi";
import { ErrorHandler } from "../../../system/ApiService";
import AlertModal from "../../Common/AlertModal";
import {
  Grid,
  IconButton,
  InputBase,
  Pagination,
  Paper,
  styled,
} from "@mui/material";
import { CustomButtonGreen } from "../../../styles/button";
import { Mobile, Pc } from "../../../pages/Layout/ResponsiveScreen";
import VehicleManagementTable from "./VehicleManagementTable";
import VehicleManagementCard from "./VehicleManagementCard";
import VehicleEdit from "./VehicleEdit";
import { InitVehicleInfo } from "../../../system/types/initObject";
import SearchIcon from "@mui/icons-material/Search";

function VehicleManagement() {
  const [data, setData] = useState<VehicleInfo>(InitVehicleInfo);
  const [vehicleData, setVehicleData] = useState<VehicleInfo[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setData(InitVehicleInfo);
  };

  const refreshData = () => {
    VehicleApi.getVehicle(pageNumber, 12, searchWord)
      .then((res) => {
        setVehicleData(res.data.vehicles);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  const [searchWord, setSearchWord] = useState("");
  const [preSearchWord, setPreSearchWord] = useState("");
  useEffect(() => {
    refreshData();
  }, [pageNumber, searchWord]);
  const onChangePaging = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value - 1);
  };

  const onChangeSearch = (e: any) => {
    setPreSearchWord(e.target.value);
  };
  const ClickSearch = () => {
    setSearchWord(preSearchWord);
  };

  const handleOnKeyDownSearch = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      ClickSearch();
    }
  };

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        mt="10px"
      >
        <SearchBoxComponent>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="차량번호를 입력하세요."
            inputProps={{ "aria-label": "차량번호를 입력하세요." }}
            onChange={onChangeSearch}
            onKeyDown={handleOnKeyDownSearch}
            autoComplete="new-password"
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={ClickSearch}
          >
            <SearchIcon />
          </IconButton>
        </SearchBoxComponent>
        <CustomButtonGreen onClick={handleOpen}>차량 등록</CustomButtonGreen>
      </Grid>
      <Grid container item xs={12}>
        <Pc>
          <VehicleManagementTable
            vehicleData={vehicleData}
            refreshData={refreshData}
          />
        </Pc>
        <Mobile>
          <VehicleManagementCard
            vehicleData={vehicleData}
            refreshData={refreshData}
          />
        </Mobile>
      </Grid>
      {vehicleData.length === 0 ? (
        <EmptyBox container justifyContent="center">
          조회된 차량이 없습니다.
        </EmptyBox>
      ) : (
        <Grid container justifyContent="center" mt="10px">
          <Pagination
            count={totalPage}
            variant="outlined"
            page={pageNumber + 1}
            showFirstButton
            showLastButton
            onChange={onChangePaging}
            style={{ textAlign: "center" }}
          />
        </Grid>
      )}
      {open && (
        <VehicleEdit
          type="생성"
          open={open}
          handleClose={handleClose}
          data={data}
          setData={setData}
          refreshData={refreshData}
        />
      )}
    </Grid>
  );
}

export default VehicleManagement;

const EmptyBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  padding: "20% 0px",
});

const SearchBoxComponent = styled(Paper)({
  padding: "0px 4px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
  width: 250,
  height: "40px",
});
