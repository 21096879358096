import { Grid } from "@mui/material";
import {
  VehicleReserveButton,
  VehicleReserveContentBox,
  VehicleReserveTitleBox,
  VehicleReserveTitleBoxDisabled,
  VehicleReserveValidationBox,
} from "../../../styles/vehicleStyle";
import { VehicleReservationInfo } from "../../../system/types/Vehicle";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TIME_SELECT_TYPE } from "../../../system/constants/VehicleConstants";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

interface VRSTimeProps {
  reservationData: VehicleReservationInfo;
  changeSelect: (type: string) => void;
  timeSelect: boolean;
  onChangeDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedDate?: string | undefined;
}

function VRSTime({
  reservationData,
  changeSelect,
  timeSelect,
  onChange,
  onChangeDate,
  selectedDate,
}: VRSTimeProps) {
  const minDate = new Date();
  return (
    <>
      {selectedDate ? (
        <VehicleReserveTitleBoxDisabled item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={9} mt="3px">
              <Grid container alignItems="center">
                <Grid item> 1. 시간 선택</Grid>

                <VehicleReserveValidationBox>
                  {reservationData.startDate} {reservationData.time}
                </VehicleReserveValidationBox>
              </Grid>
            </Grid>
          </Grid>
        </VehicleReserveTitleBoxDisabled>
      ) : (
        <VehicleReserveTitleBox
          item
          xs={12}
          onClick={() => {
            changeSelect("time");
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={9}>
              <Grid container alignItems="center">
                <Grid item> 1. 시간 선택</Grid>

                <VehicleReserveValidationBox>
                  {reservationData.startDate} {reservationData.time}
                </VehicleReserveValidationBox>
              </Grid>
            </Grid>

            <VehicleReserveButton container justifyContent="right" item xs={3}>
              {timeSelect ? (
                <CaretUp size={30} weight="bold" />
              ) : (
                <CaretDown size={30} weight="bold" />
              )}
            </VehicleReserveButton>
          </Grid>
        </VehicleReserveTitleBox>
      )}
      {timeSelect && (
        <VehicleReserveContentBox
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          viewheight={100}
        >
          <Grid container item xs={6} alignItems="center">
            <Grid item xs={12} sm={3} container justifyContent="center">
              날짜
            </Grid>
            <Grid item xs={12} sm={9}>
              <DatePickerComponent
                name="startDate"
                value={
                  reservationData.startDate
                    ? new Date(reservationData.startDate)
                    : undefined
                }
                placeholder="예약 날짜"
                onChange={onChangeDate}
                min={minDate}
                format="yyyy-MM-dd"
                locale="ko"
                showClearButton={false}
              />
            </Grid>
          </Grid>

          <Grid container item xs={6} mb="4px" alignItems="center">
            <Grid item xs={12} sm={3} container justifyContent="center">
              시간대
            </Grid>
            <Grid item xs={12} sm={9}>
              <DropDownListComponent
                name="time"
                value={reservationData.time}
                onChange={onChange}
                dataSource={TIME_SELECT_TYPE}
                placeholder="오전 / 오후 / 종일"
              />
            </Grid>
          </Grid>
        </VehicleReserveContentBox>
      )}
    </>
  );
}

export default VRSTime;
