import { Grid } from "@mui/material";
import { MyReservation } from "../../../system/types/Reservation";
import {
  BlueText,
  CardDataBox,
  CardDataCenterContent,
  CardDataContent,
  CardDataLayout,
  GreenContentsText,
  RedContentsText,
  ThickText,
  RaffleContent,
  StyledEmptyBodyNoBorder,
} from "../../../styles/theme";

import MyAccommodationChip from "../../Common/Accommodation/MyAccommodationChip";

import AccommodationButton from "../../Common/Accommodation/AccommodationButton";

interface MyAccommodationCardProps {
  mydata: MyReservation[];
  refreshReservationData: () => void;
}

function MyAccommodationCard({
  mydata,
  refreshReservationData,
}: MyAccommodationCardProps) {
  return (
    <Grid item xs={12}>
      <CardDataLayout>
        {mydata.map((item, idx) => {
          return (
            <CardDataBox key={idx}>
              <Grid item xs={12}>
                <CardDataContent>
                  <RaffleContent>
                    <ThickText>
                      {item.accommodationName} {item.type}
                    </ThickText>
                    <ThickText>추첨 결과</ThickText>
                  </RaffleContent>
                </CardDataContent>
                <CardDataContent>
                  <RaffleContent>
                    <MyAccommodationChip status={item.status} />

                    {(item.status === "당첨" ||
                      item.status === "이용중" ||
                      item.status === "이용종료" ||
                      item.status === "보고완료") && (
                      <GreenContentsText>당첨</GreenContentsText>
                    )}

                    {item.status === "예약중" && <BlueText>발표 전</BlueText>}
                    {item.status === "미당첨" && (
                      <RedContentsText>미당첨</RedContentsText>
                    )}
                  </RaffleContent>
                </CardDataContent>
                <CardDataContent>
                  {item.dong}동 {item.ho !== 0 ? " " + item.ho + "호" : null}
                </CardDataContent>
                <CardDataContent>
                  {item.startDate} - {item.endDate}
                </CardDataContent>
                <CardDataCenterContent>
                  <AccommodationButton
                    data={item}
                    refreshReservationData={refreshReservationData}
                  />
                </CardDataCenterContent>
              </Grid>
            </CardDataBox>
          );
        })}
      </CardDataLayout>
      {mydata.length === 0 && (
        <Grid container>
          <StyledEmptyBodyNoBorder>
            조회된 데이터가 없습니다.
          </StyledEmptyBodyNoBorder>
        </Grid>
      )}
    </Grid>
  );
}

export default MyAccommodationCard;
