import { Grid } from "@mui/material";

import {
  CenterLayout,
  LeftMainItem,
  MainTopTitleBox,
  SubMainBox,
  TopTitleBox,
  TopTitleBoxMobile,
} from "../../styles/theme";

import { useHistory } from "react-router-dom";

import { Mobile, Pc } from "../../pages/Layout/ResponsiveScreen";
import { ADMIN_TYPE } from "../../system/Constants";
import { CustomButtonCommon } from "../../styles/button";

function AdminMainTable() {
  const history = useHistory();

  const onMoveSite = (route: string) => {
    history.push({
      pathname: `${route}`,
    });
  };
  return (
    <Grid container spacing={2}>
      <Mobile>
        <LeftMainItem item sm={12}>
          <TopTitleBoxMobile>인바디</TopTitleBoxMobile>
          <TopTitleBoxMobile>예약 서비스</TopTitleBoxMobile>
          <TopTitleBoxMobile>관리자 페이지</TopTitleBoxMobile>
          <SubMainBox item xs={12}></SubMainBox>
          <CenterLayout>
            {ADMIN_TYPE.map((item, index) => {
              return (
                <span key={index}>
                  <CustomButtonCommon onClick={() => onMoveSite(item.routes)}>
                    {item.text}
                  </CustomButtonCommon>
                </span>
              );
            })}
          </CenterLayout>
        </LeftMainItem>
      </Mobile>
      <Pc>
        <Grid item sm={4}></Grid>
        <LeftMainItem item sm={4}>
          <MainTopTitleBox>인바디</MainTopTitleBox>
          <TopTitleBox>예약 서비스</TopTitleBox>
          <TopTitleBox>관리자 페이지</TopTitleBox>
          <SubMainBox item xs={12}></SubMainBox>
          <CenterLayout>
            {ADMIN_TYPE.map((item, index) => {
              return (
                <span key={index}>
                  <CustomButtonCommon onClick={() => onMoveSite(item.routes)}>
                    {item.text}
                  </CustomButtonCommon>
                </span>
              );
            })}
          </CenterLayout>
        </LeftMainItem>
        <Grid container item sm={4} alignItems="center"></Grid>
      </Pc>
    </Grid>
  );
}

export default AdminMainTable;
