import { Box, Chip, Grid, Modal, styled } from "@mui/material";
import { useState } from "react";
import { X } from "@phosphor-icons/react";
import InfoIcon from "@mui/icons-material/Info";
import {
  AppliedItem,
  MainTitle,
  ModalStyle,
  RaffleContent,
} from "../../styles/theme";
import { VehicleInfo } from "../../system/types/Vehicle";
import { CustomText } from "../../styles/CustomText";

interface VehicleInfoModalProps {
  data?: VehicleInfo | null;
}

function VehicleInfoModal({ data }: VehicleInfoModalProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <InfoIcon
        color="action"
        fontSize="small"
        onClick={handleOpen}
        style={{ marginTop: "-3px", cursor: "pointer" }}
      />
      {open && (
        <Modal open={open} onClose={handleClose}>
          <Box sx={ModalStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RaffleContent>
                  <MainTitle>차량 정보</MainTitle>
                  <AppliedItem onClick={handleClose}>
                    <X size={32} />
                  </AppliedItem>
                </RaffleContent>
              </Grid>
              {data ? (
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    {/* <CustomText type="subtitle2">기본 정보</CustomText> */}
                    <Chip label="기본 정보" variant="outlined" />
                  </Grid>
                  <VehicleInfoBigBox container mt="10px">
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">이름</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.name}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">번호</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.number}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">종류</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.shapeType}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">크기</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.sizeType}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">탑승 인원</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.seater} 인승
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">연료</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.fuelType}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">용도</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.usageType ? data.usageType : "-"}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">누적 주행 거리</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.totalMileage.toLocaleString()} {data.unitType}
                      </Grid>
                    </VehicleInfoBox>
                  </VehicleInfoBigBox>
                  <Grid item xs={12} mt="20px">
                    {/* <CustomText type="subtitle2">기본 정보</CustomText> */}
                    <Chip label="보험 정보" variant="outlined" />
                  </Grid>
                  <VehicleInfoBigBox container mt="10px">
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">보험사명</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data.insurance.company}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">보험 나이</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {"만 " + data.insurance.applicableAge + " 세 이상"}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12}>
                      <Grid
                        container
                        justifyContent="center"
                        item
                        xs={4}
                        sm={2}
                      >
                        <CustomText type="subtitle">연락처</CustomText>
                      </Grid>
                      <Grid item xs={8} sm={10}>
                        {data.insurance.contact}
                      </Grid>
                    </VehicleInfoBox>
                  </VehicleInfoBigBox>
                  <Grid item xs={12} mt="20px">
                    <Chip label="담당자 정보" variant="outlined" />
                  </Grid>
                  <VehicleInfoBigBox container mt="10px">
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">이름</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data?.manager?.name}
                      </Grid>
                    </VehicleInfoBox>
                    <VehicleInfoBox container item xs={12} sm={6}>
                      <Grid container justifyContent="center" item xs={4}>
                        <CustomText type="subtitle">연락처</CustomText>
                      </Grid>
                      <Grid item xs={8}>
                        {data?.manager?.phone}
                      </Grid>
                    </VehicleInfoBox>
                  </VehicleInfoBigBox>
                </Grid>
              ) : (
                <Grid container justifyContent="center" mt="50px">
                  등록된 차량 정보가 없습니다.
                </Grid>
              )}
            </Grid>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default VehicleInfoModal;

const VehicleInfoBigBox = styled(Grid)({
  borderTop: "1px solid #DCDFE3",
});

const VehicleInfoBox = styled(Grid)({
  borderBottom: "1px solid #DCDFE3",
  padding: "0.7rem 0rem",
  fontSize: "1rem",
  height: "3rem",
});
