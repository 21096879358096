import { useEffect, useState } from "react";
import { Mobile, Pc } from "../../../pages/Layout/ResponsiveScreen";
import { AccommodationRezData } from "../../../system/types/Accommodation";
import AdminReservationCheckCard from "./ReservationCheck/AdminReservationCheckCard";
import AdminReservationCheckTable from "./ReservationCheck/AdminReservationCheckTable";
import {
  AppliedItem,
  BoardDropdownRight,
  DisabledItem,
  MainTitle,
  RaffleContent,
  TypeGap,
} from "../../../styles/theme";
import { ListBullets, SquaresFour, X } from "@phosphor-icons/react";
import { Grid } from "@mui/material";
import { useLoadingDispatch } from "../../../system/context/LoadingContext";
import { AccommodationApi } from "../../../system/api/AccommodationApi";
import { ErrorHandler } from "../../../system/ApiService";
import { ReservationSearchData } from "../../../system/types/Reservation";
import { isoDateFormatter } from "../../Common/IsoDateFormatter";
import AlertModal from "../../Common/AlertModal";
import { useRecoilValue } from "recoil";
import { ReservationStatusAtom } from "../../../system/atoms";

interface AccommodationRezProps {
  selectedAccommodationData: AccommodationRezData;
  handleClose: () => void;
  accommodationRegion: string;
}

function AdminCalendarModal({
  selectedAccommodationData,
  handleClose,
  accommodationRegion,
}: AccommodationRezProps) {
  const Loading = useLoadingDispatch();
  const [cardType, setCardType] = useState(false);
  const [reservationData, setReservationData] = useState<
    ReservationSearchData[]
  >([]);
  const onChangeCardType = () => {
    setCardType(!cardType);
  };
  const reservationStatus = useRecoilValue(ReservationStatusAtom);

  function refreshSelecctedAccommodationData() {
    if (accommodationRegion === "") {
      if (selectedAccommodationData.Id) {
        var now = new Date(selectedAccommodationData.Data.EndTime);
        Loading({ type: "LOADING" });
        AccommodationApi.getReservationSearch(
          selectedAccommodationData.Data.StartTime.split("T")[0],
          isoDateFormatter(
            new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
          ),
          selectedAccommodationData.Data.GroupId
        )
          .then((res) => {
            setReservationData(res.data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          });
      }
    } else {
      if (selectedAccommodationData.Id) {
        var now = new Date(selectedAccommodationData.Data.EndTime);
        Loading({ type: "LOADING" });
        AccommodationApi.getReservationSearch(
          selectedAccommodationData.Data.StartTime.split("T")[0],
          isoDateFormatter(
            new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
          ),
          selectedAccommodationData.Data.GroupId,
          selectedAccommodationData.Data.RoomId
        )
          .then((res) => {
            setReservationData(res.data);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            Loading({ type: "COMPLETE" });
          });
      }
    }
  }

  useEffect(() => {
    refreshSelecctedAccommodationData();
  }, [reservationStatus]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RaffleContent>
            <MainTitle>휴양소 예약 상세 정보</MainTitle>
            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>
        </Grid>
        <Grid item xs={12}>
          <Pc>
            <TypeGap>
              {cardType ? (
                <BoardDropdownRight>
                  <DisabledItem onClick={onChangeCardType}>
                    <ListBullets size={32} />{" "}
                  </DisabledItem>
                  <AppliedItem onClick={onChangeCardType}>
                    {" "}
                    <SquaresFour size={32} />
                  </AppliedItem>
                </BoardDropdownRight>
              ) : (
                <BoardDropdownRight>
                  {" "}
                  <AppliedItem onClick={onChangeCardType}>
                    <ListBullets size={32} />{" "}
                  </AppliedItem>
                  <DisabledItem onClick={onChangeCardType}>
                    {" "}
                    <SquaresFour size={32} />
                  </DisabledItem>
                </BoardDropdownRight>
              )}
            </TypeGap>
            {reservationData ? (
              <>
                {cardType ? (
                  <AdminReservationCheckCard
                    data={selectedAccommodationData}
                    adminData={reservationData}
                  />
                ) : (
                  <AdminReservationCheckTable
                    data={selectedAccommodationData}
                    adminData={reservationData}
                  />
                )}
              </>
            ) : (
              <>
                {" "}
                {cardType ? (
                  <AdminReservationCheckCard
                    data={selectedAccommodationData}
                    adminData={reservationData}
                  />
                ) : (
                  <AdminReservationCheckTable
                    data={selectedAccommodationData}
                    adminData={reservationData}
                  />
                )}
              </>
            )}
          </Pc>
          <Mobile>
            {reservationData ? (
              <>
                {" "}
                <AdminReservationCheckCard
                  data={selectedAccommodationData}
                  adminData={reservationData}
                />
              </>
            ) : (
              <AdminReservationCheckCard
                data={selectedAccommodationData}
                adminData={reservationData}
              />
            )}
          </Mobile>
        </Grid>
      </Grid>
    </>
  );
}

export default AdminCalendarModal;
