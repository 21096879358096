import { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

interface ContentProps {
  children: ReactNode;
}

export const Mobile = ({ children }: ContentProps) => {
  const isMobile = useMediaQuery({
    query: "(max-width:1200px)",
  });
  return <>{isMobile && children}</>;
};

export const Pc = ({ children }: ContentProps) => {
  const isPc = useMediaQuery({
    query: "(min-width:1201px)",
  });
  return <>{isPc && children}</>;
};

export const MobileSmall = ({ children }: ContentProps) => {
  const isMobileSmall = useMediaQuery({
    query: "(max-width:768px)",
  });
  return <>{isMobileSmall && children}</>;
};

export const Tablet = ({ children }: ContentProps) => {
  const isTablet = useMediaQuery({
    query: "(min-width:768px)",
  });
  return <>{isTablet && children}</>;
};

export const VehicleMobile = ({ children }: ContentProps) => {
  const isMobile = useMediaQuery({
    query: "(max-width:620px)",
  });
  return <>{isMobile && children}</>;
};

export const VehiclePc = ({ children }: ContentProps) => {
  const isPc = useMediaQuery({
    query: "(min-width:621px)",
  });
  return <>{isPc && children}</>;
};
