import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledBody, StyledHead1, StyledHead2 } from "../../../../styles/theme";
import { ReservationSearchData } from "../../../../system/types/Reservation";
import MyAccommodationChip from "../../../Common/Accommodation/MyAccommodationChip";
import AdminAccommodationButton from "../../../Common/Accommodation/AdminAccommodationButton";
import { CheckCircle } from "@phosphor-icons/react";

interface ReservationCheckTableProps {
  adminData: ReservationSearchData[];
}

function ReservationCheckTable({ adminData }: ReservationCheckTableProps) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHead1 align="center">위치</StyledHead1>
            <StyledHead2 align="center">기간</StyledHead2>
            <StyledHead1 align="center">이름</StyledHead1>
            <StyledHead1 align="center">상태</StyledHead1>
            <StyledHead1 align="center">관리자 예약 여부</StyledHead1>
            <StyledHead2 align="center"></StyledHead2>
          </TableRow>
        </TableHead>
        <TableBody>
          {adminData.map((item: ReservationSearchData, idx: number) => {
            return (
              <TableRow key={idx}>
                <StyledBody align="center">{item.accommodationName}</StyledBody>
                <StyledBody align="center">
                  {item.startDate} - {item.endDate}
                </StyledBody>
                <StyledBody align="center">{item.employeeName}</StyledBody>
                <StyledBody align="center">
                  {" "}
                  <MyAccommodationChip status={item.status} />
                </StyledBody>
                <StyledBody align="center">
                  {item.adminReservation && <CheckCircle size={32} />}{" "}
                </StyledBody>
                <StyledBody align="center">
                  <AdminAccommodationButton data={item} />
                </StyledBody>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default ReservationCheckTable;
