import { styled } from "@mui/material/styles";
import {
  BLUECONTENTS,
  GRAYNeutralDISABLED,
  GRAYPALEBACKGROUND1,
  GRAYPALEBORDER,
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  GREENACCENT,
  ORANGECONTENTS,
  REDTITLE,
} from "./color";
import { Autocomplete, FormControl, Grid, TableCell } from "@mui/material";
// 리마인더

// Pc
export const ReminderHeaderInfoBox = styled("div")(() => ({
  display: "flex",
  border: "1px solid #DCDFE3",
  borderRadius: "5px",
  alignItems: "center",
  height: "3rem",
  marginBottom: "10px",
  paddingLeft: "10px",
  color: GRAYPALETITLE,
}));

export const ReminderHeaderInfoBox2 = styled("div")(() => ({
  display: "flex",
  borderRadius: "5px",
  alignItems: "center",
  height: "3rem",
  marginBottom: "10px",
  paddingLeft: "10px",
  color: GRAYPALETITLE,
}));

export const RecentReminderTitleBox = styled("div")({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

export const RecentReminderTitle = styled("div")({
  width: "15%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  margin: "0px 5px",
  textAlign: "center",
});

export const RecentReminderSearchTitle = styled("div")({
  width: "10%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  margin: "0px 5px",
  textAlign: "center",
});

export const RecentReminderContents = styled("div")({
  width: "20%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  margin: "0px 5px",
  textAlign: "center",
});

export const ReminderTitleGreen = styled("div")({
  width: "15%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  margin: "0px 5px",
  textAlign: "center",
  color: GREENACCENT,
  fontWeight: 700,
});

export const ReminderTitleOrange = styled("div")({
  width: "30%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  margin: "0px 5px",
  textAlign: "center",
});

export const RecentReminderContent = styled("div")({
  width: "30%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",

  margin: "0px 5px 0px 6.6%",
});

export const RecentReminderSearchContent = styled("div")({
  width: "100%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "-3px",
});

// Mobile
export const MobileMainTitle = styled(Grid)(() => ({
  marginLeft: "15px",
  color: "#4B4F5A",
  fontWeight: "bold",
}));

export const RecentReminderCardBox = styled("div")(() => ({
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  margin: "1rem 15px",
  padding: "1rem 2rem",
  height: "140px",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const RecentReminderCardTitle = styled("div")({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  margin: "10px 0px",
});

export const ReminderCardTitleGreen = styled("div")({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: GREENACCENT,
  fontWeight: 700,
  fontSize: "1.2rem",
});

export const ReminderCardTitleOrange = styled("div")({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: ORANGECONTENTS,
  fontWeight: 600,
});

export const MobileSmallContentBox = styled("div")({
  margin: "0px 10px 0px 15px",
});

export const ReminderMobileSearchBox = styled("div")(() => ({
  display: "flex",
  border: "1px solid #DCDFE3",
  borderRadius: "5px",
  alignItems: "center",
  height: "3rem",
  margin: "1rem 15px",
  paddingLeft: "10px",
  color: GRAYPALETITLE,
}));
export const ReminderMobileSearchBox2 = styled("div")(() => ({
  display: "flex",
  border: "1px solid #DCDFE3",
  borderRadius: "5px",
  alignItems: "center",
  height: "3rem",
  margin: "1rem 15px",
  justifyContent: "center",
  color: GRAYPALETITLE,
}));
export const RecentReminderSearchMobileContent = styled("div")({
  width: "67%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "flex",
  justifyContent: "space-between",
  margin: "0px 5px",
});

export const ReminderMobileSearchContent = styled("div")({
  width: "70%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  margin: "0px 15px",
  textAlign: "center",
});

// modal
export const ReminderModalMainBox = styled("div")({
  marginTop: "5px",
  display: "flex",
  alignItems: "center",
});

export const ReminderModalMainStartBox = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  marginTop: "5px",
});

export const ReminderModalMainNoMarginBox = styled("div")({
  display: "flex",
  alignItems: "flex-start",
});
export const ReminderMobileAllDayBox = styled("div")({
  marginTop: "10px",
  marginLeft: "10px",
});

export const ReminderModalTitleBox = styled("div")({
  paddingLeft: "5px",
  marginTop: "10px",
  minWidth: "80px",
  marginRight: "10px",
  fontWeight: "700",
  color: GRAYPALETITLE,
});

export const ReminderModalTitleMarginBox = styled("div")({
  paddingLeft: "5px",
  marginTop: "20px",
  minWidth: "80px",
  marginRight: "10px",
  fontWeight: "700",
  color: GRAYPALETITLE,
});

export const ReminderModalTitleNoMarginBox = styled("div")({
  paddingLeft: "5px",
  minWidth: "70px",
  marginRight: "10px",
  fontWeight: "700",
  color: GRAYPALETITLE,
});

export const ReminderModalContentBox = styled("div")({
  marginTop: "10px",
  width: "100%",
});

export const ReminderModalContentNoMarginBox = styled("div")({
  width: "100%",
});

export const ReminderModalDateTimeBox = styled("div")({
  marginTop: "10px",
  width: "80%",
  display: "flex",
});
export const ReminderModalDateTimeLeftBox = styled("div")({
  marginRight: "10px",
});
export const ReminderModalDateTimeRightBox = styled("div")({
  marginLeft: "10px",
});

export const ReminderModalPicBox = styled("div")({
  marginTop: "10px",
  width: "30%",
  display: "flex",
  justifyContent: "space-between",
});

export const ReminderModalPicNoMarginBox = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
});
export const ReminderSelectedPicBox = styled("div")({
  width: "90%",
});

export const ReminderModalRepeatBox = styled("div")({
  backgroundColor: GRAYPALEBACKGROUND1,
  borderRadius: "8px",
  flexWrap: "wrap",
  marginTop: "10px",
  padding: "10px 0px 15px 5px",

  width: "100%",
});

export const ReminderModalDetailedRepeatBox = styled("div")({
  backgroundColor: GRAYPALEBACKGROUND1,
  borderRadius: "8px",
  flexWrap: "wrap",
  marginTop: "10px",
  padding: "10px 5px 15px 5px",
  width: "100%",
});

export const AutocompleteNameBox = styled("span")({
  minWidth: "50px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const AutocompleteBox = styled("span")({
  marginLeft: "10px",
  color: GRAYPALECONTENTS,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const AutocompleteTextBox = styled("span")({
  marginTop: "5px",
  marginRight: "10px",
  whiteSpace: "nowrap",
});

export const RecurrenceTitleBox = styled("div")({
  color: GRAYPALETITLE,
  fontWeight: "700",
  width: "75px",
});

export const RecurrenceContentBox = styled("div")({
  width: "100%",
});

export const RecurrenceDropdownList = styled("div")({
  display: "flex",
  alignItems: "baseline",
  flexWrap: "wrap",
});

export const RecurrenceDropdownComponent = styled("div")({
  width: "50px",
  marginRight: "5px",
});
export const RecurrenceDropdownMobileBox = styled("div")({
  width: "100%",
});
export const RecurrenceDropdownMobileComponent = styled("div")({
  width: "100%",
  marginBottom: "5px",
});

export const RecurrenceButtonBox = styled("div")({
  marginLeft: "-5px",
  marginTop: "10px",
});

export const RecurrenceButton = styled("button")({
  margin: "5px",
  backgroundColor: GRAYPALEBORDER,
  border: `1px solid ${GRAYPALEBORDER}`,
});

export const RecurrenceSelectedButton = styled("button")({
  margin: "5px",
  backgroundColor: BLUECONTENTS,
  border: `1px solid ${BLUECONTENTS}`,
  color: "#ffffff",
  fontWeight: "700",
});

export const ReminderSaveButtonBox = styled("div")({
  textAlign: "right",
  marginRight: "-5px",
});

export const ReminderModalLayout = styled("div")({
  margin: "15px 0px 10px 5px",
});

export const ReminderModalTopMarginLayout = styled("div")({
  marginTop: "10px",
});
export const ReminderAllDayText = styled("span")({
  marginLeft: "20px",
  color: GRAYPALETITLE,
});

export const ReminderPagination = styled("div")({
  marginTop: "30px",
  display: "flex",
  justifyContent: "center",
});

export const ReminderInfoContents = styled("span")({
  color: GRAYPALECONTENTS,
  fontSize: "1rem",
  marginLeft: "20px",
  fontWeight: "500",
});

export const ReminderInfoMobileContents = styled("div")({
  color: GRAYPALECONTENTS,
  fontSize: "1rem",
  marginLeft: "15px",
});

export const ReminderAutocomplete = styled(Autocomplete)(() => ({
  border: "1px solid #DCDFE3",
  borderRadius: "8px",
  "&:hover": { border: "1px solid #81848B" },
  "&:focus": { border: "1px solid #81848B", outline: "none" },
  "&:active": { border: "1px solid #81848B" },
}));

//  repeat

export const ReminderRepeatModalMainBox = styled("div")({
  display: "flex",
  alignItems: "baseline",
  marginLeft: "5px",
  flexWrap: "wrap",
});

export const ReminderRepeatModalMainCenterBox = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: "5px",
  flexWrap: "wrap",
});

export const ReminderRepeatModalTitleBox = styled("div")({
  marginTop: "5px",
  paddingLeft: "5px",
  minWidth: "70px",
  marginRight: "10px",
  fontWeight: "700",
  color: GRAYPALETITLE,
});

export const ReminderRepeatContentBox = styled("div")({
  padding: "0px 10px 0px 5px",
});

export const ReminderSelectedBox = styled("div")({
  paddingLeft: " 5px",
});

export const ReminderRepeatFormBox = styled(FormControl)({
  paddingLeft: "5px",
});

export const ReminderRepeatSecondContentBox = styled("div")({
  paddingLeft: "15px",
});

export const RepeatStartDatePeriodText = styled("div")({
  paddingLeft: "5px",
  color: REDTITLE,
});

export const AutoCompleteNoOptionsText = styled("div")({
  color: GRAYNeutralDISABLED,
  fontWeight: 300,
  fontSize: "1rem",
});

export const ReminderDayBox = styled("div")({
  display: "flex",
  alignItems: "baseline",
  marginBottom: "10px",
});

export const ReminderComponetWidth = styled("div")({
  width: "140px",
});

export const TooltipText = styled("div")({
  fontSize: "0.7rem",
});

export const ReminderSubHeaderAlign = styled("div")({
  display: "flex",
  alignItems: "center",
});
export const ReminderCardTopLayout = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const RemindermodalContenstBox = styled("div")(() => ({
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
}));

export const RemindermodalContenstBox2 = styled("div")(() => ({
  marginTop: "50px",
  display: "flex",
  justifyContent: "center",
}));

export const RemindermodalContentMaxWidth = styled("div")(() => ({
  width: "1000px",
}));

export const ReminderStyledBody = styled(TableCell)(({ theme }) => ({
  width: "750px",
}));

export const ReminderStyledBody2 = styled(TableCell)(({ theme }) => ({
  width: "250px",
}));
export const ReminderDetailWarning = styled("div")(() => ({
  fontSize: "0.875rem",
}));
