import { useState } from "react";
import {
  AppliedItem,
  MainTitle,
  ShowUploadImg,
  RaffleContent,
  ShowUploadFullImg,
} from "../../../styles/theme";

import { CheckOutConfirm } from "../../../system/types/Reservation";

import uuid from "react-uuid";

import { X } from "@phosphor-icons/react";
import {
  ModalNoDataText,
  ReportAfterImgBox,
  ReportAfterImgItem,
  ReportAfterUseBox,
  ReportAfterUseItemBox,
  SelectAccommodationText,
} from "../../../styles/accommodationStyle";

interface CheckOutModalProps {
  handleClose: () => void;
  data: CheckOutConfirm;
}

function CheckOutModalReport({ handleClose, data }: CheckOutModalProps) {
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const ImageViewer = (url: string) => {
    if (selectedImageUrl === url) {
      setSelectedImageUrl("");
    } else {
      setSelectedImageUrl(url);
    }
  };

  return (
    <>
      <RaffleContent>
        <MainTitle>사용 후 보고</MainTitle>
        <AppliedItem onClick={handleClose}>
          <X size={32} />
        </AppliedItem>
      </RaffleContent>
      <SelectAccommodationText>
        ※ 사진 클릭 시, 해당 사진을 원본 크기로 확인하실 수 있습니다.
      </SelectAccommodationText>
      {data ? (
        <ReportAfterUseBox>
          <ReportAfterUseItemBox>
            <ReportAfterImgBox>
              {data?.attachmentFiles.map((image, idx) => (
                <ReportAfterImgItem key={uuid()}>
                  <ShowUploadImg
                    src={image.url}
                    alt="reportImg"
                    onClick={() => {
                      ImageViewer(image.url);
                    }}
                  />
                </ReportAfterImgItem>
              ))}
            </ReportAfterImgBox>
          </ReportAfterUseItemBox>
          {selectedImageUrl && (
            <>
              <SelectAccommodationText>
                [ 선택된 사진 확인 ]
              </SelectAccommodationText>
              <ShowUploadFullImg src={selectedImageUrl} alt="fullReportImg" />
            </>
          )}
        </ReportAfterUseBox>
      ) : (
        <ReportAfterUseBox>
          <ModalNoDataText>등록된 사진이 없습니다.</ModalNoDataText>
        </ReportAfterUseBox>
      )}
    </>
  );
}

export default CheckOutModalReport;
