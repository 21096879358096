import { Grid } from "@mui/material";
import { MaxWidth } from "../../styles/theme";
import HomeIcon from "@mui/icons-material/Home";

// import HomeIcon from "@mui/icons-material/Home";
import { AdminRoutes } from "../../system/types/type";
import { CustomText } from "../../styles/CustomText";
import { useHistory } from "react-router-dom";
import { GRAYPALECONTENTS, GRAYPALETITLE } from "../../styles/color";

interface AdminTitleProps {
  titleName: string;
}

function AdminTitle({ titleName }: AdminTitleProps) {
  const history = useHistory();
  function moveToAdmin() {
    history.push({
      pathname: `${AdminRoutes.root}`,
    });
  }
  return (
    <MaxWidth>
      <Grid container alignItems="baseline">
        <CustomText
          type="subtitle2"
          color={GRAYPALECONTENTS}
          cursorPointer
          onClick={moveToAdmin}
          marginRight="10px"
        >
          <HomeIcon sx={{ mr: 1, fontSize: 28 }} />
          관리자 페이지 /
        </CustomText>
        <CustomText type="subtitle2" color={GRAYPALETITLE}>
          {titleName}
        </CustomText>
      </Grid>
    </MaxWidth>
  );
}

export default AdminTitle;
