import client from "../ApiService";
import HttpClient from "../http-client";
import { NoticeData } from "../types/Notice";

const baseUri: string = "/notice";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _NoticeApi extends _BasicApi {
  getNoticeData(type: string, page?: number, size?: number) {
    return this.client.get(
      `${baseUri}/${type}?page=${page}&size=${size}&sort=isMainNotice,desc&sort=createdAt,desc`
    );
  }
  createNotice(notice: NoticeData) {
    return this.client.post(`${baseUri}`, notice);
  }
  updateNotice(notice: NoticeData) {
    return this.client.put(`${baseUri}`, notice);
  }
  deleteNotice(id: number) {
    return this.client.delete(`${baseUri}`, { params: { id: id } });
  }
}

export const NoticeApi = new _NoticeApi(client);
