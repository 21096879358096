import client from "../ApiService";
import HttpClient from "../http-client";
import {
  DestinationBookmarkInfo,
  ResourceBookmarkInfo,
} from "../types/Bookmark";

const baseUri: string = "/bookmark";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _BookmarkApi extends _BasicApi {
  getBookmarkDestination() {
    return this.client.get(`${baseUri}/destination`);
  }
  createBookmarkDestination(bookmark: DestinationBookmarkInfo) {
    return this.client.post(`${baseUri}/destination`, bookmark);
  }
  updateBookmarkDestination(bookmark: DestinationBookmarkInfo) {
    return this.client.put(`${baseUri}/destination`, bookmark);
  }
  deleteBookmarkDestination(id: number) {
    return this.client.delete(`${baseUri}/destination`, {
      params: { bookmarkId: id },
    });
  }
  getBookmark(type: string) {
    return this.client.get(`${baseUri}/resource`, {
      params: { bookmarkType: type },
    });
  }
  createBookmark(bookmark: ResourceBookmarkInfo) {
    return this.client.post(`${baseUri}/resource`, bookmark);
  }
  updateBookmark(bookmark: ResourceBookmarkInfo) {
    return this.client.put(`${baseUri}/resource`, bookmark);
  }
  deleteBookmark(id: number) {
    return this.client.delete(`${baseUri}/resource`, {
      params: { bookmarkId: id },
    });
  }
}

export const BookmarkApi = new _BookmarkApi(client);
