import {
  RecurrenceButton,
  RecurrenceButtonBox,
  RecurrenceSelectedButton,
} from "../../styles/reminderStyle";
import _ from "lodash";
import { RecurrenceData } from "../../system/types/Reminder";
import { InitRecurrence } from "../../system/types/initObject";

interface SelectedDayProps {
  daysOfWeek: string[];
  setDaysOfWeek: (status: string[]) => void;
  recurrenceType: string;
  numberOfWeek: number;
  repeatData: RecurrenceData;
  setRepeatData: (status: RecurrenceData) => void;
  setRepeatPeriodStr: (status: string) => void;
}

function SelectedDay({
  daysOfWeek,
  setDaysOfWeek,
  recurrenceType,
  numberOfWeek,
  repeatData,
  setRepeatData,
  setRepeatPeriodStr,
}: SelectedDayProps) {
  const dayOfWeekList = ["1월", "2화", "3수", "4목", "5금", "6토", "7일"];
  const engAllDaysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  // 월,화,수,목,금,토,일 버튼 클릭 이벤트
  const btOnClick = (name: string) => {
    if (
      recurrenceType === "주" &&
      numberOfWeek === 1 &&
      daysOfWeek.length === 6 &&
      daysOfWeek.includes(name) === false
    ) {
      setRepeatPeriodStr("일");
      setRepeatData({
        ...InitRecurrence,
        recurrenceEndDate: repeatData.recurrenceEndDate,
        recurrenceRangeType: repeatData.recurrenceRangeType,
        daysOfWeek: engAllDaysOfWeek,
        recurrencePatternType: "DAILY",
      });
      return;
    }
    if (daysOfWeek.includes(name)) {
      if (daysOfWeek.length === 1 && name === daysOfWeek[0]) {
        let tmpDaysOfWeek = [];
        let now = new Date();
        let dayOfWeekNum = now.getDay() === 0 ? 6 : now.getDay() - 1;
        tmpDaysOfWeek.push(dayOfWeekList[dayOfWeekNum]);
        setDaysOfWeek(tmpDaysOfWeek);
      } else {
        setDaysOfWeek(
          _.remove(daysOfWeek, (n) => {
            return n !== name;
          })
        );
      }
    } else {
      let clonedDayOfWeek = _.cloneDeep(daysOfWeek);
      clonedDayOfWeek.push(name);
      clonedDayOfWeek.sort();
      setDaysOfWeek(clonedDayOfWeek);
    }
  };

  return (
    <RecurrenceButtonBox>
      {daysOfWeek.includes("7일") ? (
        <RecurrenceSelectedButton onClick={() => btOnClick("7일")}>
          일
        </RecurrenceSelectedButton>
      ) : (
        <RecurrenceButton onClick={() => btOnClick("7일")}>일</RecurrenceButton>
      )}
      {daysOfWeek.includes("1월") ? (
        <RecurrenceSelectedButton onClick={() => btOnClick("1월")}>
          월
        </RecurrenceSelectedButton>
      ) : (
        <RecurrenceButton onClick={() => btOnClick("1월")}>월</RecurrenceButton>
      )}

      {daysOfWeek.includes("2화") ? (
        <RecurrenceSelectedButton onClick={() => btOnClick("2화")}>
          화
        </RecurrenceSelectedButton>
      ) : (
        <RecurrenceButton onClick={() => btOnClick("2화")}>화</RecurrenceButton>
      )}
      {daysOfWeek.includes("3수") ? (
        <RecurrenceSelectedButton onClick={() => btOnClick("3수")}>
          수
        </RecurrenceSelectedButton>
      ) : (
        <RecurrenceButton onClick={() => btOnClick("3수")}>수</RecurrenceButton>
      )}
      {daysOfWeek.includes("4목") ? (
        <RecurrenceSelectedButton onClick={() => btOnClick("4목")}>
          목
        </RecurrenceSelectedButton>
      ) : (
        <RecurrenceButton onClick={() => btOnClick("4목")}>목</RecurrenceButton>
      )}
      {daysOfWeek.includes("5금") ? (
        <RecurrenceSelectedButton onClick={() => btOnClick("5금")}>
          금
        </RecurrenceSelectedButton>
      ) : (
        <RecurrenceButton onClick={() => btOnClick("5금")}>금</RecurrenceButton>
      )}
      {daysOfWeek.includes("6토") ? (
        <RecurrenceSelectedButton onClick={() => btOnClick("6토")}>
          토
        </RecurrenceSelectedButton>
      ) : (
        <RecurrenceButton onClick={() => btOnClick("6토")}>토</RecurrenceButton>
      )}
    </RecurrenceButtonBox>
  );
}

export default SelectedDay;
