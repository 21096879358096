import { Grid, Pagination } from "@mui/material";
import { AppliedItem, MainTitle, RaffleContent } from "../../styles/theme";
import { X } from "@phosphor-icons/react";
import { NoticeData } from "../../system/types/Notice";
import { useCallback, useEffect, useState } from "react";
import { NoticeApi } from "../../system/api/NoticeApi";
import { ErrorHandler } from "../../system/ApiService";
import AlertModal from "../Common/AlertModal";
import VehicleNoticePcTable from "./Notice/VehicleNoticePcTable";
import { CustomButtonGreen } from "../../styles/button";
import { useRecoilState } from "recoil";
import { RoleCheckAtom } from "../../system/atoms";
import VehicleNoticeDetail from "./Notice/VehicleNoticeDetail";
import VehicleNoticeEdit from "./Notice/VehicleNoticeEdit";
import { InitNoticeDetailData } from "../../system/types/initObject";

interface VehicleNoticeProps {
  handleClose: () => void;
  noticeState: string;
  setNoticeState: (dt: string) => void;
  selectedNotice: NoticeData;
  setSelectedNotice: (dt: NoticeData) => void;
  refreshMainData: () => void;
}

function VehicleNotice({
  handleClose,
  noticeState,
  setNoticeState,
  selectedNotice,
  setSelectedNotice,
  refreshMainData,
}: VehicleNoticeProps) {
  const [noticeData, setNoticeData] = useState<NoticeData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState(0);
  const [RoleCheck] = useRecoilState(RoleCheckAtom);
  const refreshData = useCallback(() => {
    NoticeApi.getNoticeData("차량", pageNumber, 10)
      .then((res) => {
        setNoticeData(res.data.notice);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [pageNumber]);

  useEffect(() => {
    if (noticeState === "table") {
      refreshData();
    }
  }, [pageNumber, noticeState, refreshData]);

  const onChangePaging = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value - 1);
  };

  const onChangeState = (changeState: string, selectedData: NoticeData) => {
    setNoticeState(changeState);
    setSelectedNotice(InitNoticeDetailData);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RaffleContent>
            <MainTitle>공지사항</MainTitle>
            <AppliedItem onClick={handleClose}>
              <X size={32} />
            </AppliedItem>
          </RaffleContent>
        </Grid>
      </Grid>
      {noticeState === "table" && (
        <Grid
          container
          justifyContent="right"
          mt="10px"
          style={{ minHeight: "30px" }}
        >
          {RoleCheck && (
            <CustomButtonGreen
              onClick={() => {
                onChangeState("edit", selectedNotice);
              }}
            >
              공지사항 작성
            </CustomButtonGreen>
          )}
        </Grid>
      )}

      {noticeState === "table" && (
        <VehicleNoticePcTable
          noticeData={noticeData}
          setSelectedNotice={setSelectedNotice}
          setNoticeState={setNoticeState}
        />
      )}

      {noticeState === "detail" && (
        <VehicleNoticeDetail
          data={selectedNotice}
          setNoticeState={setNoticeState}
        />
      )}
      {noticeState === "edit" && (
        <VehicleNoticeEdit
          data={selectedNotice}
          setNoticeState={setNoticeState}
          setData={setSelectedNotice}
          refreshMainData={refreshMainData}
        />
      )}
      {noticeState === "table" && (
        <Grid container justifyContent="center" mt="10px">
          <Pagination
            count={totalPage}
            variant="outlined"
            page={pageNumber + 1}
            showFirstButton
            showLastButton
            onChange={onChangePaging}
            style={{ textAlign: "center" }}
          />
        </Grid>
      )}
    </>
  );
}

export default VehicleNotice;
