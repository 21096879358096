export const PRIMARY = "#971B2F";
export const SECONDARY = "#5B6770";

// Gray Neutral
export const GRAYNeutralACCENT = "#252933";
export const GRAYNeutralTITLE = "#4B4F5A";
export const GRAYNeutralCONTENTS = "#81848B";
export const GRAYNeutralDISABLED = "#B2B4B8";
export const GRAYNeutralSTROKE = "#CFD0D1";
export const GRAYNeutralBORDER = "#E0E0E0";
export const GRAYNeutralBOX = "#EDEDED";
export const GRAYNeutralBACKGROUND2 = "#F4F4F4";
export const GRAYNeutralBACKGROUND1 = "#F8F8F8";

// Gray Pale
export const GRAYPALEACCENT = "#182031";
export const GRAYPALETITLE = "#3D4861";
export const GRAYPALECONTENTS = "#718093";
export const GRAYPALEDISABLED = "#B3BBC5";
export const GRAYPALESTROKE = "#C7CBD1";
export const GRAYPALEBORDER = "#DCDFE3";
export const GRAYPALEBOX = "#E9ECF0";
export const GRAYPALEBACKGROUND2 = "#F0F2F5";
export const GRAYPALEBACKGROUND1 = "#F7F8FA";

// RED
export const REDTITLE = "#CC1F3B";
export const REDCONTENTS = "#F25C75";
export const REDDISABLED = "#F78194";
export const REDSTROKE = "#FCB6C3";
export const REDBORDER = "#FFD4DC";
export const REDBOX = "#FFE6EB";
export const REDBACKGROUND = "#FFF0F3";

// GREEN
export const GREENACCENT = "#0A6649";
export const GREENTITLE = "#0E8A65";
export const GREENCONTENTS = "#47B998";
export const GREENDISABLED = "#70CCAF";
export const GREENSTROKE = "#9FE0CC";
export const GREENBORDER = "#CAEDE2";
export const GREENBOX = "#E4F7F1";
export const GREENBACKGROUND = "#F0FAF7";

// BLUE
export const BLUEACCENT = "#124FB3";
export const BLUETITLE = "#1E66D9";
export const BLUECONTENTS = "#4C8DF5";
export const BLUEDISABLED = "#7CABF7";
export const BLUESTROKE = "#B6D0FC";
export const BLUEBORDER = "#D4E4FF";
export const BLUEBOX = "#E6EFFF";
export const BLUEBACKGROUND = "#F0F6FF";

// ORANGE
export const ORANGEACCENT = "#A65800";
export const ORANGETITLE = "#D47100";
export const ORANGECONTENTS = "#EF8100";
export const ORANGEDISABLED = "#FFA947";
export const ORANGESTROKE = "#FFCD94";
export const ORANGEBORDER = "#FFDCB8";
export const ORANGEBOX = "#FFEAD1";
export const ORANGEBACKGROUND = "#FCF1E3";

// ACCOMMODATION
export const TOTALACCOMMODATION = "#000000";
export const YANGPYEONG = "#D47100";
export const GOSEONG = "#971B2F";
export const TAEAN = "#0A6649";
export const YEOSU = "#1E66D9";

// TICKET
export const GOLDTICKET = "#FE9923";
