import client from "../ApiService";
import HttpClient from "../http-client";

const baseUri: string = "/reservation";

class _BasicApi {
  public client: HttpClient;
  public constructor(client: HttpClient) {
    this.client = client;
  }
}

class _ReservationApi extends _BasicApi {
  getMyReservation(
    type: string,
    startDate: string | undefined,
    endDate: string | undefined,
    branchEmployeeId: number | null
  ) {
    return this.client.get(`${baseUri}/${type}`, {
      params: {
        startDate: startDate,
        endDate: endDate,
        branchEmployeeId: branchEmployeeId ? branchEmployeeId : null,
      },
    });
  }
}

export const ReservationApi = new _ReservationApi(client);
