import { Box, Grid, Modal } from "@mui/material";

import {
  CardReminderDataBox,
  CardDataContent,
  CardDataLayout,
  ModalStyle,
  CardDataPointerContent,
  CenterLayout,
  GrayPaleContentsText,
  CardDataContent2,
  CardDataCenterContent,
} from "../../styles/theme";
import { ReminderData } from "../../system/types/Reminder";
import {
  ReminderCardTitleGreen,
  ReminderCardTopLayout,
} from "../../styles/reminderStyle";
import MyAccommodationChipSmall from "../Common/Accommodation/MyAccommodationChipSmall";
import ReminderButton from "../Common/Reminder/ReminderButton";
import { useState } from "react";
import { InitReminderData } from "../../system/types/initObject";
import ReminderDetail from "./ReminderDetail";
import { useRecoilValue } from "recoil";
import { MyDataAtom } from "../../system/atoms";

interface ReminderCardProps {
  data: ReminderData[];
  refreshData?: () => void;
}

function ReminderCard({ data, refreshData }: ReminderCardProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalType, setModalType] = useState("");
  const [detailedData, setDetailedData] =
    useState<ReminderData>(InitReminderData);
  const movetoModal = (detailedData: ReminderData, type: string) => {
    handleOpen();
    setModalType(type);
    setDetailedData(detailedData);
  };
  const myData = useRecoilValue(MyDataAtom);
  return (
    <Grid item xs={12}>
      <CardDataLayout>
        {data.map((item, idx) => {
          return (
            <CardReminderDataBox key={idx}>
              <Grid item xs={12}>
                <CardDataContent>
                  <ReminderCardTopLayout>
                    <CenterLayout>
                      {item.status !== undefined ? (
                        <MyAccommodationChipSmall status={item.status} />
                      ) : null}
                    </CenterLayout>
                    <CenterLayout>
                      {item.status === "사용중" ? (
                        <>
                          {item.dday === 0 ? (
                            <ReminderCardTitleGreen>
                              D - DAY
                            </ReminderCardTitleGreen>
                          ) : (
                            <ReminderCardTitleGreen>
                              {" "}
                              D - {item.dday}
                            </ReminderCardTitleGreen>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                      <GrayPaleContentsText>
                        {item.nextEventDate}
                      </GrayPaleContentsText>
                    </CenterLayout>
                  </ReminderCardTopLayout>
                </CardDataContent>

                <CardDataPointerContent
                  onClick={() => movetoModal(item, "detail")}
                >
                  {item.title}
                </CardDataPointerContent>
                <CardDataContent2>
                  담당부서: {item?.pic?.department}
                </CardDataContent2>
                <CardDataContent2>
                  담당자: {item?.pic?.name}, 관리자: {item?.manager?.name}
                </CardDataContent2>
                <CardDataCenterContent>
                  <ReminderButton data={item} refreshData={refreshData} />
                </CardDataCenterContent>
              </Grid>
            </CardReminderDataBox>
          );
        })}
      </CardDataLayout>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <ReminderDetail data={detailedData} handleClose={handleClose} />
        </Box>
      </Modal>
    </Grid>
  );
}

export default ReminderCard;
