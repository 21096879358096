import { Grid, Modal } from "@mui/material";
import { Dispatch, createContext, useContext, useReducer } from "react";
import HashLoader from "react-spinners/HashLoader";
import { CustomText } from "../../styles/CustomText";
import { GRAYPALEBACKGROUND2 } from "../../styles/color";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "rgba(255, 255, 255, 0.7)",
  borderWidth: "10px",
};

type LoadingState = {
  loading: boolean;
  message: string | null;
};

type Action =
  | { type: "LOADING_MESSAGE"; message: string }
  | { type: "COMPLETE" }
  | { type: "LOADING" };
type LoadingDispatch = Dispatch<Action>;

const LoadingStateContext = createContext<LoadingState | undefined>(undefined);
const LoadingDispatchContext = createContext<LoadingDispatch | undefined>(
  undefined
);

function LoadingReducer(state: LoadingState, action: Action): LoadingState {
  switch (action.type) {
    case "LOADING_MESSAGE":
      return {
        loading: true,
        message: action.message,
      };
    case "LOADING":
      return {
        loading: true,
        message: "Loading...",
      };
    case "COMPLETE":
      return {
        loading: false,
        message: null,
      };
  }
}

export function LoadingContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(LoadingReducer, {
    loading: false,
    message: null,
  });

  return (
    <LoadingDispatchContext.Provider value={dispatch}>
      <LoadingStateContext.Provider value={state}>
        {children}
        <Modal
          open={state.loading}
          style={{
            width: "100%",
            height: "100%",
            textAlign: "center",
          }}
        >
          <div className="overlay-content">
            <div className="wrapper">
              <HashLoader
                color={"#971B2F"}
                loading={true}
                cssOverride={override}
                size={40}
              />
              {state?.message === "파일 업로드 중입니다." && (
                <Grid container justifyContent="center" mt="10px">
                  <Grid item xs={12}>
                    <CustomText type="subtitle2" color={GRAYPALEBACKGROUND2}>
                      {state.message}
                    </CustomText>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomText type="subtitle2" color={GRAYPALEBACKGROUND2}>
                      네트워크 상태에 따라 시간이 조금 걸릴 수 있습니다.
                    </CustomText>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </Modal>
      </LoadingStateContext.Provider>
    </LoadingDispatchContext.Provider>
  );
}

//custom hook
export function useLoadingState() {
  const state = useContext(LoadingStateContext);
  if (!state) throw new Error("LoadingStateContext not found");
  return state;
}

export function useLoadingDispatch() {
  const dispatch = useContext(LoadingDispatchContext);
  if (!dispatch) throw new Error("LoadingDispatchContext not found");
  return dispatch;
}
